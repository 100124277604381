import MaxBonusesApi from '@/services/configuration/MaxBonusesApi';

export interface MaxBonusesInterface {
    id: bigint;
    max_legend_bonus: bigint;
    max_rank_bonus: bigint;
    max_reward_bonus: bigint;
    max_subscription_bonus: bigint;
}

export class MaxBonusesDTO implements MaxBonusesInterface {
    id = 0n;
    max_legend_bonus = 0n;
    max_rank_bonus = 0n;
    max_reward_bonus = 0n;
    max_subscription_bonus = 0n;
}

export default class MaxBonuses extends MaxBonusesDTO {
    static api = MaxBonusesApi;

    constructor(DTO: MaxBonusesDTO){
        super();
        Object.assign(this, DTO);
    }
}
