import AuthApi from "@/services/auth/AuthApi";
import store from "@/store"
import { logout } from "@/mixins/logout";

export default async function (): Promise<void> {
    const accessToken = localStorage.getItem("access_token");
    const refreshToken = localStorage.getItem("refresh_token");
    let expiresAt = localStorage.getItem("expiry_date");

    if (!accessToken || !expiresAt || !refreshToken) {
        return await logout.methods.logout();
    } 

    expiresAt = atob(expiresAt);
    const now = new Date();
    const expiryDate = new Date(expiresAt);

    if (now < expiryDate) {
        store.commit('setAuthenticated', true);
        return;
    }

    const response = await AuthApi.refreshToken(refreshToken)
        .catch(async error => {
            console.warn(`Refresh Token API failed.`);
            console.log(error.response);
            await logout.methods.logout();
        });

    if (!response || !response.data) {
        return await logout.methods.logout();
    }

    const date = new Date();
    const newExpiryDate = new Date(date.getTime() + response.data.expires_in * 1000);

    localStorage.setItem('access_token', response.data.access_token);
    localStorage.setItem('refresh_token', response.data.refresh_token);
    localStorage.setItem('expiry_date', btoa(newExpiryDate.toString()));

    store.commit('setAuthenticated', true);
}