<template>
    <div>
        <div style="margin-top: -36px;">
            <v-row align="center">
                <v-col
                    cols="12"
                    class="text-center text-sm-right"
                >
                    <v-btn
                        :to="`${$store.getters.arena ? `/${titleToKebabCase($store.getters.arena.name)}` : ''}/quests`"
                        :block="$vuetify.breakpoint.xsOnly"
                        color="primary"
                        class="global-button mr-2 mb-2 mb-sm-0"
                    >
                        <v-icon left>mdi-trophy</v-icon>
                        {{ $t('entities.quests.title') }}
                    </v-btn>
                    <v-btn
                        :to="`${$store.getters.arena ? `/${titleToKebabCase($store.getters.arena.name)}` : ''}/quest-groups`"
                        :block="$vuetify.breakpoint.xsOnly"
                        color="primary"
                        class="global-button"
                    >
                        <v-icon left>category</v-icon>
                        {{ $t('entities.questGroups.title') }}
                    </v-btn>
                </v-col>
            </v-row>
        </div>
        <v-card class="elevation-2">
            <DataTableHeader
                :pagination="pagination"
                :searchAttributes="searchAttributes"
                :selectedSearchAttributes.sync="selectedSearchAttributes"
                :disabled="loading"
            />
            <DataTableSkeletonLoader :loading="initialLoad"/>
            <v-data-table
                v-show="!initialLoad"
                :headers="headers"
                :items="entities"
                :dense="$vuetify.breakpoint.xs && !loading"
                :loading="loading"
                :loading-text="$t('components.noData.fetchingEntries')"
                :options.sync="options"
                :server-items-length="pagination.total"
                :items-per-page="pagination.per_page"
                :header-props="{ sortIcon: 'arrow_circle_up', sortByText: $t('miscellaneous.sortBy') }"
                :class="{ 'mobile-empty-datatable' : $vuetify.breakpoint.xsOnly }"
                hide-default-footer
            >
                <template v-slot:top>
                    <v-toolbar flat>
                        <v-toolbar-title>{{ $t('entities.questTypes.title') }}</v-toolbar-title>
                        <v-divider
                            class="mx-4"
                            inset
                            vertical
                        ></v-divider>
                        <small class="mr-2">{{ pagination.total }}</small>
                        <RefreshButton
                            :refresh="refresh"
                            :loading="loading"
                        />
                        <v-spacer></v-spacer>
                        <v-dialog
                            v-model="viewDialog"
                            max-width="1000px"
                        >
                            <v-card>
                                <v-card-text class="px-0 pb-0">
                                    <v-toolbar
                                        class="elevation-2"
                                        color="primary"
                                    >
                                        <v-toolbar-title class="ml-0">
                                            <span class="font-weight-bold white--text">{{ $t('entities.quests.viewEntity') }}</span>
                                        </v-toolbar-title>
                                        <v-spacer/>
                                        <v-btn
                                            @click="viewDialog = false"
                                            icon
                                            dark
                                        >
                                            <v-icon>mdi-close</v-icon>
                                        </v-btn>
                                    </v-toolbar>
                                    <v-container>
                                        <DialogImage
                                            :image="editedEntity.image"
                                            :name="editedEntity.name"
                                        />
                                        <v-card>
                                            <v-simple-table
                                                class="do-not-apply-hover"
                                                dense
                                            >
                                                <template v-slot:default>
                                                    <tbody>
                                                        <tr>
                                                            <td>{{ $t('generalAttributes.name') }}</td>
                                                            <td class="font-weight-bold">
                                                                {{ editedEntity.name }}
                                                            </td>
                                                            <td>{{ $t('entities.achievements.attributes.active') }}</td>
                                                            <td class="font-weight-bold">
                                                                <Boolean :boolean="editedEntity.active"/>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>{{ $t('generalAttributes.description') }}</td>
                                                            <td
                                                                colspan="3"
                                                                class="font-weight-bold"
                                                            >
                                                                {{ editedEntity.description || '/' }}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </template>
                                            </v-simple-table>
                                            <template v-if="editedEntity.quests.length">
                                                <v-card
                                                    v-for="pool in editedEntity.quests"
                                                    :key="`${pool.pool_type}-0`"
                                                    class="mx-4 mt-6"
                                                >
                                                    <v-simple-table
                                                        class="do-not-apply-hover"
                                                        dense
                                                    >
                                                        <template v-slot:default>
                                                            <tbody>
                                                                <template>
                                                                    <tr>
                                                                        <td
                                                                            colspan="4"
                                                                            class="do-not-apply-hover mx-0 px-0"
                                                                        >
                                                                            <v-toolbar
                                                                                class="elevation-2"
                                                                                color="primary"
                                                                            >
                                                                                <v-toolbar-title class="ml-0">
                                                                                    <span class="font-weight-bold white--text">{{ $t(`entities.quests.${pool.pool_type.toLowerCase()}`) }}</span>
                                                                                </v-toolbar-title>
                                                                            </v-toolbar>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>{{ $t('generalAttributes.name') }}</td>
                                                                        <td>{{ pool.name || '/' }}</td>
                                                                        <td>{{ $t('miscellaneous.image') }}</td>
                                                                        <td class="font-weight-bold">
                                                                            <template v-if="pool.image">
                                                                                <img
                                                                                    :src="pool.image"
                                                                                    :alt="pool.pool_type"
                                                                                    width="20px"
                                                                                />
                                                                            </template>
                                                                            <template v-else>
                                                                                /
                                                                            </template>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>{{ $t('entities.achievements.attributes.task') }}</td>
                                                                        <td class="font-weight-bold">
                                                                            {{ pool.task }}
                                                                        </td>
                                                                        <td>{{ $t('entities.quests.attributes.expiryDatetime') }}</td>
                                                                        <td class="font-weight-bold">
                                                                            {{ formatDate(new Date(pool.ending_date_time), true, true) }}
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>{{ $t('entities.achievements.attributes.active') }}</td>
                                                                        <td 
                                                                            colspan="3"
                                                                            class="font-weight-bold"
                                                                        >
                                                                            <Boolean :boolean="pool.active"/>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td colspan="4">{{ $t('entities.quests.attributes.rewards') }}</td>
                                                                    </tr>
                                                                    <template v-for="(reward, index) in pool.rewards">
                                                                        <tr :key="`reward-${reward.id}-${index}`">
                                                                            <td 
                                                                                class="pl-10"
                                                                                colspan="4"
                                                                            >
                                                                                {{ reward.quantity }}x {{ reward.name }}
                                                                            </td>
                                                                        </tr>
                                                                    </template>
                                                                </template>
                                                            </tbody>
                                                        </template>
                                                    </v-simple-table>
                                                    <AchievableConfiguration
                                                        :viewDialog="viewDialog"
                                                        :settings="pool.configuration.settings"
                                                    />
                                                </v-card>
                                            </template>
                                            <v-row class="mt-6 px-4">
                                                <v-col
                                                    cols="12"
                                                    sm="4"
                                                    class="text-center py-0"
                                                >
                                                    <RowsPerPage :pagination="usersPagination"/>
                                                </v-col>
                                                <v-col
                                                    cols="12"
                                                    sm="4"
                                                    class="text-center py-0"
                                                >
                                                    <v-text-field
                                                        v-model="usersPagination.search"
                                                        @click:append="() => (usersPagination.search = '')"
                                                        @keydown.native.escape="usersPagination.search = ''"
                                                        :label="userSelectedSearchAttributes.length ? $t('components.dataTableHeader.search') : $t('components.dataTableHeader.searchDisabled')"
                                                        :disabled="!userSelectedSearchAttributes.length"
                                                        :append-icon="usersPagination.search ? 'close': ''"
                                                        prepend-inner-icon="search"
                                                        class="search mb-1"
                                                        hide-details
                                                        solo
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col
                                                    cols="12"
                                                    sm="4"
                                                    class="text-center py-0"
                                                >
                                                    <v-select
                                                        @change="userSelectedSearchAttributes = $event"
                                                        :items="userSearchAttributes"
                                                        :value="userSelectedSearchAttributes"
                                                        :label="$t('components.dataTableHeader.searchBy')"
                                                        :menu-props="{ bottom: true, offsetY: true }"
                                                        :no-data-text="$t('components.dataTableHeader.noDataAvailable')"
                                                        item-text="label"
                                                        item-value="value"
                                                        multiple
                                                        solo
                                                    >
                                                        <template v-slot:selection="data">
                                                            <v-chip
                                                                color="primary"
                                                                small
                                                            >
                                                                {{ data.item.label }}
                                                            </v-chip>
                                                        </template>
                                                    </v-select>
                                                </v-col>
                                            </v-row>
                                            <v-data-table
                                                :headers="achievedHeaders"
                                                :items="editedEntity.users"
                                                :options.sync="usersPagination"
                                                :server-items-length="usersPagination.total"
                                                :items-per-page="usersPagination.per_page"
                                                :header-props="{ sortIcon: 'arrow_circle_up', sortByText: $t('miscellaneous.sortBy') }"
                                                :no-data-text="$t('components.dataTableHeader.noDataAvailable')"
                                                :footer-props="{ itemsPerPageText : $t('components.rowsPerPage.rows'), itemsPerPageAllText: $t('components.rowsPerPage.all') }"
                                                class="achievements-users-table do-not-apply-hover px-2"
                                                hide-default-footer
                                                dense
                                            >
                                                <template v-slot:top>
                                                    <v-toolbar flat>
                                                        <v-toolbar-title>{{ $t('entities.users.title') }}</v-toolbar-title>
                                                        <v-divider
                                                            class="mx-4"
                                                            inset
                                                            vertical
                                                        ></v-divider>
                                                        <small>{{ usersPagination.total }}</small>
                                                        <v-spacer></v-spacer>
                                                    </v-toolbar>
                                                </template>
                                                <template v-slot:item.username="{ item }">
                                                    {{ item.username || '/' }}
                                                </template>
                                                <template v-slot:item.quest.pool_type="{ item }">
                                                    <v-chip
                                                        color="primary"
                                                        class="font-weight-bold mr-2"
                                                        text-color="white"
                                                        small
                                                    >
                                                        {{ $t(`entities.quests.${item.quest.pool_type.toLowerCase()}`) }}
                                                    </v-chip>
                                                </template>
                                                <template v-slot:item.created_at="{ item }">
                                                    <span v-if="item.created_at">
                                                        {{ fromUtcDateTimeToCurrent(item.created_at, true, true) }}
                                                    </span>
                                                    <span v-else>
                                                        /
                                                    </span>
                                                </template>
                                                <template v-slot:item.progress.value="{ item }">
                                                    <span>{{ Math.round(item.progress.value * 100) / 100 }}%</span>
                                                </template>
                                                <template v-slot:item.progress.updated_at="{ item }">
                                                    <span v-if="item.progress.updated_at">
                                                        {{ fromUtcDateTimeToCurrent(item.progress.updated_at, true, true) }}
                                                    </span>
                                                    <span v-else>
                                                        /
                                                    </span>
                                                </template>
                                                <template v-slot:no-data>
                                                    <template v-if="usersPagination.search.length && searchingUser">
                                                        {{ $t('entities.users.fetching') }}
                                                    </template>
                                                    <template v-else-if="usersPagination.search.length && !editedEntity.users.length">
                                                        {{ $t('components.noData.noEntriesFound') }}
                                                    </template>
                                                    <template v-else>
                                                        {{ $t('purchase.searchUser') }}
                                                    </template>
                                                </template>
                                                <template v-slot:footer.page-text="items">
                                                    {{ items.pageStart }} - {{ items.pageStop }} {{ $t('components.pagination.of') }} {{ items.itemsLength }}
                                                </template>
                                            </v-data-table>
                                            <DataTableFooter :pagination="usersPagination"/>
                                        </v-card>
                                    </v-container>
                                </v-card-text>
                                <v-divider/>
                                <v-card-actions>
                                    <v-spacer/>
                                    <v-btn
                                        @click="viewDialog = false"
                                        color="dark darken-1"
                                        text
                                    >
                                        {{ $t('miscellaneous.ok') }}
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                        <v-dialog
                            v-model="editDialog"
                            max-width="1000px"
                        >
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    v-on="on"
                                    color="primary"
                                    class="mb-2"
                                    dark
                                >
                                    {{ $t('miscellaneous.add') }}
                                </v-btn>
                            </template>
                            <v-card>
                                <v-card-text class="px-0 pb-0">
                                    <EditToolbar
                                        :formTitle="formTitle"
                                        :closeEditDialog="closeEditDialog"
                                    />
                                    <v-container>
                                        <v-card class="px-4 pb-4">
                                            <v-form
                                                @submit.prevent="save"
                                                method="POST"
                                                ref="form"
                                            >
                                                <RequiredFields/>
                                                <DialogImage
                                                    :image="editedEntity.image"
                                                    :name="editedEntity.name"
                                                />
                                                <v-row
                                                    align="center"
                                                    class="mt-2"
                                                >
                                                    <v-col
                                                        cols="12"
                                                        sm="6"
                                                        class="position-relative"
                                                    >
                                                        <v-text-field
                                                            :value="editedEntity.name"
                                                            @change="v => (editedEntity.name = v)"
                                                            :rules="[required]"
                                                            :label="$t('generalAttributes.name')"
                                                            prepend-icon="mdi-format-title"
                                                        ></v-text-field>
                                                        <span class="required-fields">*</span>
                                                    </v-col>
                                                    <v-col
                                                        cols="12"
                                                        sm="6"
                                                        class="position-relative"
                                                    >
                                                        <v-flex>
                                                            <ImageInput
                                                                :file.sync="file"
                                                                :alreadyHasImage="editedEntity.image"
                                                            />
                                                        </v-flex>
                                                    </v-col>
                                                    <v-col
                                                        cols="12"
                                                        sm="9"
                                                    >
                                                        <v-text-field
                                                            :value="editedEntity.description"
                                                            @change="v => (editedEntity.description = v)"
                                                            :label="$t('generalAttributes.description')"
                                                            prepend-icon="short_text"
                                                        ></v-text-field>
                                                    </v-col>
                                                    <v-col
                                                        cols="12"
                                                        sm="3"
                                                    >
                                                        <v-switch
                                                            :input-value="editedEntity.active"
                                                            @change="v => (editedEntity.active = v)"
                                                            :label="$t('entities.quests.attributes.active')"
                                                            prepend-icon="done"
                                                            inset
                                                        ></v-switch>
                                                    </v-col>
                                                    <v-col cols="12">
                                                        <v-select
                                                            :value="editedEntity.group ? editedEntity.group.id : null"
                                                            @change="updateGroup($event)"
                                                            :items="groups"
                                                            :rules="[v => !!v || $t('validation.required')]"
                                                            :menu-props="{ bottom: true, offsetY: true }"
                                                            item-text="name"
                                                            item-value="id"
                                                            class="do-not-apply-transform"
                                                            prepend-inner-icon="category"
                                                            return-object
                                                            solo
                                                        >
                                                            <template v-slot:label>
                                                                {{ $t('entities.achievements.selectGroup') }}
                                                                <span :style="{ color: 'red' }">*</span>
                                                            </template>
                                                            <template v-slot:selection="data">
                                                                {{ $t('entities.questGroups.entity') }}: {{ data.item.name }}
                                                            </template>
                                                        </v-select>
                                                    </v-col>
                                                </v-row>
                                                <v-row>
                                                    <template v-if="editedEntity.quests.length">
                                                        <v-card
                                                            v-for="(value, poolIndex) in editedEntity.quests"
                                                            :key="`pool-${poolIndex}`"
                                                            :class="{ 'mt-10' : poolIndex !== 0 }"
                                                            class="mx-4"
                                                        >
                                                            <v-col cols="12">
                                                                <v-row align="center">
                                                                    <v-col
                                                                        cols="12"
                                                                        class="text-center px-0"
                                                                    >
                                                                        <v-toolbar
                                                                            class="elevation-2"
                                                                            color="primary"
                                                                        >
                                                                            <v-toolbar-title class="ml-0">
                                                                                <span class="font-weight-bold white--text">
                                                                                    {{ $t(`entities.quests.${editedEntity.quests[poolIndex].pool_type.toLowerCase()}`) }}
                                                                                </span>
                                                                            </v-toolbar-title>
                                                                            <v-spacer></v-spacer>
                                                                            <v-btn
                                                                                @click="removePool(poolIndex)"
                                                                                color="error"
                                                                                rounded
                                                                            >
                                                                                X
                                                                            </v-btn>
                                                                        </v-toolbar>
                                                                    </v-col>
                                                                </v-row>
                                                                <v-row 
                                                                    v-if="editDialog"
                                                                    align="center"
                                                                >
                                                                    <v-col
                                                                        cols="12"
                                                                        sm="12"
                                                                        class="position-relative"
                                                                    >
                                                                        <v-text-field
                                                                            :value="editedEntity.quests[poolIndex].name"
                                                                            @change="v => (editedEntity.quests[poolIndex].name = v)"
                                                                            :rules="[required]"
                                                                            :label="$t('generalAttributes.name')"
                                                                            prepend-icon="mdi-format-title"
                                                                        ></v-text-field>
                                                                        <span class="required-fields">*</span>
                                                                    </v-col>
                                                                    <v-col
                                                                        cols="12"
                                                                        sm="9"
                                                                        class="position-relative py-0"
                                                                    >
                                                                        <v-text-field
                                                                            :value="editedEntity.quests[poolIndex].task"
                                                                            @change="v => (editedEntity.quests[poolIndex].task = v)"
                                                                            :rules="[required]"
                                                                            :label="$t('entities.achievements.attributes.task')"
                                                                            prepend-icon="mdi-format-title"
                                                                        ></v-text-field>
                                                                        <span class="required-fields">*</span>
                                                                    </v-col>
                                                                    <v-col
                                                                        cols="12"
                                                                        sm="3"
                                                                        class="py-0"
                                                                    >
                                                                        <v-switch
                                                                            :input-value="editedEntity.quests[poolIndex].active"
                                                                            @change="v => (editedEntity.quests[poolIndex].active = v)"
                                                                            :label="$t('entities.quests.attributes.active')"
                                                                            prepend-icon="done"
                                                                            inset
                                                                        ></v-switch>
                                                                    </v-col>
                                                                    <v-col
                                                                        cols="12"
                                                                        class="mt-4 py-0"
                                                                    >
                                                                        <v-select
                                                                            :value="editedEntity.quests[poolIndex].configuration.settings.type"
                                                                            @change="editedEntity.quests[poolIndex].configuration.settings.type = $event"
                                                                            :items="types"
                                                                            :rules="[v => !!v || $t('validation.required')]"
                                                                            :menu-props="{ bottom: true, offsetY: true }"
                                                                            item-text="name"
                                                                            item-value="id"
                                                                            class="do-not-apply-transform"
                                                                            prepend-inner-icon="category"
                                                                            return-object
                                                                            solo
                                                                        >
                                                                            <template v-slot:label>
                                                                                {{ $t(`entities.achievements.type`) }}
                                                                                <span :style="{ color: 'red' }">*</span>
                                                                            </template>
                                                                            <template v-slot:selection="data">
                                                                                {{ $t(`entities.achievements.type`) }}:
                                                                                <PrimaryChip :text="$t(`entities.achievements.${data.item.toLowerCase()}`)"/>
                                                                            </template>
                                                                            <template v-slot:item="data">
                                                                                <PrimaryChip
                                                                                    :text="$t(`entities.achievements.${data.item.toLowerCase()}`)"
                                                                                    margin="ml-0"
                                                                                />
                                                                            </template>
                                                                        </v-select>
                                                                    </v-col>
                                                                    <template v-if="editedEntity.quests[poolIndex].configuration.settings.type === 'RECURRENT' && editedEntity.quests[poolIndex].configuration.settings.period">
                                                                        <v-col
                                                                            cols="12"
                                                                            sm="4"
                                                                            class="pb-0"
                                                                        >
                                                                            <v-select
                                                                                :value="editedEntity.quests[poolIndex].configuration.settings.period.inner_type"
                                                                                @change="editedEntity.quests[poolIndex].configuration.settings.period.inner_type = $event"
                                                                                :items="innerTypes"
                                                                                :rules="[v => !!v || $t('validation.required')]"
                                                                                :menu-props="{ bottom: true, offsetY: true }"
                                                                                item-text="name"
                                                                                item-value="id"
                                                                                class="do-not-apply-transform"
                                                                                prepend-inner-icon="category"
                                                                                return-object
                                                                                solo
                                                                            >
                                                                                <template v-slot:label>
                                                                                    {{ $t(`entities.achievements.type`) }}
                                                                                    <span :style="{ color: 'red' }">*</span>
                                                                                </template>
                                                                                <template v-slot:selection="data">
                                                                                    {{ $t(`entities.achievements.innerType`) }}:
                                                                                    <PrimaryChip :text="$t(`entities.achievements.${data.item.toLowerCase()}`)"/>
                                                                                </template>
                                                                                <template v-slot:item="data">
                                                                                    <PrimaryChip :text="$t(`entities.achievements.${data.item.toLowerCase()}`)"/>
                                                                                </template>
                                                                            </v-select>
                                                                        </v-col>
                                                                        <v-col
                                                                            cols="12"
                                                                            sm="3"
                                                                            class="pb-0"
                                                                        >
                                                                            <v-select
                                                                                :value="editedEntity.quests[poolIndex].configuration.settings.period.unit"
                                                                                @change="editedEntity.quests[poolIndex].configuration.settings.period.unit = $event"
                                                                                :items="units"
                                                                                :rules="[v => !!v || $t('validation.required')]"
                                                                                :menu-props="{ bottom: true, offsetY: true }"
                                                                                item-text="name"
                                                                                item-value="id"
                                                                                class="do-not-apply-transform"
                                                                                prepend-inner-icon="category"
                                                                                return-object
                                                                                solo
                                                                            >
                                                                                <template v-slot:label>
                                                                                    {{ $t(`entities.achievements.unit`) }}
                                                                                    <span :style="{ color: 'red' }">*</span>
                                                                                </template>
                                                                                <template v-slot:selection="data">
                                                                                    {{ $t(`entities.achievements.unit`) }}: {{ $t(`miscellaneous.units.${data.item.toLowerCase()}`) }}
                                                                                </template>
                                                                                <template v-slot:item="data">
                                                                                    {{ $t(`miscellaneous.units.${data.item.toLowerCase()}`) }}
                                                                                </template>
                                                                            </v-select>
                                                                        </v-col>
                                                                        <v-col
                                                                            cols="12"
                                                                            sm="2"
                                                                            class="position-relative"
                                                                        >
                                                                            <v-text-field
                                                                                :value="editedEntity.quests[poolIndex].configuration.settings.period.count"
                                                                                @change="v => (editedEntity.quests[poolIndex].configuration.settings.period.count = v)"
                                                                                :rules="[integer, min]"
                                                                                :label="$t('entities.achievements.count')"
                                                                                type="number"
                                                                                prepend-icon="looks_one"
                                                                            >
                                                                            </v-text-field>
                                                                            <span class="required-fields">*</span>
                                                                        </v-col>
                                                                        <v-col
                                                                            cols="12"
                                                                            sm="3"
                                                                        >
                                                                            <v-switch
                                                                                :input-value="editedEntity.quests[poolIndex].configuration.settings.period.consecutive"
                                                                                @change="v => (editedEntity.quests[poolIndex].configuration.settings.period.consecutive = v)"
                                                                                :label="$t('entities.achievements.consecutive')"
                                                                                prepend-icon="done"
                                                                                inset
                                                                            ></v-switch>
                                                                        </v-col>
                                                                    </template>
                                                                    <v-col
                                                                        v-for="(value, conditionIndex) in editedEntity.quests[poolIndex].configuration.settings.conditions"
                                                                        :key="`condition-${conditionIndex}`"
                                                                        cols="12"
                                                                        class="py-0"
                                                                    >
                                                                        <v-row align="center">
                                                                            <v-col
                                                                                v-if="conditionIndex === 0"
                                                                                cols="12"
                                                                                class="text-left"
                                                                            >
                                                                                <h3>
                                                                                    {{ $tc('entities.quests.attributes.condition', multipleConditions(poolIndex) ? 2 : 1) }}
                                                                                </h3>
                                                                            </v-col>
                                                                        </v-row>
                                                                        <v-card
                                                                            v-if="conditionIndex < 1 ? true : multipleConditions(poolIndex)"
                                                                            class="my-4 pa-4"
                                                                        >
                                                                            <v-row align="center">
                                                                                <v-col
                                                                                    cols="12"
                                                                                    sm="6"
                                                                                    class="position-relative"
                                                                                >
                                                                                    <v-select
                                                                                        :value="editedEntity.quests[poolIndex].configuration.settings.conditions[conditionIndex].context ? editedEntity.quests[poolIndex].configuration.settings.conditions[conditionIndex].context.id : null"
                                                                                        @change="editedEntity.quests[poolIndex].configuration.settings.conditions[conditionIndex].context = $event"
                                                                                        :items="editedEntity.group && editedEntity.group.id ? editedEntity.group.contexts : []"
                                                                                        :rules="[v => !!v || $t('validation.required')]"
                                                                                        :menu-props="{ bottom: true, offsetY: true }"
                                                                                        item-text="strategy"
                                                                                        item-value="id"
                                                                                        class="do-not-apply-transform"
                                                                                        prepend-inner-icon="category"
                                                                                        return-object
                                                                                        solo
                                                                                    >
                                                                                        <template v-slot:label>
                                                                                            {{ $t('entities.achievements.selectContext') }}
                                                                                            <span :style="{ color: 'red' }">*</span>
                                                                                        </template>
                                                                                        <template v-slot:selection="data">
                                                                                            {{ $tc('entities.achievements.context', 1) }}: {{ data.item.strategy }}
                                                                                        </template>
                                                                                    </v-select>
                                                                                </v-col>
                                                                                <v-col
                                                                                    cols="12"
                                                                                    :sm="conditionIndex === 0 ? 6 : 5"
                                                                                    class="position-relative"
                                                                                >
                                                                                    <v-text-field
                                                                                        :value="editedEntity.quests[poolIndex].configuration.settings.conditions[conditionIndex].value"
                                                                                        @change="v => (editedEntity.quests[poolIndex].configuration.settings.conditions[conditionIndex].value = v)"
                                                                                        :rules="[required, integer, min]"
                                                                                        :label="$t('entities.achievements.value')"
                                                                                        type="number"
                                                                                        prepend-icon="looks_one"
                                                                                    ></v-text-field>
                                                                                    <span class="required-fields">*</span>
                                                                                </v-col>
                                                                                <v-col
                                                                                    v-if="conditionIndex !== 0"
                                                                                    cols="12"
                                                                                    sm="1"
                                                                                    class="mb-2 pl-0 pr-4"
                                                                                >
                                                                                    <v-btn
                                                                                        @click="editedEntity.quests[poolIndex].configuration.settings.conditions.splice(conditionIndex, 1)"
                                                                                        color="error"
                                                                                        rounded
                                                                                    >
                                                                                        X
                                                                                    </v-btn>
                                                                                </v-col>
                                                                            </v-row>
                                                                        </v-card>
                                                                    </v-col>
                                                                    <v-col
                                                                        v-if="multipleConditions(poolIndex)"
                                                                        cols="12"
                                                                    >
                                                                        <v-row
                                                                            justify="center"
                                                                            align="center"
                                                                        >
                                                                            <v-col
                                                                                cols="auto"
                                                                                sm="12"
                                                                                class="text-center"
                                                                            >
                                                                                <v-btn
                                                                                    @click="addCondition(poolIndex)"
                                                                                    color="primary"
                                                                                >
                                                                                    {{ $t('entities.achievements.addCondition') }}
                                                                                </v-btn>
                                                                            </v-col>
                                                                        </v-row>
                                                                    </v-col>
                                                                    <v-col
                                                                        v-for="(value, rewardIndex) in editedEntity.quests[poolIndex].rewards"
                                                                        :key="`reward-${rewardIndex}`"
                                                                        cols="12"
                                                                        class="py-0"
                                                                    >
                                                                        <v-row align="center">
                                                                            <v-col
                                                                                v-if="rewardIndex === 0"
                                                                                cols="12"
                                                                                class="text-left"
                                                                            >
                                                                                <h3>
                                                                                    {{ $t('entities.quests.attributes.rewards') }}
                                                                                </h3>
                                                                            </v-col>
                                                                        </v-row>
                                                                        <v-row align="center">
                                                                            <v-col
                                                                                cols="12"
                                                                                sm="6"
                                                                            >
                                                                                <v-select
                                                                                    :value="editedEntity.quests[poolIndex].rewards[rewardIndex]"
                                                                                    @change="updateReward(poolIndex, rewardIndex, $event)"
                                                                                    :items="items"
                                                                                    :rules="[v => !!v.id || $t('validation.required')]"
                                                                                    :menu-props="{ bottom: true, offsetY: true }"
                                                                                    :no-data-text="$t('components.dataTableHeader.noDataAvailable')"
                                                                                    item-text="name"
                                                                                    item-value="item_id"
                                                                                    class="do-not-apply-transform"
                                                                                    prepend-inner-icon="mdi-trophy-variant-outline"
                                                                                    return-object
                                                                                    solo
                                                                                >
                                                                                    <template v-slot:label>
                                                                                        {{ $t('entities.quests.attributes.rewards') }}
                                                                                        <span :style="{ color: 'red' }">*</span>
                                                                                    </template>
                                                                                    <template v-slot:selection="data">
                                                                                        {{ data.item.name }}
                                                                                    </template>
                                                                                    <template v-slot:item="data">
                                                                                        {{ data.item.name }}
                                                                                    </template>
                                                                                </v-select>
                                                                            </v-col>
                                                                            <v-col
                                                                                cols="12"
                                                                                :sm="rewardIndex === 0 ? 6 : 5"
                                                                                class="position-relative mb-2"
                                                                            >
                                                                                <v-text-field
                                                                                    :value="editedEntity.quests[poolIndex].rewards[rewardIndex].quantity"
                                                                                    @change="v => (editedEntity.quests[poolIndex].rewards[rewardIndex].quantity = v)"
                                                                                    :rules="[required, integer, min]"
                                                                                    :label="$t('generalAttributes.quantity')"
                                                                                    type="number"
                                                                                >
                                                                                </v-text-field>
                                                                                <span class="required-fields">*</span>
                                                                            </v-col>
                                                                            <v-col
                                                                                v-if="rewardIndex !== 0"
                                                                                cols="12"
                                                                                sm="1"
                                                                                class="mb-6 pl-0 pr-4"
                                                                            >
                                                                                <v-btn
                                                                                    @click="editedEntity.quests[poolIndex].rewards.splice(rewardIndex, 1)"
                                                                                    color="error"
                                                                                    rounded
                                                                                >
                                                                                    X
                                                                                </v-btn>
                                                                            </v-col>
                                                                        </v-row>
                                                                    </v-col>
                                                                    <v-col cols="12">
                                                                        <v-row
                                                                            justify="center"
                                                                            align="center"
                                                                        >
                                                                            <v-col
                                                                                cols="auto"
                                                                                sm="12"
                                                                                class="text-center"
                                                                            >
                                                                                <v-btn
                                                                                    @click="addReward(poolIndex)"
                                                                                    color="primary"
                                                                                >
                                                                                    {{ $t('entities.quests.addReward') }}
                                                                                </v-btn>
                                                                            </v-col>
                                                                        </v-row>
                                                                    </v-col>
                                                                </v-row>
                                                            </v-col>
                                                        </v-card>
                                                    </template>
                                                    <template v-else>
                                                        <v-col
                                                            cols="12"
                                                            class="position-relative text-center pt-4"
                                                        >
                                                            <span>{{ $t('entities.quests.noActivePools') }}</span>
                                                        </v-col>
                                                    </template>
                                                    <v-col cols="12">
                                                        <v-row
                                                            justify="center"
                                                            align="center"
                                                        >
                                                            <v-col
                                                                cols="auto"
                                                                sm="12"
                                                                class="text-center"
                                                            >
                                                                <v-menu offset-y>
                                                                    <template v-slot:activator="{ on, attrs }">
                                                                        <v-btn
                                                                            color="primary"
                                                                            dark
                                                                            v-bind="attrs"
                                                                            v-on="on"
                                                                        >
                                                                            {{ $t('entities.quests.addPool') }}
                                                                        </v-btn>
                                                                    </template>
                                                                    <v-list>
                                                                        <v-list-item
                                                                            v-for="(item, index) in poolTypes"
                                                                            :key="index"
                                                                            @click="addPool(item)"
                                                                            :disabled="editedEntity.pool_types[item]"
                                                                        >
                                                                            <v-list-item-title>{{ $t(`entities.quests.${item.toLowerCase()}`) }}</v-list-item-title>
                                                                        </v-list-item>
                                                                    </v-list>
                                                                </v-menu>
                                                            </v-col>
                                                        </v-row>
                                                    </v-col>
                                                </v-row>
                                                <ValidationErrors
                                                    v-if="validationErrors.length"
                                                    :errors="validationErrors"
                                                    class="mt-6"
                                                />
                                            </v-form>
                                        </v-card>
                                    </v-container>
                                </v-card-text>
                                <EditCardActions
                                    :save="save"
                                    :saving="saving"
                                    :closeEditDialog="closeEditDialog"
                                />
                            </v-card>
                        </v-dialog>
                        <v-dialog
                            v-model="deleteDialog"
                            max-width="1000px"
                        >
                            <v-card>
                                <v-card-text class="px-0 pb-0">
                                    <DeleteToolbar
                                        :text="$t('entities.quests.deleteEntity')"
                                        :closeDeleteDialog="closeDeleteDialog"
                                    />
                                    <v-container>
                                        <DialogImage
                                            :image="toDeleteEntity.image"
                                            :name="toDeleteEntity.name"
                                        />
                                        <v-card>
                                            <v-simple-table
                                                class="do-not-apply-hover"
                                                dense
                                            >
                                                <template v-slot:default>
                                                    <tbody>
                                                    <tr>
                                                        <td>{{ $t('generalAttributes.name') }}</td>
                                                        <td class="font-weight-bold">
                                                            {{ toDeleteEntity.name }}
                                                        </td>
                                                        <td>{{ $t('entities.quests.attributes.active') }}</td>
                                                        <td class="font-weight-bold">
                                                            <Boolean :boolean="toDeleteEntity.active"/>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>{{ $t('generalAttributes.description') }}</td>
                                                        <td
                                                            colspan="3"
                                                            class="font-weight-bold"
                                                        >
                                                            {{ toDeleteEntity.description || '/' }}
                                                        </td>
                                                    </tr>
                                                    </tbody>
                                                </template>
                                            </v-simple-table>
                                        </v-card>
                                    </v-container>
                                </v-card-text>
                                <DeleteCardActions
                                    :deleteEntity="deleteEntity"
                                    :deleting="deleting"
                                    :closeDeleteDialog="closeDeleteDialog"
                                />
                            </v-card>
                        </v-dialog>
                    </v-toolbar>
                </template>
                <template v-slot:item.name="{ item }">
                    {{ item.name }}
                </template>
                <template v-slot:item.description="{ item }">
                    {{ item.description || '/' }}
                </template>
                <template v-slot:item.quests="{ item }">
                    <template v-if="item.quests.length">
                        <v-chip
                            v-for="pool in item.quests"
                            :color="pool.active ? 'success' : 'error'"
                            :key="pool.pool_type"
                            class="font-weight-bold mr-2"
                            text-color="white"
                            small
                        >
                            {{ $t(`entities.quests.${pool.pool_type.toLowerCase()}`) }}
                        </v-chip>
                    </template>
                    <template v-else>
                        /
                    </template>
                </template>
                <template v-slot:item.active="{ item }">
                    <Boolean :boolean="item.active"/>
                </template>
                <template v-slot:item.action="{ item }">
                    <v-tooltip
                        color="transparent"
                        top
                    >
                        <template v-slot:activator="{ on }">
                            <v-btn
                                @click="openViewDialog(item)"
                                v-on="on"
                                :disabled="loadingView"
                                class="glow ml-2"
                                color="secondary"
                                depressed
                                x-small
                                fab
                            >
                                <template v-if="loadingView">
                                    <ProgressCircular color="secondary"/>
                                </template>
                                <template v-else>
                                    <v-icon
                                        class="white--text"
                                        small
                                    >
                                        pageview
                                    </v-icon>
                                </template>
                            </v-btn>
                        </template>
                        <v-card class="elevation-2 px-4 py-2">
                            <span>{{ $t('entities.questTypes.viewEntity') }}</span>
                        </v-card>
                    </v-tooltip>
                    <EditButton
                        :text="$t('entities.questTypes.editEntity')"
                        :item="item"
                        :openEditDialog="openEditDialog"
                    />
                    <DeleteButton
                        :text="$t('entities.questTypes.deleteEntity')"
                        :item="item"
                        :openDeleteDialog="openDeleteDialog"
                    />
                </template>
                <template v-slot:no-data>
                    <NoData
                        :pagination="pagination"
                        :typing="typing"
                        :fetchEntities="fetchEntities"
                    />
                </template>
            </v-data-table>
            <DataTableFooter
                :pagination="pagination"
                :loading="loading"
            />
        </v-card>
        <Snackbar
            :show.sync="snackbar.show"
            :color="snackbar.color"
            :message="snackbar.message"
        />
    </div>
</template>

<script>

import Vue from 'vue';
import Boolean from '@/components/Boolean.vue';
import PrimaryChip from '@/components/PrimaryChip.vue';
import ProgressCircular from '@/components/ProgressCircular.vue';
import AchievableConfiguration from "@/components/AchievableConfiguration";
import NoData from '@/components/crud/NoData.vue';
import ImageInput from '@/components/ImageInput.vue';
import RefreshButton from '@/components/crud/RefreshButton.vue';
import EditButton from '@/components/crud/edit/EditButton.vue';
import EditToolbar from '@/components/crud/edit/EditToolbar.vue';
import EditCardActions from '@/components/crud/edit/EditCardActions.vue';
import DeleteButton from '@/components/crud/delete/DeleteButton.vue';
import DeleteToolbar from '@/components/crud/delete/DeleteToolbar.vue';
import DeleteCardActions from '@/components/crud/delete/DeleteCardActions.vue';
import DialogImage from '@/components/DialogImage';
import RequiredFields from '@/components/crud/RequiredFields.vue';
import DataTableHeader from '@/components/crud/DataTableHeader.vue';
import DataTableSkeletonLoader from '@/components/crud/DataTableSkeletonLoader.vue';
import DataTableFooter from '@/components/crud/DataTableFooter.vue';
import RowsPerPage from '@/components/crud/RowsPerPage.vue';
import ValidationErrors from '@/components/crud/ValidationErrors.vue';
import Snackbar from '@/components/Snackbar.vue';
import Quest from '@/models/competitions/Quest';
import QuestGroup from '@/models/competitions/QuestGroup';
import User from '@/models/users/User';
import Item from '@/models/shop/Item';
import FilesApi from '@/services/misc/FilesApi';

import convertDate from '@/mixins/convert-date';
import convertCase from '@/mixins/convert-case';
import formatDate from '@/mixins/format-date';
import required from '@/mixins/rules/required';
import thousandSeparator from '@/mixins/thousand-separator';
import { axiosCatch } from '@/mixins/crud/methods/axios-catch.js';
import { showSnackbar } from "@/mixins/crud/methods/show-snackbar";
import { paginationWatch } from '@/mixins/crud/watch/pagination.js';
import { editDialog } from '@/mixins/crud/watch/edit-dialog.js';
import { deleteDialog } from '@/mixins/crud/watch/delete-dialog.js';
import { deleteEntity } from '@/mixins/crud/methods/delete-entity.js';

export default Vue.extend({
    components: {
        Boolean,
        NoData,
        PrimaryChip,
        ProgressCircular,
        ImageInput,
        AchievableConfiguration,
        RefreshButton,
        DataTableHeader,
        DataTableSkeletonLoader,
        DataTableFooter,
        RowsPerPage,
        ValidationErrors,
        EditToolbar,
        EditButton,
        EditCardActions,
        DeleteToolbar,
        DeleteButton,
        DeleteCardActions,
        DialogImage,
        RequiredFields,
        Snackbar,
    },

    mixins: [
        convertDate,
        axiosCatch,
        showSnackbar,
        paginationWatch,
        editDialog,
        deleteDialog,
        deleteEntity,
        convertCase,
        formatDate,
        thousandSeparator,
        required,
    ],

    data: () => ({
        initialLoad: true,
        loading: false,
        loadingView: false,
        saving: false,
        deleting: false,
        searchingUser: false,
        typing: false,
        apiURI: process.env.VUE_APP_ACHIEVEMENTS_BASE_URI,
        route: 'quests',
        entity: 'questTypes',
        entities: [],
        groups: [],
        items: [],
        types: ['SIMPLE', 'AND', 'OR', 'RECURRENT'],
        innerTypes: ['SIMPLE', 'AND', 'OR'],
        units: ['DAY', 'WEEK', 'MONTH'],
        poolTypes: ['DAILY', 'WEEKLY', 'MONTHLY'],
        selectedSearchAttributes: ['Name'],
        validationErrors: [],
        pagination: {
            search: '',
            current_page: 1,
            from: 1,
            last_page: 0,
            path: '',
            per_page: 10,
            to: 0,
            total: 0
        },
        options: {
            page: 1,
            itemsPerPage: 10,
            sortBy: ['id'],
            sortDesc: ['DESC'],
            groupBy: [],
            groupDesc: [],
            mustSort: false,
            multiSort: false
        },
        usersPagination: {
            search: '',
            current_page: 1,
            from: 1,
            last_page: 0,
            path: '',
            per_page: 10,
            to: 0,
            total: 0
        },
        userOptions: {
            page: 1,
            itemsPerPage: 10,
            sortBy: ['id'],
            sortDesc: ['DESC'],
            groupBy: [],
            groupDesc: [],
            mustSort: false,
            multiSort: false
        },
        userSelectedSearchAttributes: ['Username'],
        viewDialog: false,
        editDialog: false,
        deleteDialog: false,
        editedIndex: -1,
        file: null,
        image: null,
        editedEntity: {
            id: null,
            name: '',
            description: '',
            image: '',
            active: 1,
            quests: [],
            users: [],
            pool_types: {
                DAILY: false,
                WEEKLY: false,
                MONTHLY: false,
            }
        },
        defaultEntity: {
            id: null,
            name: '',
            description: '',
            image: '',
            active: 1,
            quests: [],
            users: [],
            pool_types: {
                DAILY: false,
                WEEKLY: false,
                MONTHLY: false,
            }
        },
        toDeleteEntity: {
            id: null,
            name: '',
            description: '',
            image: '',
            active: 1,
            quests: [],
            users: [],
            pool_types: {
                DAILY: false,
                WEEKLY: false,
                MONTHLY: false,
            }
        }
    }),

    computed: {
        headers() {
            return [
                { text: this.$t('generalAttributes.name'), align: 'left', value: 'name' },
                { text: this.$t('generalAttributes.description'), align: 'left', value: 'description' },
                { text: this.$t('entities.quests.title'), align: 'left', value: 'quests', sortable: false },
                { text: this.$t('entities.quests.attributes.active'), align: 'left', value: 'active' },
                { text: this.$t('miscellaneous.actions'), value: 'action', sortable: false }
            ];
        },
        achievedHeaders() {
            return [
                { text: this.$t('entities.achievements.userId'), align: 'left', value: 'user_id', sortable: false },
                { text: this.$t('entities.users.attributes.username'), align: 'left', value: 'username', sortable: false },
                { text: this.$t('entities.quests.attributes.pool'), align: 'left', value: 'quest.pool_type', sortable: false },
                { text: this.$t('entities.achievements.attributes.progress'), align: 'left', value: 'progress.value', sortable: false },
                { text: this.$t('miscellaneous.createdAt'), align: 'left', value: 'created_at', sortable: false },
                { text: this.$t('entities.achievements.attributes.progressUpdatedAt'), align: 'left', value: 'progress.updated_at', sortable: false },
            ];
        },
        searchAttributes() {
            return [
                { label: this.$t('generalAttributes.name'), value: 'Name' },
            ]
        },
        userSearchAttributes() {
            return [
                { label: this.$t('entities.users.attributes.username'), value: 'Username' },
            ]
        },
        formTitle() {
            return this.editDialog ? (this.$t(`entities.${this.entity}.${this.editedIndex === -1 ? 'new' : 'edit'}Entity`)) : '';
        },
        integer() {
            return v => !v || ((!isNaN(v) && parseInt(Number(v)) == v && !isNaN(parseInt(v, 10))) || this.$t('validation.integer'));
        },
        min() {
            return v => (v >= 0) || this.$t('validation.min', { amount: 0 });
        },
    },

    watch: {
        viewDialog(val) {
            if (!val) {
                this.editedIndex = -1;
                this.editedEntity = {...this.defaultEntity};
                this.editedEntity.users = [];
                this.usersPagination = {
                    search: '',
                    current_page: 1,
                    from: 1,
                    last_page: 0,
                    path: '',
                    per_page: 10,
                    to: 0,
                    total: 0
                };
            }
        },
        'usersPagination.per_page': function() {
            if (this.usersPagination.current_page !== 1) {
                this.usersPagination.current_page = 1;
            } else {
                this.getQuestUsers(
                    this.editedEntity.id,
                    `&size=${this.usersPagination.per_page}&${this.formatUserSelectedSearchAttributes()}&page=${this.usersPagination.current_page}`
                );
            }
        },
        'usersPagination.search': function() {
            this.searchingUser = true;
            if (this.debounce) {
                clearTimeout(this.debounce)
            }
            this.debounce = setTimeout(() => {
                this.editedEntity.users = [];
                this.usersPagination.current_page = 1;
                this.usersPagination.from = 1;
                this.usersPagination.last_page = 0;
                this.usersPagination.path = '';
                this.usersPagination.per_page = 10;
                this.usersPagination.to = 0;
                this.usersPagination.total = 0;
                if (this.usersPagination.search) {
                    if (this.usersPagination.current_page !== 1) {
                        this.usersPagination.current_page = 1;
                    } else {
                        this.getQuestUsers(
                            this.editedEntity.id,
                            `&size=${this.usersPagination.per_page}&${this.formatUserSelectedSearchAttributes()}&page=${this.usersPagination.current_page}`
                        );
                    }
                } else {
                    this.getQuestUsers(this.editedEntity.id);
                }
                this.searchingUser = false;
            }, 250);
        },
        'usersPagination.current_page': function() {
            if (this.options.sortBy.length) {
                const sortBy = this.options.sortBy[0];
                const sortDirection = this.options.sortDesc[0] ? 'DESC' : 'ASC';
                this.getQuestUsers(
                    this.editedEntity.id,
                    `&size=${this.usersPagination.per_page}&${this.formatUserSelectedSearchAttributes()}&page=${this.usersPagination.current_page}&sort=${sortBy},${sortDirection}`
                );
            } else {
                this.getQuestUsers(
                    this.editedEntity.id,
                    `&size=${this.usersPagination.per_page}&${this.formatUserSelectedSearchAttributes()}&page=${this.usersPagination.current_page}`
                );
            }
        },
    },

    mounted() {
        this.refresh();
    },

    methods: {
        async fetchEntities(append = `&size=${this.pagination.per_page}&page=${this.pagination.current_page}&sort=id,DESC`) {
            try {
                this.loading = true;
                const response = await Quest.api.getTypes(append);
                const data = response.data;
                this.entities = data.content.map(QuestDTO => new Quest(QuestDTO));
                this.pagination.current_page = data.pageable.page_number + 1;
                this.pagination.per_page = data.pageable.page_size;
                this.pagination.total = data.total_elements;
                this.pagination.last_page = data.total_pages;
                this.pagination.from = data.pageable.offset + 1;
                this.pagination.to = this.pagination.from + data.number_of_elements - 1;
            } catch (e) {
                console.warn(`Quests API failed.`);
                console.log(e);
            } finally {
                this.initialLoad = false;
                this.loading = false;
            }
        },
        async fetchGroups() {
            try {
                const response = await QuestGroup.api.get('size=100');
                this.groups = response.data.content.map(QuestGroupDTO => new QuestGroup(QuestGroupDTO));
            } catch (e) {
                console.warn('Quest Groups API failed.');
                console.log(e);
            }
        },
        async fetchItems() {
            try {
                const response = await Item.api.get('/global', '?name=chest');
                const items = response.data.data;
                if (items.length == 1) {
                    this.items = items[0].variants.map(ItemDTO => {
                        const item = new Item(ItemDTO)
                        item.quantity = 0;
                        item.item_id = item.id; // Mapping for quest rewards
                        return item;
                    });
                }
            } catch (e) {
                console.warn('Items API failed.');
                console.log(e);
            }
        },
        async save() {
            try {
                this.saving = true;

                if (!this.$refs.form.validate()) {
                    return;
                }

                if (this.file) {
                    await FilesApi.upload('achievements', this.file)
                        .then((response) => {
                            this.file = null;
                            if (!response || response.status !== 200) {
                                this.showSnackbar('error', this.$t('miscellaneous.fileUploadFailed'));
                                return;
                            }
                            this.image = response.data.link;
                        })
                        .catch(() => {
                            this.showSnackbar('error', this.$t('miscellaneous.fileUploadFailed'));
                        });
                }

                const data = {
                    group_id: this.editedEntity.group.id,
                    name: this.editedEntity.name,
                    active: this.editedEntity.active ? 1 : 0,
                };

                if (this.editedEntity.quests) {
                    const quests = [];
                    for (const pool in this.editedEntity.quests) {
                        const poolData = {
                            name: this.editedEntity.quests[pool].name,
                            task: this.editedEntity.quests[pool].task,
                            active: this.editedEntity.quests[pool].active ? 1 : 0,
                            poolType: this.editedEntity.quests[pool].pool_type,
                            rewards: [],
                            configuration: {}
                        };

                        for (let i = 0; i < this.editedEntity.quests[pool].rewards.length; i++) {
                            poolData.rewards.push({});

                            // If creating, map reward id from inventory item id, else, take item id from quests
                            if (this.editedIndex === -1) {
                                poolData.rewards[i].itemId = this.editedEntity.quests[pool].rewards[i].id
                            } else {
                                poolData.rewards[i].itemId = this.editedEntity.quests[pool].rewards[i].item_id
                            }

                            poolData.rewards[i].name = this.editedEntity.quests[pool].rewards[i].name
                            poolData.rewards[i].type = 'chest' // Only chests
                            poolData.rewards[i].quantity = this.editedEntity.quests[pool].rewards[i].quantity

                            if (this.editedEntity.quests[pool].rewards[i].image) {
                                poolData.rewards[i].image = this.editedEntity.quests[pool].rewards[i].image
                            }
                        }

                        if (this.editedEntity.quests[pool].configuration.settings) {
                            poolData.configuration.settings = {
                                type: this.editedEntity.quests[pool].configuration.settings.type
                            };

                            if (this.editedEntity.quests[pool].configuration.settings.conditions) {
                                if (this.multipleConditions(pool)) {
                                    poolData.configuration.settings.conditions = this.editedEntity.quests[pool].configuration.settings.conditions.map(function(condition) {
                                        const conditionData = {
                                            context: {
                                                id: condition.context.id,
                                                strategy: condition.context.strategy,
                                                data: condition.context.data,
                                            },
                                            value: condition.value,
                                        };
                                        if (condition.id) {
                                            conditionData.id = condition.id;
                                        }
                                        return conditionData;
                                    });
                                } else {
                                    const condition = this.editedEntity.quests[pool].configuration.settings.conditions[0];
                                    const conditionData = {
                                        context: {
                                            id: condition.context.id,
                                            strategy: condition.context.strategy,
                                            data: condition.context.data,
                                        },
                                        value: condition.value,
                                    };
                                    if (condition.id) {
                                        conditionData.id = condition.id;
                                    }
                                    poolData.configuration.settings.conditions = [conditionData];
                                }
                            }

                            if (this.editedEntity.quests[pool].configuration.settings.type === 'RECURRENT') {
                                poolData.configuration.settings.period = {
                                    unit: this.editedEntity.quests[pool].configuration.settings.period.unit,
                                    innerType: this.editedEntity.quests[pool].configuration.settings.period.inner_type,
                                    count: this.editedEntity.quests[pool].configuration.settings.period.count,
                                    consecutive: this.editedEntity.quests[pool].configuration.settings.period.consecutive ? 1 : 0
                                };
                            }
                        }

                        quests.push(poolData);
                    }

                    data.quests = quests;
                }

                if (this.editedEntity.description) {
                    data.description = this.editedEntity.description;
                }

                if (this.image) {
                    data.image = this.image;
                }

                if (this.editedIndex > -1) {
                    await Quest.api.update(this.editedEntity.id, data)
                        .then(() => {
                            if (this.pagination.search.length) {
                                this.pagination.search = '';
                            } else {
                                this.fetchEntities();
                            }
                            this.showSnackbar('success', this.$t('entities.questTypes.notifications.update'));
                            this.closeEditDialog();
                            this.image = null;
                        })
                        .catch(error => {
                            this.axiosCatch(error);
                        });
                } else {
                    await Quest.api.create(data)
                        .then(() => {
                            if (this.pagination.search.length) {
                                this.pagination.search = '';
                            } else {
                                this.fetchEntities();
                            }
                            this.showSnackbar('success', this.$t('entities.questTypes.notifications.creation'));
                            this.closeEditDialog();
                            this.image = null;
                        }).catch(error => {
                            this.axiosCatch(error);
                        });
                }
            } catch (error) {
                console.warn(`Quest Save API failed.`);
                console.log(error);
            } finally {
                this.saving = false;
            }
        },
        async getQuestUsers(id, append = '') {
            if (id) {
                try {
                    const response = await Quest.api.getUsers(id, append);
                    const data = response.data;
                    this.$set(this.editedEntity, 'users', data.content.map(UserDTO => new User(UserDTO)));
                    this.usersPagination.current_page = data.pageable.page_number + 1;
                    this.usersPagination.per_page = data.pageable.page_size;
                    this.usersPagination.total = data.total_elements;
                    this.usersPagination.last_page = data.total_pages;
                    this.usersPagination.from = data.pageable.offset + 1;
                    this.usersPagination.to = this.usersPagination.from + data.number_of_elements - 1;
                } catch (e) {
                    console.warn(`Quest Users API failed.`);
                    console.log(e);
                }
            }
        },
        async openViewDialog(entity) {
            this.loadingView = true;
            this.editedIndex = this.entities.indexOf(entity);
            this.editedEntity = {...entity};
            await this.getQuestUsers(entity.id);
            this.viewDialog = true;
            this.loadingView = false;
        },
        openEditDialog(entity) {
            this.editedIndex = this.entities.indexOf(entity);
            this.editedEntity = JSON.parse(JSON.stringify(entity));
            for (const pool in this.editedEntity.quests) {
                if (!this.editedEntity.quests[pool].configuration.settings) {
                    this.editedEntity.quests[pool].configuration.settings = JSON.parse(JSON.stringify(
                        {
                            conditions: [
                                {
                                    value: 0,
                                    context: {}
                                }
                            ],
                            period: {
                                inner_type: "SIMPLE",
                                unit: "DAY",
                                count: 0,
                                consecutive: 0
                            },
                            type: "SIMPLE",
                        },
                    ));
                }
                if (!this.editedEntity.quests[pool].configuration.settings || !this.editedEntity.quests[pool].configuration.settings.period) {
                    this.editedEntity.quests[pool].configuration.settings.period = JSON.parse(JSON.stringify(
                        {
                            inner_type: "SIMPLE",
                            unit: "DAY",
                            count: 0,
                            consecutive: 0
                        }
                    ));
                }
            }
            if (this.$refs.form) {
                this.$refs.form.resetValidation();
            }
            this.editDialog = true;
        },
        closeEditDialog() {
            this.editedEntity = JSON.parse(JSON.stringify(this.defaultEntity));
            this.editedIndex = -1;
            this.file = null;
            this.editDialog = false;
        },
        openDeleteDialog(entity) {
            this.editedIndex = this.entities.indexOf(entity);
            this.toDeleteEntity = {...entity};
            this.deleteDialog = true;
        },
        closeDeleteDialog() {
            this.deleteDialog = false;
            this.toDeleteEntity = {...this.defaultEntity};
            this.editedIndex = -1;
        },
        formatUserSelectedSearchAttributes() {
            if (!this.usersPagination.search.length) {
                return '';
            }

            const searchAttributes = this.userSelectedSearchAttributes.map(attribute => `${attribute.toLowerCase()}=${this.usersPagination.search}`);
            return '&' + searchAttributes.join('&');
        },
        addPool(poolType) {
            if (!this.editedEntity.quests) {
                this.$set(this.editedEntity, 'quests', []);
            }
            this.editedEntity.quests.push({
                active: 1,
                task: '',
                rewards: [
                    {
                        type: "",
                        name: "",
                        quantity: 1,
                        image: "",
                    }
                ],
                configuration: {
                    settings: JSON.parse(JSON.stringify(
                        {
                            conditions: [
                                {
                                    value: 0,
                                    context: {}
                                }
                            ],
                            period: {
                                inner_type: "SIMPLE",
                                unit: "DAY",
                                count: 0,
                                consecutive: 0
                            },
                            type: "SIMPLE",
                        },
                    )),
                },
                pool_type: poolType
            });
            this.editedEntity.pool_types[poolType] = true;
        },
        removePool(index) {
            this.editedEntity.pool_types[this.editedEntity.quests[index].pool_type] = false;
            this.editedEntity.quests.splice(index, 1);
        },
        addReward(poolIndex) {
            this.editedEntity.quests[poolIndex].rewards.push({
                type: "",
                name: "",
                quantity: 1,
                image: "",
            });
        },
        addCondition(poolIndex) {
            this.editedEntity.quests[poolIndex].configuration.settings.conditions.push({
                value: 0,
                context: {}
            });
        },
        multipleConditions(poolIndex) {
            const pool = this.editedEntity.quests[poolIndex];
            return pool.configuration.settings.type === 'AND'
                || pool.configuration.settings.type === 'OR'
                || (pool.configuration.settings.type === 'RECURRENT'
                    && pool.configuration.settings.period
                    && pool.configuration.settings.period.inner_type !== 'SIMPLE'
                );
        },
        updateGroup(group) {
            this.$set(this.editedEntity, 'group', group);
        },
        updateReward(poolIndex, rewardIndex, reward) {
            this.$set(this.editedEntity.quests[poolIndex].rewards, rewardIndex, {
                id: reward.id,
                image: reward.image,
                item_id: reward.id,
                name: reward.name,
                type: reward.type,
                quantity: this.editedEntity.quests[poolIndex].rewards[rewardIndex].quantity,
            });
        },
        refresh() {
            this.fetchEntities();
            this.fetchGroups();
            this.fetchItems();
        }
    }
});

</script>


<style>

.position-relative {
    position: relative;
}

.required-fields {
    position: absolute;
    right: 13px;
    top: 15px;
    color: red;
}

.v-list.v-sheet {
    padding-top: 0;
    padding-bottom: 0;
}

</style>
