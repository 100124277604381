import { AxiosResponse } from 'axios';
import axios from '@/services/axios';

export default abstract class FilesApi {

    public static route = process.env.VUE_APP_FILE_UPLOAD_BASE_URI;
    public static entity = 'upload';

    public static async upload(namespace: string, file: Blob): Promise<AxiosResponse> {
        const data = new FormData();
        data.append('namespace', namespace);
        data.append('file', file);
        return await axios.post(
            `${this.route}/${this.entity}`,
            data,
            { headers: { 'content-type': 'multipart/form-data;' }}
        );
    }
}
