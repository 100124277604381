import CompaniesApi from '@/services/setup/CompaniesApi';

export interface CompanyInterface {
    id: bigint;
    name: string;
    address: string;
    phone: string;
    fiscal_data: object[];
}

export class CompanyDTO implements CompanyInterface {
    id = 0n;
    name = '';
    address = '';
    phone = '';
    fiscal_data = [];
}

export default class Company extends CompanyDTO {
    static api = CompaniesApi;

    constructor(DTO: CompanyDTO){
        super();
        Object.assign(this, DTO);
    }
}