

import Vue from 'vue';

import convertCase from '@/mixins/convert-case';
import User from '@/models/users/User';

export default Vue.extend({
    props: ['text', 'item'],

    mixins: [
        convertCase,
    ],

    computed: {
        arenaName() {
            return this.$store.getters.arena ? `${this.titleToKebabCase(this.$store.getters.arena.name)}` : '';
        }
    },

    methods: {
        createPurchase(user: User) {
            this.$router.push({
                name: 'navigationDrawer.shop.purchase',
                params: {
                    arena: this.arenaName,
                    user: JSON.stringify(user)
                }
            });
        }
    }
});

