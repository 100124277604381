import formatDate from "@/mixins/format-date";

const convertDate = {
    methods: {
        fromUtcTimeToCurrent(time: string): string {
            const value = time.split(':');
            const now = new Date();

            now.setHours(parseInt(value[0]));
            now.setMinutes(parseInt(value[1]));

            const localDate = new Date(now.getTime() - now.getTimezoneOffset() * 60 * 1000);
            const timeString = localDate.toTimeString();
            const hoursAndMinutes = timeString.split(':');

            return hoursAndMinutes[0] + ":" + hoursAndMinutes[1];
        },
        fromCurrentTimeToUtc(time: string): string {
            const value = time.split(':');
            const now = new Date();

            now.setHours(parseInt(value[0]));
            now.setMinutes(parseInt(value[1]));

            const isoString = now.toISOString();
            const timeString = isoString.split('T');
            const hoursAndMinutes = timeString[1].split(':');

            return hoursAndMinutes[0] + ":" + hoursAndMinutes[1];
        },
        fromUtcDateTimeToCurrent(datetime: string, useSeconds = false, toLocale = false): string {
            const date = new Date(datetime);
            if (datetime.endsWith('Z')) {
                return formatDate.methods.formatDate(date, useSeconds, toLocale);
            }
            const localDate = new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000);
            return formatDate.methods.formatDate(localDate, useSeconds, toLocale);
        },
        fromCurrentDateTimeToUtc(datetime: string): string {
            const date = new Date(datetime);

            const iso = date.toISOString();
            const dateTime = iso.split('T');
            const hoursAndMinutes = dateTime[1].split(':');

            return dateTime[0] + ' ' + hoursAndMinutes[0] + ':' + hoursAndMinutes[1];
        },
    }
};

export default convertDate;