import currency from '@/mixins/currency';
import i18n from '@/i18n'
import { ChartType, TooltipItem } from 'chart.js';

export const chartsData = {
    income: {
        display: true,
        type: 'bar',
        data: {
            labels: [`${i18n.t('entities.categories.title')}`],
            datasets: [],
        },
        options: {
            plugins: {
                tooltip: {
                    backgroundColor: 'white',
                    titleColor: 'black',
                    bodyColor: 'black',
                    borderColor: 'rgba(0, 0, 0, 0.25)',
                    borderWidth: 1,
                    callbacks: {
                        title: function (tooltipItem: Array<TooltipItem<ChartType>>) {
                            return tooltipItem[0].dataset.label;
                        },
                        label: function (tooltipItem: TooltipItem<ChartType>) {
                            const raw = parseInt(tooltipItem.raw as string);
                            return `${i18n.t('miscellaneous.income')}: ${currency.methods.format(raw * 100, false)}`;
                        },
                    }
                }
            },
        }
    },
};

export default chartsData;