import EventsApi from '@/services/social/EventsApi';

export interface EventInterface {
    id: bigint;
    title: string;
    subtitle: string;
    content: string;
    image: string;
    startsAt: string;
    endsAt: string;
}

export class EventDTO implements EventInterface {
    id = 0n;
    title = '';
    subtitle = '';
    content = '';
    image = '';
    startsAt = '';
    endsAt = '';
}

export default class Event extends EventDTO {
    static api = EventsApi;

    constructor(DTO: EventDTO){
        super();
        Object.assign(this, DTO);
    }
}
