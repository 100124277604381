import AppsApi from '@/services/configuration/AppsApi';

export interface AppInterface {
    id: string;
    name: string;
    slug: string;
    description: string;
    image: string;
    icon: string;
    settings: object;
}

export class AppDTO implements AppInterface {
    id = '';
    name = '';
    slug = '';
    description = '';
    image = '';
    icon = '';
    settings = {};

}

export default class App extends AppDTO {
    static api = AppsApi;

    constructor(DTO: AppDTO){
        super();
        Object.assign(this, DTO);
    }
}