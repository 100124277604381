

import Vue from 'vue';

export default Vue.extend({
    props: {
        route: {
            type: String
        },
        color: {
            type: String
        },
        icon: {
            type: String
        },
        text: {
            type: String
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
})

