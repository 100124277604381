import RanksApi from '@/services/users/RanksApi';

export interface RankInterface {
    id: bigint;
    name: string;
    active: boolean;
    bonus: bigint;
    gain: bigint;
    decay: number;
    lower: bigint;
    upper: bigint;
    image: string;
    icon: string;
    created_at: string;
    updated_at: string | null;
}

export class RankDTO implements RankInterface {
    id = 0n;
    name = '';
    active = false;
    bonus = 0n;
    gain = 0n;
    decay = 0;
    lower = 0n;
    upper = 0n;
    image = '';
    icon = '';
    created_at = '';
    updated_at = null;
}

export default class Rank extends RankDTO {
    public static api = RanksApi;

    constructor(DTO: RankDTO){
        super();
        Object.assign(this, DTO);
    }
}