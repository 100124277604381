import ArticlesApi from '@/services/social/ArticlesApi';

export interface ArticleInterface {
    id: bigint;
    title: string;
    subtitle: string;
    content: string;
    active: boolean;
    image: string;
}

export class ArticleDTO implements ArticleInterface {
    id = 0n;
    title = '';
    subtitle = '';
    content = '';
    active = false;
    image = '';
}

export default class Article extends ArticleDTO {
    static api = ArticlesApi;

    constructor(DTO: ArticleDTO){
        super();
        Object.assign(this, DTO);
    }
}
