import SkillsApi from '@/services/competitions/SkillsApi';

export interface SkillInterface {
    id: bigint;
    name: string;
    description: string;
}

export class SkillDTO implements SkillInterface {
    id = 0n;
    name = '';
    description = '';
}

export default class Skill extends SkillDTO {
    public static api = SkillsApi;

    constructor(DTO: SkillDTO){
        super();
        Object.assign(this, DTO);
    }
}