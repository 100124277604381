
import brown from '@/plugins/vuetify/themes/brown';
import classic from '@/plugins/vuetify/themes/classic';
import teal from '@/plugins/vuetify/themes/teal';

export default function (val: string, app?: Vue): void {
    localStorage.setItem('theme', val)
    if (app) {
        app.$vuetify.theme.dark = val === 'Dark';
        if (val === 'Brown') {
            app.$vuetify.theme.themes.light = brown;
        } else if (val === 'Classic') {
            app.$vuetify.theme.themes.light = classic;
        }  else if (val === 'Teal') {
            app.$vuetify.theme.themes.light = teal;
        }
    }
}