import { AxiosResponse } from 'axios';
import axios from '@/services/axios';

export default abstract class ArenaAppsApi {

    public static route = process.env.VUE_APP_ARENA_HUB_BASE_URI;
    public static entity = 'apps';

    public static async get(arenaId: bigint, params: string): Promise<AxiosResponse> {
        return await axios.get(`${this.route}/arenas/${arenaId}/${this.entity}?${params}`);
    }

    public static async create(arenaId: bigint, data: object): Promise<AxiosResponse> {
        return await axios.post(`${this.route}/arenas/${arenaId}/${this.entity}`, data);
    }

    public static async update(id: bigint, arenaId: bigint, data: object): Promise<AxiosResponse>{
        return await axios.put(`${this.route}/arenas/${arenaId}/${this.entity}/${id}`, data);
    }

    public static async delete(arenaId: bigint, id: bigint): Promise<AxiosResponse> {
        return await axios.delete(`${this.route}/arenas/${arenaId}/${this.entity}/${id}`);
    }
}
