<template>
    <div>
        <DataTableHeader
            :pagination="pagination"
            :searchAttributes="searchAttributes"
            :selectedSearchAttributes.sync="selectedSearchAttributes"
            :disabled="loading"
        />
        <DataTableSkeletonLoader :loading="initialLoad"/>
        <v-data-table
            v-show="!initialLoad"
            :headers="headers"
            :items="entities"
            :dense="$vuetify.breakpoint.xs && !loading"
            :loading="loading"
            :loading-text="$t('components.noData.fetchingEntries')"
            :options.sync="options"
            :server-items-length="pagination.total"
            :items-per-page="pagination.per_page"
            :header-props="{ sortIcon: 'arrow_circle_up', sortByText: $t('miscellaneous.sortBy') }"
            :class="{ 'mobile-empty-datatable' : $vuetify.breakpoint.xsOnly }"
            hide-default-footer
        >
            <template v-slot:top>
                <v-toolbar flat>
                    <v-toolbar-title>{{ title }}</v-toolbar-title>
                    <v-divider
                        class="mx-4"
                        inset
                        vertical
                    ></v-divider>
                    <small class="mr-2">{{ addThousandSeparator(pagination.total) }}</small>
                    <RefreshButton
                        :refresh="refresh"
                        :loading="loading"
                    />
                    <v-spacer/>
                    <v-dialog
                        v-model="viewDialog"
                        max-width="1000px"
                    >
                        <v-card>
                            <v-card-text class="px-0 pb-0">
                                <v-toolbar
                                    class="elevation-2"
                                    color="primary"
                                >
                                    <v-toolbar-title class="ml-0">
                                        <span class="font-weight-bold white--text">{{ $t('entities.invoices.viewEntity') }}</span>
                                    </v-toolbar-title>
                                    <v-spacer/>
                                    <v-btn
                                        @click="viewDialog = false"
                                        icon
                                        dark
                                    >
                                        <v-icon>mdi-close</v-icon>
                                    </v-btn>
                                </v-toolbar>
                                <ViewDialog :editedEntity="editedEntity"/>
                            </v-card-text>
                            <v-divider/>
                            <v-card-actions>
                                <v-spacer/>
                                <v-btn
                                    @click="viewDialog = false"
                                    color="dark darken-1"
                                    text
                                >
                                    {{ $t('miscellaneous.ok') }}
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                    <v-dialog
                        v-model="restoreDialog"
                        max-width="1000px"
                    >
                        <v-card>
                            <v-card-text class="px-0 pb-0">
                                <v-toolbar
                                    class="elevation-2"
                                    color="error"
                                >
                                    <v-toolbar-title class="ml-0">
                                        <span class="font-weight-bold white--text">{{ $t('entities.invoices.restoreEntity') }}</span>
                                    </v-toolbar-title>
                                    <v-spacer/>
                                    <v-btn
                                        @click="restoreDialog = false"
                                        icon
                                        dark
                                    >
                                        <v-icon>mdi-close</v-icon>
                                    </v-btn>
                                </v-toolbar>
                                <v-container>
                                    <v-card class="pb-2">
                                        <v-simple-table
                                            class="do-not-apply-hover"
                                            dense
                                        >
                                            <template v-slot:default>
                                                <tbody>
                                                    <tr>
                                                        <td>{{ $t('entities.invoices.attributes.number') }}</td>
                                                        <td
                                                            colspan="2"
                                                            class="font-weight-bold"
                                                        >
                                                            {{ toRestoreEntity.number }}
                                                        </td>
                                                        <td>{{ $t('entities.invoices.attributes.byUser') }}</td>
                                                        <td
                                                            colspan="3"
                                                            class="font-weight-bold"
                                                        >
                                                            {{ toRestoreEntity.by_user.username }}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>{{ $t('miscellaneous.gross') }}</td>
                                                        <td
                                                            colspan="2"
                                                            class="font-weight-bold"
                                                        >
                                                            {{ format(toRestoreEntity.cart.gross, true, toRestoreEntity.arena.company.country.currency) }}
                                                        </td>
                                                        <td>{{ $t('miscellaneous.net') }}</td>
                                                        <td
                                                            colspan="3"
                                                            class="font-weight-bold"
                                                        >
                                                            {{ format(toRestoreEntity.cart.net, true, toRestoreEntity.arena.company.country.currency) }}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>{{ $t('entities.invoices.attributes.label') }}</td>
                                                        <td
                                                            colspan="2"
                                                            class="font-weight-bold"
                                                        >
                                                            {{ toRestoreEntity.label }}
                                                        </td>
                                                        <td>{{ $t('miscellaneous.arena') }}</td>
                                                        <td
                                                            colspan="3"
                                                            class="font-weight-bold"
                                                        >
                                                            {{ toRestoreEntity.arena.name }}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </template>
                                        </v-simple-table>
                                        <v-row class="mt-4">
                                            <v-col cols="12">
                                                <v-form
                                                    @submit.prevent="restoreInvoice"
                                                    method="POST"
                                                    ref="form"
                                                >
                                                    <v-row align="center">
                                                        <v-col
                                                            cols="12"
                                                            class="position-relative px-6"
                                                        >
                                                            <v-textarea
                                                                v-model="toRestoreEntity.restoring_reason"
                                                                :rules="required"
                                                                :label="$t('entities.invoices.attributes.restoringReason')"
                                                                prepend-icon="mdi-text"
                                                                rows="1"
                                                                auto-grow
                                                                persistent-hint
                                                            ></v-textarea>
                                                            <span class="required-fields mr-3">*</span>
                                                        </v-col>
                                                    </v-row>
                                                    <ValidationErrors
                                                        v-if="validationErrors.length"
                                                        :errors="validationErrors"
                                                    />
                                                </v-form>
                                            </v-col>
                                        </v-row>
                                    </v-card>
                                </v-container>
                            </v-card-text>
                            <v-card-actions>
                                <v-btn
                                    @click="restoreDialog = false"
                                    color="dark darken-1"
                                    text
                                >
                                    {{ $t('miscellaneous.cancel') }}
                                </v-btn>
                                <v-spacer></v-spacer>
                                <v-btn
                                    @click="restoreInvoice"
                                    :disabled="restoring"
                                    :loading="restoring"
                                    color="error"
                                    text
                                >
                                    <span>{{ restoring ? $t('entities.invoices.restoringEntity') : $t('entities.invoices.restoreEntity') }}</span>
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-toolbar>
            </template>
            <template v-slot:item.label="{ item }">
                <span>{{ item.label || '/' }}</span>
            </template>
            <template v-slot:item.user.username="{ item }">
                <span>{{ item.user && item.user.username ? item.user.username : '/' }}</span>
            </template>
            <template v-slot:item.cart.gross="{ item }">
                <span class="font-weight-bold">{{ format(item.cart.gross, true, item.arena.company.country.currency) }}</span>
            </template>
            <template v-slot:item.cart.net="{ item }">
                <span>{{ format(item.cart.net, true, item.arena.company.country.currency) }}</span>
            </template>
            <template v-slot:item.created_at="{ item }">
                {{ fromUtcDateTimeToCurrent(item.created_at, true, true) }}
            </template>
            <template v-slot:item.fiscalized="{ item }">
                <Boolean :boolean="item.fiscalized"/>
            </template>
            <template v-slot:item.payment_method.name="{ item }">
                <span>{{ $t(`miscellaneous.paymentMethods.${titleToCamelCase(item.payment_method.name)}`) }}</span>
            </template>
            <template v-slot:item.action="{ item }">
                <ViewButton
                    :text="$t('entities.invoices.viewEntity')"
                    :item="item"
                    :openViewDialog="openViewDialog"
                />
                <v-tooltip
                    top
                    color="transparent"
                >
                    <template v-slot:activator="{ on }">
                        <v-btn
                            @click="print(item)"
                            v-on="on"
                            color="accent darken-2"
                            class="ml-2"
                            depressed
                            x-small
                            dark
                            fab
                        >
                            <v-icon
                                small
                                dark
                            >
                                print
                            </v-icon>
                        </v-btn>
                    </template>
                    <v-card class="elevation-2 px-4 py-2">
                        <span>{{ $t('entities.invoices.printEntity') }}</span>
                    </v-card>
                </v-tooltip>
                <v-tooltip
                    top
                    color="transparent"
                >
                    <template v-slot:activator="{ on }">
                        <v-btn
                            @click="openRestoreDialog(item)"
                            v-on="on"
                            :disabled="!(item.cart.gross > 0 && !item.restored_invoice_id)"
                            color="error"
                            class="ml-2"
                            depressed
                            x-small
                            fab
                        >
                            <v-icon
                                color="white"
                                small
                            >
                                restore
                            </v-icon>
                        </v-btn>
                    </template>
                    <v-card class="elevation-2 px-4 py-2">
                        <span>{{ $t('entities.invoices.restoreEntity') }}</span>
                    </v-card>
                </v-tooltip>
                <template v-if="!item.fiscalized">
                    <v-tooltip
                        top
                        color="transparent"
                    >
                        <template v-slot:activator="{ on }">
                            <v-btn
                                @click="fiscalize(item)"
                                v-on="on"
                                :disabled="fiscalizing && loadingIndexes.includes(item.id)"
                                class="ml-2"
                                color="purple"
                                depressed
                                x-small
                                fab
                            >
                                <template v-if="fiscalizing && loadingIndexes.includes(item.id)">
                                    <ProgressCircular color="purple"/>
                                </template>
                                <template v-else>
                                    <v-icon
                                        color="white"
                                        small
                                    >
                                        receipt
                                    </v-icon>
                                </template>
                            </v-btn>
                        </template>
                        <v-card class="elevation-2 px-4 py-2">
                            <span>{{ $t('entities.invoices.fiscalizeEntity') }}</span>
                        </v-card>
                    </v-tooltip>
                </template>
            </template>
            <template v-slot:no-data>
                <NoData
                    :pagination="pagination"
                    :typing="typing"
                    :fetchEntities="fetchEntities"
                />
            </template>
        </v-data-table>
        <DataTableFooter 
            :pagination="pagination"
            :loading="loading"
        />
        <Snackbar
            :show.sync="snackbar.show"
            :color="snackbar.color"
            :message="snackbar.message"
            :icon="snackbar.icon"
            :timeout="snackbar.timeout"
        />
        <Print :invoiceData="invoiceData"/>
    </div>
</template>

<script>

import Vue from 'vue';
import Boolean from '@/components/Boolean.vue';
import ViewDialog from '@/components/invoices/dialogs/ViewDialog.vue';
import NoData from '@/components/crud/NoData.vue';
import ViewButton from '@/components/crud/view/ViewButton.vue';
import RefreshButton from '@/components/crud/RefreshButton.vue';
import DataTableHeader from '@/components/crud/DataTableHeader.vue';
import DataTableSkeletonLoader from '@/components/crud/DataTableSkeletonLoader.vue';
import DataTableFooter from '@/components/crud/DataTableFooter.vue';
import ValidationErrors from '@/components/crud/ValidationErrors.vue';
import ProgressCircular from '@/components/ProgressCircular.vue';
import Print from '@/views/shop/Print.vue';
import Snackbar from '@/components/Snackbar.vue';
import Invoice from '@/models/shop/Invoice';

import { paginationWatch } from '@/mixins/crud/watch/pagination.js';
import currency from '@/mixins/currency';
import { axiosCatch } from '@/mixins/crud/methods/axios-catch.js';
import convertDate from '@/mixins/convert-date';
import { printInvoice } from '@/mixins/print-invoice.js';
import user from '@/mixins/crud/computed/user';
import convertCase from '@/mixins/convert-case';
import { showSnackbar } from "@/mixins/crud/methods/show-snackbar";
import thousandSeparator from '@/mixins/thousand-separator';
import isRole from '@/mixins/crud/computed/isRole';

export default Vue.extend({
    props: ['initialLoad', 'loading', 'title'],

    components: {
        Boolean,
        ViewDialog,
        NoData,
        Print,
        Snackbar,
        ProgressCircular,
        ViewButton,
        RefreshButton,
        DataTableHeader,
        DataTableSkeletonLoader,
        DataTableFooter,
        ValidationErrors,
    },

    mixins: [
        paginationWatch,
        user,
        currency,
        convertDate,
        convertCase,
        printInvoice,
        showSnackbar,
        thousandSeparator,
        axiosCatch,
        isRole
    ],

    data: () => ({
        viewDialog: false,
        restoreDialog: false,
        restoring: false,
        fiscalizing: false,
        typing: false,
        loadingIndexes: [],
        entity: 'invoices',
        entities: [],
        validationErrors: [],
        selectedSearchAttributes: ['To User'],
        editedIndex: -1,
        pagination: {
            search: '',
            current_page: 1,
            from: 1,
            last_page: 0,
            path: '',
            per_page: 10,
            to: 0,
            total: 0
        },
        options: {
            page: 1,
            itemsPerPage: 10,
            sortBy: [],
            sortDesc: [],
            groupBy: [],
            groupDesc: [],
            mustSort: false,
            multiSort: false
        },
        editedEntity: {
            id: '',
            restored_invoice_id: '',
            number: '',
            label: '',
            cart: {
                gross: '',
                net: '',
            },
            fiscalized: false,
            user: {},
            by_user: {},
            arena: {
                company: {
                    country: {
                        code: '',
                        currency: '',
                    }
                }
            },
            created_at: '',
            fiscal_data: {},
            payment_method: {},
            restored_invoice: null,
            restored_by_invoice: null
        },
        defaultEntity: {
            id: '',
            restored_invoice_id: '',
            number: '',
            label: '',
            cart: {
                gross: '',
                net: '',
            },
            fiscalized: false,
            user: {},
            by_user: {},
            arena: {
                company: {
                    country: {
                        code: '',
                        currency: '',
                    }
                }
            },
            created_at: '',
            fiscal_data: {},
            payment_method: {},
            restored_invoice: null,
            restored_by_invoice: null
        },
        toRestoreEntity: {
            id: '',
            restored_invoice_id: '',
            number: '',
            label: '',
            cart: {
                gross: '',
                net: '',
            },
            fiscalized: false,
            user: {},
            by_user: {},
            arena: {
                company: {
                    country: {
                        code: '',
                        currency: '',
                    }
                }
            },
            created_at: '',
            fiscal_data: {},
            payment_method: {},
            restored_invoice: null,
            restored_by_invoice: null,
            restoring_reason: ''
        },
        snackbar: {
            show: false,
            color: '',
            message: '',
        }
    }),

    computed: {
        headers() {
            const headers = [
                { text: this.$t('entities.invoices.attributes.number'), align: 'left', value: 'number' },
                { text: this.$t('entities.invoices.attributes.toUser'), align: 'left', value: 'user.username', sortable: false },
                { text: this.$t('miscellaneous.gross'), align: 'left', value: 'cart.gross', sortable: false },
                { text: this.$t('miscellaneous.createdAt'), align: 'left', value: 'created_at' },
                { text: this.$t('entities.invoices.attributes.fiscalized'), align: 'left', value: 'fiscalized', sortable: false },
                { text: this.$t('miscellaneous.paymentMethod'), align: 'left', value: 'payment_method.name', sortable: false },
                { text: this.$t('miscellaneous.actions'), value: 'action', sortable: false }
            ];

            if (!this.$store.getters.arena) {
                headers.splice(0, 0, { text: this.$t('miscellaneous.arena'), align: 'left', value: 'arena.name', sortable: false })
            }
            
            return headers;
        },
        searchAttributes() {
            return [
                { label: this.$t('entities.invoices.attributes.number'), value: 'Number' },
                { label: this.$t('entities.invoices.attributes.byUser'), value: 'By User' },
                { label: this.$t('entities.invoices.attributes.toUser'), value: 'To User' },
                { label: this.$t('entities.items.entity'), value: 'Item' },
                { label: this.$t('miscellaneous.paymentMethod'), value: 'Payment Method' },
                { label: this.$t('miscellaneous.createdAt'), value: 'Created At' },
            ]
        },
        required() {
            return [ v => !!v || this.$t('validation.required') ]
        },
    },

    watch: {
        restoreDialog(val) {
            val || this.closeRestoreDialog();
            if (!val) {
                this.$refs.form.resetValidation();
                this.validationErrors = [];
            }
        },
    },

    mounted() {
        this.refresh();
        if (this.$route.params && this.$route.params.entity) {
            this.openViewDialog(this.$route.params.entity);
        }
    },

    methods: {
        async fetchEntities() {
            try {
                const append = this.setParams();

                this.$emit('update:loading', true);

                let query = '';

                if (this.$route.path.includes('invoices')) {
                    // Invoices page
                    query = '&arena_id=';
                    if (this.isAdmin) {
                        query += this.$store.getters.arena ? this.$store.getters.arena.id : '';
                    } else {
                        // If not admin and arena not chosen, fetch non-existing arena
                        query += this.$store.getters.arena ? this.$store.getters.arena.id : '-1';
                    }
                } else {
                    // Employee dashboard page
                    query = `&date=today&by_user_id=${this.user.id}`;
                    if (this.isAdmin) {
                        query += this.$store.getters.arena ? `&arena_id=${this.$store.getters.arena.id}` : '';
                    } else {
                        query += this.$store.getters.arena ? `&arena_id=${this.$store.getters.arena.id}` : '&arena_id=-1';
                    }
                }
                const response = await Invoice.api.get(1, `${query}${append}`);
                this.entities = response.data.data.map(InvoiceDTO => new Invoice(InvoiceDTO));
                response.data.meta.per_page = parseInt(response.data.meta.per_page);
                Object.assign(this.pagination, response.data.meta);
            } catch (e) {
                console.warn('Invoices API failed.');
                console.log(e);
            } finally {
                this.$emit('update:initialLoad', false);
                this.$emit('update:loading', false);
            }
        },
        async restoreInvoice() {
            try {
                this.restoring = true;

                if (!this.$refs.form.validate()) {
                    return;
                }

                const data = {
                    by_user: {
                        id: this.user.id,
                        name: this.user.name,
                        email: this.user.email,
                        username: this.user.username,
                        pidn: this.user.pidn
                    },
                    restoring_reason: this.toRestoreEntity.restoring_reason,
                };

                await Invoice.api.restore(this.toRestoreEntity.id, data);

                this.closeRestoreDialog();
                this.showSnackbar('success', this.$t('entities.invoices.notifications.restore'));
                this.$emit('update:loading', true);
                if (this.pagination.search.length) {
                    this.pagination.search = '';
                } else {
                    await this.fetchEntities();
                }
            } catch (error) {
                let message = error.response.data.message;
                const errors = [];
                let iconAndColor = 'error';

                // Validation errors
                if (error.response.status === 422) {
                    iconAndColor = 'warning';
                    for (const i in error.response.data.errors) {
                        errors.push(`${i}:${error.response.data.errors[i]}`)
                    }
                    message += ' The following rules failed: ' + errors.join(', ');
                }

                // Unexpected errors
                if (error.response.status === 500) {
                    if (typeof error.response.data.errors !== 'undefined' ) {
                        for (const i in error.response.data.errors) {
                            errors.push(error.response.data.errors[i])
                        }
                        message += ' The following errors occurred: ' + errors.join(', ');
                    }
                }

                this.editedIndex = -1;
                this.restoreDialog = false;
                this.refresh();

                this.showSnackbar(iconAndColor, message, 10000, iconAndColor);
            } finally {
                this.restoring = false;
                this.$emit('update:loading', false);
            }
        },
        async fiscalize(invoice) {
            try {
                this.loadingIndexes.push(invoice.id);
                this.fiscalizing = true;
                this.$emit('update:loading', true);

                const data = {
                    by_user: {
                        id: this.user.id,
                        name: this.user.name,
                        email: this.user.email,
                        username: this.user.username,
                        pidn: this.user.pidn
                    },
                };

                await Invoice.api.fiscalize(invoice.id, data);

                if (this.pagination.search.length) {
                    this.pagination.search = '';
                } else {
                    await this.fetchEntities();
                }
                this.closeRestoreDialog();
                this.showSnackbar('success', this.$t('entities.invoices.notifications.fiscalize'));
            } catch (error) {
                let message = error.response.data.message;
                const errors = [];
                let iconAndColor = 'error';

                // Validation errors
                if (error.response.status === 422) {
                    iconAndColor = 'warning';
                    for (const i in error.response.data.errors) {
                        errors.push(`${i}:${error.response.data.errors[i]}`)
                    }
                    message += ' The following rules failed: ' + errors.join(', ');
                }

                // Unexpected errors
                if (error.response.status === 500) {
                    if (typeof error.response.data.errors !== 'undefined' ) {
                        for (const i in error.response.data.errors) {
                            errors.push(error.response.data.errors[i])
                        }
                        message += ' The following errors occurred: ' + errors.join(', ');
                    }
                }

                this.showSnackbar(iconAndColor, message, 10000, iconAndColor);
            } finally {
                this.$emit('update:loading', false);
                this.fiscalizing = false;
                this.loadingIndexes = this.loadingIndexes.filter(i => i !== invoice.id)
            }
        },
        openViewDialog(entity) {
            this.editedIndex = this.entities.indexOf(entity);
            this.editedEntity = {...entity};
            this.viewDialog = true;
        },
        openRestoreDialog(entity) {
            this.editedIndex = this.entities.indexOf(entity);
            this.toRestoreEntity = {...entity};
            this.restoreDialog = true;
        },
        closeRestoreDialog() {
            this.restoreDialog = false;
            this.toRestoreEntity = {...this.defaultEntity};
            this.editedIndex = -1;
        },
        print(invoice) {
            this.$set(this, 'invoiceData', {
                arena: {
                    invoice_settings: invoice.arena.invoice_settings,
                    company: {
                        pidn: invoice.arena.company.pidn,
                        name: invoice.arena.company.name,
                        address: invoice.arena.company.address,
                        image: invoice.arena.company.image,
                        fiscalData: {
                            inTaxSystem: invoice.arena.company.fiscal_data.inTaxSystem,
                        },
                        country: {
                            currency: invoice.arena.company.country.currency,
                            locale: invoice.arena.company.country.locale
                        }
                    },
                },
                invoice: {
                    restoredInvoiceLabel: invoice.restored_by_invoice ? invoice.restored_by_invoice.label : null,
                    label: invoice.label,
                    cart: {
                        gross: invoice.cart.gross,
                        net: invoice.cart.net,
                        items: invoice.cart.items,
                    },
                    taxes: invoice.taxes,
                    fiscalData: invoice.fiscal_data,
                    byUser: {
                        name: invoice.by_user.name
                    },
                    user: invoice.user,
                    paymentMethod: {
                        name: invoice.payment_method.name
                    },
                    created_at: invoice.created_at
                }
            });

            this.$nextTick(() => {
                this.printInvoice(false);
            });
        },
        refresh() {
            this.fetchEntities();
        }
    }
});

</script>


<style>

.position-relative {
    position: relative;
}

.required-fields {
    position: absolute;
    right: 13px;
    top: 15px;
    color: red;
}

</style>
