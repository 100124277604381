<template>
    <div>
        <v-card class="elevation-2">
            <DataTableHeader
                :pagination="pagination"
                :searchAttributes="searchAttributes"
                :selectedSearchAttributes.sync="selectedSearchAttributes"
                :disabled="loading"
            />
            <DataTableSkeletonLoader :loading="initialLoad"/>
            <v-data-table
                v-show="!initialLoad"
                :headers="headers"
                :items="entities"
                :dense="$vuetify.breakpoint.xs && !loading"
                :loading="loading"
                :loading-text="$t('components.noData.fetchingEntries')"
                :options.sync="options"
                :server-items-length="pagination.total"
                :items-per-page="pagination.per_page"
                :header-props="{ sortIcon: 'arrow_circle_up', sortByText: $t('miscellaneous.sortBy') }"
                :class="{ 'mobile-empty-datatable' : $vuetify.breakpoint.xsOnly }"
                hide-default-footer
            >
                <template v-slot:top>
                    <v-toolbar flat>
                        <v-toolbar-title>{{ $t('entities.inventoryItemTypes.title') }}</v-toolbar-title>
                        <v-divider
                            class="mx-4"
                            inset
                            vertical
                        ></v-divider>
                        <small class="mr-2">{{ addThousandSeparator(pagination.total) }}</small>
                        <RefreshButton
                            :refresh="refresh"
                            :loading="loading"
                        />
                        <v-spacer/>
                        <v-dialog
                            v-model="editDialog"
                            max-width="1000px"
                        >
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    v-on="on"
                                    color="primary"
                                    class="mb-2"
                                    dark
                                >
                                    {{ $t('miscellaneous.add') }}
                                </v-btn>
                            </template>
                            <v-card>
                                <v-card-text class="px-0 pb-0">
                                    <EditToolbar
                                        :formTitle="formTitle"
                                        :closeEditDialog="closeEditDialog"
                                    />
                                    <v-container>
                                        <v-card class="px-4 pb-4">
                                            <v-form
                                                @submit.prevent="save"
                                                method="POST"
                                                ref="form"
                                            >
                                                <RequiredFields/>
                                                <v-row
                                                    align="center"
                                                    class="mt-2"
                                                >
                                                    <v-col
                                                        cols="12"
                                                        sm="9"
                                                        class="position-relative"
                                                    >
                                                        <v-text-field
                                                            :value="editedEntity.name"
                                                            @change="v => (editedEntity.name = v)"
                                                            :rules="[required]"
                                                            :label="$t('generalAttributes.name')"
                                                            prepend-icon="mdi-format-title"
                                                        ></v-text-field>
                                                        <span class="required-fields">*</span>
                                                    </v-col>
                                                    <v-col
                                                        cols="12"
                                                        sm="3"
                                                    >
                                                        <v-switch
                                                            :input-value="editedEntity.active"
                                                            @change="v => (editedEntity.active = v)"
                                                            :label="$t('entities.items.attributes.active')"
                                                            prepend-icon="done"
                                                            inset
                                                        ></v-switch>
                                                    </v-col>
                                                </v-row>
                                                <ValidationErrors
                                                    v-if="validationErrors.length"
                                                    :errors="validationErrors"
                                                    class="mt-6"
                                                />
                                            </v-form>
                                        </v-card>
                                    </v-container>
                                </v-card-text>
                                <EditCardActions
                                    :save="save"
                                    :saving="saving"
                                    :closeEditDialog="closeEditDialog"
                                />
                            </v-card>
                        </v-dialog>
                        <v-dialog
                            v-model="deleteDialog"
                            max-width="1000px"
                        >
                            <v-card>
                                <v-card-text class="px-0 pb-0">
                                    <DeleteToolbar
                                        :text="$t('entities.inventoryItemTypes.deleteEntity')"
                                        :closeDeleteDialog="closeDeleteDialog"
                                    />
                                    <v-container>
                                        <v-card>
                                            <v-simple-table
                                                class="do-not-apply-hover"
                                                dense
                                            >
                                                <template v-slot:default>
                                                    <tbody>
                                                        <tr>
                                                            <td>{{ $t('generalAttributes.name') }}</td>
                                                            <td class="font-weight-bold">
                                                                {{ toDeleteEntity.name }}
                                                            </td>
                                                            <td>{{ $t('entities.achievements.type') }}</td>
                                                            <td class="font-weight-bold">
                                                                <Boolean :boolean="toDeleteEntity.active"/>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </template>
                                            </v-simple-table>
                                        </v-card>
                                        <ValidationErrors
                                            v-if="validationErrors.length"
                                            :errors="validationErrors"
                                            class="mt-6"
                                        />
                                    </v-container>
                                </v-card-text>
                                <DeleteCardActions
                                    :deleteEntity="deleteEntity"
                                    :deleting="deleting"
                                    :closeDeleteDialog="closeDeleteDialog"
                                />
                            </v-card>
                        </v-dialog>
                    </v-toolbar>
                </template>
                <template v-slot:item.active="{ item }">
                    <Boolean :boolean="item.active"/>
                </template>
                <template v-slot:item.action="{ item }">
                    <EditButton
                        :text="$t('entities.inventoryItemTypes.editEntity')"
                        :item="item"
                        :openEditDialog="openEditDialog"
                    />
                    <DeleteButton
                        :text="$t('entities.inventoryItemTypes.deleteEntity')"
                        :item="item"
                        :openDeleteDialog="openDeleteDialog"
                    />
                </template>
                <template v-slot:no-data>
                    <NoData
                        :pagination="pagination"
                        :typing="typing"
                        :fetchEntities="fetchEntities"
                    />
                </template>
            </v-data-table>
            <DataTableFooter
                :pagination="pagination"
                :loading="loading"
            />
        </v-card>
        <Snackbar
            :show.sync="snackbar.show"
            :color="snackbar.color"
            :message="snackbar.message"
        />
    </div>
</template>

<script>

import Vue from 'vue';
import Boolean from '@/components/Boolean.vue';
import NoData from '@/components/crud/NoData.vue';
import RequiredFields from '@/components/crud/RequiredFields.vue';
import DataTableHeader from '@/components/crud/DataTableHeader.vue';
import RefreshButton from '@/components/crud/RefreshButton.vue';
import EditButton from '@/components/crud/edit/EditButton.vue';
import EditToolbar from '@/components/crud/edit/EditToolbar.vue';
import EditCardActions from '@/components/crud/edit/EditCardActions.vue';
import DeleteButton from '@/components/crud/delete/DeleteButton.vue';
import DeleteToolbar from '@/components/crud/delete/DeleteToolbar.vue';
import DeleteCardActions from '@/components/crud/delete/DeleteCardActions.vue';
import DataTableSkeletonLoader from '@/components/crud/DataTableSkeletonLoader.vue';
import DataTableFooter from '@/components/crud/DataTableFooter.vue';
import InventoryItemType from '@/models/configuration/InventoryItemType';
import ValidationErrors from '@/components/crud/ValidationErrors.vue';
import Snackbar from '@/components/Snackbar.vue';

import required from '@/mixins/rules/required';
import thousandSeparator from '@/mixins/thousand-separator';
import convertCase from '@/mixins/convert-case';
import { deleteEntity } from '@/mixins/crud/methods/delete-entity.js';
import { paginationWatch } from '@/mixins/crud/watch/pagination.js';
import { editDialog } from '@/mixins/crud/watch/edit-dialog.js';
import { axiosCatch } from '@/mixins/crud/methods/axios-catch.js';
import { showSnackbar } from "@/mixins/crud/methods/show-snackbar";

export default Vue.extend({
    components: {
        NoData,
        Boolean,
        DataTableHeader,
        ValidationErrors,
        RefreshButton,
        RequiredFields,
        DataTableSkeletonLoader,
        DataTableFooter,
        EditToolbar,
        EditButton,
        EditCardActions,
        DeleteToolbar,
        DeleteButton,
        DeleteCardActions,
        Snackbar,
    },

    mixins: [
        deleteEntity,
        paginationWatch,
        editDialog,
        showSnackbar,
        axiosCatch,
        thousandSeparator,
        convertCase,
        required,
    ],

    data: () => ({
        initialLoad: true,
        loading: false,
        loadingView: false,
        saving: false,
        deleting: false,
        typing: false,
        apiURI: process.env.VUE_APP_INVENTORY_BASE_URI,
        route: 'inventory-items', // TODO
        entity: 'inventoryItemTypes',
        entities: [],
        selectedSearchAttributes: ['Name'],
        validationErrors: [],
        pagination: {
            search: '',
            current_page: 1,
            from: 1,
            last_page: 0,
            path: '',
            per_page: 10,
            to: 0,
            total: 0
        },
        options: {
            page: 1,
            itemsPerPage: 10,
            sortBy: ['id'],
            sortDesc: ['DESC'],
            groupBy: [],
            groupDesc: [],
            mustSort: false,
            multiSort: false
        
        },
        editDialog: false,
        deleteDialog: false,
        editedIndex: -1,
        defaultEntity: {
            id: '',
            name: '',
            active: 1,
            image: '',
        },
        editedEntity: {
            id: '',
            name: '',
            active: 1,
            image: '',
        },
        toDeleteEntity: {
            id: '',
            name: '',
            active: 1,
            image: '',
        },
        snackbar: {
            show: false,
            color: '',
            message: '',
        }
    }),

    computed: {
        headers() {
            return [
                { text: this.$t('generalAttributes.name'), align: 'left', value: 'name' },
                { text: this.$t('entities.items.attributes.active'), align: 'left', value: 'active' },
                { text: this.$t('miscellaneous.actions'), value: 'action', sortable: false }
            ];
        },
        searchAttributes() {
            return [
                { label: this.$t('generalAttributes.name'), value: 'Name' },
            ]
        },
        formTitle() {
            return this.editDialog ? (this.$t(`entities.${this.entity}.${this.editedIndex === -1 ? 'new' : 'edit'}Entity`)) : '';
        },
    },

    watch: {
        deleteDialog(val) {
            if (!val) {
                this.validationErrors = [];
            }
        }
    },

    mounted() {
        this.refresh();
    },

    methods: {
        async fetchEntities(append = `&size=${this.pagination.per_page}&page=${this.pagination.current_page}&sort=id,DESC`) {
            try {
                this.loading = true;
                const response = await InventoryItemType.api.get(append);
                this.entities = response.data.map(InventoryItemTypeDTO => new InventoryItemType(InventoryItemTypeDTO));
                this.pagination.current_page = 1;
                this.pagination.per_page = 100;
                this.pagination.total = this.entities.length;
                this.pagination.last_page = 1;
                this.pagination.from = 1;
                this.pagination.to = this.entities.length;
                // TODO => Pagination
                /*
                this.pagination.current_page = data.pageable.page_number + 1;
                this.pagination.per_page = data.pageable.page_size;
                this.pagination.total = data.total_elements;
                this.pagination.last_page = data.total_pages;
                this.pagination.from = data.pageable.offset + 1;
                this.pagination.to = this.pagination.from + data.number_of_elements - 1;
                */
            } catch (e) {
                console.warn('Inventory Item Types API failed.');
                console.log(e);
            } finally {
                this.initialLoad = false;
                this.loading = false;
            }
        },
        async save() {
            try {
                this.saving = true;

                if (!this.$refs.form.validate()) {
                    return;
                }
                
                // TODO
                const data = {
                    name: this.editedEntity.name,
                    active: this.editedEntity.active,
                };

                if (this.editedIndex > -1) {
                    await InventoryItemType.api.update(this.editedEntity.id, data)
                        .then(() => {
                            if (this.pagination.search.length) {
                                this.pagination.search = '';
                            } else {
                                this.fetchEntities();
                            }
                            this.showSnackbar('success', this.$t('entities.inventoryItemTypes.notifications.update'));
                            this.closeEditDialog();
                        })
                        .catch(error => {
                            this.axiosCatch(error);
                        });
                } else {
                    await InventoryItemType.api.create(data)
                        .then(() => {
                            if (this.pagination.search.length) {
                                this.pagination.search = '';
                            } else {
                                this.fetchEntities();
                            }
                            this.showSnackbar('success', this.$t('entities.inventoryItemTypes.notifications.creation'));
                            this.closeEditDialog();
                        }).catch(error => {
                            this.axiosCatch(error);
                        });
                }
            } catch (error) {
                console.warn(`Inventory Item Type Save API failed.`);
                console.log(error);
            } finally {
                this.saving = false;
            }
        },
        openEditDialog(entity) {
            this.editedIndex = this.entities.indexOf(entity);
            this.editedEntity = JSON.parse(JSON.stringify(entity));
            if (this.$refs.form) {
                this.$refs.form.resetValidation();
            }
            this.editDialog = true;
        },
        closeEditDialog() {
            this.editDialog = false;
            this.editedEntity = {...this.defaultEntity};
            this.editedIndex = -1;
        },
        openDeleteDialog(entity) {
            this.editedIndex = this.entities.indexOf(entity);
            this.toDeleteEntity = {...entity};
            this.deleteDialog = true;
        },
        closeDeleteDialog() {
            this.deleteDialog = false;
            this.toDeleteEntity = {...this.defaultEntity};
            this.editedIndex = -1;
        },
        refresh() {
            this.fetchEntities();
        }
    }
});

</script>