

import Vue from 'vue';

export default Vue.extend({
    props: {
        boolean: {
            type: Boolean,
            default: true
        },
        text: {
            type: String
        },
        margin: {
            type: String,
            default: 'ml-2'
        }
    },
})

