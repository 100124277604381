<template>
    <div>
        <GlobalButton
            :route="'/users'"
            :text="$t('entities.users.title')"
            icon="people"
        />
        <v-card class="elevation-2">
            <DataTableHeader
                :pagination="pagination"
                :searchAttributes="searchAttributes"
                :selectedSearchAttributes.sync="selectedSearchAttributes"
            />
            <DataTableSkeletonLoader :loading="initialLoad"/>
            <v-data-table
                v-show="!initialLoad"
                :headers="headers"
                :items="entities"
                :dense="$vuetify.breakpoint.xs && !loading"
                :loading="loading"
                :loading-text="$t('components.noData.fetchingEntries')"
                :options.sync="options"
                :server-items-length="pagination.total"
                :items-per-page="pagination.per_page"
                :header-props="{ sortIcon: 'arrow_circle_up', sortByText: $t('miscellaneous.sortBy') }"
                hide-default-footer
            >
                <template v-slot:top>
                    <v-toolbar flat>
                        <v-toolbar-title>{{ $t('entities.users.faultyUsers') }}</v-toolbar-title>
                        <v-divider
                            class="mx-4"
                            inset
                            vertical
                        ></v-divider>
                        <small class="mr-2">{{ pagination.total }}</small>
                        <RefreshButton :refresh="refresh"/>
                        <v-spacer></v-spacer>
                        <v-dialog
                            v-model="editDialog"
                            max-width="1000px"
                        >
                            <v-card>
                                <v-card-text class="px-0 pb-0">
                                    <EditToolbar
                                        :formTitle="formTitle"
                                        :closeEditDialog="closeEditDialog"
                                    />
                                    <v-container>
                                        <v-card class="mt-4 px-4 pb-4">
                                            <v-form
                                                @submit.prevent="save"
                                                method="POST"
                                                ref="form"
                                            >
                                                <v-row align="center">
                                                    <RequiredFields/>
                                                    <v-col
                                                        cols="12"
                                                        class="position-relative"
                                                    >
                                                        <v-text-field
                                                            :value="editedEntity.username"
                                                            @change="v => (editedEntity.username = v)"
                                                            :rules="[required, username, minimum, maximum]"
                                                            :label="$t('entities.users.attributes.username')"
                                                            prepend-icon="mdi-account-box"
                                                        ></v-text-field>
                                                        <span class="required-fields">*</span>
                                                    </v-col>
                                                </v-row>
                                                <ValidationErrors
                                                    v-if="validationErrors.length"
                                                    :errors="validationErrors"
                                                    class="mt-6"
                                                />
                                            </v-form>
                                        </v-card>
                                    </v-container>
                                </v-card-text>
                                <EditCardActions
                                    :save="save"
                                    :saving="saving"
                                    :closeEditDialog="closeEditDialog"
                                />
                            </v-card>
                        </v-dialog>
                    </v-toolbar>
                </template>
                <template v-slot:item.name="{ item }">
                    {{ item.name || '/' }}
                </template>
                <template v-slot:item.email="{ item }">
                    {{ item.email || '/' }}
                </template>
                <template v-slot:item.coins="{ item }">
                    {{ addThousandSeparator(item.coins) }}
                    <CoinImage/>
                </template>
                <template v-slot:item.experience="{ item }">
                    {{ addThousandSeparator(item.experience) }}
                    <ExperienceImage class="pt-1"/>
                </template>
                <template v-slot:item.action="{ item }">
                    <v-tooltip
                        color="transparent"
                        top
                    >
                        <template v-slot:activator="{ on }">
                            <v-btn
                                @click="openEditDialog(item)"
                                v-on="on"
                                color="primary"
                                class="glow ml-2"
                                depressed
                                x-small
                                dark
                                fab
                            >
                                <v-icon
                                    small
                                    dark
                                >
                                    person_add
                                </v-icon>
                            </v-btn>
                        </template>
                        <v-card class="elevation-2 px-4 py-2">
                            <span>{{ $t('entities.users.import') }}</span>
                        </v-card>
                    </v-tooltip>
                </template>
                <template v-slot:no-data>
                    <NoData
                        :pagination="pagination"
                        :typing="typing"
                    />
                </template>
            </v-data-table>
            <DataTableFooter :pagination="pagination"/>
        </v-card>
        <Snackbar
            :show.sync="snackbar.show"
            :color="snackbar.color"
            :message="snackbar.message"
        />
    </div>
</template>

<script>

import Vue from 'vue';
import NoData from '@/components/crud/NoData.vue';
import GlobalButton from '@/components/GlobalButton.vue';
import RefreshButton from '@/components/crud/RefreshButton.vue';
import EditToolbar from '@/components/crud/edit/EditToolbar.vue';
import EditCardActions from '@/components/crud/edit/EditCardActions.vue';
import RequiredFields from '@/components/crud/RequiredFields.vue';
import DataTableHeader from '@/components/crud/DataTableHeader.vue';
import DataTableSkeletonLoader from '@/components/crud/DataTableSkeletonLoader.vue';
import DataTableFooter from '@/components/crud/DataTableFooter.vue';
import ValidationErrors from '@/components/crud/ValidationErrors.vue';
import CoinImage from '@/components/CoinImage.vue';
import ExperienceImage from '@/components/ExperienceImage.vue';
import Snackbar from '@/components/Snackbar.vue';
import User from '@/models/users/User';

import user from '@/mixins/crud/computed/user';
import isRole from '@/mixins/crud/computed/isRole';
import thousandSeparator from '@/mixins/thousand-separator';
import required from '@/mixins/rules/required';
import { showSnackbar } from "@/mixins/crud/methods/show-snackbar";
import { paginationWatch } from '@/mixins/crud/watch/pagination.js';
import { deleteDialog } from '@/mixins/crud/watch/delete-dialog.js';

export default Vue.extend({
    components: {
        NoData,
        GlobalButton,
        CoinImage,
        ExperienceImage,
        DataTableHeader,
        DataTableSkeletonLoader,
        DataTableFooter,
        ValidationErrors,
        RefreshButton,
        EditToolbar,
        EditCardActions,
        RequiredFields,
        Snackbar,
    },

    mixins: [
        showSnackbar,
        paginationWatch,
        thousandSeparator,
        deleteDialog,
        user,
        isRole,
        required,
    ],

    data: () => ({
        initialLoad: true,
        loading: false,
        saving: false,
        typing: false,
        entity: 'users',
        entities: [],
        selectedSearchAttributes: ['Username', 'Name'],
        validationErrors: [],
        pagination: {
            search: '',
            current_page: 1,
            from: 1,
            last_page: 0,
            path: '',
            per_page: 10,
            to: 0,
            total: 0
        },
        options: {},
        editDialog: false,
        editedEntity: {
            id: null,
            username: '',
        },
    }),

    computed: {
        headers() {
            return [
                { text: this.$t('entities.users.attributes.username'), align: 'left', value: 'username' },
                { text: this.$t('entities.users.attributes.name'), align: 'left', value: 'name' },
                { text: this.$t('entities.users.attributes.email'), align: 'left', value: 'email' },
                { text: this.$t('generalAttributes.coins'), align: 'left', value: 'coins' },
                { text: this.$t('entities.ranks.attributes.experience'), align: 'left', value: 'experience' },
                { text: this.$t('miscellaneous.actions'), value: 'action', sortable: false }
            ];
        },
        searchAttributes() {
            return [
                { label: this.$t('entities.users.attributes.username'), value: 'Username' },
                { label: this.$t('entities.users.attributes.name'), value: 'Name' },
                { label: this.$t('entities.users.attributes.email'), value: 'Email' },
            ]
        },
        formTitle() {
            return this.$t('entities.users.import');
        },
        minimum() {
            return v => v && v.length >= 3 || this.$t('validation.minimum', { count: 3 });
        },
        maximum() {
            return v => v && v.length <= 64 || this.$t('validation.maximum', { count: 64 });
        },
        username() {
            return v => /^[a-zA-Z0-9_]+$/.test(v) || this.$t('validation.username');
        },
    },

    watch: {
        editDialog(val) {
            if (this.$refs.form) {
                this.$refs.form.resetValidation();
            }
            val || this.closeEditDialog();
            if (!val) {
                this.validationErrors = [];
            } else {
                this.editedEntity.username = '';
            }
        },
    },

    mounted() {
        this.refresh();
    },

    methods: {
        async fetchEntities(append = ``) {
            try {
                this.loading = true;
                const response = await User.api.getOld(append);
                const data = response.data;
                this.entities = data.content.map(UserDTO => new User(UserDTO));
                this.pagination.current_page = data.pageable.page_number + 1;
                this.pagination.per_page = data.pageable.page_size;
                this.pagination.total = data.total_elements;
                this.pagination.last_page = data.total_pages;
                this.pagination.from = data.pageable.offset + 1;
                this.pagination.to = this.pagination.from + data.number_of_elements - 1;
            } catch (e) {
                console.warn(`Users API failed.`);
                console.log(e);
            } finally {
                this.initialLoad = false;
                this.loading = false;
            }
        },
        async save() {
            try {
                this.saving = true;

                if (!this.$refs.form.validate()) {
                    return;
                }

                await User.api.import(this.editedEntity.id, { username: this.editedEntity.username })
                    .then(() => {
                        if (this.pagination.search.length) {
                            this.pagination.search = '';
                        } else {
                            this.fetchEntities();
                        }
                        this.showSnackbar('success', this.$t('entities.users.notifications.import'));
                        this.closeEditDialog();
                    }).catch(error => {
                        // Server error
                        if (error.response.status === 500) {
                            this.validationErrors.push(`Something went wrong with error code: 500. ${error.response.data}.`)
                        }
                    });
            } catch (error) {
                console.warn(`User Import API failed.`);
                console.log(error);
            } finally {
                this.saving = false;
            }
        },
        openEditDialog(entity) {
            this.editedIndex = this.entities.indexOf(entity);
            this.editedEntity = {...entity};
            if (this.$refs.form) {
                this.$refs.form.resetValidation();
            }
            this.editDialog = true;
        },
        closeEditDialog() {
            this.editDialog = false;
            this.editedEntity = {...this.defaultEntity};
            this.editedIndex = -1;
        },
        openDeleteDialog(entity) {
            this.editedIndex = this.entities.indexOf(entity);
            this.toDeleteEntity = {...entity};
            this.deleteDialog = true;
        },
        closeDeleteDialog() {
            this.deleteDialog = false;
            this.toDeleteEntity = {...this.defaultEntity};
            this.editedIndex = -1;
        },
        refresh() {
            this.fetchEntities();
        }
    }
});

</script>


<style>

.position-relative {
    position: relative;
}

.required-fields {
    position: absolute;
    right: 13px;
    top: 15px;
    color: red;
}

</style>
