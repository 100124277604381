<template>
    <div>
        <v-row v-if="!$store.getters.arena">
            <v-col
                cols="12"
                class="pt-0"
            >
                <v-alert type="info">
                    <span class="mb-0 text--white">{{ $t('entities.analytics.chooseArena') }}</span>
                </v-alert>
            </v-col>
        </v-row>
        <v-row>
            <v-col
                cols="12"
                sm="6"
                class="pb-0"
            >
                <v-row align="center">
                    <v-col
                        cols="auto"
                        class="py-0 pr-0"
                    >
                        <v-tooltip
                            color="transparent"
                            top
                        >
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    @click="refresh"
                                    v-on="on"
                                    :disabled="loading"
                                    class="glow mr-2"
                                    color="primary"
                                    x-small
                                    fab
                                >
                                    <v-icon>
                                        refresh
                                    </v-icon>
                                </v-btn>
                            </template>
                            <v-card class="elevation-2 px-2 py-1">
                                <small>{{ $t('refreshData') }}</small>
                            </v-card>
                        </v-tooltip>
                    </v-col>
                    <v-col
                        cols="10"
                        class="py-0 pl-2"
                    >
                        <p class="mt-2 mb-0">
                            {{ $t('generated') }}: {{ dateString }}
                        </p>
                    </v-col>
                </v-row>
            </v-col>
            <v-col
                cols="12"
                sm="6"
                class="text-center text-sm-right"
            >
                <v-row :justify="$vuetify.breakpoint.xsOnly ? 'center' : 'end'">
                    <v-col
                        :cols="$vuetify.breakpoint.xsOnly ? '12' : '5'"
                        class="pb-0 pt-8 pt-sm-0"
                    >
                        <v-select
                            v-model="range"
                            :items="ranges"
                            @input="updateRanges"
                            :menu-props="{ bottom: true, offsetY: true }"
                            :label="$t('entities.analytics.predefinedRanges')"
                            class="do-not-apply-transform"
                            prepend-inner-icon="mdi-label"
                            solo
                        >
                            <template v-slot:selection="data">
                                {{ $t(`entities.analytics.ranges.${data.item}`) }}
                            </template>
                            <template v-slot:item="data">
                                {{ $t(`entities.analytics.ranges.${data.item}`) }}
                            </template>
                        </v-select>
                    </v-col>
                    <v-col
                        :cols="$vuetify.breakpoint.xsOnly ? '12' : 'auto'"
                        class="pb-0 pt-0"
                    >
                        <v-menu
                            v-model="menu"
                            :nudge-width="200"
                            :close-on-content-click="false"
                            class="pl-4"
                            offset-x
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    v-bind="attrs"
                                    v-on="on"
                                    :block="$vuetify.breakpoint.xsOnly"
                                    color="primary"
                                    class="font-weight-bold"
                                >
                                    {{ $t('entities.analytics.chooseRange') }}
                                </v-btn>
                            </template>
                            <v-card>
                                <v-list>
                                    <v-list-item class="px-0">
                                        <v-list-item-content class="text-capitalize py-0">
                                            <v-date-picker
                                                v-model="dates"
                                                :max="new Date().toISOString().substr(0, 10)"
                                                :selected-items-text="dateRangeText"
                                                :locale="$i18n.locale"
                                                range
                                            ></v-date-picker>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list>
                            </v-card>
                        </v-menu>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <v-row>
            <v-col
                cols="12"
                class="d-flex flex-column text-left"
            >
                <v-card class="flex d-flex flex-column position-relative pa-6">
                    <v-row>
                        <v-col
                            cols="12"
                            class="py-0"
                        >
                            <p class="mt-8 mt-sm-3 grey--text">
                                {{ $t('entities.analytics.dataIsShownForRange') }} {{ dateRangeText }}
                            </p>
                        </v-col>
                        <v-col
                            cols="12"
                            sm="10"
                            class="py-0"
                        >
                            <h3 class="grey--text text--darken-1 mb-6">
                                {{ $t('miscellaneous.income') }}<span v-if="!loading">:</span><template v-if="$store.getters.arena"><v-skeleton-loader :loading="loading" type="image" width="75px" height="25px" class="d-inline-block ml-2"><span>{{ format(totalIncome, true, $store.getters.arena.company.country.currency) }}</span></v-skeleton-loader></template>
                            </h3>
                        </v-col>
                    </v-row>
                    <canvas
                        v-show="chartsData.income.display"
                        id="income-chart"
                    ></canvas>
                    <div
                        v-if="loading"
                        :style="{ top : `${$vuetify.breakpoint.xsOnly ? '60' : '45'}%`, left : `${$vuetify.breakpoint.xsOnly ? '40' : '45'}%` }"
                        class="overlay"
                    >
                        <span class="grey--text text--darken-1">{{ $t('miscellaneous.loading') }}</span>
                        <ProgressCircular 
                            color="primary"
                            class="mb-1 ml-2"
                        />
                    </div>
                    <p
                        v-show="!chartsData.income.display"
                        class="grey--text"
                    >
                        {{ $t('entities.analytics.noData') }}
                    </p>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>

import Vue from 'vue';
import ProgressCircular from '@/components/ProgressCircular.vue';
import DashboardApi from '@/services/main/DashboardApi';
import Chart from 'chart.js/auto';

import formatDate from '@/mixins/format-date';
import chartsData from '@/mixins/charts-data';
import currency from '@/mixins/currency';

export default Vue.extend({
    components: {
        ProgressCircular,
    },

    mixins: [
        formatDate,
        currency,
    ],

    data: () => ({
        initialLoad: true,
        loading: false,
        menu: false,
        date: new Date(),
        dateString: '',
        range: 2,
        ranges: [0, 1, 2, 3, 4, 5, 6, 6],
        dates: [
            new Date(new Date().getFullYear(), new Date().getMonth(), 2).toISOString().substr(0, 10),
            new Date().toISOString().substr(0, 10)
        ],
        charts: {
            income: null,
        },
        chartsData: chartsData,
        totalIncome: 0,
    }),

    watch: {
        dates() {
            if (this.dates.length === 2) {
                this.fetchAnalyticsData(true);
            }
        }
    },

    computed: {
        dateRangeText() {
            if (this.dates.length === 2) {
                const firstDate = new Date(this.dates[0]);
                const secondDate = new Date(this.dates[1]);
                return secondDate > firstDate ? (this.reformatDate(this.dates[0]) + ' - ' + this.reformatDate(this.dates[1])) : (this.reformatDate(this.dates[0]) + ' - ' + this.reformatDate(this.dates[0]));
            } else {
                return this.reformatDate(this.dates[0]);
            }
        }
    },

    mounted() {
        this.refresh(false);
    },

    methods: {
        async fetchAnalyticsData(update = false) {
            try {
                this.loading = true;
                if (this.$store.getters.arena) {
                    const params = {
                        params: {
                            arena_id: this.$store.getters.arena.id,
                            dates: this.dates
                        }
                    }
                    const response = await DashboardApi.analytics(params);
                    this.chartsData.income.data.datasets = response.data.datasets;
                    this.chartsData.income.display = response.data.display;
                    this.totalIncome = response.data.total_income;
                    if (update) {
                        this.charts.income.destroy();
                    }
                    const ctx = document.getElementById('income-chart');
                    this.charts.income = new Chart(ctx, {
                        type: this.chartsData.income.type,
                        data: this.chartsData.income.data,
                        options: this.chartsData.income.options,
                    });
                }
            } catch (e) {
                console.warn(`Analytics API failed.`);
                console.log(e);
            } finally {
                this.initialLoad = false;
                this.loading = false;
            }
        },
        reformatDate(dateStr) {
            return this.formatDate(new Date(dateStr), false, true, true);
        },
        updateRanges() {
            const date = new Date();
            if (this.range === 0) {
                const d = new Date(date);
                const day = d.getDay(), diff = d.getDate() - day + (day === 0 ? -6 : 1);
                this.dates = [
                    new Date(d.setDate(diff)).toISOString().substr(0, 10),
                    date.toISOString().substr(0, 10)
                ];
            } else if (this.range === 1) {
                const to = date.setTime(date.getTime() - (date.getDay() ? date.getDay() : 7) * 24 * 60 * 60 * 1000);
                const from = date.setTime(date.getTime() - 6 * 24 * 60 * 60 * 1000);
                this.dates = [
                    new Date(from).toISOString().substr(0, 10),
                    new Date(to).toISOString().substr(0, 10),
                ];
            } else if (this.range === 2) {
                const firstDayOfTheMonth = new Date(date.getFullYear(), date.getMonth(), 2);
                this.dates = [
                    firstDayOfTheMonth.toISOString().substr(0, 10),
                    date.toISOString().substr(0, 10)
                ];
            } else if (this.range === 3) {
                const firstDayPreviousMonth = new Date(date.getFullYear(), date.getMonth() - 1, 2);
                const lastDayPreviousMonth = new Date(date.getFullYear(), date.getMonth(), 1);
                this.dates = [
                    firstDayPreviousMonth.toISOString().substr(0, 10),
                    lastDayPreviousMonth.toISOString().substr(0, 10)
                ];
            } else if (this.range === 4) {
                const firstDayMonth = new Date(date.getFullYear(), date.getMonth() - 3, 2);
                this.dates = [
                    firstDayMonth.toISOString().substr(0, 10),
                    date.toISOString().substr(0, 10)
                ];
            } else if (this.range === 5) {
                this.dates = [
                    date.getFullYear() + '-01-01',
                    date.toISOString().substr(0, 10)
                ];
            } else if (this.range === 6) {
                const lastYear = (parseInt(date.getFullYear()) - 1).toString();
                this.dates = [
                    lastYear + '-01-01',
                    lastYear + '-12-31',
                ];
            }
        },
        refresh(update = true) {
            this.dateString = this.formatDate(new Date(), true, true);
            this.fetchAnalyticsData(update);
        },
    }
});

</script>

<style>

.overlay {
    position: absolute;
    background: #fff;
    padding: 10px 15px;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.25);
}

</style>