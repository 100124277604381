

import Vue from 'vue';
import Pagination from '@/components/crud/Pagination.vue';

import thousandSeparator from '@/mixins/thousand-separator';

export default Vue.extend({
    props: {
        pagination: {
            type: Object
        },
        disabled: {
            type: Boolean,
            default: false
        },
        loading: {
            type: Boolean,
            default: false
        }
    },

    components: {
        Pagination,
    },

    mixins: [
        thousandSeparator
    ]
});

