<template>
    <div>
        <v-card class="elevation-2">
            <DataTableHeader
                :pagination="pagination"
                :searchAttributes="searchAttributes"
                :selectedSearchAttributes.sync="selectedSearchAttributes"
                :disabled="loading"
            />
            <DataTableSkeletonLoader :loading="initialLoad"/>
            <v-data-table
                v-show="!initialLoad"
                :headers="headers"
                :items="entities"
                :dense="$vuetify.breakpoint.xs && !loading"
                :loading="loading"
                :loading-text="$t('components.noData.fetchingEntries')"
                :options.sync="options"
                :server-items-length="pagination.total"
                :items-per-page="pagination.per_page"
                :header-props="{ sortIcon: 'arrow_circle_up', sortByText: $t('miscellaneous.sortBy') }"
                :class="{ 'mobile-empty-datatable' : $vuetify.breakpoint.xsOnly }"
                hide-default-footer
            >
                <template v-slot:top>
                    <v-toolbar flat>
                        <v-toolbar-title>{{ $t('entities.countries.title') }}</v-toolbar-title>
                        <v-divider
                            class="mx-4"
                            inset
                            vertical
                        ></v-divider>
                        <small class="mr-2">{{ pagination.total }}</small>
                        <RefreshButton
                            :refresh="refresh"
                            :loading="loading"
                        />
                        <v-spacer></v-spacer>
                        <v-dialog
                            v-model="editDialog"
                            max-width="1000px"
                        >
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    v-on="on"
                                    color="primary"
                                    class="mb-2"
                                    dark
                                >
                                    {{ $t('miscellaneous.add') }}
                                </v-btn>
                            </template>
                            <v-card>
                                <v-card-text class="px-0 pb-0">
                                    <EditToolbar
                                        :formTitle="formTitle"
                                        :closeEditDialog="closeEditDialog"
                                    />
                                    <v-container>
                                        <v-card class="mt-4 px-4 pb-4">
                                            <v-form
                                                @submit.prevent="save"
                                                method="POST"
                                                ref="form"
                                            >
                                                <v-row align="center">
                                                    <RequiredFields/>
                                                    <v-col
                                                        cols="12"
                                                        sm="6"
                                                        class="position-relative"
                                                    >
                                                        <v-text-field
                                                            :value="editedEntity.name"
                                                            @change="v => (editedEntity.name = v)"
                                                            :rules="[required]"
                                                            :label="$t('generalAttributes.name')"
                                                            prepend-icon="mdi-format-title"
                                                        ></v-text-field>
                                                        <span class="required-fields">*</span>
                                                    </v-col>
                                                    <v-col
                                                        cols="12"
                                                        sm="6"
                                                        class="position-relative"
                                                    >
                                                        <v-text-field
                                                            :value="editedEntity.currency"
                                                            @change="v => (editedEntity.currency = v)"
                                                            :rules="[required]"
                                                            :label="$t('entities.countries.attributes.currency')"
                                                            prepend-icon="payments"
                                                        ></v-text-field>
                                                        <span class="required-fields">*</span>
                                                    </v-col>
                                                    <v-col
                                                        cols="12"
                                                        sm="6"
                                                        class="position-relative"
                                                    >
                                                        <v-text-field
                                                            :value="editedEntity.locale"
                                                            @change="v => (editedEntity.locale = v)"
                                                            :rules="[required]"
                                                            :label="$t('entities.countries.attributes.locale')"
                                                            prepend-icon="language"
                                                        ></v-text-field>
                                                        <span class="required-fields">*</span>
                                                    </v-col>
                                                    <v-col
                                                        cols="12"
                                                        sm="6"
                                                        class="position-relative"
                                                    >
                                                        <v-text-field
                                                            :value="editedEntity.code"
                                                            @change="v => (editedEntity.code = v)"
                                                            :rules="[required]"
                                                            :label="$t('entities.countries.attributes.code')"
                                                            prepend-icon="short_text"
                                                        ></v-text-field>
                                                        <span class="required-fields">*</span>
                                                    </v-col>
                                                    <v-col
                                                        cols="12"
                                                        sm="6"
                                                        class="position-relative"
                                                    >
                                                        <v-text-field
                                                            :value="editedEntity.legal_age"
                                                            @change="v => (editedEntity.legal_age = v)"
                                                            :rules="[integer, between]"
                                                            :label="$t('entities.countries.attributes.legalAge')"
                                                            prepend-icon="face"
                                                        ></v-text-field>
                                                        <span class="required-fields">*</span>
                                                    </v-col>
                                                    <v-col
                                                        cols="12"
                                                        sm="6"
                                                        class="position-relative"
                                                    >
                                                        <v-text-field
                                                            :value="editedEntity.coins_per_currency"
                                                            @change="v => (editedEntity.coins_per_currency = v)"
                                                            :rules="[integer]"
                                                            :label="$t('entities.countries.attributes.coinsPerCurrency')"
                                                            type="number"
                                                        >
                                                            <template v-slot:prepend>
                                                                <CoinImage/>
                                                            </template>
                                                        </v-text-field>
                                                        <span class="required-fields">*</span>
                                                    </v-col>
                                                    <v-col
                                                        cols="12"
                                                        class="px-3"
                                                    >
                                                        <v-expansion-panels
                                                            :value="editDialog"
                                                            flat
                                                        >
                                                            <v-expansion-panel>
                                                                <v-expansion-panel-header class="pr-4 grey-border">
                                                                    <h4 class="pl-4">
                                                                        {{ $t('entities.countries.taxes.title') }}
                                                                    </h4>
                                                                </v-expansion-panel-header>
                                                                <v-expansion-panel-content class="grey-border pt-4">
                                                                    <template v-if="editedEntity.taxes && editedEntity.taxes.length">
                                                                        <v-row
                                                                            v-for="index in editedEntity.taxes.length"
                                                                            :key="'tax-' + index"
                                                                            align="center"
                                                                            class="px-3"
                                                                        >
                                                                            <v-col
                                                                                cols="12"
                                                                                sm="4"
                                                                                class="position-relative px-2"
                                                                            >
                                                                                <v-text-field
                                                                                    :value="editedEntity.taxes[index - 1].name"
                                                                                    @change="v => (editedEntity.taxes[index - 1].name = v)"
                                                                                    :rules="[required]"
                                                                                    :label="$t('generalAttributes.name')"
                                                                                    prepend-icon="mdi-format-title"
                                                                                ></v-text-field>
                                                                                <span class="required-fields">*</span>
                                                                            </v-col>
                                                                            <v-col
                                                                                cols="12"
                                                                                sm="4"
                                                                                class="px-2"
                                                                            >
                                                                                <v-text-field
                                                                                    :value="editedEntity.taxes[index - 1].amount"
                                                                                    @change="v => (editedEntity.taxes[index - 1].amount = v)"
                                                                                    :rules="[integer, between]"
                                                                                    :label="$t('generalAttributes.amount')"
                                                                                    prepend-icon="input"
                                                                                    type="number"
                                                                                    suffix="%"
                                                                                ></v-text-field>
                                                                            </v-col>
                                                                            <v-col
                                                                                cols="12"
                                                                                sm="3"
                                                                                class="px-2"
                                                                            >
                                                                                <v-switch
                                                                                    :input-value="editedEntity.taxes[index - 1].active"
                                                                                    @change="v => (editedEntity.taxes[index - 1].active = v)"
                                                                                    :label="$t('entities.countries.taxes.attributes.active')"
                                                                                    prepend-icon="done"
                                                                                    inset
                                                                                ></v-switch>
                                                                            </v-col>
                                                                            <v-col
                                                                                cols="12"
                                                                                sm="1"
                                                                                class="px-2"
                                                                            >
                                                                                <v-btn
                                                                                    @click="editedEntity.taxes.splice(index - 1, 1)"
                                                                                    color="error"
                                                                                    rounded
                                                                                >
                                                                                    X
                                                                                </v-btn>
                                                                            </v-col>
                                                                        </v-row>
                                                                    </template>
                                                                    <template v-if="editedIndex !== -1 && (!editedEntity.taxes || !editedEntity.taxes.length)">
                                                                        <p class="pl-4 pt-4 pb-0">{{ $t('entities.countries.taxes.notFound') }}</p>
                                                                    </template>
                                                                    <v-row v-if="editedIndex !== -1">
                                                                        <v-col class="text-center pt-0 mb-2">
                                                                            <v-btn
                                                                                @click="editedEntity.taxes.push({ name: '', amount: 0, active: 1 })"
                                                                                color="primary"
                                                                                class="px-12"
                                                                                rounded
                                                                            >
                                                                                {{ $t('entities.countries.taxes.addNew') }}
                                                                            </v-btn>
                                                                        </v-col>
                                                                    </v-row>
                                                                    <v-row v-else>
                                                                        <v-col>
                                                                            <p class="pl-4 pb-0">{{ $t('entities.countries.taxes.canBeCreated') }}</p>
                                                                        </v-col>
                                                                    </v-row>
                                                                </v-expansion-panel-content>
                                                            </v-expansion-panel>
                                                        </v-expansion-panels>
                                                    </v-col>
                                                    <v-col
                                                        cols="12"
                                                        class="px-3"
                                                    >
                                                        <v-expansion-panels
                                                            :value="editDialog"
                                                            flat
                                                        >
                                                            <v-expansion-panel>
                                                                <v-expansion-panel-header class="pr-4 grey-border">
                                                                    <h4 class="pl-4">
                                                                        {{ $t('generalAttributes.map') }}
                                                                    </h4>
                                                                </v-expansion-panel-header>
                                                                <v-expansion-panel-content class="grey-border">
                                                                    <v-row
                                                                        align="center"
                                                                        class="mt-2 px-3"
                                                                    >
                                                                        <v-col
                                                                            cols="12"
                                                                            sm="4"
                                                                        >
                                                                            <v-text-field
                                                                                :value="editedEntity.geo_map.latitude"
                                                                                @change="v => (editedEntity.geo_map.latitude = v)"
                                                                                :label="$t('generalAttributes.latitude')"
                                                                                prepend-icon="language"
                                                                            ></v-text-field>
                                                                        </v-col>
                                                                        <v-col
                                                                            cols="12"
                                                                            sm="4"
                                                                        >
                                                                            <v-text-field
                                                                                :value="editedEntity.geo_map.longitude"
                                                                                @change="v => (editedEntity.geo_map.longitude = v)"
                                                                                :label="$t('generalAttributes.longitude')"
                                                                                prepend-icon="language"
                                                                            ></v-text-field>
                                                                        </v-col>
                                                                        <v-col
                                                                            cols="12"
                                                                            sm="4"
                                                                        >
                                                                            <v-text-field
                                                                                :value="editedEntity.geo_map.zoom"
                                                                                @change="v => (editedEntity.geo_map.zoom = v)"
                                                                                :label="$t('generalAttributes.zoom')"
                                                                                prepend-icon="zoom_in"
                                                                            ></v-text-field>
                                                                        </v-col>
                                                                    </v-row>
                                                                </v-expansion-panel-content>
                                                            </v-expansion-panel>
                                                        </v-expansion-panels>
                                                    </v-col>
                                                </v-row>
                                                <ValidationErrors
                                                    v-if="validationErrors.length"
                                                    :errors="validationErrors"
                                                    class="mt-6"
                                                />
                                            </v-form>
                                        </v-card>
                                    </v-container>
                                </v-card-text>
                                <EditCardActions
                                    :save="save"
                                    :saving="saving"
                                    :closeEditDialog="closeEditDialog"
                                />
                            </v-card>
                        </v-dialog>
                        <v-dialog
                            v-model="deleteDialog"
                            max-width="1000px"
                        >
                            <v-card>
                                <v-card-text class="px-0 pb-0">
                                    <DeleteToolbar
                                        :text="$t('entities.countries.deleteEntity')"
                                        :closeDeleteDialog="closeDeleteDialog"
                                    />
                                    <v-container>
                                        <DialogImage
                                            :image="toDeleteEntity.image"
                                            :name="toDeleteEntity.name"
                                        />
                                        <v-card>
                                            <v-simple-table
                                                class="do-not-apply-hover"
                                                dense
                                            >
                                                <template v-slot:default>
                                                    <tbody>
                                                        <tr>
                                                            <td>{{ $t('generalAttributes.name') }}</td>
                                                            <td class="font-weight-bold">
                                                                {{ $te(`miscellaneous.countries.${toDeleteEntity.code.toLowerCase()}`) ? $t(`miscellaneous.countries.${toDeleteEntity.code.toLowerCase()}`) : toDeleteEntity.name }}
                                                            </td>
                                                            <td>{{ $t('entities.countries.attributes.currency') }}</td>
                                                            <td class="font-weight-bold">
                                                                {{ toDeleteEntity.currency }}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>{{ $t('entities.countries.attributes.locale') }}</td>
                                                            <td
                                                                colspan="3"
                                                                class="font-weight-bold"
                                                            >
                                                                {{ toDeleteEntity.locale }}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>{{ $t('entities.countries.attributes.code') }}</td>
                                                            <td class="font-weight-bold">
                                                                {{ toDeleteEntity.code }}
                                                            </td>
                                                            <td>{{ $t('entities.countries.attributes.legalAge') }}</td>
                                                            <td class="font-weight-bold">
                                                                {{ toDeleteEntity.legal_age }}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                {{ $t('generalAttributes.map') }}
                                                                <v-tooltip
                                                                    color="transparent"
                                                                    top
                                                                >
                                                                    <template v-slot:activator="{ on }">
                                                                        <v-btn
                                                                            :href="`https://maps.google.com/maps?z=${toDeleteEntity.geo_map.zoom}&t=m&q=loc:${toDeleteEntity.geo_map.latitude}+${toDeleteEntity.geo_map.longitude}`"
                                                                            v-on="on"
                                                                            target="_blank"
                                                                            class="ml-4"
                                                                            x-small
                                                                            fab
                                                                        >
                                                                            <v-icon color="error">location_on</v-icon>
                                                                        </v-btn>
                                                                    </template>
                                                                    <v-card class="elevation-2 px-2 py-1">
                                                                        <small>{{ $t('miscellaneous.viewInMaps') }}</small>
                                                                    </v-card>
                                                                </v-tooltip>
                                                            </td>
                                                            <td class="font-weight-bold">
                                                                {{ $t('generalAttributes.latitude') }}: {{ toDeleteEntity.geo_map.latitude }}<br>
                                                                {{ $t('generalAttributes.longitude') }}: {{ toDeleteEntity.geo_map.longitude }}<br>
                                                                {{ $t('generalAttributes.zoom') }}: {{ toDeleteEntity.geo_map.zoom }}
                                                            </td>
                                                            <td>{{ $t('entities.countries.attributes.coinsPerCurrency') }}</td>
                                                            <td class="font-weight-bold">
                                                                {{ toDeleteEntity.coins_per_currency }} <CoinImage/>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </template>
                                            </v-simple-table>
                                        </v-card>
                                    </v-container>
                                </v-card-text>
                                <DeleteCardActions
                                    :deleteEntity="deleteEntity"
                                    :deleting="deleting"
                                    :closeDeleteDialog="closeDeleteDialog"
                                />
                            </v-card>
                        </v-dialog>
                        <v-dialog
                            v-model="viewDialog"
                            max-width="1000px"
                        >
                            <v-card>
                                <v-card-text class="px-0 pb-0">
                                    <v-toolbar
                                        class="elevation-2"
                                        color="primary"
                                    >
                                        <v-toolbar-title class="ml-0">
                                            <span class="font-weight-bold white--text">{{ $t('entities.countries.viewEntity') }}</span>
                                        </v-toolbar-title>
                                        <v-spacer></v-spacer>
                                        <v-btn
                                            @click="viewDialog = false"
                                            icon
                                            dark
                                        >
                                            <v-icon>mdi-close</v-icon>
                                        </v-btn>
                                    </v-toolbar>
                                    <v-container>
                                        <v-card>
                                            <v-simple-table
                                                class="do-not-apply-hover"
                                                dense
                                            >
                                                <template v-slot:default>
                                                    <tbody>
                                                        <tr>
                                                            <td>{{ $t('generalAttributes.name') }}</td>
                                                            <td class="font-weight-bold">
                                                                {{ $te(`miscellaneous.countries.${editedEntity.code.toLowerCase()}`) ? $t(`miscellaneous.countries.${editedEntity.code.toLowerCase()}`) : editedEntity.name }}
                                                            </td>
                                                            <td>{{ $t('entities.countries.attributes.currency') }}</td>
                                                            <td class="font-weight-bold">
                                                                {{ editedEntity.currency }}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>{{ $t('entities.countries.attributes.locale') }}</td>
                                                            <td
                                                                colspan="3"
                                                                class="font-weight-bold"
                                                            >
                                                                {{ editedEntity.locale }}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>{{ $t('entities.countries.attributes.code') }}</td>
                                                            <td class="font-weight-bold">
                                                                {{ editedEntity.code }}
                                                            </td>
                                                            <td>{{ $t('entities.countries.attributes.legalAge') }}</td>
                                                            <td class="font-weight-bold">
                                                                {{ editedEntity.legal_age }}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                {{ $t('generalAttributes.map') }}
                                                                <v-tooltip
                                                                    color="transparent"
                                                                    top
                                                                >
                                                                    <template v-slot:activator="{ on }">
                                                                        <v-btn
                                                                            :href="`https://maps.google.com/maps?z=${editedEntity.geo_map.zoom}&t=m&q=loc:${editedEntity.geo_map.latitude}+${editedEntity.geo_map.longitude}`"
                                                                            v-on="on"
                                                                            target="_blank"
                                                                            class="ml-4"
                                                                            x-small
                                                                            fab
                                                                        >
                                                                            <v-icon color="error">location_on</v-icon>
                                                                        </v-btn>
                                                                    </template>
                                                                    <v-card class="elevation-2 px-2 py-1">
                                                                        <small>{{ $t('miscellaneous.viewInMaps') }}</small>
                                                                    </v-card>
                                                                </v-tooltip>
                                                            </td>
                                                            <td class="font-weight-bold">
                                                                {{ $t('generalAttributes.latitude') }}: {{ editedEntity.geo_map.latitude }}<br>
                                                                {{ $t('generalAttributes.longitude') }}: {{ editedEntity.geo_map.longitude }}<br>
                                                                {{ $t('generalAttributes.zoom') }}: {{ editedEntity.geo_map.zoom }}
                                                            </td>
                                                            <td>{{ $t('entities.countries.attributes.coinsPerCurrency') }}</td>
                                                            <td class="font-weight-bold">
                                                                {{ editedEntity.coins_per_currency }} <CoinImage/>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </template>
                                            </v-simple-table>
                                        </v-card>
                                    </v-container>
                                </v-card-text>
                                <v-divider/>
                                <v-card-actions>
                                    <v-spacer/>
                                    <v-btn
                                        @click="viewDialog = false"
                                        color="dark darken-1"
                                        text
                                    >
                                        {{ $t('miscellaneous.ok') }}
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </v-toolbar>
                </template>
                <template v-slot:item.name="{ item }">
                    {{ $te(`miscellaneous.countries.${item.code.toLowerCase()}`) ? $t(`miscellaneous.countries.${item.code.toLowerCase()}`) : item.name }}
                </template>
                <template v-slot:item.currency="{ item }">
                    {{ item.currency || '/' }}
                </template>
                <template v-slot:item.locale="{ item }">
                    {{ item.locale || '/' }}
                </template>
                <template v-slot:item.action="{ item }">
                    <ViewButton
                        :text="$t('entities.countries.viewEntity')"
                        :item="item"
                        :openViewDialog="openViewDialog"
                    />
                    <EditButton
                        :text="$t('entities.countries.editEntity')"
                        :item="item"
                        :openEditDialog="openEditDialog"
                    />
                    <DeleteButton
                        :text="$t('entities.countries.deleteEntity')"
                        :item="item"
                        :openDeleteDialog="openDeleteDialog"
                    />
                </template>
                <template v-slot:no-data>
                    <NoData
                        :pagination="pagination"
                        :typing="typing"
                        :fetchEntities="fetchEntities"
                    />
                </template>
            </v-data-table>
            <DataTableFooter
                :pagination="pagination"
                :loading="loading"
            />
        </v-card>
        <Snackbar
            :show.sync="snackbar.show"
            :color="snackbar.color"
            :message="snackbar.message"
        />
    </div>
</template>

<script>

import Vue from 'vue';
import CoinImage from '@/components/CoinImage.vue';
import NoData from '@/components/crud/NoData.vue';
import ViewButton from '@/components/crud/view/ViewButton.vue';
import RefreshButton from '@/components/crud/RefreshButton.vue';
import EditButton from '@/components/crud/edit/EditButton.vue';
import EditToolbar from '@/components/crud/edit/EditToolbar.vue';
import EditCardActions from '@/components/crud/edit/EditCardActions.vue';
import DeleteButton from '@/components/crud/delete/DeleteButton.vue';
import DeleteToolbar from '@/components/crud/delete/DeleteToolbar.vue';
import DeleteCardActions from '@/components/crud/delete/DeleteCardActions.vue';
import DialogImage from '@/components/DialogImage';
import RequiredFields from '@/components/crud/RequiredFields.vue';
import DataTableHeader from '@/components/crud/DataTableHeader.vue';
import DataTableSkeletonLoader from '@/components/crud/DataTableSkeletonLoader.vue';
import DataTableFooter from '@/components/crud/DataTableFooter.vue';
import ValidationErrors from '@/components/crud/ValidationErrors.vue';
import Snackbar from '@/components/Snackbar.vue';
import Country from '@/models/setup/Country';

import thousandSeparator from '@/mixins/thousand-separator';
import convertCase from '@/mixins/convert-case';
import required from '@/mixins/rules/required';
import { axiosCatch } from '@/mixins/crud/methods/axios-catch.js';
import { showSnackbar } from "@/mixins/crud/methods/show-snackbar";
import { paginationWatch } from '@/mixins/crud/watch/pagination.js';
import { editDialog } from '@/mixins/crud/watch/edit-dialog.js';
import { deleteDialog } from '@/mixins/crud/watch/delete-dialog.js';
import { deleteEntity } from '@/mixins/crud/methods/delete-entity.js';

export default Vue.extend({
    components: {
        NoData,
        CoinImage,
        RefreshButton,
        DataTableHeader,
        DataTableSkeletonLoader,
        DataTableFooter,
        ValidationErrors,
        ViewButton,
        EditToolbar,
        EditButton,
        EditCardActions,
        DeleteToolbar,
        DeleteButton,
        DeleteCardActions,
        DialogImage,
        RequiredFields,
        Snackbar,
    },

    mixins: [
        axiosCatch,
        showSnackbar,
        paginationWatch,
        editDialog,
        deleteDialog,
        deleteEntity,
        thousandSeparator,
        convertCase,
        required,
    ],

    data: () => ({
        initialLoad: true,
        loading: false,
        saving: false,
        deleting: false,
        viewDialog: false,
        typing: false,
        apiURI: process.env.VUE_APP_ARENAS_BASE_URI,
        route: 'countries',
        entity: 'countries',
        entities: [],
        pagination: {
            search: '',
            current_page: 1,
            from: 1,
            last_page: 0,
            path: '',
            per_page: 10,
            to: 0,
            total: 0
        },
        options: {
            page: 1,
            itemsPerPage: 10,
            sortBy: [],
            sortDesc: [],
            groupBy: [],
            groupDesc: [],
            mustSort: false,
            multiSort: false
        },
        selectedSearchAttributes: ['Name'],
        validationErrors: [],
        editDialog: false,
        deleteDialog: false,
        editedIndex: -1,
        editedEntity: {
            id: '',
            name: '',
            legal_age: 0,
            currency: '',
            locale: '',
            coins_per_currency: 0,
            code: '',
            taxes: [],
            geo_map: {
                latitude: "",
                longitude: "",
                zoom: ""
            },
        },
        defaultEntity: {
            id: '',
            name: '',
            legal_age: 0,
            currency: '',
            locale: '',
            coins_per_currency: 0,
            code: '',
            taxes: [],
            geo_map: {
                latitude: "",
                longitude: "",
                zoom: ""
            },
        },
        toDeleteEntity: {
            id: '',
            name: '',
            legal_age: 0,
            currency: '',
            locale: '',
            coins_per_currency: 0,
            code: '',
            taxes: [],
            geo_map: {
                latitude: "",
                longitude: "",
                zoom: ""
            },
        },
    }),

    watch: {
        editDialog() {
            if (this.$refs.form) {
                this.$refs.form.resetValidation();
            }
        },
        viewDialog(val) {
            if (!val) {
                this.editedIndex = -1;
                this.editedEntity = {...this.defaultEntity};
            }
        }
    },

    computed: {
        headers() {
            return [
                { text: this.$t('generalAttributes.name'), align: 'left', value: 'name' },
                { text: this.$t('entities.countries.attributes.legalAge'), align: 'left', value: 'legal_age' },
                { text: this.$t('entities.countries.attributes.currency'), align: 'left', value: 'currency' },
                { text: this.$t('entities.countries.attributes.locale'), align: 'left', value: 'locale' },
                { text: this.$t('entities.countries.attributes.code'), align: 'left', value: 'code' },
                { text: this.$t('miscellaneous.actions'), value: 'action', sortable: false }
            ];
        },
        searchAttributes() {
            return [
                { label: this.$t('generalAttributes.name'), value: 'Name'}
            ]
        },
        formTitle() {
            return this.editDialog ? (this.$t(`entities.${this.entity}.${this.editedIndex === -1 ? 'new' : 'edit'}Entity`)) : '';
        },
        between() {
            return v => (v >= 0 && v <= 100) || this.$t('validation.between', { from: 0, to: 100 });
        },
        integer() {
            return v => (!isNaN(v) && parseInt(Number(v)) == v && !isNaN(parseInt(v, 10))) || this.$t('validation.integer');
        },
    },

    mounted() {
        this.refresh();
    },

    methods: {
        async fetchEntities(append = `&size=${this.pagination.per_page}&page=${this.pagination.current_page}`) {
            try {
                this.loading = true;
                const response = await Country.api.get(append);
                const data = response.data;
                this.entities = data.content.map((CountryDTO) => new Country(CountryDTO));
                this.pagination.current_page = data.pageable.page_number + 1;
                this.pagination.per_page = data.pageable.page_size;
                this.pagination.total = data.total_elements;
                this.pagination.last_page = data.total_pages;
                this.pagination.from = data.pageable.offset + 1;
                this.pagination.to = this.pagination.from + data.number_of_elements - 1;
            } catch (e) {
                console.warn('Countries API failed.');
                console.log(e);
            } finally {
                this.initialLoad = false;
                this.loading = false;
            }
        },
        async save() {
            try {
                this.saving = true;

                if (!this.$refs.form.validate()) {
                    return;
                }

                const data = {
                    name: this.editedEntity.name,
                    currency: this.editedEntity.currency,
                    locale: this.editedEntity.locale,
                    code: this.editedEntity.code,
                    legal_age: this.editedEntity.legal_age,
                    coins_per_currency: this.editedEntity.coins_per_currency,
                    geo_map: this.editedEntity.geo_map
                };

                if (this.editedEntity.taxes.length) {
                    data.taxes = this.editedEntity.taxes;
                }

                if (this.editedIndex > -1) {
                    await Country.api.update(this.editedEntity.id, data)
                        .then((resp) => {
                            if (this.$store.getters.arena && this.$store.getters.arena.company.country.id === this.editedEntity.id) {
                                const arena = this.$store.getters.arena;
                                arena.company.country.taxes = resp.data.taxes;
                                arena.company.country.coins_per_currency = resp.data.coins_per_currency;
                                this.$store.commit('setArena', arena);
                            }
                            if (this.pagination.search.length) {
                                this.pagination.search = '';
                            } else {
                                this.fetchEntities();
                            }
                            this.showSnackbar('success', this.$t('entities.countries.notifications.update'));
                            this.closeEditDialog();
                        })
                        .catch(error => {
                            this.axiosCatch(error);
                        });
                } else {
                    await Country.api.create(data)
                        .then(() => {
                            if (this.pagination.search.length) {
                                this.pagination.search = '';
                            } else {
                                this.fetchEntities();
                            }
                            this.closeEditDialog();
                            this.showSnackbar('success', this.$t('entities.countries.notifications.creation'));
                        })
                        .catch(error => {
                            this.axiosCatch(error);
                        });
                }
            } catch (error) {
                console.warn(`Country Save API failed.`);
                console.log(error);
            } finally {
                this.saving = false;
            }
        },
        openEditDialog(entity) {
            this.editedIndex = this.entities.indexOf(entity);
            this.editedEntity = {...entity};
            if (this.$refs.form) {
                this.$refs.form.resetValidation();
            }
            this.editDialog = true;
        },
        closeEditDialog() {
            this.editDialog = false;
            this.editedEntity = {...this.defaultEntity};
            this.editedIndex = -1;
        },
        openDeleteDialog(entity) {
            this.editedIndex = this.entities.indexOf(entity);
            this.toDeleteEntity = {...entity};
            this.deleteDialog = true;
        },
        closeDeleteDialog() {
            this.deleteDialog = false;
            this.toDeleteEntity = {...this.defaultEntity};
            this.editedIndex = -1;
        },
        openViewDialog(entity) {
            this.editedIndex = this.entities.indexOf(entity);
            this.editedEntity = {...entity};
            this.viewDialog = true;
        },
        refresh() {
            this.fetchEntities();
        }
    }
});

</script>
