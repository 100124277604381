import { AxiosResponse } from 'axios';
import axios from '@/services/axios';

export default abstract class DashboardApi {

    public static shop = process.env.VUE_APP_SHOP_BASE_URI;
    public static arenas = process.env.VUE_APP_ARENA_HUB_BASE_URI;
    public static core = process.env.VUE_APP_CORE_BASE_URI;
    public static entity = 'dashboard';

    public static async shopStats(arenaId: bigint): Promise<AxiosResponse> {
        return await axios.get(`${this.shop}/${this.entity}/admin?&arena_id=${arenaId}`);
    }

    public static async arenaStats(arenaId: bigint): Promise<AxiosResponse> {
        return await axios.get(`${this.arenas}/arenas/${arenaId}/stats`);
    }

    public static async coreStats(arenaId: bigint): Promise<AxiosResponse> {
        return await axios.get(`${this.core}/arenas/${arenaId}/dashboard`);
    }

    public static async dailyStats(arenaId: bigint, params: string): Promise<AxiosResponse> {
        return await axios.get(`${this.shop}/${this.entity}/admin/daily-stats?&arena_id=${arenaId}${params}`);
    }

    public static async finance(arenaId: bigint, params: string): Promise<AxiosResponse> {
        return await axios.get(`${this.shop}/${this.entity}/finance?&arena_id=${arenaId}${params}`);
    }

    public static async analytics(data: object): Promise<AxiosResponse> {
        return await axios.get(`${this.shop}/${this.entity}/analytics`, data);
    }

    public static async shifts(arenaId: bigint, params: string): Promise<AxiosResponse> {
        return await axios.get(`${this.shop}/${this.entity}/finance/shifts?&arena_id=${arenaId}${params}`);
    }

    public static async startShift(data: object): Promise<AxiosResponse> {
        return await axios.post(`${this.shop}/${this.entity}/finance/shifts`, data);
    }

    public static async stopShift(id: bigint): Promise<AxiosResponse> {
        return await axios.post(`${this.shop}/${this.entity}/finance/shifts/${id}/stop`);
    }

    public static async generateReport(data: object): Promise<AxiosResponse> {
        return await axios.get(`${this.shop}/${this.entity}/finance/generate-report`, data);
    }
}
