

import Vue from 'vue';

import currency from '@/mixins/currency';

export default Vue.extend({
    props: ['report'],

    mixins: [
        currency
    ],
})

