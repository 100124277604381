export const editDialog = {

    watch: {
        editDialog(val) {
            val || this.closeEditDialog();
            if (!val) {
                this.$refs.form.resetValidation();
                this.validationErrors = [];
            }
        }
    },

};