

import Vue from 'vue';
import Boolean from '@/components/Boolean.vue';
import CoinImage from '@/components/CoinImage.vue';

import currency from '@/mixins/currency';
import convertCase from '@/mixins/convert-case';
import thousandSeparator from '@/mixins/thousand-separator';
import convertDate from '@/mixins/convert-date';

export default Vue.extend({
    components: {
        Boolean,
        CoinImage,
    },

    mixins: [
        currency,
        convertCase,
        convertDate,
        thousandSeparator,
    ],

    props: ['editedEntity'],

});

