export interface VariantInterface {
    id: bigint;
    name: string;
    active: boolean;
    description: string;
    image: string;
    price: number;
    cost: number;
    stock: number;
    sku: string;
    category_attributes: Array<object>;
    inventory_attributes: Array<object>;
    custom_attributes: Array<object>;
}

export class VariantDTO implements VariantInterface {
    id = 0n;
    type = {};
    name = '';
    active = false;
    description = '';
    image = '';
    price = 0;
    cost = 0;
    stock = 0;
    sku = '';
    category_attributes = [];
    inventory_attributes = [];
    custom_attributes = [];
}

export default class Variant extends VariantDTO {
    constructor(DTO: VariantDTO){
        super();
        Object.assign(this, DTO);
    }
}
