<template>
    <div>
        <v-card class="elevation-2">
            <DataTableSkeletonLoader :loading="initialLoad"/>
            <v-data-table
                v-show="!initialLoad"
                :headers="headers"
                :items="entities"
                :dense="$vuetify.breakpoint.xs && !loading"
                :loading="loading"
                :loading-text="$t('components.noData.fetchingEntries')"
                :options.sync="options"
                :server-items-length="pagination.total"
                :items-per-page="pagination.per_page"
                :header-props="{ sortIcon: 'arrow_circle_up', sortByText: $t('miscellaneous.sortBy') }"
                :class="{ 'mobile-empty-datatable' : $vuetify.breakpoint.xsOnly }"
                hide-default-footer
            >
                <template v-slot:top>
                    <v-toolbar flat>
                        <v-toolbar-title>{{ $t(`entities.mobileNotifications.${sent ? 'title' : 'scheduled'}`) }}</v-toolbar-title>
                        <v-divider
                            class="mx-4"
                            inset
                            vertical
                        ></v-divider>
                        <small class="mr-2">{{ pagination.total }}</small>
                        <RefreshButton
                            :refresh="refresh"
                            :loading="loading"
                        />
                        <v-spacer></v-spacer>
                        <v-switch
                            :input-value="sent"
                            @change="v => (sent = v)"
                            :label="$t('entities.mobileNotifications.sent')"
                            class="mt-5 mr-4"
                            inset
                        ></v-switch>
                        <v-dialog
                            v-model="editDialog"
                            max-width="1000px"
                        >
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    v-on="on"
                                    color="primary"
                                    class="mb-2"
                                    dark
                                >
                                    {{ $t('miscellaneous.add') }}
                                </v-btn>
                            </template>
                            <v-card>
                                <v-card-text class="px-0 pb-0">
                                    <EditToolbar
                                        :formTitle="formTitle"
                                        :closeEditDialog="closeEditDialog"
                                    />
                                    <v-container>
                                        <v-card class="px-4 pb-4">
                                            <v-form
                                                @submit.prevent="save"
                                                method="POST"
                                                ref="form"
                                            >
                                                <RequiredFields/>
                                                <DialogImage
                                                    :image="editedEntity.image"
                                                    :name="editedEntity.title"
                                                />
                                                <v-row
                                                    align="center"
                                                    class="mt-2"
                                                >
                                                    <v-col
                                                        cols="12"
                                                        sm="6"
                                                        class="position-relative"
                                                    >
                                                        <v-text-field
                                                            :value="editedEntity.title"
                                                            @change="v => (editedEntity.title = v)"
                                                            :rules="[required]"
                                                            :label="$t('entities.mobileNotifications.attributes.title')"
                                                            prepend-icon="mdi-format-title"
                                                        ></v-text-field>
                                                        <span class="required-fields">*</span>
                                                    </v-col>
                                                    <v-col
                                                        cols="12"
                                                        sm="6"
                                                        class="position-relative"
                                                    >
                                                        <v-flex>
                                                            <ImageInput
                                                                :file.sync="imageFile"
                                                                :alreadyHasImage="editedEntity.image"
                                                                :rules="[v => !v || v.size < 300000 || $t('validation.imageSize', { value: 300000 / 1000 })]"
                                                            />
                                                        </v-flex>
                                                    </v-col>
                                                    <v-col
                                                        cols="12"
                                                        sm="6"
                                                        class="position-relative"
                                                    >
                                                        <v-text-field
                                                            :value="editedEntity.text"
                                                            @change="v => (editedEntity.text = v)"
                                                            :rules="[required]"
                                                            :label="$t('entities.mobileNotifications.attributes.text')"
                                                            prepend-icon="mdi-text"
                                                        ></v-text-field>
                                                        <span class="required-fields">*</span>
                                                    </v-col>
                                                    <v-col
                                                        cols="12"
                                                        sm="6"
                                                    >
                                                        <v-dialog
                                                            v-model="scheduleModal"
                                                            :return-value.sync="dateSchedule"
                                                            ref="dialog_schedule"
                                                            width="290px"
                                                        >
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <v-text-field
                                                                    v-model="editedEntity.scheduled_at"
                                                                    :label="$t('entities.mobileNotifications.attributes.scheduledAt')"
                                                                    v-bind="attrs"
                                                                    v-on="on"
                                                                    prepend-icon="mdi-calendar-start"
                                                                    readonly
                                                                ></v-text-field>
                                                            </template>
                                                            <v-tabs
                                                                v-model="tab"
                                                                background-color="primary"
                                                                icons-and-text
                                                                centered
                                                                dark
                                                                grow
                                                            >
                                                                <v-tabs-slider color="primary lighten-2"></v-tabs-slider>
                                                                <v-tab href="#tab-1">
                                                                    <v-icon>event</v-icon>
                                                                </v-tab>
                                                                <v-tab href="#tab-2">
                                                                    <v-icon>more_time</v-icon>
                                                                </v-tab>
                                                            </v-tabs>
                                                            <v-tabs-items v-model="tab">
                                                                <v-tab-item
                                                                    value="tab-1"
                                                                    class="text-capitalize"
                                                                >
                                                                    <v-date-picker
                                                                        v-model="dateSchedule"
                                                                        :locale="$i18n.locale"
                                                                        :min="new Date().toISOString().slice(0, 10)"
                                                                        scrollable
                                                                    >
                                                                        <v-spacer></v-spacer>
                                                                        <v-btn
                                                                            @click="scheduleModal = false"
                                                                            color="primary"
                                                                            text
                                                                        >
                                                                            {{ $t('miscellaneous.cancel') }}
                                                                        </v-btn>
                                                                    </v-date-picker>
                                                                </v-tab-item>
                                                                <v-tab-item value="tab-2">
                                                                    <v-time-picker
                                                                        v-model="timeSchedule"
                                                                        format="24hr"
                                                                    ></v-time-picker>
                                                                    <v-flex class="text-right mt-6 pb-2">
                                                                        <v-btn
                                                                            @click="scheduleModal = false"
                                                                            color="primary"
                                                                            text
                                                                        >
                                                                            {{ $t('miscellaneous.cancel') }}
                                                                        </v-btn>
                                                                        <v-btn
                                                                            @click="$refs.dialog_schedule.save(dateSchedule)"
                                                                            color="primary"
                                                                            text
                                                                        >
                                                                            {{ $t('miscellaneous.confirm') }}
                                                                        </v-btn>
                                                                    </v-flex>
                                                                </v-tab-item>
                                                            </v-tabs-items>
                                                        </v-dialog>
                                                    </v-col>
                                                    <v-col 
                                                        cols="12"
                                                        sm="6"
                                                    >
                                                        <v-select
                                                            :value="editedEntity.platforms"
                                                            @change="v => (editedEntity.platforms = v)"
                                                            :items="topics.filter(topic => topic.entity === 'platform')"
                                                            :menu-props="{ bottom: true, offsetY: true }"
                                                            item-text="name"
                                                            item-value="id"
                                                            :label="$t('entities.mobileNotifications.attributes.platforms')"
                                                            :no-data-text="$t('components.dataTableHeader.noDataAvailable')"
                                                            prepend-inner-icon="mdi-android"
                                                            deletable-chips
                                                            clearable
                                                            multiple
                                                            chips
                                                            solo
                                                        >
                                                        </v-select>
                                                    </v-col>
                                                    <v-col 
                                                        cols="12"
                                                        sm="6"
                                                    >
                                                        <v-select
                                                            :value="editedEntity.click_action"
                                                            @change="v => handleClickActionChange(v)"
                                                            :items="clickActions"
                                                            :menu-props="{ bottom: true, offsetY: true }"
                                                            :label="$t('entities.mobileNotifications.attributes.clickAction')"
                                                            :no-data-text="$t('components.dataTableHeader.noDataAvailable')"
                                                            prepend-inner-icon="mdi-cursor-default-click"
                                                            deletable-chips
                                                            return-object
                                                            clearable
                                                            solo
                                                        >
                                                        </v-select>
                                                    </v-col>
                                                    <v-col>
                                                        <v-text-field
                                                            v-if="showExternalLinkField"
                                                            :value="editedEntity.action_link"
                                                            @change="v => (editedEntity.action_link = v)"
                                                            :label="$t('entities.mobileNotifications.attributes.externalLink')"
                                                            prepend-icon="mdi-link"
                                                        ></v-text-field>
                                                    </v-col>
                                                    <v-col cols="12">
                                                        <v-select
                                                            :value="editedEntity.topics"
                                                            @change="v => (editedEntity.topics = v)"
                                                            :items="topics.filter(topic => topic.entity !== 'platform')"
                                                            :menu-props="{ bottom: true, offsetY: true }"
                                                            item-text="name"
                                                            item-value="id"
                                                            :label="$t('entities.mobileNotifications.attributes.topics')"
                                                            :no-data-text="$t('components.dataTableHeader.noDataAvailable')"
                                                            prepend-inner-icon="mdi-format-list-numbered"
                                                            deletable-chips
                                                            clearable
                                                            multiple
                                                            chips
                                                            solo
                                                        >
                                                        </v-select>
                                                    </v-col>
                                                </v-row>
                                                <ValidationErrors
                                                    v-if="validationErrors.length"
                                                    :errors="validationErrors"
                                                    class="mt-6"
                                                />
                                            </v-form>
                                        </v-card>
                                    </v-container>
                                </v-card-text>
                                <div>
                                    <v-divider/>
                                    <v-card-actions>
                                        <v-btn
                                            @click="closeEditDialog()"
                                            color="dark darken-1"
                                            text
                                        >
                                            {{ $t('miscellaneous.cancel') }}
                                        </v-btn>
                                        <v-spacer/>
                                        <v-btn
                                            @click="save"
                                            :disabled="saving"
                                            :loading="saving"
                                            color="primary"
                                            text
                                        >
                                            <template v-if="editedEntity.scheduled_at">
                                                <span>{{ $t(`entities.mobileNotifications.${sending ? 'scheduling' : 'schedule'}`)  }}</span>
                                            </template>
                                            <template v-else>
                                                <span>{{ $t(`entities.mobileNotifications.${sending ? 'sending' : 'send'}`)  }}</span>
                                            </template>
                                        </v-btn>
                                    </v-card-actions>
                                </div>
                            </v-card>
                        </v-dialog>
                        <v-dialog
                            v-model="deleteDialog"
                            max-width="1000px"
                        >
                            <v-card>
                                <v-card-text class="px-0 pb-0">
                                    <DeleteToolbar
                                        :text="$t('entities.mobileNotifications.deleteEntity')"
                                        :closeDeleteDialog="closeDeleteDialog"
                                    />
                                    <v-container>
                                        <DialogImage
                                            :image="toDeleteEntity.image"
                                            :name="toDeleteEntity.title"
                                        />
                                        <v-card>
                                            <v-simple-table
                                                class="do-not-apply-hover"
                                                dense
                                            >
                                                <template v-slot:default>
                                                    <tbody>
                                                        <tr>
                                                            <td>{{ $t('entities.mobileNotifications.attributes.title') }}</td>
                                                            <td class="font-weight-bold">
                                                                {{ toDeleteEntity.title }}
                                                            </td>
                                                            <td>{{ $t('entities.mobileNotifications.attributes.scheduledAt') }}</td>
                                                            <td
                                                                v-if="deleteDialog"
                                                                class="font-weight-bold"
                                                            >
                                                                {{ toDeleteEntity.scheduled_at ? formatDate(new Date(toDeleteEntity.scheduled_at), false, true) : '/' }}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>{{ $t('entities.mobileNotifications.attributes.text') }}</td>
                                                            <td class="font-weight-bold">
                                                                {{ toDeleteEntity.text }}
                                                            </td>
                                                            <td>{{ $t('entities.mobileNotifications.attributes.clickAction') }}</td>
                                                            <td class="font-weight-bold">
                                                                {{ toDeleteEntity.click_action || '/' }}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </template>
                                            </v-simple-table>
                                        </v-card>
                                    </v-container>
                                </v-card-text>
                                <DeleteCardActions
                                    :deleteEntity="deleteEntity"
                                    :deleting="deleting"
                                    :closeDeleteDialog="closeDeleteDialog"
                                />
                            </v-card>
                        </v-dialog>
                    </v-toolbar>
                </template>
                <template v-slot:item.click_action="{ item }">
                    {{ item.click_action || '/' }}
                </template>
                <template v-slot:item.scheduled_at="{ item }">
                    {{ item.scheduled_at ? formatDate(new Date(item.scheduled_at), false, true) : '/' }}
                </template>
                <template v-slot:item.created_at="{ item }">
                    {{ formatDate(new Date(item.created_at), false, true) }}
                </template>
                <template v-slot:item.action="{ item }">
                    <EditButton
                        :text="$t('entities.mobileNotifications.editEntity')"
                        :item="item"
                        :openEditDialog="openEditDialog"
                    />
                    <DeleteButton
                        :text="$t('entities.mobileNotifications.deleteEntity')"
                        :item="item"
                        :openDeleteDialog="openDeleteDialog"
                    />
                </template>
                <template v-slot:no-data>
                    <NoData
                        :pagination="pagination"
                        :typing="typing"
                        :fetchEntities="fetchEntities"
                    />
                </template>
            </v-data-table>
        </v-card>
        <Snackbar
            :show.sync="snackbar.show"
            :color="snackbar.color"
            :message="snackbar.message"
        />
    </div>
</template>

<script>

import Vue from 'vue';
import NoData from '@/components/crud/NoData.vue';
import ImageInput from '@/components/ImageInput.vue';
import RefreshButton from '@/components/crud/RefreshButton.vue';
import EditButton from '@/components/crud/edit/EditButton.vue';
import EditToolbar from '@/components/crud/edit/EditToolbar.vue';
import DeleteButton from '@/components/crud/delete/DeleteButton.vue';
import DeleteToolbar from '@/components/crud/delete/DeleteToolbar.vue';
import DeleteCardActions from '@/components/crud/delete/DeleteCardActions.vue';
import DialogImage from '@/components/DialogImage';
import RequiredFields from '@/components/crud/RequiredFields.vue';
import DataTableSkeletonLoader from '@/components/crud/DataTableSkeletonLoader.vue';
import ValidationErrors from '@/components/crud/ValidationErrors.vue';
import Snackbar from '@/components/Snackbar.vue';
import MobileNotification from '@/models/social/MobileNotification';
import Topic from '@/models/social/Topic';
import FilesApi from '@/services/misc/FilesApi';

import convertDate from '@/mixins/convert-date';
import formatDate from '@/mixins/format-date';
import convertCase from '@/mixins/convert-case';
import thousandSeparator from '@/mixins/thousand-separator';
import required from '@/mixins/rules/required';
import { axiosCatch } from '@/mixins/crud/methods/axios-catch.js';
import { showSnackbar } from "@/mixins/crud/methods/show-snackbar";
import { paginationWatch } from '@/mixins/crud/watch/pagination.js';
import { editDialog } from '@/mixins/crud/watch/edit-dialog.js';
import { deleteDialog } from '@/mixins/crud/watch/delete-dialog.js';
import { deleteEntity } from '@/mixins/crud/methods/delete-entity.js';
import isRole from "@/mixins/crud/computed/isRole";

export default Vue.extend({
    components: {
        NoData,
        ImageInput,
        RefreshButton,
        DataTableSkeletonLoader,
        ValidationErrors,
        EditToolbar,
        EditButton,
        DeleteToolbar,
        DeleteButton,
        DeleteCardActions,
        DialogImage,
        RequiredFields,
        Snackbar,
    },

    mixins: [
        convertDate,
        axiosCatch,
        showSnackbar,
        formatDate,
        paginationWatch,
        editDialog,
        deleteDialog,
        deleteEntity,
        convertCase,
        thousandSeparator,
        required,
        isRole,
    ],

    data: () => ({
        initialLoad: true,
        loading: false,
        saving: false,
        sending: false,
        deleting: false,
        typing: false,
        sent: false,
        apiURI: process.env.VUE_APP_NOTIFICATIONS_BASE_URI,
        route: 'mobile-notifications',
        entity: 'mobileNotifications',
        tab: null,
        scheduleModal: false,
        dateSchedule: null,
        timeSchedule: null,
        entities: [],
        topics: [],
        clickActions: ['external-link'],
        validationErrors: [],
        pagination: {
            search: '',
            current_page: 1,
            from: 1,
            last_page: 0,
            path: '',
            per_page: 100,
            to: 0,
            total: 0
        },
        options: {
            page: 1,
            itemsPerPage: 10,
            sortBy: ['id'],
            sortDesc: ['DESC'],
            groupBy: [],
            groupDesc: [],
            mustSort: false,
            multiSort: false
        },
        viewDialog: false,
        editDialog: false,
        deleteDialog: false,
        editedIndex: -1,
        imageFile: null,
        image: null,
        showExternalLinkField: false,
        editedEntity: {
            id: null,
            title: '',
            text: '',
            image: '',
            scheduled_at: '',
            click_action: '',
            action_link: null,
            sent: 0,
            topics: [],
            platforms: [],
        },
        defaultEntity: {
            id: null,
            title: '',
            text: '',
            image: '',
            scheduled_at: '',
            click_action: '',
            action_link: null,
            sent: 0,
            topics: [],
            platforms: [],
        },
        toDeleteEntity: {
            id: null,
            title: '',
            text: '',
            image: '',
            scheduled_at: '',
            click_action: '',
            action_link: null,
            sent: 0,
            topics: [],
            platforms: [],
        }
    }),

    computed: {
        headers() {
            return [
                { text: this.$t('entities.mobileNotifications.attributes.title'), align: 'left', value: 'title' },
                { text: this.$t('entities.mobileNotifications.attributes.clickAction'), align: 'left', value: 'click_action' },
                { text: this.$t('entities.mobileNotifications.attributes.scheduledAt'), align: 'left', value: 'scheduled_at' },
                { text: this.$t('miscellaneous.createdAt'), align: 'left', value: 'created_at' },
                { text: this.$t('entities.mobileNotifications.attributes.text'), align: 'left', value: 'text' },
                { text: this.$t('miscellaneous.actions'), value: 'action', sortable: false }
            ];
        },
        formTitle() {
            return this.editDialog ? (this.$t(`entities.${this.entity}.${this.editedIndex === -1 ? 'new' : 'edit'}Entity`)) : '';
        },
    },
    
    watch: {
        editDialog(val) {
            if (val) {
                if (this.editedEntity.scheduled_at) {
                    const date = this.formatDate(new Date(this.editedEntity.scheduled_at));
                    this.dateSchedule = date.slice(0, 10);
                    this.timeSchedule = date.slice(11, 16);
                    this.editedEntity.scheduled_at = `${this.dateSchedule} ${this.timeSchedule}`;
                }
            } else {
                this.dateSchedule = null;
                this.timeSchedule = null;
            }
        },
        scheduleModal() {
            this.tab = 'tab-1';
            if (this.dateSchedule && this.timeSchedule) {
                this.editedEntity.scheduled_at = `${this.dateSchedule} ${this.timeSchedule}`;
            }
        },
        dateSchedule() {
            this.tab = 'tab-2';
        },
        sent() {
            this.refresh();
        },
    },

    mounted() {
        this.refresh();
    },

    methods: {
        async fetchEntities(append = `?sent=${this.sent}&size=${this.pagination.per_page}&page=${this.pagination.current_page}&sort=id,DESC`) {
            try {
                this.loading = true;
                const response = await MobileNotification.api.get(append);
                // Move platforms from topics
                this.entities = response.data.map(MobileNotificationDTO => {
                    const mobileNotification = new MobileNotification(MobileNotificationDTO);
                    mobileNotification.platforms = [];
                    if (!mobileNotification.topics) {
                        mobileNotification.topics = [];
                    } 
                    for (const topicId of mobileNotification.topics) {
                        const topic = this.topics.find(t => t.id === topicId);
                        if (topic && topic.entity === 'platform') {
                            mobileNotification.platforms.push(topicId);
                            mobileNotification.topics = mobileNotification.topics.filter(e => e !== topicId);
                        }
                    }
                    return mobileNotification;
                });
                this.pagination.current_page = 1;
                this.pagination.per_page = 100;
                this.pagination.total = this.entities.length;
                this.pagination.last_page = 1;
                this.pagination.from = 1;
                this.pagination.to = this.entities.length;
                // TODO => Pagination
                /*
                this.pagination.current_page = data.pageable.page_number + 1;
                this.pagination.per_page = data.pageable.page_size;
                this.pagination.total = data.total_elements;
                this.pagination.last_page = data.total_pages;
                this.pagination.from = data.pageable.offset + 1;
                this.pagination.to = this.pagination.from + data.number_of_elements - 1;
                */
            } catch (e) {
                console.warn(`Mobile Notifications API failed.`);
                console.log(e);
            } finally {
                this.initialLoad = false;
                this.loading = false;
            }
        },
        async fetchTopics() {
            try {
                this.loading = true;
                const response = await MobileNotification.api.getTopics();
                this.topics = response.data.map(TopicDTO => new Topic(TopicDTO));
            } catch (e) {
                console.warn(`Topics API failed.`);
                console.log(e);
            } finally {
                this.loading = false;
            }
        },
        async save() {
            try {
                this.saving = true;

                if (!this.$refs.form.validate()) {
                    return;
                }

                if (this.imageFile) {
                    await FilesApi.upload('notifications', this.imageFile)
                        .then((response) => {
                            this.imageFile = null;
                            if (!response || response.status !== 200) {
                                this.showSnackbar('error', this.$t('miscellaneous.fileUploadFailed'));
                                return;
                            }
                            this.image = response.data.link;
                        })
                        .catch(() => {
                            this.showSnackbar('error', this.$t('miscellaneous.fileUploadFailed'));
                        });
                }

                const notification = {
                    title: this.editedEntity.title,
                    text: this.editedEntity.text,
                    image: this.image || this.editedEntity.image,
                    click_action: this.editedEntity.click_action,
                };

                if (!this.isAdmin && this.editedEntity.topics.length === 0) {
                    // Handle the error: At least one topic must be presented
                    this.showSnackbar('error', 'At least one topic must be presented');
                    return;
                }


                notification.topics = [...this.editedEntity.topics, ...this.editedEntity.platforms];

                notification.action_link = this.editedEntity.action_link;

                let scheduling = false;
                let data;

                if (this.editedEntity.scheduled_at) {
                    scheduling = true;
                    notification.sent = false;
                    data = {
                        scheduled_at: this.fromCurrentDateTimeToUtc(this.editedEntity.scheduled_at),
                        notification: notification
                    };
                } else {
                    data = notification;
                }

                if (scheduling) {
                    if (this.editedIndex > -1) {
                        await MobileNotification.api.update(this.editedEntity.id, data)
                            .then(() => {
                                this.fetchEntities();
                                this.showSnackbar('success', this.$t('entities.mobileNotifications.notifications.update'));
                                this.closeEditDialog();
                                this.image = null;
                            })
                            .catch(error => {
                                this.axiosCatch(error);
                            });
                    } else {
                        await MobileNotification.api.create(data)
                            .then(() => {
                                this.fetchEntities();
                                this.showSnackbar('success', this.$t('entities.mobileNotifications.notifications.creation'));
                                this.closeEditDialog();
                                this.image = null;
                            }).catch(error => {
                                this.axiosCatch(error);
                            });
                    }
                } else {
                    await MobileNotification.api.send(data)
                        .then(() => {
                            if (this.sent) {
                                this.fetchEntities();
                            }
                            this.showSnackbar('success', this.$t('entities.mobileNotifications.notifications.sent'));
                            this.closeEditDialog();
                            this.image = null;
                        }).catch(error => {
                            this.axiosCatch(error);
                        });
                }
            } catch (error) {
                console.warn(`Mobile Notification Save API failed.`);
                console.log(error);
            } finally {
                this.saving = false;
            }
        },
        openEditDialog(entity) {
            this.editedIndex = this.entities.indexOf(entity);
            this.editedEntity = JSON.parse(JSON.stringify(entity));
            if (this.$refs.form) {
                this.$refs.form.resetValidation();
            }
            this.editDialog = true;
            this.handleClickActionChange(this.editedEntity.click_action);
        },
        closeEditDialog() {
          this.editedEntity = JSON.parse(JSON.stringify(this.defaultEntity));
          this.editedIndex = -1;
          this.imageFile = null;
          this.editDialog = false;
          this.showExternalLinkField = false;
        },
        openDeleteDialog(entity) {
            this.editedIndex = this.entities.indexOf(entity);
            this.toDeleteEntity = {...entity};
            this.deleteDialog = true;
        },
        closeDeleteDialog() {
            this.deleteDialog = false;
            this.toDeleteEntity = {...this.defaultEntity};
            this.editedIndex = -1;
        },
        refresh() {
            this.fetchTopics();
            this.fetchEntities();
        },
        handleClickActionChange(value) {
            this.editedEntity.click_action = value
            this.showExternalLinkField = value === 'external-link';
        },
    }
});

</script>


<style lang="scss">

.position-relative {
    position: relative;
}

.required-fields {
    position: absolute;
    right: 13px;
    top: 15px;
    color: red;
}

.active-checkmark {
    position: absolute !important;
    bottom: 7px;
    left: 5px;
}

</style>
