<template>
    <div>
        <v-row v-if="!$store.getters.arena">
            <v-col
                cols="12"
                class="pt-0"
            >
                <v-alert type="info">
                    <span class="mb-0 text--white">{{ $t('entities.finance.chooseArena') }}</span>
                </v-alert>
            </v-col>
        </v-row>
        <v-row>
            <v-col
                cols="12"
                sm="6"
            >
                <v-row align="center">
                    <v-col
                        cols="auto"
                        class="py-0 pr-0"
                    >
                        <v-tooltip
                            color="transparent"
                            top
                        >
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    @click="refresh"
                                    v-on="on"
                                    :disabled="loading"
                                    class="glow mr-2"
                                    color="primary"
                                    x-small
                                    fab
                                >
                                    <v-icon>
                                        refresh
                                    </v-icon>
                                </v-btn>
                            </template>
                            <v-card class="elevation-2 px-2 py-1">
                                <small>{{ $t('refreshData') }}</small>
                            </v-card>
                        </v-tooltip>
                    </v-col>
                    <v-col
                        cols="10"
                        class="py-0 pl-2"
                    >
                        <p class="mt-2 mb-0">
                            {{ $t('generated') }}: {{ dateString }}
                        </p>
                    </v-col>
                </v-row>
            </v-col>
            <v-col
                v-if="$store.getters.arena"
                cols="12"
                sm="6"
                class="text-center text-sm-right"
            >
                <v-dialog
                    v-model="shiftDialog"
                    max-width="1000px"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            :block="$vuetify.breakpoint.xsOnly"
                            v-bind="attrs"
                            v-on="on"
                            color="primary"
                        >
                            <v-icon left>print</v-icon>
                            {{ $t('entities.finance.shiftReport') }}
                        </v-btn>
                    </template>
                    <v-card>
                        <v-card-text class="px-0 pb-0">
                            <v-toolbar
                                class="elevation-2"
                                color="primary"
                            >
                                <v-toolbar-title class="ml-0">
                                    <span class="font-weight-bold white--text">{{ $t('entities.finance.shiftReport') }}</span>
                                </v-toolbar-title>
                                <v-spacer/>
                                <v-btn
                                    @click="shiftDialog = false"
                                    icon
                                    dark
                                >
                                    <v-icon>mdi-close</v-icon>
                                </v-btn>
                            </v-toolbar>
                            <v-container>
                                <v-card class="mt-4 px-4 pb-4">
                                    <v-row
                                        justify="center"
                                        align="center"
                                        class="mb-2 pt-4"
                                    >
                                        <v-col
                                            cols="12"
                                            sm="2"
                                            class="py-0"
                                        >
                                            {{ $t('entities.finance.bill') }}
                                        </v-col>
                                        <v-col
                                            cols="12"
                                            sm="2"
                                            class="py-0"
                                        >
                                            {{ $t('generalAttributes.quantity') }}
                                        </v-col>
                                        <v-col
                                            cols="12"
                                            sm="2"
                                            class="py-0"
                                        >
                                            {{ $t('entities.finance.total') }}
                                        </v-col>
                                        <v-col
                                            cols="12"
                                            sm="2"
                                            class="py-0"
                                        >
                                            {{ $t('entities.finance.bill') }}
                                        </v-col>
                                        <v-col
                                            cols="12"
                                            sm="2"
                                            class="py-0"
                                        >
                                            {{ $t('generalAttributes.quantity') }}
                                        </v-col>
                                        <v-col
                                            cols="12"
                                            sm="2"
                                            class="py-0"
                                        >
                                            {{ $t('entities.finance.total') }}
                                        </v-col>
                                    </v-row>
                                    <v-divider/>
                                    <v-row
                                        justify="center"
                                        align="center"
                                        class="mt-2"
                                    >
                                        <template v-for="(item, index) in report">
                                            <v-col
                                                :key="`${index}-0`"
                                                cols="12"
                                                sm="2"
                                                class="py-0"
                                            >
                                                {{ format(item.key * 100, true, $store.getters.arena.company.country.currency) }}
                                            </v-col>
                                            <v-col
                                                :key="`${index}-1`"
                                                cols="12"
                                                sm="2"
                                                class="py-0"
                                            >
                                                <v-text-field
                                                    v-model="report[index].value"
                                                    type="number"
                                                ></v-text-field>
                                            </v-col>
                                            <v-col
                                                :key="`${index}-2`"
                                                cols="12"
                                                sm="2"
                                                class="py-0"
                                            >
                                                {{ format(item.key * 100 * report[index].value, true, $store.getters.arena.company.country.currency) }}
                                            </v-col>
                                        </template>
                                    </v-row>
                                    <v-divider/>
                                    <v-row 
                                        justify="center"
                                        align="center"
                                        class="mt-2"
                                    >
                                        <v-col
                                            cols="12"
                                            sm="2"
                                            offset-sm="8"
                                            class="text-right font-weight-bold"
                                        >
                                            {{ $t('entities.finance.total') }}:
                                        </v-col>
                                        <v-col
                                            cols="12"
                                            sm="2"
                                            class="font-weight-bold"
                                        >
                                            {{ format(total, true, $store.getters.arena.company.country.currency) }}
                                        </v-col>
                                    </v-row>
                                </v-card>
                            </v-container>
                        </v-card-text>
                        <v-divider/>
                        <v-card-actions>
                            <v-btn
                                @click="shiftDialog = false"
                                color="dark darken-1"
                                text
                            >
                                {{ $t('miscellaneous.cancel') }}
                            </v-btn>
                            <v-spacer/>
                            <v-btn
                                @click="print()"
                                color="primary"
                                text
                            >
                                {{ $t('entities.finance.printShiftReport') }}
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-col>
        </v-row>
        <v-row>
            <v-col
                cols="12"
                sm="6"
            >
                <CardStat
                    :loading="loading"
                    :value="`${todaysIncome}`"
                    :text="$t('entities.finance.todaysIncome')"
                />
            </v-col>
            <v-col
                cols="12"
                sm="6"
            >
                <CardStat
                    :loading="loading"
                    :value="`${monthlyIncome}`"
                    :text="`${date} ${$t('entities.finance.monthlyIncome')}`"
                    color="orange"
                />
            </v-col>
        </v-row>
        <v-row class="mt-4">
            <v-col cols="12">
                <v-card class="elevation-2">
                    <DataTableHeader
                        :pagination="pagination"
                        :searchAttributes="searchAttributes"
                        :selectedSearchAttributes.sync="selectedSearchAttributes"
                        :disabled="loadingShifts"
                    />
                    <DataTableSkeletonLoader :loading="initialLoad"/>
                    <v-data-table
                        v-show="!initialLoad"
                        :headers="shiftsHeaders"
                        :items="shifts"
                        :dense="$vuetify.breakpoint.xs && !loadingShifts"
                        :loading="loadingShifts"
                        :loading-text="$t('components.noData.fetchingEntries')"
                        :options.sync="options"
                        :server-items-length="pagination.total"
                        :items-per-page="pagination.per_page"
                        :header-props="{ sortIcon: 'arrow_circle_up', sortByText: $t('miscellaneous.sortBy') }"
                        :class="{ 'mobile-empty-datatable' : $vuetify.breakpoint.xsOnly }"
                        hide-default-footer
                    >
                        <template v-slot:top>
                            <v-toolbar
                                class="mb-10 pt-6"
                                flat
                            >
                                <v-toolbar-title>{{ $t('entities.shifts.title') }}</v-toolbar-title>
                                <v-divider
                                    class="mx-4"
                                    inset
                                    vertical
                                ></v-divider>
                                <small class="mr-2">{{ addThousandSeparator(pagination.total) }}</small>
                                <RefreshButton
                                    :refresh="fetchEntities"
                                    :loading="loadingShifts"
                                />
                                <v-spacer/>
                                <v-col
                                    cols="auto"
                                    class="text-center"
                                >
                                    <v-btn
                                        @click="startShift()"
                                        :disabled="startingShift"
                                        :loading="startingShift"
                                        color="primary"
                                        class="mb-2"
                                    >
                                        <v-icon left>mdi-clock-start</v-icon>
                                        {{ startingShift ? $t('miscellaneous.saving') : $t('entities.shifts.startShift') }}
                                    </v-btn>
                                </v-col>
                            </v-toolbar>
                        </template>
                        <template v-slot:item.income="{ item }">
                            {{ format(item.income, true, $store.getters.arena.company.country.currency) }}
                        </template>
                        <template v-slot:item.started_at="{ item }">
                            {{ fromUtcDateTimeToCurrent(item.started_at, true, true) }} ({{ formatDay(item.started_at).substring(0, 3) }})
                        </template>
                        <template v-slot:item.stopped_at="{ item }">
                            <template v-if="item.stopped_at">
                                {{ fromUtcDateTimeToCurrent(item.stopped_at, true, true) }} ({{ formatDay(item.stopped_at).substring(0, 3) }})
                            </template>
                            <template v-else>
                                <v-btn
                                    @click="stopShift(item.id)"
                                    :disabled="stoppingShift"
                                    :loading="stoppingShift"
                                    color="primary"
                                    class="mb-2"
                                >
                                    <v-icon left>mdi-clock-end</v-icon>
                                    {{ stoppingShift ? $t('miscellaneous.saving') : $t('entities.shifts.stopShift') }}
                                </v-btn>
                            </template>
                        </template>
                        <template v-slot:no-data>
                            <v-row>
                                <v-col class="my-4 text-center">
                                    <p class="mb-0">{{ $t('components.noData.noEntriesFound') }}</p>
                                    <v-btn
                                        v-if="$store.getters.arena && !pagination.search.length"
                                        @click="fetchEntities()"
                                        color="primary"
                                        class="mt-3"
                                        outlined
                                    >
                                        {{ $t('components.noData.tryAgain') }}
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </template>
                    </v-data-table>
                    <DataTableFooter
                        :pagination="pagination"
                        :loading="loadingShifts"
                    />
                </v-card>
            </v-col>
            <v-col cols="12">
                <v-card class="elevation-2">
                    <DataTableSkeletonLoader :loading="initialLoad"/>
                    <v-data-table
                        v-show="!initialLoad"
                        :headers="dailyFinanceHeaders"
                        :items="dailyFinance"
                        :dense="$vuetify.breakpoint.xs && !loading"
                        :loading="loading"
                        :loading-text="$t('components.noData.fetchingEntries')"
                        :options.sync="dailyFinanceOptions"
                        :server-items-length="dailyFinancePagination.total"
                        :items-per-page="dailyFinancePagination.per_page"
                        :header-props="{ sortIcon: 'arrow_circle_up', sortByText: $t('miscellaneous.sortBy') }"
                        :class="{ 'mobile-empty-datatable' : $vuetify.breakpoint.xsOnly }"
                        hide-default-footer
                    >
                        <template v-slot:top>
                            <v-toolbar
                                class="mb-10 pt-6"
                                flat
                            >
                                <v-toolbar-title>{{ $t('entities.finance.dailyFinance') }}</v-toolbar-title>
                                <v-divider
                                    class="mx-4"
                                    inset
                                    vertical
                                ></v-divider>
                                <small>{{ addThousandSeparator(dailyFinancePagination.total) }}</small>
                                <v-spacer/>
                                <!-- Desktop -->
                                <template v-if="$store.getters.arena && $vuetify.breakpoint.smAndUp">
                                    <v-col
                                        cols="auto"
                                        class="text-center mt-10"
                                    >
                                        <v-dialog
                                            ref="dialog"
                                            v-model="modal"
                                            :return-value.sync="date"
                                            persistent
                                            width="290px"
                                        >
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-text-field
                                                    v-model="date"
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    :label="$t('entities.finance.chooseMonth')"
                                                    prepend-icon="mdi-calendar"
                                                    readonly
                                                ></v-text-field>
                                            </template>
                                            <v-date-picker
                                                v-model="date"
                                                :locale="$i18n.locale"
                                                :max="new Date().toISOString().substr(0, 7)"
                                                type="month"
                                                scrollable
                                            >
                                                <v-spacer></v-spacer>
                                                <v-btn
                                                    text
                                                    color="primary"
                                                    @click="modal = false"
                                                >
                                                    {{ $t('miscellaneous.cancel') }}
                                                </v-btn>
                                                <v-btn
                                                    text
                                                    color="primary"
                                                    @click="save($event)"
                                                >
                                                    {{ $t('miscellaneous.ok') }}
                                                </v-btn>
                                            </v-date-picker>
                                        </v-dialog>
                                    </v-col>
                                    <v-col
                                        cols="auto"
                                        class="text-center"
                                    >
                                        <v-tooltip
                                            color="transparent"
                                            top
                                        >
                                            <template v-slot:activator="{ on }">
                                                <v-btn
                                                    @click="generateReport('monthly', null, 'pdf')"
                                                    v-on="on"
                                                    class="glow ml-2"
                                                    color="primary"
                                                    :disabled="loadingFinancialReport && loadingIndexes.includes(date)"
                                                    depressed
                                                    x-small
                                                    fab
                                                >
                                                    <template v-if="loadingFinancialReport && loadingIndexes.includes(date)">
                                                        <ProgressCircular color="primary"/>
                                                    </template>
                                                    <template v-else>
                                                        <v-icon
                                                            color="white"
                                                            small
                                                        >
                                                            mdi-file-pdf-box
                                                        </v-icon>
                                                    </template>
                                                </v-btn>
                                            </template>
                                            <v-card class="elevation-2 px-4 py-2">
                                                <span>{{ $t('entities.finance.generatePdf') }}</span>
                                            </v-card>
                                        </v-tooltip>
                                        <v-tooltip
                                            color="transparent"
                                            top
                                        >
                                            <template v-slot:activator="{ on }">
                                                <v-btn
                                                    @click="generateReport('monthly', null,'csv')"
                                                    v-on="on"
                                                    class="glow ml-2"
                                                    color="primary"
                                                    :disabled="loadingFinancialReport && loadingIndexes.includes(date)"
                                                    depressed
                                                    x-small
                                                    fab
                                                >
                                                    <template v-if="loadingFinancialReport && loadingIndexes.includes(date)">
                                                        <ProgressCircular color="primary"/>
                                                    </template>
                                                    <template v-else>
                                                        <v-icon
                                                            color="white"
                                                            small
                                                        >
                                                            mdi-file-excel-box
                                                        </v-icon>
                                                    </template>
                                                </v-btn>
                                            </template>
                                            <v-card class="elevation-2 px-4 py-2">
                                                <span>{{ $t('entities.finance.generateCSV') }}</span>
                                            </v-card>
                                        </v-tooltip>
                                    </v-col>
                                </template>
                            </v-toolbar>
                            <!-- Mobile -->
                            <v-row
                                v-if="$store.getters.arena && $vuetify.breakpoint.xs"
                                justify="center"
                                align="center"
                            >
                                <v-col
                                    cols="7"
                                    class="text-center pl-6"
                                >
                                    <v-dialog
                                        ref="dialog"
                                        v-model="modal"
                                        :return-value.sync="date"
                                        persistent
                                        width="290px"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                v-model="date"
                                                v-bind="attrs"
                                                v-on="on"
                                                :label="$t('entities.finance.chooseMonth')"
                                                prepend-icon="mdi-calendar"
                                                readonly
                                            ></v-text-field>
                                        </template>
                                        <v-date-picker
                                            v-model="date"
                                            :locale="$i18n.locale"
                                            type="month"
                                            scrollable
                                        >
                                            <v-spacer></v-spacer>
                                            <v-btn
                                                text
                                                color="primary"
                                                @click="modal = false"
                                            >
                                                {{ $t('miscellaneous.cancel') }}
                                            </v-btn>
                                            <v-btn
                                                text
                                                color="primary"
                                                @click="save($event)"
                                            >
                                                {{ $t('miscellaneous.ok') }}
                                            </v-btn>
                                        </v-date-picker>
                                    </v-dialog>
                                </v-col>
                                <v-col
                                    cols="5"
                                    class="text-center"
                                >
                                    <v-tooltip
                                        color="transparent"
                                        top
                                    >
                                        <template v-slot:activator="{ on }">
                                            <v-btn
                                                @click="generateReport('monthly', null, 'pdf')"
                                                v-on="on"
                                                class="glow ml-2"
                                                color="primary"
                                                :disabled="loadingFinancialReport && loadingIndexes.includes(date)"
                                                depressed
                                                x-small
                                                fab
                                            >
                                                <template v-if="loadingFinancialReport && loadingIndexes.includes(date)">
                                                    <ProgressCircular color="primary"/>
                                                </template>
                                                <template v-else>
                                                    <v-icon
                                                        color="white"
                                                        small
                                                    >
                                                        mdi-file-pdf-box
                                                    </v-icon>
                                                </template>
                                            </v-btn>
                                        </template>
                                        <v-card class="elevation-2 px-4 py-2">
                                            <span>{{ $t('entities.finance.generatePdf') }}</span>
                                        </v-card>
                                    </v-tooltip>
                                    <v-tooltip
                                        color="transparent"
                                        top
                                    >
                                        <template v-slot:activator="{ on }">
                                            <v-btn
                                                @click="generateReport('monthly', null, 'csv')"
                                                v-on="on"
                                                class="glow ml-2"
                                                color="primary"
                                                :disabled="loadingFinancialReport && loadingIndexes.includes(date)"
                                                depressed
                                                x-small
                                                fab
                                            >
                                                <template v-if="loadingFinancialReport && loadingIndexes.includes(date)">
                                                    <ProgressCircular color="primary"/>
                                                </template>
                                                <template v-else>
                                                    <v-icon
                                                        color="white"
                                                        small
                                                    >
                                                        mdi-file-excel-box
                                                    </v-icon>
                                                </template>
                                            </v-btn>
                                        </template>
                                        <v-card class="elevation-2 px-4 py-2">
                                            <span>{{ $t('entities.finance.generateCSV') }}</span>
                                        </v-card>
                                    </v-tooltip>
                                </v-col>
                            </v-row>
                        </template>
                        <template v-slot:item.date="{ item }">
                            {{ formatDate(new Date(item.date), true, true, true) }} ({{ formatDay(item.date).substring(0, 3) }})
                        </template>
                        <template v-slot:item.cash="{ item }">
                            <span v-if="$store.getters.arena && item.income['Cash']">
                                <b>{{ format(item.income['Cash'], true, $store.getters.arena.company.country.currency) }}</b>
                            </span>
                            <span v-else>
                                /
                            </span>
                        </template>
                        <template v-slot:item.credit_card="{ item }">
                            <span v-if="$store.getters.arena && item.income['Credit Card']">
                                <b>{{ format(item.income['Credit Card'], true, $store.getters.arena.company.country.currency) }}</b>
                            </span>
                            <span v-else>
                                /
                            </span>
                        </template>
                        <template v-slot:item.bank="{ item }">
                            <span v-if="$store.getters.arena && item.income['Bank']">
                                <b>{{ format(item.income['Bank'], true, $store.getters.arena.company.country.currency) }}</b>
                            </span>
                            <span v-else>
                                /
                            </span>
                        </template>
                        <template v-slot:item.income="{ item }">
                             <span v-if="$store.getters.arena">
                                 <b>{{ format(item.income['Total'], true, $store.getters.arena.company.country.currency) }}</b>
                            </span>
                            <span v-else>
                                /
                            </span>
                        </template>
                        <template v-slot:item.action="{ item }">
                            <v-tooltip
                                color="transparent"
                                top
                            >
                                <template v-slot:activator="{ on }">
                                    <v-btn
                                        @click="generateReport('daily', item.date, 'pdf')"
                                        v-on="on"
                                        class="glow ml-2"
                                        color="primary"
                                        :disabled="loadingFinancialReport && loadingIndexes.includes(item.date)"
                                        depressed
                                        x-small
                                        fab
                                    >
                                        <template v-if="loadingFinancialReport && loadingIndexes.includes(item.date)">
                                            <ProgressCircular color="primary"/>
                                        </template>
                                        <template v-else>
                                            <v-icon
                                                color="white"
                                                small
                                            >
                                                mdi-file-pdf-box
                                            </v-icon>
                                        </template>
                                    </v-btn>
                                </template>
                                <v-card class="elevation-2 px-4 py-2">
                                    <span>{{ $t('entities.finance.generatePdf') }}</span>
                                </v-card>
                            </v-tooltip>
                            <v-tooltip
                                color="transparent"
                                top
                            >
                                <template v-slot:activator="{ on }">
                                    <v-btn
                                        @click="generateReport('daily', item.date, 'csv')"
                                        v-on="on"
                                        class="glow ml-2"
                                        color="primary"
                                        :disabled="loadingFinancialReport && loadingIndexes.includes(item.date)"
                                        depressed
                                        x-small
                                        fab
                                    >
                                        <template v-if="loadingFinancialReport && loadingIndexes.includes(item.date)">
                                            <ProgressCircular color="primary"/>
                                        </template>
                                        <template v-else>
                                            <v-icon
                                                color="white"
                                                small
                                            >
                                                mdi-file-excel-box
                                            </v-icon>
                                        </template>
                                    </v-btn>
                                </template>
                                <v-card class="elevation-2 px-4 py-2">
                                    <span>{{ $t('entities.finance.generateCSV') }}</span>
                                </v-card>
                            </v-tooltip>
                        </template>
                        <template v-slot:no-data>
                            <v-row>
                                <v-col class="my-4 text-center">
                                    <p class="mb-0">{{ $t('components.noData.noEntriesFound') }}</p>
                                    <v-btn
                                        v-if="$store.getters.arena && !dailyFinancePagination.search.length"
                                        @click="fetchDailyFinance()"
                                        color="primary"
                                        class="mt-3"
                                        outlined
                                    >
                                        {{ $t('components.noData.tryAgain') }}
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </template>
                    </v-data-table>
                </v-card>
            </v-col>
        </v-row>
        <PrintReport
            v-if="$store.getters.arena"
            :report="report"
        />
        <Snackbar
            :show.sync="snackbar.show"
            :color="snackbar.color"
            :message="snackbar.message"
        />
    </div>
</template>

<script>

import Vue from 'vue';
import CardStat from '@/components/CardStat.vue';
import DataTableHeader from '@/components/crud/DataTableHeader.vue';
import DataTableFooter from '@/components/crud/DataTableFooter.vue';
import DataTableSkeletonLoader from '@/components/crud/DataTableSkeletonLoader.vue';
import ProgressCircular from '@/components/ProgressCircular.vue';
import RefreshButton from '@/components/crud/RefreshButton.vue';
import PrintReport from '@/views/data/PrintReport.vue';
import Snackbar from '@/components/Snackbar.vue';
import DashboardApi from '@/services/main/DashboardApi';
import DailyFinance from '@/models/misc/DailyFinance';
import Shift from '@/models/shop/Shift';

import { axiosCatch } from '@/mixins/crud/methods/axios-catch.js';
import { showSnackbar } from '@/mixins/crud/methods/show-snackbar';
import { paginationWatch } from '@/mixins/crud/watch/pagination.js';
import currency from '@/mixins/currency';
import convertDate from '@/mixins/convert-date';
import formatDate from '@/mixins/format-date';
import formatDay from '@/mixins/format-day';
import thousandSeparator from '@/mixins/thousand-separator';
import user from '@/mixins/crud/computed/user';
import convertCase from '@/mixins/convert-case';

export default Vue.extend({
    components: {
        CardStat,
        ProgressCircular,
        DataTableHeader,
        DataTableFooter,
        DataTableSkeletonLoader,
        RefreshButton,
        PrintReport,
        Snackbar,
    },

    mixins: [
        convertDate,
        axiosCatch,
        showSnackbar,
        paginationWatch,
        currency,
        thousandSeparator,
        user,
        formatDate,
        formatDay,
        convertCase,
    ],

    data: () => ({
        dateString: '',
        initialLoad: true,
        loading: false,
        loadingShifts: false,
        loadingFinancialReport: false,
        typing: false,
        shiftDialog: false,
        startingShift: false,
        stoppingShift: false,
        loadingIndexes: [],
        todaysIncome: 0,
        monthlyIncome: 0,
        paymentMethods: [],
        date: new Date().toISOString().substr(0, 7),
        menu: false,
        modal: false,
        dailyFinance: [],
        shifts: [],
        selectedSearchAttributes: ['Username'],
        report: [
            {
                key: 500,
                value: 0
            },
            {
                key: 200,
                value: 0
            },
            {
                key: 100,
                value: 0
            },
            {
                key: 50,
                value: 0
            },
            {
                key: 20,
                value: 0
            },
            {
                key: 10,
                value: 0
            },
            {
                key: 5,
                value: 0
            },
            {
                key: 2,
                value: 0
            },
            {
                key: 1,
                value: 0
            },
            {
                key: 0.5,
                value: 0
            },
            {
                key: 0.2,
                value: 0
            },
            {
                key: 0.1,
                value: 0
            },
            {
                key: 0.05,
                value: 0
            },
            {
                key: 0.02,
                value: 0
            },
        ],
        pagination: {
            search: '',
            current_page: 1,
            from: 1,
            last_page: 0,
            path: '',
            per_page: 5,
            to: 0,
            total: 0
        },
        options: {
            page: 1,
            itemsPerPage: 10,
            sortBy: [],
            sortDesc: [],
            groupBy: [],
            groupDesc: [],
            mustSort: false,
            multiSort: false
        },
        dailyFinancePagination: {
            search: '',
            current_page: 1,
            from: 1,
            last_page: 0,
            path: '',
            per_page: 31,
            to: 0,
            total: 0
        },
        dailyFinanceOptions: {
            page: 1,
            itemsPerPage: 10,
            sortBy: [],
            sortDesc: [],
            groupBy: [],
            groupDesc: [],
            mustSort: false,
            multiSort: false
        },
    }),

    watch: {
        shiftDialog(val) {
            if (!val) {
                this.report.map(function(item) {
                    item.value = 0; 
                });
            }
        },
    },

    computed: {
        dailyFinanceHeaders() {
            const headers = [
                { text: this.$t('miscellaneous.date'), align: 'left', value: 'date', sortable: false },
                { text: this.$t('miscellaneous.income'), align: 'left', value: 'income', sortable: false },
                { text: this.$t('miscellaneous.actions'), value: 'action', sortable: false }
            ];

            let counter = 1;
            for (const paymentMethod in this.paymentMethods) {
                headers.splice(counter, 0, {
                        text: this.$t(`miscellaneous.paymentMethods.${this.titleToCamelCase(this.paymentMethods[paymentMethod])}`),
                        align: 'left',
                        value: this.camelToSnakeCase(this.titleToCamelCase(this.paymentMethods[paymentMethod])),
                        sortable: false
                    }
                )
                counter++;
            }

            return headers;
        },
        shiftsHeaders() {
            return [
                { text: this.$t('entities.users.attributes.username'), align: 'left', value: 'user.username', sortable: false },
                { text: this.$t('miscellaneous.income'), align: 'left', value: 'income', sortable: false },
                { text: this.$t('entities.shifts.attributes.startedAt'), align: 'left', value: 'started_at', sortable: false },
                { text: this.$t('entities.shifts.attributes.stoppedAt'), align: 'left', value: 'stopped_at', sortable: false },
            ];
        },
        searchAttributes() {
            return [
                { label: this.$t('entities.users.attributes.username'), value: 'Username' },
            ]
        },
        total() {
            return !this.report ? 0 : this.report.reduce((sum, bill) => { 
                return sum + (bill.key * bill.value * 100); 
            }, 0);
        },
    },

    mounted() {
        this.refresh();
    },

    methods: {
        async fetchDailyFinance(append = `&size=${this.dailyFinancePagination.per_page}&page=${this.dailyFinancePagination.current_page}&date=${this.date}`) {
            try {
                this.loading = true;
                if (this.$store.getters.arena) {
                    const response = await DashboardApi.finance(this.$store.getters.arena.id, append);
                    this.dailyFinance = Object.entries(response.data.daily_income.data).map(([date, DailyFinanceDto]) => {
                        return {
                            income: new DailyFinance(DailyFinanceDto),
                            date: date
                        };
                    })
                    this.todaysIncome = this.format(response.data.todays_income, true, this.$store.getters.arena.company.country.currency);
                    this.monthlyIncome = this.format(response.data.monthly_income, true, this.$store.getters.arena.company.country.currency);
                    this.paymentMethods = response.data.payment_methods;
                    response.data.daily_income.per_page = parseInt(response.data.daily_income.per_page);
                    this.dailyFinancePagination.current_page = response.data.daily_income.current_page;
                    this.dailyFinancePagination.per_page = response.data.daily_income.per_page;
                    this.dailyFinancePagination.total = response.data.daily_income.total;
                    this.dailyFinancePagination.last_page = response.data.daily_income.last_page;
                    this.dailyFinancePagination.from = response.data.daily_income.from;
                    this.dailyFinancePagination.to = response.data.daily_income.to;
                }
            } catch (e) {
                console.warn(`Finance API failed.`);
                console.log(e);
            } finally {
                this.initialLoad = false;
                this.loading = false;
            }
        },
        async fetchEntities(append = `&size=${this.pagination.per_page}&page=${this.pagination.current_page}`) {
            try {
                this.loadingShifts = true;
                if (this.$store.getters.arena) {
                    const response = await DashboardApi.shifts(this.$store.getters.arena.id, append);
                    this.shifts = response.data.data.map(ShiftDTO => new Shift(ShiftDTO));
                    response.data.per_page = parseInt(response.data.per_page);
                    this.pagination.current_page = response.data.current_page;
                    this.pagination.per_page = response.data.per_page;
                    this.pagination.total = response.data.total;
                    this.pagination.last_page = response.data.last_page;
                    this.pagination.from = response.data.from;
                    this.pagination.to = response.data.to;
                }
            } catch (e) {
                console.warn(`Shifts API failed.`);
                console.log(e);
            } finally {
                this.initialLoad = false;
                this.loadingShifts = false;
            }
        },
        async startShift() {
             try {
                this.startingShift = true;
                const arena = this.$store.getters.arena;
                if (arena) {
                    await DashboardApi.startShift({
                        arena: {
                            id: arena.id,
                            name: arena.name,
                            timezone: arena.timezone,
                            currency: arena.company.country.currency,
                            coins_per_currency: arena.company.country.coins_per_currency
                        },
                        user: {
                            id: this.user.id,
                            username: this.user.username,
                        },
                    }).then(() => {
                        if (this.pagination.search.length) {
                            this.pagination.search = '';
                        } else {
                            this.fetchEntities();
                        }
                        this.showSnackbar('success', this.$t('entities.shifts.notifications.start'));
                    }).catch(error => {
                        this.axiosCatch(error);
                    });
                }
            } catch (e) {
                console.warn(`Start Shift API failed.`);
                console.log(e);
            } finally {
                this.startingShift = false;
            }
        },
        async stopShift(id) {
             try {
                this.stoppingShift = true;
                const arena = this.$store.getters.arena;
                if (arena) {
                    await DashboardApi.stopShift(id).then(() => {
                        if (this.pagination.search.length) {
                            this.pagination.search = '';
                        } else {
                            this.fetchEntities();
                        }
                        this.showSnackbar('success', this.$t('entities.shifts.notifications.stop'));
                    }).catch(error => {
                        this.axiosCatch(error);
                    });
                }
            } catch (e) {
                console.warn(`Stop Shift API failed.`);
                console.log(e);
            } finally {
                this.stoppingShift = false;
            }
        },
        async generateReport(type = 'monthly', date = null, exportFormat = 'pdf') {
            if (!date) {
                date = this.date;
            }
            try {
                this.loadingIndexes.push(date);
                this.loadingFinancialReport = true;
                const arena = this.$store.getters.arena;
                if (arena) {
                    await DashboardApi.generateReport({
                        responseType: 'blob',
                        params: {
                            arena: JSON.stringify({
                                id: arena.id,
                                name: arena.name,
                                timezone: arena.timezone,
                                currency: arena.company.country.currency,
                                coins_per_currency: arena.company.country.coins_per_currency
                            }),
                            date: date,
                            type: type,
                            by_user: this.user.name,
                            export_format: exportFormat,
                        }
                    }).then((response) => {
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', `${arena.name.replaceAll(' ', '_')}_${date.replaceAll('-', '_')}_Financial_Report.${exportFormat}`);
                        document.body.appendChild(link);
                        link.click();
                    }).catch(error => {
                        this.axiosCatch(error);
                    });
                }
            } catch (e) {
                console.warn(`Generate Report API failed.`);
                console.log(e);
            } finally {
                this.loadingFinancialReport = false;
                this.loadingIndexes = this.loadingIndexes.filter(i => i !== date)
            }
        },
        save() {
            this.$refs.dialog.save(this.date);
            this.fetchDailyFinance();
        },
        print() {
            this.$nextTick(() => {
                if (typeof window === 'undefined') {
                    return;
                }

                const element = document.getElementById('print');
                const win = window.open(document.URL,'_blank');

                win.document.write(`
                    <html>
                        <head>
                            <title>${document.title}</title>
                            <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.0-beta2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-BmbxuPwQa2lc/FVzBcNJ7UAyJxM6wuqIj61tLrc4wSX0szH/Ev+nYRRuWlolflfl" crossorigin="anonymous">
                        </head>
                        <body>
                            ${element.innerHTML}
                        </body>
                    </html>
                `);

                setTimeout(() => {
                    win.document.close();
                    win.focus();
                    win.print();
                    win.close();
                }, 1000);

                this.shiftDialog = false;
            });
        },
        refresh() {
            this.dateString = this.formatDate(new Date(), true, true);
            this.fetchDailyFinance();
            this.fetchEntities();
        },
    }
});

</script>

<style>

.v-picker__title__btn {
    text-transform: capitalize !important;
}

</style>
