import ItemsApi from '@/services/shop/ItemsApi';

export interface ItemInterface {
    id: bigint;
    category: object;
    name: string;
    active: boolean;
    description: string;
    image: string;
}

export class ItemDTO implements ItemInterface {
    id = 0n;
    category = {};
    name = '';
    active = false;
    description = '';
    image = '';
}

export default class Item extends ItemDTO {
    static api = ItemsApi;

    constructor(DTO: ItemDTO){
        super();
        Object.assign(this, DTO);
    }
}
