import AvatarsApi from '@/services/users/AvatarsApi';

export interface AvatarInterface {
    id: bigint;
    url: string;
    name: string;
    key: string;
}

export class AvatarDTO implements AvatarInterface {
    id = 0n;
    url = '';
    name = '';
    key = '';
}

export default class Avatar extends AvatarDTO {
    static api = AvatarsApi;

    constructor(DTO: AvatarDTO){
        super();
        Object.assign(this, DTO);
    }
}