export const axiosCatch = {

    methods: {
        axiosCatch(error) {
            if (error.response) {
                // Bad request
                if (error.response.status === 400) {
                    if (this.validationErrors.length) {
                        this.validationErrors = [];
                    }
                    if (error.response.data && error.response.data.ErrorText) {
                        this.validationErrors.push(error.response.data && error.response.data.ErrorText)
                    } else if (error.response.data && error.response.data.errors) {
                        let message = error.response.data.message + ': ';
                        message = message.charAt(0).toUpperCase() + message.slice(1);
                        const errors = [];
                        for (const i in error.response.data.errors) {
                            let msg = error.response.data.errors[i];
                            msg = msg.charAt(0).toUpperCase() + msg.slice(1);
                            errors.push(msg)
                        }
                        message += ' ' + errors.join(', ');
                        this.validationErrors.push(message)
                    } else {
                        this.validationErrors.push(`Something went wrong with bad request error code: 400. Bad request.`)
                    }
                }
                // Conflict error
                if (error.response.status === 409) {
                    if (this.validationErrors.length) {
                        this.validationErrors = [];
                    }
                    if (error.response.data && error.response.data.message) {
                        this.validationErrors.push(error.response.data.message)
                    } else {
                        this.validationErrors.push(`Something went wrong with conflict error code: 409. Unique validation failed.`)
                    }
                }
                // Validation error
                if (error.response.status === 422) {
                    if (this.validationErrors.length) {
                        this.validationErrors = [];
                    }
                    for (const i in error.response.data.message) {
                        this.validationErrors.push(`The attribute ${i} failed for rule: ${error.response.data.message[i]}`)
                    }
                }
                // Server error
                if (error.response.status === 500) {
                    if (this.validationErrors.length) {
                        this.validationErrors = [];
                    }
                    this.validationErrors.push(`Something went wrong with error code: 500. Contact the administrators and check the logs.`)
                    if (error.response.data.errors) {
                        this.validationErrors.push(error.response.data.errors)
                    }
                    if (error.response.data.message) {
                        this.validationErrors.push(error.response.data.message)
                    }
                }
            }
        },
    },

};
