

import Vue from 'vue';
import CoinImage from '@/components/CoinImage.vue';

export default Vue.extend({
    components: {
        CoinImage
    },
    props: {
        loading: {
            type: Boolean
        },
        value: {
            type: String
        },
        text: {
            type: String
        },
        color: {
            type: String,
            default: 'primary'
        },
        width: {
            type: String,
            default: '50px'
        },
        coins: {
            type: Boolean,
            default: false
        },
    },
})

