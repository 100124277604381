export const printInvoice = {

    data: () => ({
        invoiceData: {
            arena: {
                invoice_settings: {
                    automatically_open_print_upon_invoice_creation: false,
                    invoice_footer: '',
                    invoice_header: '',
                },
                company: {
                    pidn: '',
                    name: '',
                    address: '',
                    image: '',
                    fiscalData: {
                        inTaxSystem: '',
                    },
                    country: {
                        currency: '',
                        locale: ''
                    }
                },
            },
            invoice: {
                restoredInvoiceLabel: null,
                label: '',
                cart: {
                    gross: '',
                    net: '',
                    items: [],
                },
                taxes: [],
                fiscalData: {
                    zki: '',
                    jir: '',
                    bf: '',
                    qr: '',
                },
                byUser: {
                    name: ''
                },
                user: {
                    company: {
                        name: '',
                        pidn: ''
                    }
                },
                paymentMethod: {
                    name: ''
                },
                created_at: ''
            }
        },
        defaultInvoiceData: {
            arena: {
                invoice_settings: {
                    automatically_open_print_upon_invoice_creation: false,
                    invoice_footer: '',
                    invoice_header: '',
                },
                company: {
                    pidn: '',
                    name: '',
                    address: '',
                    image: '',
                    fiscalData: {
                        inTaxSystem: '',
                    },
                    country: {
                        currency: '',
                        locale: ''
                    }
                },
            },
            invoice: {
                restoredInvoiceLabel: null,
                label: '',
                cart: {
                    gross: '',
                    net: '',
                    items: [],
                },
                taxes: [],
                fiscalData: {
                    zki: '',
                    jir: '',
                    bf: ''
                },
                byUser: {
                    name: ''
                },
                user: {
                    company: {
                        name: '',
                        pidn: ''
                    }
                },
                paymentMethod: {
                    name: ''
                },
                created_at: ''
            }
        },
    }),

    methods: {
        printInvoice(showNotification = true) {
            if (typeof window === 'undefined') {
                return;
            }

            const element = document.getElementById('print');
            const win = window.open(document.URL,'_blank');

            win.document.write(`
                <html>
                    <head>
                        <title>${document.title}</title>
                        <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.0-beta2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-BmbxuPwQa2lc/FVzBcNJ7UAyJxM6wuqIj61tLrc4wSX0szH/Ev+nYRRuWlolflfl" crossorigin="anonymous">
                    </head>
                    <body>
                        ${element.innerHTML}
                    </body>
                </html>
            `);

            setTimeout(() => {
                win.document.close();
                win.focus();
                win.print();
                win.close();
                if (showNotification) {
                    this.showSnackbar('success', this.$t('entities.invoices.notifications.creation'));
                }
            }, 1000);

            this.invoiceData = {...this.defaultInvoiceData};
        },
    }
};
