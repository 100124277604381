

import Vue from 'vue';
import CurrentlyPlaying from '@/components/CurrentlyPlaying.vue';

export default Vue.extend({
    components: {
        CurrentlyPlaying
    },
});

