

import Vue from 'vue';

import user from '@/mixins/crud/computed/user';
import convertCase from '@/mixins/convert-case';
import { logout } from '@/mixins/logout';

export default Vue.extend({

    mixins: [
        convertCase,
        logout,
        user,
    ],

    data: () => ({
        menu: false,
        saveTheme: false,
        saveLanguage: true,
    }),

    computed: {
        arenaName() {
            return this.$store.getters.arena ? `/${this.titleToKebabCase(this.$store.getters.arena.name)}` : '';
        }
    },
})
