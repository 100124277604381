

import Vue from 'vue';
import User from '@/models/users/User';

import user from '@/mixins/crud/computed/user';
import updateLanguage from '@/mixins/update-language';

export default Vue.extend({
    mixins: [
        user,
    ],

    data: () => ({
        locales: process.env.VUE_APP_I18N_SUPPORTED_LOCALES?.split(','),
    }),

    computed: {
        locale() {
            return this.$i18n.locale;
        }
    },

    methods: {
        switchLocale(locale: string) {
            if (this.$i18n.locale !== locale) {
                // this.$vuetify.lang.current = locale; // TODO => Supply vuetify with i18n translations
                updateLanguage(locale);
                // TODO => Update user object in local storage
                User.api.updateSettingByNamespace(this.user.id, 'dashboard', 'language', { value: locale });
            }
        }
    },
})
