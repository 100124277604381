import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        authenticated: false,
        arena: null,
        selectedCashRegisterLabel: null,
        country: 'hr',
    },
    mutations: {
        setAuthenticated(state, authenticated) {
            state.authenticated = authenticated;
        },
        setArena(state, arena) {
            state.arena = arena;
        },
        setSelectedCashRegisterLabel(state, selectedCashRegisterLabel) {
            state.selectedCashRegisterLabel = selectedCashRegisterLabel;
        },
        setCountry(state, country) {
            state.country = country;
        },
    },
    getters: {
        authenticated: (state) => {
            return state.authenticated;
        },
        arena: (state) => {
            return state.arena;
        },
        selectedCashRegisterLabel: (state) => {
            return state.selectedCashRegisterLabel;
        },
        country: (state) => {
            return state.country;
        },
    },
    actions: {},
    modules: {}
})
