import { AxiosResponse } from 'axios';
import axios from '@/services/axios';

export default abstract class CountriesApi {

    public static route = process.env.VUE_APP_ARENAS_BASE_URI;
    public static entity = 'countries';

    public static async get(params: string): Promise<AxiosResponse> {
        return await axios.get(`${this.route}/${this.entity}?${params}`);
    }

    public static async create(data: object): Promise<AxiosResponse>{
        return await axios.post(`${this.route}/${this.entity}`, data);
    }

    public static async update(id: bigint, data: object): Promise<AxiosResponse>{
        return await axios.put(`${this.route}/${this.entity}/${id}`, data);
    }
}

