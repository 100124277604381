import UsersApi from '@/services/users/UsersApi';

export interface UserInterface {
    id: bigint;
    username: string;
    active: boolean;
    banned: boolean;
    card_id: bigint | null;
    avatar: string;
    roles: string[];
    registered_in_arena: bigint | null;
    created_at: string;
    updated_at: string | null;
}

export class UserDTO implements UserInterface {
    id = 0n;
    username = '';
    active = true;
    banned = false;
    card_id = null;
    avatar = '';
    roles = [];
    registered_in_arena = null;
    created_at = '';
    updated_at = null;
}

export default class User extends UserDTO {
    public static api = UsersApi;

    constructor(DTO: UserDTO){
        super();
        Object.assign(this, DTO);
    }
}
