<template>
    <v-card class="mt-5">
        <v-simple-table
            class="do-not-apply-hover"
            dense
        >
            <template v-slot:default>
                <tbody>
                    <tr>
                        <td
                            colspan="8"
                            class="do-not-apply-hover mx-0 px-0"
                        >
                            <v-toolbar
                                class="elevation-2"
                                color="secondary"
                                dense
                            >
                                <v-toolbar-title class="ml-0">
                                    <small class="font-weight-bold white--text">
                                        {{ $t(`entities.quests.attributes.configuration`) }}
                                    </small>
                                </v-toolbar-title>
                            </v-toolbar>
                        </td>
                    </tr>
                    <template v-if="settings">
                        <tr>
                            <td
                                colspan="8"
                                class="font-weight-bold"
                            >
                                {{ $t(`entities.achievements.type`) }}
                            </td>
                        </tr>
                        <tr>
                            <td colspan="8">
                                <PrimaryChip
                                    :text="$t(`entities.achievements.${settings.type.toLowerCase()}`)"
                                    margin="ml-0"
                                />
                            </td>
                        </tr>
                        <template v-if="settings.type === 'RECURRENT'">
                            <tr>
                                <td
                                    colspan="8"
                                    class="font-weight-bold"
                                >
                                    {{ $t(`entities.achievements.period`) }}
                                </td>
                            </tr>
                            <tr>
                                <template v-if="settings.period">
                                    <td>
                                        {{ $t(`entities.achievements.unit`) }}
                                    </td>
                                    <td>
                                        {{ $t(`miscellaneous.units.${settings.period.unit.toLowerCase()}`) }}
                                    </td>
                                    <td>
                                        {{ $t(`entities.achievements.innerType`) }}
                                    </td>
                                    <td>
                                        <PrimaryChip
                                            :text="$t(`entities.achievements.${settings.period.inner_type.toLowerCase()}`)"
                                            margin="ml-0"
                                        />
                                    </td>
                                    <td>
                                        {{ $t(`entities.achievements.count`) }}
                                    </td>
                                    <td>
                                        {{ settings.period.count }}
                                    </td>
                                    <td>
                                        {{ $t(`entities.achievements.consecutive`) }}
                                    </td>
                                    <td>
                                        <Boolean :boolean="settings.period.consecutive"/>
                                    </td>
                                </template>
                                <template v-else>
                                    <td colspan="8">
                                        {{ $t(`entities.achievements.noPeriodAttributes`) }}
                                    </td>
                                </template>
                            </tr>
                        </template>
                        <tr>
                            <td
                                colspan="8"
                                class="font-weight-bold"
                            >
                                {{ $tc('entities.quests.attributes.condition', 2) }}
                            </td>
                        </tr>
                        <template v-if="settings.conditions">
                            <template v-for="(condition, index) in settings.conditions">
                                <tr :key="`condition-${condition.id}-0`">
                                    <td colspan="8">
                                        {{ index + 1 }}.
                                    </td>
                                </tr>
                                <tr :key="`condition-${condition.id}-2`">
                                    <td
                                        colspan="1"
                                        class="pl-10"
                                    >
                                        {{ $t(`entities.achievements.value`) }}
                                    </td>
                                    <td
                                        colspan="7"
                                        class="font-weight-bold"
                                    >
                                        {{ condition.value }}
                                    </td>
                                </tr>
                            </template>
                        </template>
                    </template>
                    <template v-else>
                        <tr>
                            <td
                                colspan="8"
                                class="py-4"
                            >
                                {{ $t(`entities.achievements.noConfigurationSet`) }}
                            </td>
                        </tr>
                    </template>
                </tbody>
            </template>
        </v-simple-table>
    </v-card>
</template>

<script>

import Vue from 'vue';
import PrimaryChip from '@/components/PrimaryChip.vue';
import Boolean from '@/components/Boolean.vue';

import convertCase from '@/mixins/convert-case';

export default Vue.extend({
    props: ['settings', 'viewDialog'],

    components: {
        PrimaryChip,
        Boolean,
    },

    mixins: [
        convertCase
    ],

    methods: {
        multipleConditions(settings) {
            return settings.type === 'AND' || settings.type === 'OR' || (settings.type === 'RECURRENT' && settings.period.inner_type !== 'SIMPLE');
        }
    }
})

</script>