import ArenasApi from '@/services/setup/ArenasApi';

export interface ArenaInterface {
    id: bigint;
    name: string;
}

export class ArenaDTO implements ArenaInterface {
    id = 0n;
    name = '';
}

export default class Arena extends ArenaDTO {
    static api = ArenasApi;

    constructor(DTO: ArenaDTO){
        super();
        Object.assign(this, DTO);
    }
}