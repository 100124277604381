import { AxiosResponse } from 'axios';
import axios from '@/services/axios';

export default abstract class OrdersApi {

    public static route = process.env.VUE_APP_SHOP_BASE_URI;
    public static entity = 'orders';

    public static async get(resources: number, params: string): Promise<AxiosResponse> {
        return await axios.get(`${this.route}/${this.entity}?resources=${resources}${params}`);
    }

    public static async create(data: object): Promise<AxiosResponse> {
        return await axios.post(`${this.route}/${this.entity}`, data);
    }

    public static async update(id: bigint, data: object): Promise<AxiosResponse> {
        return await axios.put(`${this.route}/${this.entity}/${id}`, data);
    }

    public static async createInvoice(id: bigint, data: object): Promise<AxiosResponse> {
        return await axios.post(`${this.route}/${this.entity}/${id}/create-invoice`, data);
    }
}
