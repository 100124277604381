import GamesApi from '@/services/configuration/GamesApi';

export interface GameInterface {
    id: string;
    name: string;
    genre: string;
}

export class GameDTO implements GameInterface {
    id = '';
    name = '';
    genre = '';

}

export default class Game extends GameDTO {
    static api = GamesApi;

    constructor(DTO: GameDTO){
        super();
        Object.assign(this, DTO);
    }
}