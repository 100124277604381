var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"container d-none",attrs:{"id":"print"}},[_c('div',{staticClass:"col-11 text-center"},[_c('p',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.$t('entities.finance.shiftReport')))]),_c('p',[_vm._v(_vm._s(_vm.$store.getters.arena.name))])]),_c('div',{staticClass:"col-11"},[_c('table',{staticStyle:{"width":"100%","border-collapse":"collapse"}},[_c('thead',[_c('tr',{staticStyle:{"border-top":"1px solid #000","border-bottom":"1px solid #000"}},[_c('td',{staticStyle:{"padding":"5px 0"}},[_vm._v(_vm._s(_vm.$t('entities.finance.bill')))]),_c('td',{staticStyle:{"padding":"5px 0"}},[_vm._v(_vm._s(_vm.$t('generalAttributes.quantity')))]),_c('td',{staticClass:"text-end",staticStyle:{"padding":"5px 0"}},[_vm._v(" "+_vm._s(_vm.$t('entities.finance.total'))+" ")])])]),_c('tbody',_vm._l((_vm.report),function(item,index){return _c('tr',{key:'bill-' + index,style:({ borderBottom : index === _vm.report.length - 1 ? '1px solid #000' : 'none' })},[_c('td',{style:({
                            paddingTop : `${index === 0 ? '10' : '2'}px`,
                            paddingBottom : `${index === _vm.report.length - 1 ? '10' : '0'}px`
                        })},[_vm._v(" "+_vm._s(_vm.format(item.key * 100, false))+" ")]),_c('td',{style:({
                            paddingTop : `${index === 0 ? '10' : '2'}px`,
                            paddingBottom : `${index === _vm.report.length - 1 ? '10' : '0'}px`
                        })},[_vm._v(" "+_vm._s(item.value)+" ")]),_c('td',{staticClass:"text-end",style:({
                            paddingTop : `${index === 0 ? '10' : '2'}px`,
                            paddingBottom : `${index === _vm.report.length - 1 ? '10' : '0'}px`
                        })},[_vm._v(" "+_vm._s(_vm.format(item.key * item.value * 100, false, _vm.$store.getters.arena.company.country.currency))+" ")])])}),0)])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-11 text-end"},[_c('p',{staticClass:"font-weight-bold mb-0"},[_vm._v(" "+_vm._s(_vm.format(_vm.report.reduce((sum, bill) => { return sum + (bill.key * bill.value * 100); }, 0), true, _vm.$store.getters.arena.company.country.currency))+" ")])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }