<template>
    <router-view v-if="!loading"></router-view>
</template>

<script>

import Vue from 'vue';
import Arena from '@/models/setup/Arena';

import convertCase from '@/mixins/convert-case';
import isRole from '@/mixins/crud/computed/isRole';
import user from '@/mixins/crud/computed/user';

export default Vue.extend({

    mixins: [
        convertCase,
        isRole,
        user,
    ],

    data: () => ({
        loading: true,
    }),

    mounted() {
        if (this.$route.params.arena) {
            if (!this.$store.getters.arena && this.$store.getters.authenticated) {
                this.fetchArena(this.$route.params.arena);
            } else {
                this.loading = false;
            }
        } else {
            this.loading = false;
        }
    },

    methods: {
        async fetchArena(arena) {
            try {
                this.loading = true;
                const response = await Arena.api.findByName(this.snakeToTitleCase(arena.replaceAll('-', '_')));

                if (!response.data) {
                    console.warn(`Arena not found.`);
                    this.resetStateAndRedirectToDashboard();
                } else if (!this.isAdmin && !response.data.employees.includes(this.user.id)) {
                    console.warn(`Insufficient permissions.`);
                    this.resetStateAndRedirectToDashboard();
                } else {
                    const data = response.data;
                    const arena = {
                        id: data.id,
                        name: data.name,
                        timezone: data.timezone,
                        cashRegisters: data.cash_registers,
                        paymentMethods: data.payment_methods.map(function(item) {
                            return item.payment_method
                        }),
                        company: {
                            country: {
                                id: data.company.country.id,
                                currency: data.company.country.currency,
                                taxes: data.company.country.taxes,
                                locale: data.company.country.locale,
                                coins_per_currency: data.company.country.coins_per_currency
                            }
                        },
                        automaticallyOpenPrintUponInvoiceCreation: data.invoice_settings.automatically_open_print_upon_invoice_creation,
                    };

                    this.$store.commit('setArena', arena);
                    this.$store.commit('setSelectedCashRegisterLabel', null);
                    this.$store.commit('setCountry', data.company.country.code.toLowerCase());
                    localStorage.setItem('preferred_arena', this.titleToKebabCase(arena.name));
                    if (this.onlyArenaEntered()) {
                        this.pushToDashboard(this.$router.currentRoute.path);
                    }
                }
            } catch (e) {
                if (e.response.status !== 404) {
                    console.warn(`Arena API failed.`);
                    console.log(e.response);
                }
                this.resetStateAndRedirectToDashboard();
            } finally {
                this.loading = false;
            }
        },
        pushToDashboard(arenaName) {
            this.$router.push(`${arenaName}/${(this.isAdmin || this.isManager) ? 'admin' : 'employee'}-dashboard`);
        },
        onlyArenaEntered() {
            return this.$router.currentRoute.name === 'entities.arenas.entity';
        },
        resetStateAndRedirectToDashboard() {
            this.$store.commit('setArena', null);
            this.$store.commit('setSelectedCashRegisterLabel', null);
            this.pushToDashboard('');
        }
    },
})

</script>
