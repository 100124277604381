export default {
    'primary': '#009688',
    'secondary': '#00b8a6',
    'accent': '#00bcd4',
    'error': '#ff5722',
    'warning': '#ff9800',
    'info': '#1ba52a',
    'success': '#673ab7',
    'first-gradient-level': '#009688',
    'second-gradient-level': '#008478',
    'third-gradient-level': '#00746b',
    'navigation-drawer-active-list-item': '#04786e',
    'navigation-drawer-active-group': '#098b7f',
    'main-background': '#f0f2f6',
    'primary-text': '#9e9e9e',
    'dark-text': '#ffffff',
};