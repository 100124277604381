export const showSnackbar = {

    data: () => ({
        snackbar: {
            show: false,
            color: '',
            message: '',
            timeout: 3000,
            icon: 'check_circle'
        }
    }),

    methods: {
        showSnackbar(color, message, timeout = 3000, icon = 'check_circle') {
            this.snackbar = {
                show: true,
                color: color,
                message: message,
                timeout: timeout,
                icon: icon,
            };
        },
    },

};