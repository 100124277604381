const user = {

    computed: {
        user() {
            const user = localStorage.getItem('user');
            if (user) {
                return JSON.parse(decodeURIComponent(escape(window.atob(user))));
            } else {
                return null;
            }
        },
    },

};

export default user;