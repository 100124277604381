

import Vue from 'vue';

export default Vue.extend({

    data: () => ({
        show: false
    }),

    methods: {
        onScroll(e: Event) {
            if (typeof window === 'undefined') {
                return;
            }
            const top = window.pageYOffset || (e.target as HTMLInputElement).scrollTop || 0;
            this.show = top > 20;
        },
    }
});

