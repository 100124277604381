import DashboardApi from '@/services/main/DashboardApi';

export interface ShiftInterface {
    id: bigint;
}

export class ShiftDTO implements ShiftInterface {
    id = 0n;
}

export default class Shift extends ShiftDTO {
    static api = DashboardApi;

    constructor(DTO: ShiftDTO){
        super();
        Object.assign(this, DTO);
    }
}
