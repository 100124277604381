<template>
    <v-select
        v-model="theme"
        :items="themes"
        :light="!$vuetify.theme.dark"
        :menu-props="{ bottom: true, offsetY: true }"
        class="mt-7"
        style="width: 170px;"
        solo
    >
        <template v-slot:selection="data">
            {{ $t(`themes.${data.item.toLowerCase()}`) }}
            <v-icon
                color="primary"
                class="ml-2"
                x-small
            >
                format_color_fill
            </v-icon>
        </template>
        <template v-slot:item="data">
            {{ $t(`themes.${data.item.toLowerCase()}`) }}
        </template>
    </v-select>
</template>

<script>

import Vue from 'vue';
import User from '@/models/users/User';

import user from '@/mixins/crud/computed/user';
import updateTheme from '@/mixins/update-theme';
import { app } from '@/main';

export default Vue.extend({
    mixins: [
        user,
    ],

    data: () => ({
        theme: process.env.VUE_APP_THEME,
        themes: process.env.VUE_APP_SUPPORTED_THEMES?.split(','),
    }),


    watch: {
        theme(val) {
            updateTheme(val, app);
            const theme = this.user.settings.theme;
            if (theme !== val) {
                // TODO => Update user object in local storage
                User.api.updateSettingByNamespace(this.user.id, 'dashboard', 'theme', { value: val });
            }
        }
    },

    mounted() {
        const theme = localStorage.getItem('theme') || process.env.VUE_APP_THEME;
        if (theme) {
            this.theme = theme;
            updateTheme(theme, app);
        }
    },
})
</script>
