import { AxiosResponse } from 'axios';
import axios from '@/services/axios';

export default abstract class PaymentMethodsApi {

    public static route = process.env.VUE_APP_ARENAS_BASE_URI;
    public static entity = 'payment-methods';

    public static async get(params: string): Promise<AxiosResponse> {
        return await axios.get(`${this.route}/${this.entity}?${params}`);
    }
}

