<template>
    <v-app>
        <v-main>
            <template v-if="login() || !$store.getters.authenticated">
                <router-view/>
            </template>
            <template v-else>
                <v-col cols="12">
                    <v-row
                        justify="center"
                        align="start"
                        class="my-12"
                    >
                        <v-col
                            cols="12"
                            sm="11"
                        >
                            <router-view :key="$router.currentRoute.fullPath"></router-view>
                        </v-col>
                    </v-row>
                </v-col>
                <ScrollToTop/>
                <AppBar
                    :drawer.sync="drawer"
                    :currentRouteName="$router.currentRoute.name"
                />
                <NavigationDrawer
                    :drawer.sync="drawer"
                    :loggedIn.sync="loggedIn"
                />
                <v-snackbar
                    :value="updateExists"
                    :timeout="-1"
                    :min-width="$vuetify.breakpoint.xsOnly ? '300px' : '600px'"
                    transition="slide-x-reverse-transition"
                    color="info"
                    shaped
                    bottom
                    right
                >
                    <v-row
                        align="center"
                        class="py-2"
                    >
                        <v-col
                            cols="1"
                            class="py-0"
                        >
                            <v-icon color="white">
                                info
                            </v-icon>
                        </v-col>
                        <v-col
                            :cols="$vuetify.breakpoint.xsOnly ? '10' : '9'"
                            class="py-0 pl-4 pr-0"
                        >
                            <p class="font-weight-medium mb-0 white--text">{{ $t('miscellaneous.updateAvailable') }}</p>
                        </v-col>
                        <v-col
                            :cols="$vuetify.breakpoint.xsOnly ? '1' : '2'"
                            class="py-0 px-0"
                        >
                            <v-btn
                                @click="refreshApp"
                                class="px-0"
                                text
                            >
                                <template v-if="$vuetify.breakpoint.xsOnly">
                                    <v-icon 
                                        color="white"
                                        class="mr-8"
                                    >
                                        mdi-refresh
                                    </v-icon>
                                </template>
                                <template v-else>
                                    {{ $t('miscellaneous.update') }}
                                </template>
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-snackbar>
            </template>
        </v-main>
    </v-app>
</template>

<script>

import Vue from 'vue';
import AppBar from './components/app-bar/AppBar.vue';
import ScrollToTop from './components/ScrollToTop.vue';
import NavigationDrawer from './components/NavigationDrawer.vue';

import updateTheme from '@/mixins/update-theme';
import updateApp from './mixins/update-app';

export default Vue.extend({
    mixins: [
        updateApp,
    ],

    components: {
        AppBar,
        ScrollToTop,
        NavigationDrawer,
    },

    data: () => ({
        loggedIn: false,
        drawer: null
    }),

    mounted() {
        this.$i18n.locale = localStorage.getItem('locale') || (process.env.VUE_APP_I18N_LOCALE || 'en');
        updateTheme(localStorage.getItem('theme') || process.env.VUE_APP_THEME, this.$parent);
    },

    methods: {
        login() {
            return this.$router.currentRoute.path === '/';
        },
    }
});

</script>

<style lang="scss">

* {
    font-family: 'Quicksand', sans-serif;
}

main {
    background: var(--v-main-background-base);
}

.v-btn__content {
    font-weight: bold;
}

p {
    color: var(--v-primary-text-base);
}

.v-data-table:not(.do-not-apply-hover) table tbody tr:not(.do-not-apply-hover, .v-data-table__selected, .v-data-table__empty-wrapper, .v-data-table__mobile-table-row):hover {
    box-shadow: 0 3px 15px -2px rgb(0 0 0 / 12%);
    transform: translateY(-2px);
    td:first-child {
        border-left: 5px solid var(--v-primary-base);
    }
}

.v-data-table table tbody tr:not(.v-data-table__mobile-table-row) {
    transition: box-shadow .2s, transform .2s;
    td:first-child:not(.do-not-apply-hover) {
        border-left: 5px solid transparent;
        transition: border-left .2s;
    }
}

.main-gradient-background {
    background: linear-gradient(180deg, var(--v-first-gradient-level-base) 12.88%, var(--v-second-gradient-level-base) 92.93%), var(--v-third-gradient-level-base);
}

.v-select:not(.do-not-apply-transform) {
    font-size: 13px;
}

.v-select-list {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

/*Select error details margin*/
.v-select .v-text-field__details {
    margin-top: 8px;
}

.v-data-table-header__icon {
    position: relative;
    bottom: 2px;
    left: 2px;
}

.cursor-pointer {
    cursor: pointer !important;
}

.v-input__slot:after {
    transform-origin: 100% 50%
}
.v-input__slot:hover:after {
    transform-origin: 0 50%;
}

.v-btn--loading {
  flex-direction: row-reverse;
}

.v-progress-circular.v-progress-circular--indeterminate {
  color: #fff;
  height: 18px !important;
  width: 18px !important;
}

.v-btn--loading .v-btn__content {
  opacity: 1 !important;
  margin-left: 20px;
  margin-right: 15px;
}

.v-btn__loader {
  position: relative !important;
  left: 10px !important;
  svg {
    color: var(--v-primary-base);
  }
}

.mobile-empty-datatable .v-data-table__empty-wrapper td {
    display: block;
    box-sizing: border-box;
    clear: both;
    padding-top: 15px !important;
    padding-bottom: 15px !important;
    height: auto !important;
}

</style>
