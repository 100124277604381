import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';
import 'tiptap-vuetify/dist/main.css';

Vue.use(Vuetify);

// TODO => Supply vuetify with i18n translations

const opts = {
    theme: {
        dark: false,
        options: {
            customProperties: true,
        },
        themes: {
            light: {
                'primary': '#284b76',
                'secondary': '#607d8b',
                'accent': '#4caf50',
                'error': '#d32f2f',
                'warning': '#ff9800',
                'info': '#009688',
                'success': '#007E33',
                'first-gradient-level': '#284b76',
                'second-gradient-level': '#203b5c',
                'third-gradient-level': '#203b5c',
                'navigation-drawer-active-list-item': '#203b5c',
                'navigation-drawer-active-group': '#568fd2',
                'main-background': '#f0f2f6',
                'primary-text': '#9e9e9e',
                'dark-text': '#000000',
            },
            dark: {
                'primary': '#f53737',
                'secondary': '#ba000d',
                'accent': '#cddc39',
                'error': '#e91e63',
                'warning': '#ff9800',
                'info': '#009688',
                'success': '#007E33',
                'first-gradient-level': '#121212',
                'second-gradient-level': '#1e1e1e',
                'third-gradient-level': '#363636',
                'navigation-drawer-active-list-item': '#d40d0d',
                'navigation-drawer-active-group': '#d40d0d',
                'main-background': '#121212',
                'primary-text': '#ffffff',
                'dark-text': '#ffffff',

            }
        },
    },
}

export default new Vuetify(opts);