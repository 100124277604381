const currency = {
    methods: {
        format(input: number, return_currency = true, cur = '', dual_price_display = false): string {
            const initial = input;
            let formattedInput = input.toString();
            if (typeof input === 'number') {
                formattedInput =  (input / 100).toFixed(this.fixed(2));
            }
            const negative = formattedInput.indexOf('-') >= 0 ? '-' : '';
            const numbers = this.onlyNumbers(formattedInput);
            const currency = this.numbersToCurrency(numbers, 2);
            const parts = currency.split('.');
            let integer = parts[0];
            const decimal = parts[1];
            integer = this.addThousandSeparator(integer, '.');
            let formatted = negative + this.joinIntegerAndDecimal(integer, decimal, ',');
            if (return_currency) {
                formatted += ` ${cur}`;
            }
            // TODO => Temporary dual price for Croatia. Remove on 1.1.2023.
            if (cur === 'EUR' && dual_price_display) {
                const rate = 7.53450;
                formatted += ` / ${this.format(initial * rate)}${return_currency ? ' HRK' : ''}`;
            }
            return formatted;
        },
        unFormat(input: string, precision: number): number {
            const negative = input.toString().indexOf('-') >= 0 ? -1 : 1;
            const numbers = this.onlyNumbers(input);
            const currency = this.numbersToCurrency(numbers, precision);
            return parseFloat(currency) * negative;
        },
        onlyNumbers(input: string): string {
            return input.replace(/\D+/g, '') || '0';
        },
        numbersToCurrency(numbers: string, precision: number): string {
            const exp = Math.pow(10, precision);
            const float = parseFloat(numbers) / exp;
            return float.toFixed(this.fixed(precision));
        },
        fixed(precision: number): number {
            return this.between(0, precision, 20);
        },
        between(min: number, n: number, max: number): number {
            return Math.max(min, Math.min(n, max));
        },
        addThousandSeparator(integer: string, separator: string): string {
            return integer.replace(/(\d)(?=(?:\d{3})+\b)/gm, `$1${separator}`);
        },
        joinIntegerAndDecimal(integer: string, decimal: string, separator: string): string {
            return decimal ? integer + separator + decimal : integer;
        }
    }
};

export default currency;
