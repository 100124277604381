import CountriesApi from '@/services/setup/CountriesApi';

export interface CountryInterface {
    id: bigint;
    name: string;
}

export class CountryDTO implements CountryInterface {
    id = 0n;
    name = '';
}

export default class Country extends CountryDTO {
    static api = CountriesApi;

    constructor(DTO: CountryDTO){
        super();
        Object.assign(this, DTO);
    }
}