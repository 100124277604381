import TransactionsApi from '@/services/shop/TransactionsApi';

export interface TransactionInterface {
    id: bigint;
    number: number;
    created_at: string;
}

export class TransactionDTO implements TransactionInterface {
    id = 0n;
    number = 0;
    created_at = '';
}

export default class Transaction extends TransactionDTO {
    static api = TransactionsApi;

    constructor(DTO: TransactionDTO){
        super();
        Object.assign(this, DTO);
    }
}