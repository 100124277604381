<template>
    <div>
        <GlobalButton
            :route="'/faulty-users'"
            :text="$t('entities.users.faultyUsers')"
            icon="people"
        />
        <v-card class="elevation-2">
            <v-row
                class="mb-2 px-4"
                align="center"
                justify="start"
            >
                <v-col
                    cols="12"
                    md="2"
                    class="text-center"
                >
                    <RowsPerPage
                        :pagination="pagination"
                        :disabled="loading"
                    />
                </v-col>
                <v-col
                    cols="12"
                    md="4"
                    class="text-center"
                >
                    <v-text-field
                        v-model="pagination.search"
                        @click:append="() => (pagination.search = '')"
                        @keydown.native.escape="pagination.search = ''"
                        :label="selectedSearchAttributes.length ? $t('components.dataTableHeader.search') : $t('components.dataTableHeader.searchDisabled')"
                        :disabled="!selectedSearchAttributes.length || loading"
                        :append-icon="pagination.search ? 'close': ''"
                        prepend-inner-icon="search"
                        class="search"
                        ref="userSearch"
                        hide-details
                        solo
                    ></v-text-field>
                </v-col>
                <v-col
                    cols="12"
                    md="2"
                    class="text-center"
                >
                    <v-select
                        @change="selectedFilterAttributes = $event"
                        :items="filterAttributes"
                        :value="selectedFilterAttributes"
                        :label="$t('components.dataTableHeader.filterBy')"
                        :menu-props="{ bottom: true, offsetY: true }"
                        :no-data-text="$t('components.dataTableHeader.noDataAvailable')"
                        item-text="label"
                        item-value="value"
                        hide-details="auto"
                        multiple
                        solo
                    >
                        <template v-slot:selection="data">
                            <v-chip
                                color="primary"
                                small
                            >
                                {{ data.item.label }}
                            </v-chip>
                        </template>
                    </v-select>
                </v-col>
                <v-col
                    cols="12"
                    md="4"
                    class="text-center"
                >
                    <v-select
                        @change="selectedSearchAttributes = $event"
                        :items="searchAttributes"
                        :value="selectedSearchAttributes"
                        :label="$t('components.dataTableHeader.searchBy')"
                        :menu-props="{ bottom: true, offsetY: true }"
                        :no-data-text="$t('components.dataTableHeader.noDataAvailable')"
                        item-text="label"
                        item-value="value"
                        hide-details="auto"
                        multiple
                        solo
                    >
                        <template v-slot:selection="data">
                            <v-chip
                                color="primary"
                                small
                            >
                                {{ data.item.label }}
                            </v-chip>
                        </template>
                        <template v-slot:prepend-item>
                            <v-list-item
                                @click="toggle"
                                ripple
                            >
                                <v-list-item-action>
                                    <v-icon :color="selectedSearchAttributes.length > 0 ? 'primary darken-4' : ''">{{ icon }}</v-icon>
                                </v-list-item-action>
                                <v-list-item-content>
                                    <v-list-item-title>{{ $t(`components.dataTableHeader.${selectedAllAttributes ? 'de' : ''}selectAll`) }}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-divider class="mt-2"></v-divider>
                        </template>
                    </v-select>
                </v-col>
            </v-row>
            <DataTableSkeletonLoader :loading="initialLoad"/>
            <v-data-table
                v-show="!initialLoad"
                :headers="headers"
                :items="entities"
                :dense="$vuetify.breakpoint.xs && !loading"
                :loading="loading"
                :loading-text="$t('components.noData.fetchingEntries')"
                :options.sync="options"
                :server-items-length="pagination.total"
                :items-per-page="pagination.per_page"
                :header-props="{ sortIcon: 'arrow_circle_up', sortByText: $t('miscellaneous.sortBy') }"
                :class="{ 'mobile-empty-datatable' : $vuetify.breakpoint.xsOnly }"
                hide-default-footer
            >
                <template v-slot:top>
                    <v-toolbar flat>
                        <v-toolbar-title>{{ $t('entities.users.title') }}</v-toolbar-title>
                        <v-divider
                            class="mx-4"
                            inset
                            vertical
                        ></v-divider>
                        <small class="mr-2">{{ addThousandSeparator(pagination.total) }}</small>
                        <RefreshButton
                            :refresh="refresh"
                            :loading="loading"
                        />
                        <v-spacer></v-spacer>
                        <v-dialog
                            v-model="viewDialog"
                            max-width="1000px"
                        >
                            <v-card>
                                <v-card-text class="px-0 pb-0">
                                    <v-toolbar
                                        class="elevation-2"
                                        color="primary"
                                    >
                                        <v-toolbar-title class="ml-0">
                                            <span class="font-weight-bold white--text">{{ $t('entities.users.viewEntity') }}</span>
                                        </v-toolbar-title>
                                        <v-spacer/>
                                        <v-btn
                                            @click="viewDialog = false"
                                            icon
                                            dark
                                        >
                                          <v-icon>mdi-close</v-icon>
                                        </v-btn>
                                    </v-toolbar>
                                    <v-container>
                                        <v-card class="my-4">
                                            <v-simple-table
                                                class="do-not-apply-hover"
                                                dense
                                            >
                                                <template
                                                    v-if="viewDialog"
                                                    v-slot:default
                                                >
                                                    <thead>
                                                        <tr>
                                                            <th
                                                                colspan="3"
                                                                class="text-left"
                                                            >
                                                                {{ $t('entities.orders.generalInformation') }}
                                                            </th>
                                                            <th class="text-right">
                                                                #{{ editedEntity.id }}
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>{{ $t('entities.users.attributes.username') }}</td>
                                                            <td class="font-weight-bold">
                                                                {{ editedEntity.username }}
                                                            </td>
                                                            <td>{{ $t('entities.roles.title') }}</td>
                                                            <td class="font-weight-bold">
                                                                <v-chip
                                                                    v-for="(role, index) in editedEntity.roles"
                                                                    color="primary"
                                                                    :key="`role-${index}`"
                                                                    class="font-weight-bold mr-2"
                                                                    text-color="white"
                                                                    small
                                                                >
                                                                    {{ role }}
                                                                </v-chip>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>{{ $t('entities.users.attributes.active') }}</td>
                                                            <td class="font-weight-bold">
                                                                <Boolean :boolean="editedEntity.active"/>
                                                            </td>
                                                            <td>{{ $t('entities.users.attributes.banned') }}</td>
                                                            <td class="font-weight-bold">
                                                                <Boolean :boolean="editedEntity.banned"/>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>{{ $t('generalAttributes.coins') }}</td>
                                                            <td class="font-weight-bold">
                                                                {{ addThousandSeparator(editedEntity.wallet.balance) }}
                                                                <CoinImage/>
                                                            </td>
                                                            <td>{{ $t('entities.ranks.attributes.experience') }}</td>
                                                            <td class="font-weight-bold">
                                                                {{ addThousandSeparator(editedEntity.rank.experience) }}
                                                                <ExperienceImage class="pt-1"/>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>{{ $t('entities.users.attributes.rank') }}</td>
                                                            <td class="font-weight-bold">
                                                                <v-chip
                                                                    color="primary"
                                                                    class="font-weight-bold mr-2"
                                                                    text-color="white"
                                                                    small
                                                                >
                                                                    {{ editedEntity.rank.info.name }}
                                                                </v-chip>
                                                            </td>
                                                            <td>{{ $t('entities.users.attributes.registeredAt') }}</td>
                                                            <td class="font-weight-bold">
                                                                {{ fromUtcDateTimeToCurrent(editedEntity.created_at, true, true) }}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>{{ $t('entities.users.attributes.cardId') }}</td>
                                                            <td class="font-weight-bold">
                                                                {{ editedEntity.card_id || '/' }}
                                                            </td>
                                                            <td>{{ $t('entities.users.attributes.avatar') }}</td>
                                                            <td class="font-weight-bold">
                                                                <template v-if="editedEntity.avatar">
                                                                    <img
                                                                        :src="editedEntity.avatar"
                                                                        alt="Avatar"
                                                                        width="20px"
                                                                    />
                                                                </template>
                                                                <template v-else>
                                                                    /
                                                                </template>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>{{ $t('entities.sessions.bonuses.legend') }}</td>
                                                            <td class="font-weight-bold">
                                                                {{ editedEntity.bonuses.legend_bonus * 100 }}%
                                                            </td>
                                                            <td>{{ $t('entities.sessions.bonuses.rank') }}</td>
                                                            <td class="font-weight-bold">
                                                                {{ editedEntity.bonuses.rank_bonus * 100 }}%
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>{{ $t('entities.sessions.bonuses.reward') }}</td>
                                                            <td class="font-weight-bold">
                                                                {{ editedEntity.bonuses.reward_bonus * 100 }}%
                                                            </td>
                                                            <td>{{ $t('entities.sessions.bonuses.subscription') }}</td>
                                                            <td class="font-weight-bold">
                                                                {{ editedEntity.bonuses.subscription_bonus * 100 }}%
                                                            </td>
                                                        </tr>
                                                        <tr v-if="editedEntity.reset_password">
                                                            <td colspan="2">{{ $t('entities.users.passwordResetExpiresAt') }}</td>
                                                            <td
                                                                colspan="2"
                                                                class="font-weight-bold"
                                                            >
                                                                {{ fromUtcDateTimeToCurrent(editedEntity.reset_password.expires_at, true, true) }}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </template>
                                            </v-simple-table>
                                            <v-row
                                                align="center"
                                                class="my-2"
                                            >
                                                <template v-if="canManage(editedEntity)">
                                                    <v-col
                                                        cols="12"
                                                        class="text-center"
                                                    >
                                                        <v-btn
                                                            @click="resetPassword(editedEntity.id)"
                                                            :disabled="resettingPassword"
                                                            :loading="resettingPassword"
                                                            color="primary"
                                                            small
                                                        >
                                                            <span>{{ resettingPassword ? $t('entities.users.resettingPassword') : ($t(`entities.users.resetPassword`)) }}</span>
                                                        </v-btn>
                                                    </v-col>
                                                </template>
                                            </v-row>
                                            <v-simple-table
                                                class="do-not-apply-hover"
                                                dense
                                            >
                                                <template
                                                    v-if="viewDialog"
                                                    v-slot:default
                                                >
                                                    <thead>
                                                        <tr>
                                                            <th
                                                                colspan="4"
                                                                class="text-left"
                                                            >
                                                                {{ $t('entities.users.identity') }}
                                                                <Status
                                                                    v-if="editedEntity.identity.id"
                                                                    :status="editedEntity.identity.status"
                                                                />
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <template v-if="editedEntity.identity.id">
                                                            <tr>
                                                                <td>{{ $t('generalAttributes.fullName') }}</td>
                                                                <td class="font-weight-bold">
                                                                    {{ editedEntity.identity.name || '/' }}
                                                                </td>
                                                                <td>{{ $t('generalAttributes.address') }}</td>
                                                                <td class="font-weight-bold">
                                                                    {{ editedEntity.identity.address || '/' }}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>{{ $t('entities.users.attributes.birthdate') }}</td>
                                                                <td class="font-weight-bold">
                                                                    {{ editedEntity.identity.birthdate || '/' }}
                                                                </td>
                                                                <td>{{ $t('miscellaneous.createdAt') }}</td>
                                                                <td class="font-weight-bold">
                                                                    {{ fromUtcDateTimeToCurrent(editedEntity.identity.created_at, true, true) }}
                                                                </td>
                                                            </tr>
                                                        </template>
                                                        <template v-else>
                                                            <tr>
                                                                <td colspan="4">
                                                                    <i>{{ $t('entities.users.noIdentitySet') }}</i>
                                                                </td>
                                                            </tr>
                                                        </template>
                                                    </tbody>
                                                </template>
                                            </v-simple-table>
                                            <v-row
                                                v-if="editedEntity.identity.id && editedEntity.identity.status === 'PENDING'"
                                                align="center"
                                                class="my-2"
                                            >
                                                <v-col
                                                    cols="12"
                                                    class="text-center"
                                                >
                                                    <v-btn
                                                        @click="approveIdentity(editedEntity.id)"
                                                        :disabled="approvingIdentity || rejectingIdentity"
                                                        :loading="approvingIdentity"
                                                        color="success"
                                                        class="mx-2"
                                                        small
                                                    >
                                                        <span>{{ approvingIdentity ? $t('entities.users.approvingIdentity') : ($t(`entities.users.approveIdentity`)) }}</span>
                                                    </v-btn>
                                                    <v-btn
                                                        @click="rejectIdentity(editedEntity.id)"
                                                        :disabled="approvingIdentity || rejectingIdentity"
                                                        :loading="rejectingIdentity"
                                                        color="error"
                                                        class="mx-2"
                                                        small
                                                    >
                                                        <span>{{ rejectingIdentity ? $t('entities.users.rejectingIdentity') : ($t(`entities.users.rejectIdentity`)) }}</span>
                                                    </v-btn>
                                                </v-col>
                                            </v-row>
                                            <v-simple-table
                                                class="do-not-apply-hover"
                                                dense
                                            >
                                                <template
                                                    v-if="viewDialog"
                                                    v-slot:default
                                                >
                                                    <thead>
                                                        <tr>
                                                            <th
                                                                colspan="4"
                                                                class="text-left"
                                                            >
                                                                {{ $t('entities.users.attributes.email') }}
                                                                <Status
                                                                    v-if="editedEntity.email.id"
                                                                    :status="editedEntity.email.status"
                                                                />
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <template v-if="editedEntity.email.id">
                                                                <td>{{ $t('generalAttributes.address') }}</td>
                                                                <td class="font-weight-bold">
                                                                    <CopyTooltip :text="editedEntity.email.address"/>
                                                                </td>
                                                                <td>{{ $t('miscellaneous.createdAt') }}</td>
                                                                <td class="font-weight-bold">
                                                                    {{ fromUtcDateTimeToCurrent(editedEntity.email.created_at, true, true) }}
                                                                </td>
                                                            </template>
                                                            <template v-else>
                                                                <td colspan="4">
                                                                    <i>{{ $t('entities.users.noEmailSet') }}</i>
                                                                </td>
                                                            </template>
                                                        </tr>
                                                    </tbody>
                                                    <thead>
                                                        <tr>
                                                            <th
                                                                colspan="4"
                                                                class="text-left"
                                                            >
                                                                {{ $t('entities.users.attributes.phone') }}
                                                                <Status
                                                                    v-if="editedEntity.phone.id"
                                                                    :status="editedEntity.phone.status"
                                                                />
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <template v-if="editedEntity.phone.id">
                                                                <td>{{ $t('entities.orders.attributes.number') }}</td>
                                                                <td class="font-weight-bold">
                                                                    <CopyTooltip :text="`+${editedEntity.phone.number}`"/>
                                                                </td>
                                                                <td>{{ $t('miscellaneous.createdAt') }}</td>
                                                                <td class="font-weight-bold">
                                                                    {{ fromUtcDateTimeToCurrent(editedEntity.phone.created_at, true, true) }}
                                                                </td>
                                                            </template>
                                                            <template v-else>
                                                                <td colspan="4">
                                                                    <i>{{ $t('entities.users.noPhoneSet') }}</i>
                                                                </td>
                                                            </template>
                                                        </tr>
                                                    </tbody>
                                                </template>
                                            </v-simple-table>
                                        </v-card>
                                        <v-card class="my-4">
                                            <template v-if="viewDialog">
                                                <v-data-table
                                                    :headers="inventoryHeaders"
                                                    :items="editedEntity.inventory"
                                                    :items-per-page="5"
                                                    :header-props="{ sortIcon: 'arrow_circle_up', sortByText: $t('miscellaneous.sortBy') }"
                                                    :no-data-text="$t('components.dataTableHeader.noDataAvailable')"
                                                    :footer-props="{ itemsPerPageText : $t('components.rowsPerPage.rows'), itemsPerPageAllText: $t('components.rowsPerPage.all') }"
                                                    class="do-not-apply-hover px-2"
                                                    dense
                                                >
                                                    <template v-slot:top>
                                                        <v-toolbar flat>
                                                            <v-toolbar-title>
                                                                {{ $t('entities.users.inventory') }}
                                                            </v-toolbar-title>
                                                            <v-divider
                                                                class="mx-4"
                                                                inset
                                                                vertical
                                                            ></v-divider>
                                                            <small>{{ editedEntity.inventory.length }}</small>
                                                            <v-spacer></v-spacer>
                                                        </v-toolbar>
                                                    </template>
                                                    <template v-slot:item.name="{ item }">
                                                        <span class="text-capitalize">{{ item.name }}</span>
                                                        <template v-if="item.type === 'bonus'">
                                                            ({{ item.amount * 100 }}%)
                                                            <v-chip
                                                                class="font-weight-bold"
                                                                :color="item.active ? 'success' : 'error'"
                                                                text-color="white"
                                                                small
                                                            >
                                                                {{ $t(`entities.games.${item.active ? 'attributes.active' : 'notActive'}`) }}
                                                            </v-chip>
                                                            <template v-if="item.duration">
                                                                <PrimaryChip :text="`${$t('entities.inventoryItems.duration')}: ${fromSecondsToHumanReadableFormat(item.duration)}`"/>
                                                                 <v-chip
                                                                    v-if="item.updated_at"
                                                                    class="font-weight-bold ml-2"
                                                                    color="secondary"
                                                                    text-color="white"
                                                                    small
                                                                >
                                                                    {{ $t('entities.carts.attributes.expiresAt') }}: {{ formatDate(new Date(new Date(item.updated_at).getTime() + item.duration * 1000), true, true) }}
                                                                </v-chip>
                                                            </template>
                                                        </template>
                                                        <template v-else-if="item.type === 'coin'">
                                                            ({{ item.amount }} <CoinImage/>)
                                                        </template>
                                                    </template>
                                                    <template v-slot:item.created_at="{ item }">
                                                        {{ formatDate(new Date(item.created_at), true, true) }}
                                                    </template>
                                                    <template v-slot:footer.page-text="items">
                                                        {{ items.pageStart }} - {{ items.pageStop }} {{ $t('components.pagination.of') }} {{ items.itemsLength }}
                                                    </template>
                                                </v-data-table>
                                            </template>
                                        </v-card>
                                        <v-card class="my-4">
                                            <template v-if="viewDialog">
                                                <v-data-table
                                                    :headers="achievementsHeaders"
                                                    :items="editedEntity.achievements"
                                                    :items-per-page="5"
                                                    :header-props="{ sortIcon: 'arrow_circle_up', sortByText: $t('miscellaneous.sortBy') }"
                                                    :no-data-text="$t('components.dataTableHeader.noDataAvailable')"
                                                    :footer-props="{ itemsPerPageText : $t('components.rowsPerPage.rows'), itemsPerPageAllText: $t('components.rowsPerPage.all') }"
                                                    class="do-not-apply-hover px-2"
                                                    dense
                                                >
                                                    <template v-slot:top>
                                                        <v-toolbar flat>
                                                            <v-toolbar-title>{{ $t('entities.achievements.title') }}</v-toolbar-title>
                                                            <v-divider
                                                                class="mx-4"
                                                                inset
                                                                vertical
                                                            ></v-divider>
                                                            <small>{{ editedEntity.achievements.length }}</small>
                                                            <v-spacer></v-spacer>
                                                        </v-toolbar>
                                                    </template>
                                                    <template v-slot:item.points="{ item }">
                                                        <PrimaryChip
                                                            :text="item.points.toString()"
                                                            margin="ml-0"
                                                        />
                                                    </template>
                                                    <template v-slot:item.created_at="{ item }">
                                                        <span v-if="item.created_at">
                                                            {{ fromUtcDateTimeToCurrent(item.created_at, true, true) }}
                                                        </span>
                                                        <span v-else>
                                                            /
                                                        </span>
                                                    </template>
                                                    <template v-slot:item.progress_value="{ item }">
                                                        <span>{{ Math.round(item.progress_value * 100) / 100 }}%</span>
                                                    </template>
                                                    <template v-slot:item.progress_updated_at="{ item }">
                                                        <span v-if="item.progress_updated_at">
                                                            {{ fromUtcDateTimeToCurrent(item.progress_updated_at, true, true) }}
                                                        </span>
                                                            <span v-else>
                                                            /
                                                        </span>
                                                    </template>
                                                    <template v-slot:footer.page-text="items">
                                                        {{ items.pageStart }} - {{ items.pageStop }} {{ $t('components.pagination.of') }} {{ items.itemsLength }}
                                                    </template>
                                                </v-data-table>
                                            </template>
                                        </v-card>
                                        <v-card class="my-4">
                                            <template v-if="viewDialog">
                                                <v-data-table
                                                    :headers="questsHeaders"
                                                    :items="editedEntity.quests"
                                                    :items-per-page="5"
                                                    :header-props="{ sortIcon: 'arrow_circle_up', sortByText: $t('miscellaneous.sortBy') }"
                                                    :no-data-text="$t('components.dataTableHeader.noDataAvailable')"
                                                    :footer-props="{ itemsPerPageText : $t('components.rowsPerPage.rows'), itemsPerPageAllText: $t('components.rowsPerPage.all') }"
                                                    class="do-not-apply-hover px-2"
                                                    dense
                                                >
                                                    <template v-slot:top>
                                                        <v-toolbar flat>
                                                            <v-toolbar-title>{{ $t('entities.quests.title') }}</v-toolbar-title>
                                                            <v-divider
                                                                class="mx-4"
                                                                inset
                                                                vertical
                                                            ></v-divider>
                                                            <small>{{ editedEntity.quests.length }}</small>
                                                            <v-spacer></v-spacer>
                                                        </v-toolbar>
                                                    </template>
                                                    <template v-slot:item.pool_type="{ item }">
                                                        <v-chip
                                                            color="primary"
                                                            class="font-weight-bold mr-2"
                                                            text-color="white"
                                                            small
                                                        >
                                                            {{ $t(`entities.quests.${item.pool_type.toLowerCase()}`) }}
                                                        </v-chip>
                                                    </template>
                                                    <template v-slot:item.created_at="{ item }">
                                                        <span v-if="item.created_at">
                                                            {{ fromUtcDateTimeToCurrent(item.created_at, true, true) }}
                                                        </span>
                                                            <span v-else>
                                                            /
                                                        </span>
                                                    </template>
                                                    <template v-slot:item.progress_value="{ item }">
                                                        <span>{{ Math.round(item.progress_value * 100) / 100 }}%</span>
                                                    </template>
                                                    <template v-slot:item.progress_updated_at="{ item }">
                                                        <span v-if="item.progress_updated_at">
                                                            {{ fromUtcDateTimeToCurrent(item.progress_updated_at, true, true) }}
                                                        </span>
                                                            <span v-else>
                                                            /
                                                        </span>
                                                    </template>
                                                    <template v-slot:footer.page-text="items">
                                                        {{ items.pageStart }} - {{ items.pageStop }} {{ $t('components.pagination.of') }} {{ items.itemsLength }}
                                                    </template>
                                                </v-data-table>
                                            </template>
                                        </v-card>
                                        <v-card class="my-4">
                                             <template v-if="viewDialog">
                                                <v-data-table
                                                    :headers="subscriptionsHeaders"
                                                    :items="editedEntity.subscriptions"
                                                    :items-per-page="5"
                                                    :header-props="{ sortIcon: 'arrow_circle_up', sortByText: $t('miscellaneous.sortBy') }"
                                                    :no-data-text="$t('components.dataTableHeader.noDataAvailable')"
                                                    :footer-props="{ itemsPerPageText : $t('components.rowsPerPage.rows'), itemsPerPageAllText: $t('components.rowsPerPage.all') }"
                                                    class="do-not-apply-hover px-2"
                                                    dense
                                                >
                                                    <template v-slot:top>
                                                        <v-toolbar flat>
                                                            <v-toolbar-title>
                                                                {{ $t('entities.users.subscriptions') }}
                                                            </v-toolbar-title>
                                                            <v-divider
                                                                class="mx-4"
                                                                inset
                                                                vertical
                                                            ></v-divider>
                                                            <small>{{ editedEntity.subscriptions.length }}</small>
                                                            <v-spacer></v-spacer>
                                                        </v-toolbar>
                                                    </template>
                                                    <template v-slot:item.bonus="{ item }">
                                                        {{ item.bonus }}%
                                                    </template>
                                                    <template v-slot:item.created_at="{ item }">
                                                        {{ fromUtcDateTimeToCurrent(item.created_at, true, true) }}
                                                    </template>
                                                    <template v-slot:item.expires_at="{ item }">
                                                        {{ fromUtcDateTimeToCurrent(item.expires_at, true, true) }}
                                                    </template>
                                                    <template v-slot:footer.page-text="items">
                                                        {{ items.pageStart }} - {{ items.pageStop }} {{ $t('components.pagination.of') }} {{ items.itemsLength }}
                                                    </template>
                                                </v-data-table>
                                            </template>
                                        </v-card>
                                        <v-card class="my-4">
                                             <template v-if="viewDialog">
                                                <v-data-table
                                                    :headers="settingsHeaders"
                                                    :items="editedEntity.settings"
                                                    :items-per-page="5"
                                                    :header-props="{ sortIcon: 'arrow_circle_up', sortByText: $t('miscellaneous.sortBy') }"
                                                    :no-data-text="$t('components.dataTableHeader.noDataAvailable')"
                                                    :footer-props="{ itemsPerPageText : $t('components.rowsPerPage.rows'), itemsPerPageAllText: $t('components.rowsPerPage.all') }"
                                                    class="do-not-apply-hover px-2"
                                                    dense
                                                >
                                                    <template v-slot:top>
                                                        <v-toolbar flat>
                                                            <v-toolbar-title>
                                                                {{ $t('entities.settings.title') }}
                                                            </v-toolbar-title>
                                                            <v-divider
                                                                class="mx-4"
                                                                inset
                                                                vertical
                                                            ></v-divider>
                                                            <small>{{ editedEntity.settings.length }}</small>
                                                            <v-spacer></v-spacer>
                                                        </v-toolbar>
                                                    </template>
                                                    <template v-slot:item.value="{ item }">
                                                        <template v-if="item.value === 'true' || item.value === 'false'">
                                                            <Boolean :boolean="item.value === 'true'"/>
                                                        </template>
                                                        <template v-else>
                                                            {{ item.value }}
                                                        </template>
                                                    </template>
                                                    <template v-slot:footer.page-text="items">
                                                        {{ items.pageStart }} - {{ items.pageStop }} {{ $t('components.pagination.of') }} {{ items.itemsLength }}
                                                    </template>
                                                </v-data-table>
                                            </template>
                                        </v-card>
                                    </v-container>
                                </v-card-text>
                                <v-divider/>
                                <v-card-actions>
                                    <v-spacer/>
                                    <v-btn
                                        @click="viewDialog = false"
                                        color="dark darken-1"
                                        text
                                    >
                                      {{ $t('miscellaneous.ok') }}
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                        <v-dialog
                            v-model="awardDialog"
                            max-width="600px"
                        >
                            <v-card>
                                <v-card-text class="px-0 pb-0">
                                    <v-toolbar
                                        class="elevation-2"
                                        color="primary"
                                    >
                                        <v-toolbar-title class="ml-0">
                                            <span class="font-weight-bold white--text">{{ $t('entities.users.awardUser') }} {{ editedEntity.username }}</span>
                                        </v-toolbar-title>
                                        <v-spacer/>
                                        <v-btn
                                            @click="awardDialog = false"
                                            icon
                                            dark
                                        >
                                          <v-icon>mdi-close</v-icon>
                                        </v-btn>
                                    </v-toolbar>
                                    <v-container>
                                        <v-card class="px-4 pt-4">
                                            <v-row align="center">
                                                <RequiredFields/>
                                                <v-col cols="12">
                                                    <v-form
                                                        v-if="awardDialog"
                                                        @submit.prevent="awardEvent"
                                                        method="POST"
                                                        ref="awardForm"
                                                    >
                                                        <v-col
                                                            cols="12"
                                                            class="pt-4 pb-0"
                                                        >
                                                            <v-select
                                                                v-model="editedEntity.events"
                                                                :items="filteredEvents"
                                                                :rules="[v => !!v.length || $t('validation.required')]"
                                                                :menu-props="{ bottom: true, offsetY: true }"
                                                                :no-data-text="$t('components.dataTableHeader.noDataAvailable')"
                                                                :disabled="awardingEvent"
                                                                item-text="name"
                                                                class="do-not-apply-transform"
                                                                prepend-inner-icon="mdi-trophy-award"
                                                                return-object
                                                                multiple
                                                                solo
                                                            >
                                                                <template v-slot:label>
                                                                    {{ $t('entities.events.title') }}
                                                                    <span :style="{ color: 'red' }">*</span>
                                                                </template>
                                                            </v-select>
                                                        </v-col>
                                                        <v-col
                                                            cols="12"
                                                            class="text-center pt-0"
                                                        >
                                                            <v-btn
                                                                @click="awardEvent"
                                                                :disabled="awardingEvent"
                                                                :loading="awardingEvent"
                                                                color="primary"
                                                            >
                                                                <span>{{ awardingEvent ? $t('entities.users.awarding') : ($t(`entities.users.awardProgress`)) }}</span>
                                                            </v-btn>
                                                        </v-col>
                                                    </v-form>
                                                </v-col>
                                            </v-row>
                                        </v-card>
                                    </v-container>
                                </v-card-text>
                                <v-divider/>
                                <v-card-actions>
                                    <v-spacer/>
                                    <v-btn
                                        @click="awardDialog = false"
                                        color="dark darken-1"
                                        text
                                    >
                                      {{ $t('miscellaneous.cancel') }}
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                        <v-dialog
                            v-model="simulateSessionDialog"
                            max-width="600px"
                        >
                          <v-card>
                            <v-card-text class="px-0 pb-0">
                              <v-toolbar
                                  class="elevation-2"
                                  color="primary"
                              >
                                <v-toolbar-title class="ml-0">
                                  <span class="font-weight-bold white--text">{{ $t('entities.users.simulateSession') }} {{ editedEntity.username }}</span>
                                </v-toolbar-title>
                                <v-spacer/>
                                <v-btn
                                    @click="simulateSessionDialog = false; simulationResult = null"
                                    icon
                                    dark
                                >
                                  <v-icon>mdi-close</v-icon>
                                </v-btn>
                              </v-toolbar>
                              <v-container>
                                <v-card class="px-4 pt-4">
                                  <v-row align="center">
                                    <RequiredFields/>
                                    <v-col cols="12">
                                        <v-col
                                            cols="12"
                                            class="pt-4 pb-0"
                                        >
                                            <v-select
                                                v-model="selectedSimulatorOption"
                                                :items="getSimulatorOptions"
                                                :rules="[v => !!v.length || $t('validation.required')]"
                                                :menu-props="{ bottom: true, offsetY: true }"
                                                :no-data-text="$t('components.dataTableHeader.noDataAvailable')"
                                                :disabled="simulatingSession"
                                                item-text="name"
                                                class="do-not-apply-transform"
                                                prepend-inner-icon="mdi-timer-sand"
                                                return-object
                                                solo
                                                @change="simulationResult = null"
                                            >
                                                <template v-slot:label>
                                                    {{ $t('entities.users.simulatorTitle') }}
                                                    <span :style="{ color: 'red' }">*</span>
                                                </template>
                                            </v-select>
                                        </v-col>
                                          <v-form
                                              v-if="simulateSessionDialog && isSessionCostSelected(selectedSimulatorOption)"
                                              @submit.prevent="estimateSessionCost"
                                              method="POST"
                                              ref="estimateCostForm"
                                          >
                                              <p>{{ $t('entities.users.enterDuration') }}</p>
                                              <v-row>
                                                  <v-col cols="12" sm="6">
                                                      <v-text-field
                                                          :value="editedEntity.wanted_playtime_hours"
                                                          @change="v => (editedEntity.wanted_playtime_hours = v)"
                                                          :label="$t('entities.users.wantedHours')"
                                                          :rules="[numeric, min]"
                                                          type="number"
                                                      ></v-text-field>
                                                  </v-col>
                                                  <v-col cols="12" sm="6">
                                                      <v-text-field
                                                          :value="editedEntity.wanted_playtime_minutes"
                                                          @change="v => (editedEntity.wanted_playtime_minutes = v)"
                                                          :label="$t('entities.users.wantedMinutes')"
                                                          :rules="[numeric, min]"
                                                          type="number"
                                                      ></v-text-field>
                                                  </v-col>
                                              </v-row>

                                            <v-col
                                                cols="12"
                                                class="text-center pt-0"
                                            >
                                              <v-btn
                                                  @click="estimateSessionCost"
                                                  :disabled="simulatingSession"
                                                  :loading="simulatingSession"
                                                  color="primary"
                                              >
                                                <span>{{ simulatingSession ? $t('entities.users.estimating') : ($t(`entities.users.estimateCost`)) }}</span>
                                              </v-btn>
                                            </v-col>
                                          </v-form>
                                        <v-form
                                            v-if="simulateSessionDialog && isSessionDurationSelected(selectedSimulatorOption)"
                                            @submit.prevent="estimateSessionDuration"
                                            method="POST"
                                            ref="estimateDurationForm"
                                        >
                                            <p>{{ $t('entities.users.enterCoins') }}</p>
                                            <v-col
                                                cols="12"
                                                class="text-center pt-0"
                                            >
                                                <v-text-field
                                                    :value="editedEntity.availableCoins"
                                                    @change="v => (editedEntity.availableCoins = v)"
                                                    :label="$t('entities.users.coins')"
                                                    :rules="[numeric, min]"
                                                    type="number"
                                                ></v-text-field>
                                            </v-col>
                                            <v-col
                                                cols="12"
                                                class="text-center pt-0"
                                            >
                                                <v-btn
                                                    @click="estimateSessionDuration"
                                                    :disabled="simulatingSession"
                                                    :loading="simulatingSession"
                                                    color="primary"
                                                >
                                                    <span>{{ simulatingSession ? $t('entities.users.estimating') : ($t(`entities.users.estimateDuration`)) }}</span>
                                                </v-btn>
                                            </v-col>
                                        </v-form>
                                        <div v-if="simulateSessionDialog && simulationResult">
                                            <p>
                                                {{ $t('entities.users.estimatedPlaytime') }}
                                                {{ getHoursFromMinutes(simulationResult.duration_minutes) }}h {{getRemainingMinutes(simulationResult.duration_minutes)}}min.
                                            </p>
                                            <p>
                                                {{ $t('entities.users.estimatedCost') }}: {{ simulationResult.cost }} {{ $t('generalAttributes.coins') }}.
                                            </p>
                                            <p v-if="simulationResult.daily_boost_time_remaining_secs">
                                                {{ $t('entities.users.timeRemainingUntilDailyBoost') }}
                                                {{ getHoursFromSeconds(simulationResult.daily_boost_time_remaining_secs) }}h
                                                {{ getRemainingMinutesFromSeconds(simulationResult.daily_boost_time_remaining_secs)}}min.
                                            </p>
                                            <p v-if="!simulationResult.daily_boost_time_remaining_secs">
                                                {{ $t('entities.users.afterThatDailyBoost') }}
                                            </p>
                                        </div>
                                    </v-col>
                                  </v-row>
                                </v-card>
                              </v-container>
                            </v-card-text>
                            <v-divider/>
                            <v-card-actions>
                              <v-spacer/>
                              <v-btn
                                  @click="simulateSessionDialog = false; simulationResult = null"
                                  color="dark darken-1"
                                  text
                              >
                                {{ $t('miscellaneous.cancel') }}
                              </v-btn>
                            </v-card-actions>
                          </v-card>
                        </v-dialog>
                        <v-dialog
                            v-model="editDialog"
                            max-width="1000px"
                        >
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    v-on="on"
                                    color="primary"
                                    class="mb-2"
                                    dark
                                >
                                    {{ $t('miscellaneous.add') }}
                                </v-btn>
                            </template>
                            <v-card>
                                <v-card-text class="px-0 pb-0">
                                    <EditToolbar
                                        :formTitle="formTitle"
                                        :closeEditDialog="closeEditDialog"
                                    />
                                    <v-container>
                                        <v-card class="mt-4 px-4 pb-4">
                                            <v-form
                                                @submit.prevent="save"
                                                method="POST"
                                                ref="form"
                                            >
                                                <v-row align="center">
                                                    <RequiredFields/>
                                                    <v-col
                                                        cols="12"
                                                        sm="6"
                                                        class="position-relative"
                                                    >
                                                        <v-text-field
                                                            :value="editedEntity.username"
                                                            @change="v => (editedEntity.username = v)"
                                                            :rules="editedIndex === -1 ? [required, username, minimum, maximum] : []"
                                                            :label="$t('entities.users.attributes.username')"
                                                            :disabled="editedIndex !== -1"
                                                            prepend-icon="mdi-account-box"
                                                        ></v-text-field>
                                                        <span
                                                            v-if="editedIndex === -1"
                                                            class="required-fields"
                                                        >
                                                            *
                                                        </span>
                                                    </v-col>
                                                    <v-col
                                                        cols="12"
                                                        sm="6"
                                                        class="position-relative"
                                                    >
                                                        <v-text-field
                                                            v-model="editedEntity.password"
                                                            @click:append="show = !show"
                                                            :rules="editedIndex === -1 ? [required, minimumPassword] : [minimumPasswordOrEmpty]"
                                                            :type="show ? 'text' : 'password'"
                                                            :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                                                            :prepend-icon="show ? 'lock_open' : 'lock'"
                                                            :label="$t('login.password')"
                                                            counter
                                                            loading
                                                        >
                                                            <template v-slot:progress>
                                                                <v-progress-linear
                                                                    :value="progress"
                                                                    :color="color"
                                                                    height="5"
                                                                    absolute
                                                                    striped
                                                                ></v-progress-linear>
                                                            </template>
                                                        </v-text-field>
                                                        <span
                                                            v-if="editedIndex === -1"
                                                            class="required-fields"
                                                        >
                                                            *
                                                        </span>
                                                    </v-col>
                                                    <v-col
                                                        cols="12"
                                                        sm="6"
                                                        class="pb-0"
                                                    >
                                                        <v-select
                                                            :value="editedEntity.roles"
                                                            @change="editedEntity.roles = $event"
                                                            :items="availableRoles"
                                                            :menu-props="{ bottom: true, offsetY: true }"
                                                            :label="$t(`entities.roles.title`)"
                                                            item-text="name"
                                                            item-value="id"
                                                            class="do-not-apply-transform"
                                                            prepend-inner-icon="mdi-account-circle"
                                                            return-object
                                                            multiple
                                                            solo
                                                        >
                                                            <template v-slot:selection="data">
                                                                <span v-if="data.index === 0">{{ $t(`entities.roles.title`) }}:</span>
                                                                <v-chip
                                                                    color="primary"
                                                                    small
                                                                >
                                                                    {{ data.item }}
                                                                </v-chip>
                                                            </template>
                                                        </v-select>
                                                    </v-col>
                                                    <v-col
                                                        cols="12"
                                                        sm="3"
                                                        class="pb-0"
                                                    >
                                                        <v-switch
                                                            :input-value="editedEntity.active"
                                                            @change="v => (editedEntity.active = v)"
                                                            :label="$t('entities.users.attributes.active')"
                                                            prepend-icon="done"
                                                            inset
                                                        ></v-switch>
                                                    </v-col>
                                                    <v-col
                                                        cols="12"
                                                        sm="3"
                                                        class="pb-0"
                                                    >
                                                        <v-switch
                                                            :input-value="editedEntity.banned"
                                                            @change="v => (editedEntity.banned = v)"
                                                            :label="$t('entities.users.attributes.banned')"
                                                            prepend-icon="done"
                                                            inset
                                                        ></v-switch>
                                                    </v-col>
                                                    <v-col
                                                        cols="12"
                                                        class="py-0"
                                                    >
                                                        <v-text-field
                                                            :value="editedEntity.card_id"
                                                            @change="v => (editedEntity.card_id = v)"
                                                            :label="$t('entities.users.attributes.cardId')"
                                                            :rules="[numeric, min]"
                                                            prepend-icon="mdi-card-account-details"
                                                            type="number"
                                                        ></v-text-field>
                                                    </v-col>
                                                    <v-col
                                                        cols="12"
                                                        class="py-0"
                                                    >
                                                        <h3 class="mt-4 grey--text text--darken-1">
                                                            {{ $t('entities.users.identity') }}
                                                        </h3>
                                                    </v-col>
                                                    <v-col
                                                        cols="12"
                                                        sm="6"
                                                    >
                                                        <v-text-field
                                                            :value="editedEntity.identity.name"
                                                            @change="v => (editedEntity.identity.name = v)"
                                                            :label="$t('generalAttributes.fullName')"
                                                            prepend-icon="person"
                                                        ></v-text-field>
                                                    </v-col>
                                                    <v-col
                                                        cols="12"
                                                        sm="6"
                                                        class="position-relative"
                                                    >
                                                        <v-menu
                                                            v-model="menu"
                                                            :close-on-content-click="false"
                                                            ref="menu"
                                                            transition="scale-transition"
                                                            min-width="auto"
                                                            offset-y
                                                        >
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <v-text-field
                                                                    v-model="editedEntity.identity.birthdate"
                                                                    v-bind="attrs"
                                                                    v-on="on"
                                                                    :label="$t('entities.users.attributes.birthdate')"
                                                                    prepend-icon="mdi-calendar"
                                                                    clearable
                                                                    readonly
                                                                ></v-text-field>
                                                            </template>
                                                            <v-date-picker
                                                                v-model="editedEntity.identity.birthdate"
                                                                @change="saveDate"
                                                                :active-picker.sync="activePicker"
                                                                :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
                                                                min="1921-01-01"
                                                            ></v-date-picker>
                                                        </v-menu>
                                                    </v-col>
                                                    <v-col
                                                        cols="12"
                                                        sm="6"
                                                    >
                                                        <v-text-field
                                                            :value="editedEntity.identity.address"
                                                            @change="v => (editedEntity.identity.address = v)"
                                                            :label="$t('generalAttributes.address')"
                                                            prepend-icon="house"
                                                        ></v-text-field>
                                                    </v-col>
                                                    <v-col
                                                        cols="12"
                                                        sm="6"
                                                    >
                                                        <v-text-field
                                                            :value="editedEntity.identity.pidn"
                                                            @change="v => (editedEntity.identity.pidn = v)"
                                                            :label="$t('generalAttributes.pidn')"
                                                            prepend-icon="short_text"
                                                        ></v-text-field>
                                                    </v-col>
                                                    <v-col
                                                        cols="12"
                                                        class="py-0"
                                                    >
                                                        <h3 class="mt-4 grey--text text--darken-1">
                                                            {{ $t('entities.users.contact') }}
                                                        </h3>
                                                    </v-col>
                                                    <v-col
                                                        cols="12"
                                                        sm="6"
                                                    >
                                                        <v-text-field
                                                            :value="editedEntity.email.address"
                                                            @change="v => (editedEntity.email.address = v)"
                                                            :label="$t('entities.users.attributes.email')"
                                                            :rules="[email]"
                                                            prepend-icon="email"
                                                        ></v-text-field>
                                                    </v-col>
                                                    <v-col
                                                        cols="12"
                                                        sm="6"
                                                    >
                                                        <v-row align="center">
                                                            <v-col
                                                                cols="6"
                                                                sm="5"
                                                                md="4"
                                                            >
                                                                <v-autocomplete
                                                                    v-model="editedEntity.phone.country_code"
                                                                    :items="countries"
                                                                    :menu-props="{ bottom: true, offsetY: true }"
                                                                    class="do-not-apply-transform mt-2"
                                                                    prepend-inner-icon="phone"
                                                                    item-text="name"
                                                                    item-value="dialCode"
                                                                    solo
                                                                >
                                                                    <template v-slot:selection="data">
                                                                        <img
                                                                            :src="`https://flagcdn.com/w20/${data.item.iso2}.png`"
                                                                            width="15px"
                                                                            height="15px"
                                                                            class="mr-2"
                                                                        />
                                                                        +{{ data.item.dialCode }}
                                                                    </template>
                                                                    <template v-slot:item="data">
                                                                        <img
                                                                            :src="`https://flagcdn.com/w20/${data.item.iso2}.png`"
                                                                            width="15px"
                                                                            height="15px"
                                                                            class="mr-2"
                                                                        />
                                                                        {{ data.item.name }} +{{ data.item.dialCode }}
                                                                    </template>
                                                                </v-autocomplete>
                                                            </v-col>
                                                            <v-col
                                                                cols="6"
                                                                sm="7"
                                                                md="8"
                                                                class="pl-0"
                                                            >
                                                                <v-text-field
                                                                    :value="editedEntity.phone.national_number"
                                                                    @change="v => (editedEntity.phone.national_number = v)"
                                                                    :rules="[phoneNumber]"
                                                                    :label="$t('generalAttributes.phone')"
                                                                ></v-text-field>
                                                            </v-col>
                                                        </v-row>
                                                    </v-col>
                                                </v-row>
                                                <ValidationErrors
                                                    v-if="validationErrors.length"
                                                    :errors="validationErrors"
                                                    class="mt-6"
                                                />
                                            </v-form>
                                        </v-card>
                                    </v-container>
                                </v-card-text>
                                <EditCardActions
                                    :save="save"
                                    :saving="saving"
                                    :closeEditDialog="closeEditDialog"
                                />
                            </v-card>
                        </v-dialog>
                        <v-dialog
                            v-model="deleteDialog"
                            max-width="1000px"
                        >
                            <v-card>
                                <v-card-text class="px-0 pb-0">
                                    <DeleteToolbar
                                        :text="$t('entities.users.deleteEntity')"
                                        :closeDeleteDialog="closeDeleteDialog"
                                    />
                                        <v-container>
                                            <v-card>
                                                <v-simple-table
                                                    class="do-not-apply-hover"
                                                    dense
                                                >
                                                    <template
                                                        v-if="deleteDialog"
                                                        v-slot:default
                                                    >
                                                        <thead>
                                                            <tr>
                                                                <th
                                                                    colspan="4"
                                                                    class="text-left"
                                                                >
                                                                    {{ $t('entities.orders.generalInformation') }}
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>{{ $t('entities.users.attributes.username') }}</td>
                                                                <td class="font-weight-bold">
                                                                    {{ toDeleteEntity.username }}
                                                                </td>
                                                                <td>{{ $t('entities.roles.title') }}</td>
                                                                <td class="font-weight-bold">
                                                                    <v-chip
                                                                        v-for="(role, index) in toDeleteEntity.roles"
                                                                        color="primary"
                                                                        :key="`role-${index}`"
                                                                        class="font-weight-bold mr-2"
                                                                        text-color="white"
                                                                        small
                                                                    >
                                                                        {{ role }}
                                                                    </v-chip>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>{{ $t('entities.users.attributes.active') }}</td>
                                                                <td class="font-weight-bold">
                                                                    <Boolean :boolean="toDeleteEntity.active"/>
                                                                </td>
                                                                <td>{{ $t('entities.users.attributes.banned') }}</td>
                                                                <td class="font-weight-bold">
                                                                    <Boolean :boolean="toDeleteEntity.banned"/>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>{{ $t('generalAttributes.coins') }}</td>
                                                                <td class="font-weight-bold">
                                                                    {{ addThousandSeparator(toDeleteEntity.wallet.balance) }}
                                                                    <CoinImage/>
                                                                </td>
                                                                <td>{{ $t('entities.ranks.attributes.experience') }}</td>
                                                                <td class="font-weight-bold">
                                                                    {{ addThousandSeparator(toDeleteEntity.rank.experience) }}
                                                                    <ExperienceImage class="pt-1"/>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>{{ $t('entities.users.attributes.rank') }}</td>
                                                                <td class="font-weight-bold">
                                                                    <v-chip
                                                                        color="primary"
                                                                        class="font-weight-bold mr-2"
                                                                        text-color="white"
                                                                        small
                                                                    >
                                                                        {{ toDeleteEntity.rank.info.name }}
                                                                    </v-chip>
                                                                </td>
                                                                <td>{{ $t('entities.users.attributes.registeredAt') }}</td>
                                                                <td class="font-weight-bold">
                                                                    {{ fromUtcDateTimeToCurrent(toDeleteEntity.created_at, true, true) }}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>{{ $t('entities.users.attributes.cardId') }}</td>
                                                                <td class="font-weight-bold">
                                                                    {{ toDeleteEntity.card_id || '/' }}
                                                                </td>
                                                                <td>{{ $t('entities.users.attributes.avatar') }}</td>
                                                                <td class="font-weight-bold">
                                                                    <template v-if="toDeleteEntity.avatar">
                                                                        <img
                                                                            :src="toDeleteEntity.avatar"
                                                                            alt="Avatar"
                                                                            width="20px"
                                                                        />
                                                                    </template>
                                                                    <template v-else>
                                                                        /
                                                                    </template>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                        <thead>
                                                            <tr>
                                                                <th
                                                                    colspan="4"
                                                                    class="text-left"
                                                                >
                                                                    {{ $t('entities.users.identity') }}
                                                                    <Status
                                                                        v-if="toDeleteEntity.identity.id"
                                                                        :status="toDeleteEntity.identity.status"
                                                                    />
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <template v-if="toDeleteEntity.identity.id">
                                                                <tr>
                                                                    <td>{{ $t('generalAttributes.fullName') }}</td>
                                                                    <td class="font-weight-bold">
                                                                        {{ toDeleteEntity.identity.name || '/' }}
                                                                    </td>
                                                                    <td>{{ $t('generalAttributes.address') }}</td>
                                                                    <td class="font-weight-bold">
                                                                        {{ toDeleteEntity.identity.address || '/' }}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>{{ $t('entities.users.attributes.birthdate') }}</td>
                                                                    <td class="font-weight-bold">
                                                                        {{ toDeleteEntity.identity.birthdate || '/' }}
                                                                    </td>
                                                                    <td>{{ $t('miscellaneous.createdAt') }}</td>
                                                                    <td class="font-weight-bold">
                                                                        {{ fromUtcDateTimeToCurrent(toDeleteEntity.identity.created_at, true, true) }}
                                                                    </td>
                                                                </tr>
                                                            </template>
                                                            <template v-else>
                                                                <tr>
                                                                    <td colspan="4">
                                                                        <i>{{ $t('entities.users.noIdentitySet') }}</i>
                                                                    </td>
                                                                </tr>
                                                            </template>
                                                        </tbody>
                                                        <thead>
                                                            <tr>
                                                                <th
                                                                    colspan="4"
                                                                    class="text-left"
                                                                >
                                                                    {{ $t('entities.users.attributes.email') }}
                                                                    <Status
                                                                        v-if="toDeleteEntity.email.id"
                                                                        :status="toDeleteEntity.email.status"
                                                                    />
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <template v-if="toDeleteEntity.email.address.length">
                                                                    <td>{{ $t('generalAttributes.address') }}</td>
                                                                    <td class="font-weight-bold">
                                                                        <CopyTooltip :text="toDeleteEntity.email.address"/>
                                                                    </td>
                                                                    <td>{{ $t('miscellaneous.createdAt') }}</td>
                                                                    <td class="font-weight-bold">
                                                                        {{ fromUtcDateTimeToCurrent(toDeleteEntity.email.created_at, true, true) }}
                                                                    </td>
                                                                </template>
                                                                <template v-else>
                                                                    <td colspan="4">
                                                                        <i>{{ $t('entities.users.noEmailSet') }}</i>
                                                                    </td>
                                                                </template>
                                                            </tr>
                                                        </tbody>
                                                        <thead>
                                                            <tr>
                                                                <th
                                                                    colspan="4"
                                                                    class="text-left"
                                                                >
                                                                    {{ $t('entities.users.attributes.phone') }}
                                                                    <Status
                                                                        v-if="toDeleteEntity.phone.id"
                                                                        :status="toDeleteEntity.phone.status"
                                                                    />
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <template v-if="toDeleteEntity.phone.id">
                                                                    <td>{{ $t('entities.orders.attributes.number') }}</td>
                                                                    <td class="font-weight-bold">
                                                                        <CopyTooltip :text="`+${toDeleteEntity.phone.number}`"/>
                                                                    </td>
                                                                    <td>{{ $t('miscellaneous.createdAt') }}</td>
                                                                    <td class="font-weight-bold">
                                                                        {{ fromUtcDateTimeToCurrent(toDeleteEntity.phone.created_at, true, true) }}
                                                                    </td>
                                                                </template>
                                                                <template v-else>
                                                                    <td colspan="4">
                                                                        <i>{{ $t('entities.users.noPhoneSet') }}</i>
                                                                    </td>
                                                                </template>
                                                            </tr>
                                                        </tbody>
                                                    </template>
                                                </v-simple-table>
                                            </v-card>
                                        </v-container>
                                </v-card-text>
                                <DeleteCardActions
                                    :deleteEntity="deleteEntity"
                                    :deleting="deleting"
                                    :closeDeleteDialog="closeDeleteDialog"
                                />
                            </v-card>
                        </v-dialog>
                    </v-toolbar>
                </template>
                <template v-slot:item.identity.name="{ item }">
                    {{ item.identity.name || '/' }}
                </template>
                <template v-slot:item.email="{ item }">
                    <template v-if="item.email.id">
                        <CopyTooltip :text="item.email.address"/>
                        <Status :status="item.email.status"/>
                    </template>
                    <template v-else>
                        /
                    </template>
                </template>
                <template v-slot:item.rank.info.name="{ item }">
                    <v-chip
                        color="primary"
                        small
                    >
                        {{ item.rank.info.name }}
                    </v-chip>
                </template>
                <template v-slot:item.action="{ item }">
                    <v-tooltip
                        color="transparent"
                        top
                    >
                        <template v-slot:activator="{ on }">
                            <v-btn
                                @click="openViewDialog(item)"
                                v-on="on"
                                :disabled="loadingView"
                                class="glow ml-2"
                                color="secondary"
                                depressed
                                x-small
                                fab
                            >
                                <template v-if="loadingView">
                                    <ProgressCircular color="secondary"/>
                                </template>
                                <template v-else>
                                    <v-icon
                                        class="white--text"
                                        small
                                    >
                                        pageview
                                    </v-icon>
                                </template>
                            </v-btn>
                        </template>
                        <v-card class="elevation-2 px-4 py-2">
                            <span>{{ $t('entities.users.viewEntity') }}</span>
                        </v-card>
                    </v-tooltip>
                    <ShopButton
                        :text="$t('entities.users.shop')"
                        :item="item"
                    />
                    <v-tooltip
                        color="transparent"
                        top
                    >
                        <template v-slot:activator="{ on }">
                            <v-btn
                                @click="openAwardDialog(item)"
                                v-on="on"
                                :disabled="loadingAward"
                                color="warning"
                                class="glow ml-2"
                                depressed
                                x-small
                                fab
                            >
                              <template v-if="loadingAward">
                                    <ProgressCircular color="warning"/>
                                </template>
                                <template v-else>
                                    <v-icon
                                        class="white--text"
                                        small
                                    >
                                        mdi-trophy-variant-outline
                                    </v-icon>
                                </template>
                            </v-btn>
                        </template>
                        <v-card class="elevation-2 px-4 py-2">
                            <span>{{ $t('entities.users.awardUser') }}</span>
                        </v-card>
                    </v-tooltip>
                    <v-tooltip
                        color="transparent"
                        top
                    >
                      <template v-slot:activator="{ on }">
                        <v-btn
                            @click="openSimulateSessionDialog(item)"
                            v-on="on"
                            :disabled="loadingSimulation"
                            color="warning"
                            class="glow ml-2"
                            depressed
                            x-small
                            fab
                        >
                          <template v-if="loadingSimulation">
                            <ProgressCircular color="warning"/>
                          </template>
                          <template v-else>
                            <v-icon
                                class="white--text"
                                small
                            >
                              mdi-timer-sand
                            </v-icon>
                          </template>
                        </v-btn>
                      </template>
                      <v-card class="elevation-2 px-4 py-2">
                        <span>{{ $t('entities.users.simulateSession') }}</span>
                      </v-card>
                    </v-tooltip>
                    <v-tooltip
                        v-if="!userHasActiveSession(item.id)"
                        color="transparent"
                        top
                    >
                        <template v-slot:activator="{ on }">
                            <v-btn
                                @click="startSession(item)"
                                v-on="on"
                                :disabled="!$store.getters.arena || loadingSessions || loadingIndexes.includes(item.id)"
                                color="success"
                                class="glow ml-2"
                                depressed
                                x-small
                                fab
                            >
                                <template v-if="loadingIndexes.includes(item.id)">
                                    <ProgressCircular color="success"/>
                                </template>
                                <template v-else>
                                    <v-icon
                                        class="white--text"
                                        small
                                    >
                                        power_settings_new
                                    </v-icon>
                                </template>
                            </v-btn>
                        </template>
                        <v-card class="elevation-2 px-4 py-2">
                            <span>{{ $t('entities.sessions.start') }}</span>
                        </v-card>
                    </v-tooltip>
                    <v-tooltip
                        v-if="userHasActiveSession(item.id)"
                        color="transparent"
                        top
                    >
                        <template v-slot:activator="{ on }">
                            <v-btn
                                @click="stopSession(item)"
                                v-on="on"
                                :disabled="!$store.getters.arena || loadingSessions || loadingIndexes.includes(item.id)"
                                color="error"
                                class="glow ml-2"
                                depressed
                                x-small
                                fab
                            >
                                <template v-if="loadingIndexes.includes(item.id)">
                                    <ProgressCircular color="error"/>
                                </template>
                                <template v-else>
                                    <v-icon
                                        class="white--text"
                                        small
                                    >
                                        power_settings_new
                                    </v-icon>
                                </template>
                            </v-btn>
                        </template>
                        <v-card class="elevation-2 px-4 py-2">
                            <span>{{ $t('entities.sessions.stop') }}</span>
                        </v-card>
                    </v-tooltip>
                    <EditButton
                        v-if="canManage(item)"
                        :text="$t('entities.users.editEntity')"
                        :item="item"
                        :openEditDialog="openEditDialog"
                    />
                    <DeleteButton
                        v-if="canManage(item)"
                        :text="$t('entities.users.deleteEntity')"
                        :item="item"
                        :openDeleteDialog="openDeleteDialog"
                    />
                </template>
                <template v-slot:no-data>
                    <NoData
                        :pagination="pagination"
                        :typing="typing"
                        :fetchEntities="fetchEntities"
                    />
                </template>
            </v-data-table>
            <DataTableFooter
                :pagination="pagination"
                :loading="loading"
            />
        </v-card>
        <Snackbar
            :show.sync="snackbar.show"
            :color="snackbar.color"
            :message="snackbar.message"
        />
    </div>
</template>

<script>

import Vue from 'vue';
import Boolean from '@/components/Boolean.vue';
import PrimaryChip from '@/components/PrimaryChip.vue';
import Status from '@/components/Status.vue';
import NoData from '@/components/crud/NoData.vue';
import GlobalButton from '@/components/GlobalButton.vue';
import CoinImage from '@/components/CoinImage.vue';
import ExperienceImage from '@/components/ExperienceImage.vue';
import RefreshButton from '@/components/crud/RefreshButton.vue';
import CopyTooltip from '@/components/CopyTooltip.vue';
import ProgressCircular from '@/components/ProgressCircular.vue';
import ShopButton from '@/components/crud/ShopButton.vue';
import EditButton from '@/components/crud/edit/EditButton.vue';
import EditToolbar from '@/components/crud/edit/EditToolbar.vue';
import EditCardActions from '@/components/crud/edit/EditCardActions.vue';
import DeleteButton from '@/components/crud/delete/DeleteButton.vue';
import DeleteToolbar from '@/components/crud/delete/DeleteToolbar.vue';
import DeleteCardActions from '@/components/crud/delete/DeleteCardActions.vue';
import RequiredFields from '@/components/crud/RequiredFields.vue';
import RowsPerPage from '@/components/crud/RowsPerPage.vue';
import DataTableSkeletonLoader from '@/components/crud/DataTableSkeletonLoader.vue';
import DataTableFooter from '@/components/crud/DataTableFooter.vue';
import ValidationErrors from '@/components/crud/ValidationErrors.vue';
import Snackbar from '@/components/Snackbar.vue';
import User from '@/models/users/User';
import Role from '@/models/users/Role';
import Session from '@/models/main/Session';
import Achievement from '@/models/competitions/Achievement';
import Quest from '@/models/competitions/Quest';
import Subscription from '@/models/users/Subscription';
import UserSetting from '@/models/users/UserSetting';
import InventoryItem from '@/models/configuration/InventoryItem';

import convertDate from '@/mixins/convert-date';
import formatTime from '@/mixins/format-time';
import formatDate from '@/mixins/format-date';
import user from '@/mixins/crud/computed/user';
import isRole from '@/mixins/crud/computed/isRole';
import required from '@/mixins/rules/required';
import thousandSeparator from '@/mixins/thousand-separator';
import COUNTRIES from '@/mixins/countries';
import {axiosCatch} from '@/mixins/crud/methods/axios-catch.js';
import {showSnackbar} from '@/mixins/crud/methods/show-snackbar';
import {paginationWatch} from '@/mixins/crud/watch/pagination.js';
import {deleteDialog} from '@/mixins/crud/watch/delete-dialog.js';
import {logout} from '@/mixins/logout';

export default Vue.extend({
    components: {
        Boolean,
        PrimaryChip,
        Status,
        NoData,
        GlobalButton,
        CoinImage,
        ExperienceImage,
        ProgressCircular,
        RowsPerPage,
        DataTableSkeletonLoader,
        DataTableFooter,
        ValidationErrors,
        RefreshButton,
        CopyTooltip,
        ShopButton,
        EditToolbar,
        EditButton,
        EditCardActions,
        DeleteToolbar,
        DeleteButton,
        DeleteCardActions,
        RequiredFields,
        Snackbar,
    },

    mixins: [
        axiosCatch,
        showSnackbar,
        paginationWatch,
        thousandSeparator,
        deleteDialog,
        user,
        isRole,
        convertDate,
        formatTime,
        formatDate,
        required,
    ],

    data: () => ({
        initialLoad: true,
        loading: false,
        loadingSessions: false,
        loadingView: false,
        loadingAward: false,
        loadingSimulation: false,
        saving: false,
        deleting: false,
        awardingEvent: false,
        resettingPassword: false,
        approvingIdentity: false,
        rejectingIdentity: false,
        typing: false,
        loadingIndexes: [],
        show: false,
        menu: false,
        activePicker: null,
        apiURI: process.env.VUE_APP_CORE_BASE_URI,
        entity: 'users',
        route: 'users',
        entities: [],
        roles: [],
        sessions: [],
        countries: COUNTRIES,
        events: [
            {
                name: 'Competitive Win',
                context: {
                    event: 'win',
                    type: 'competitive'
                }
            },
            {
                name: '5v5 LAN Win',
                context: {
                    event: 'win',
                    type: '5v5-lan'
                }
            },
            {
                name: '5v5 Competitive Win',
                context: {
                    event: 'win',
                    type: '5v5-competitive'
                }
            },
            {
                name: 'Instagram Follow',
                context: {
                    event: 'follow',
                    platform: 'instagram'
                }
            },
            {
                name: 'TikTok Follow',
                context: {
                    event: 'follow',
                    platform: 'tiktok'
                }
            },
            {
                name: 'Significant Other Visit',
                context: {
                    event: 'courage'
                }
            },
            {
                name: 'Early Visit',
                context: {
                    event: 'early-visit',
                }
            },
            {
                name: 'Staff Drink Treat',
                context: {
                    event: 'staff-treat',
                    type: 'drink',
                }
            },
            {
                name: 'Birthday Party',
                context: {
                    event: 'birthday-party'
                }
            },
            {
                name: 'FF League Participation',
                context: {
                    event: 'ff-league-participation'
                }
            },
            {
                name: 'FF Competition Win',
                context: {
                    event: 'win',
                    type: 'ff-competition'
                }
            },
            {
                name: 'LEGEND',
                context: {
                    event: 'legend-achieved'
                }
            },
        ],
        simulatingSession: false,
        simulatorOptions: () => {
            return [
                {
                    // name: this.$t('entities.users.estimateSessionCost'),
                    name: 'Estimate Cost',
                    context: {
                        type: 'estimate-cost',
                    },
                },
                {
                    // name: this.$t('entities.users.estimateSessionDuration'),
                    name: 'Estimate Duration',
                    context: {
                        type: 'estimate-duration',
                    },
                },
            ];
        },
        selectedSimulatorOption: {},
        selectedSearchAttributes: ['Username', 'Name'],
        selectedFilterAttributes: [],
        validationErrors: [],
        pagination: {
            search: '',
            current_page: 1,
            from: 1,
            last_page: 0,
            path: '',
            per_page: 10,
            to: 0,
            total: 0
        },
        options: {
            page: 1,
            itemsPerPage: 10,
            sortBy: ['id'],
            sortDesc: ['DESC'],
            groupBy: [],
            groupDesc: [],
            mustSort: false,
            multiSort: false
        },
        viewDialog: false,
        awardDialog: false,
        simulateSessionDialog: false,
        simulationResult: null,
        isSessionCostSelected: (selectedSimulatorOption) => {
            return selectedSimulatorOption?.context?.type === "estimate-cost";
        },
        isSessionDurationSelected: (selectedSimulatorOption) => {
            return selectedSimulatorOption?.context?.type === "estimate-duration";
        },
        editDialog: false,
        deleteDialog: false,
        editedIndex: -1,
        editedEntity: {
            id: null,
            username: '',
            password: '',
            card_id: '',
            availableCoins: 0,
            wanted_playtime_hours: 0,
            wanted_playtime_minutes: 0,
            roles: [],
            active: true,
            banned: false,
            identity: {
                id: null,
                name: '',
                birthdate: null,
                address: '',
                pidn: '',
                image: '',
                status: '',
            },
            phone: {
                country_code: '',
                national_number: '',
            },
            email: {
                address: '',
            },
            wallet: {},
            subscriptions: [],
            settings: [],
            inventory: [],
            bonuses: {
                legend_bonus: 0,
                rank_bonus: 0,
                reward_bonus: 0,
                subscription_bonus: 0,
            },
            achievements: [],
            quests: [],
            events: [],
            simulatorOptions: () => ([]),
            selectedSimulatorOption: {},
        },
        defaultEntity: {
            id: null,
            username: '',
            password: '',
            card_id: '',
            availableCoins: 0,
            wanted_playtime_hours: 0,
            wanted_playtime_minutes: 0,
            roles: [],
            active: true,
            banned: false,
            identity: {
                id: null,
                name: '',
                birthdate: null,
                address: '',
                pidn: '',
                image: '',
                status: '',
            },
            phone: {
                country_code: '',
                national_number: '',
            },
            email: {
                address: '',
            },
            wallet: {},
            subscriptions: [],
            settings: [],
            inventory: [],
            bonuses: {
                legend_bonus: 0,
                rank_bonus: 0,
                reward_bonus: 0,
                subscription_bonus: 0,
            },
            achievements: [],
            quests: [],
            events: [],
            simulatorOptions: () => ([]),
            selectedSimulatorOption: {},
        },
        toDeleteEntity: {
            id: null,
            username: '',
            password: '',
            card_id: '',
            roles: [],
            active: true,
            banned: false,
            identity: {
                id: null,
                name: '',
                birthdate: null,
                address: '',
                pidn: '',
                image: '',
                status: '',
            },
            phone: {
                country_code: '',
                national_number: '',
            },
            email: {
                address: '',
            },
            wallet: {},
            subscriptions: [],
            settings: [],
            inventory: [],
            bonuses: {
                legend_bonus: 0,
                rank_bonus: 0,
                reward_bonus: 0,
                subscription_bonus: 0,
            },
            achievements: [],
            quests: [],
            events: [],
            simulatorOptions: () => ([]),
            selectedSimulatorOption: {},
        },
        defaultIdentity: {
            name: '',
            birthdate: null,
            address: '',
            pidn: '',
            image: ''
        },
    }),

    computed: {
        headers() {
            return [
                { text: this.$t('generalAttributes.fullName'), align: 'left', value: 'identity.name' },
                { text: this.$t('entities.users.attributes.username'), align: 'left', value: 'username' },
                { text: this.$t('entities.users.attributes.email'), align: 'left', value: 'email' },
                { text: this.$t('entities.users.attributes.rank'), align: 'left', value: 'rank.info.name' },
                { text: this.$t('miscellaneous.actions'), value: 'action', sortable: false }
            ];
        },
        inventoryHeaders() {
            return [
                { text: this.$t('generalAttributes.name'), align: 'left', value: 'name' },
                { text: this.$t('miscellaneous.createdAt'), align: 'left', value: 'created_at' },
            ];
        },
        subscriptionsHeaders() {
            return [
                { text: this.$t('generalAttributes.name'), align: 'left', value: 'name' },
                { text: this.$t('entities.ranks.attributes.bonus'), align: 'left', value: 'bonus' },
                { text: this.$t('miscellaneous.createdAt'), align: 'left', value: 'created_at' },
                { text: this.$t('entities.carts.attributes.expiresAt'), align: 'left', value: 'expires_at' },
            ];
        },
        settingsHeaders() {
            return [
                { text: this.$t('entities.settings.attributes.namespace'), align: 'left', value: 'namespace' },
                { text: this.$t('entities.settings.attributes.key'), align: 'left', value: 'key' },
                { text: this.$t('entities.items.value'), align: 'left', value: 'value' },
            ];
        },
        achievementsHeaders() {
            return [
                { text: this.$t('generalAttributes.name'), align: 'left', value: 'name' },
                { text: this.$t('entities.achievements.attributes.progress'), align: 'left', value: 'progress_value' },
                { text: this.$tc('entities.achievements.attributes.points', 2), align: 'left', value: 'points' },
                { text: this.$t('miscellaneous.createdAt'), align: 'left', value: 'created_at' },
                { text: this.$t('entities.achievements.attributes.progressUpdatedAt'), align: 'left', value: 'progress_updated_at' },
            ];
        },
        questsHeaders() {
            return [
                { text: this.$t('generalAttributes.name'), align: 'left', value: 'name' },
                { text: this.$t('entities.quests.attributes.pool'), align: 'left', value: 'pool_type' },
                { text: this.$t('entities.achievements.attributes.progress'), align: 'left', value: 'progress_value' },
                { text: this.$t('miscellaneous.createdAt'), align: 'left', value: 'created_at' },
                { text: this.$t('entities.achievements.attributes.progressUpdatedAt'), align: 'left', value: 'progress_updated_at' },
            ];
        },
        filteredEvents() {
            const filteredEvents = this.events;
            if (!this.isAdmin) {
                // Remove legend from list if not admin
                filteredEvents.splice(filteredEvents.findIndex(a => a.name.toLowerCase() === "legend"), 1);
            }
            return filteredEvents;
        },
        getSimulatorOptions() {
            return this.simulatorOptions();
        },
        availableRoles() {
            if (this.isAdmin) {
                return this.roles.map(role => role.name)
            }
            if (this.isManager) {
                return this.roles.filter(role => role.name !== 'admin' && role.name !== 'manager').map(role => role.name);
            }
            return this.roles.filter(role => role.is_default).map(role => role.name);
        },
        filterAttributes() {
            return [
                { label: this.$t('entities.users.attributes.active'), value: 'Active' },
                { label: this.$t('entities.users.attributes.banned'), value: 'Banned' },
                { label: this.$t('entities.users.verifiedIdentity'), value: 'Verified Identity' },
                { label: this.$t('entities.users.verifiedEmail'), value: 'Verified Email' },
                { label: this.$t('entities.users.verifiedPhone'), value: 'Verified Phone' },
            ]
        },
        searchAttributes() {
            return [
                { label: this.$t('entities.users.attributes.username'), value: 'Username' },
                { label: this.$t('entities.users.attributes.name'), value: 'Name' },
                { label: this.$t('entities.users.attributes.email'), value: 'Email' },
                { label: this.$t('entities.users.attributes.phone'), value: 'Phone' },
                { label: this.$t('entities.users.attributes.cardId'), value: 'Card ID' },
                { label: this.$t('entities.users.attributes.rank'), value: 'Rank' },
                { label: this.$t('entities.roles.entity'), value: 'Role' },
            ]
        },
        selectedAllAttributes() {
            return this.selectedSearchAttributes.length === this.searchAttributes.length;
        },
        selectedSomeAttributes() {
            return this.selectedSearchAttributes.length > 0 && !this.selectedAllAttributes;
        },
        icon() {
            if (this.selectedAllAttributes) {
                return 'mdi-close-box';
            }
            if (this.selectedSomeAttributes) {
                return 'mdi-minus-box';
            }
            return 'mdi-checkbox-blank-outline';
        },
        formTitle() {
            return this.editDialog ? (this.$t(`entities.${this.entity}.${this.editedIndex === -1 ? 'new' : 'edit'}Entity`)) : '';
        },
        numeric() {
            return v  => (!isNaN(v)) || this.$t('validation.numeric');
        },
        min() {
            return v => (v >= 0) || this.$t('validation.min', { amount: 0 });
        },
        minimumPassword() {
            return v => v && v.length >= 6 || this.$t('validation.minimum', { count: 6 });
        },
        minimumPasswordOrEmpty() {
            return v => !v || (v && v.length >= 6 || this.$t('validation.minimum', { count: 6 }));
        },
        minimum() {
            return v => v && v.length >= 3 || this.$t('validation.minimum', { count: 3 });
        },
        maximum() {
            return v => v && v.length <= 64 || this.$t('validation.maximum', { count: 64 });
        },
        email() {
            return v =>  !v || (/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || this.$t('validation.email'));
        },
        phoneNumber() {
            return v =>  !v || (/^[1-9]\d*$/.test(v) || this.$t('validation.phoneNumber'));
        },
        username() {
            return v => /^[\w.-]+$/.test(v) || this.$t('validation.username');
        },
        progress() {
            let strength = 0;

            if (this.editedEntity.password) {
                strength += /[A-Z]+/.test(this.editedEntity.password) && this.editedEntity.password.length > 1 ? 25 : 0;
                strength += /[a-z]+/.test(this.editedEntity.password) && this.editedEntity.password.length > 3 ? 25 : 0;
                strength += /[0-9]+/.test(this.editedEntity.password) && this.editedEntity.password.length > 5 ? 25 : 0;
                strength += /[\W]+/.test(this.editedEntity.password) && this.editedEntity.password.length > 7 ? 25 : 0;
            }

            return strength;
        },
        color() {
            return ['red', 'red', 'orange', 'yellow', 'green'][Math.floor(this.progress / 25)];
        }
    },

    watch: {
        menu(val) {
            val && setTimeout(() => (this.activePicker = 'YEAR'))
        },
        editDialog(val) {
            if (this.$refs.form) {
                this.$refs.form.resetValidation();
            }
            val || this.closeEditDialog();
            if (!val) {
                this.editedEntity.identity = {...this.defaultIdentity};
                this.validationErrors = [];
            } else {
                if (this.editedIndex === -1) {
                    this.$set(this.editedEntity, 'roles', this.roles.filter(role => role.is_default).map(role => role.name));
                }
                if (!this.editedEntity.phone.country_code) {
                    const defaultCountry = this.$store.getters.country;
                    const country = this.countries.find(c => c.iso2 === defaultCountry)
                    if (country) {
                        this.editedEntity.phone.country_code = country.dialCode;
                    } else {
                        this.editedEntity.phone.country_code = defaultCountry;
                    }
                }
            }
        },
        viewDialog(val) {
            if (!val) {
                this.editedIndex = -1;
                this.editedEntity = {...this.defaultEntity};
                this.editedEntity.subscriptions = [];
                this.editedEntity.settings = [];
                this.editedEntity.inventory = [];
                this.editedEntity.achievements = [];
                this.editedEntity.quests = [];
            }
        },
        awardDialog(val) {
            if (!val) {
                this.editedIndex = -1;
                this.editedEntity = {...this.defaultEntity};
            }
        },
        simulateSessionDialog(val) {
          if (!val) {
            this.editedIndex = -1;
            this.editedEntity = {...this.defaultEntity};
          }
        },
        selectedFilterAttributes() {
            this.fetchEntities();
        },
    },

    mounted() {
        this.refresh();
        this.fetchRoles();
    },

    created() {
        if (this.$route.params && this.$route.params.openDialog) {
            this.editDialog = true;
        }
    },

    methods: {
        async fetchEntities() {
            try {
                let append = this.setParams();
                // Append filters
                if (this.selectedFilterAttributes.length) {
                    const filter = this.selectedFilterAttributes.map(attribute => `${attribute.replaceAll(' ', '_').toLowerCase()}=true`);
                    append += '&' + filter.join('&');
                }
                this.loading = true;
                const response = await User.api.get(append);
                const data = response.data;
                this.entities = data.content.map((UserDTO) => {
                    const user = new User(UserDTO);
                    if (!user.identity) {
                        user.identity = {
                            name: '',
                            birthdate: null,
                            address: '',
                            pidn: '',
                            image: ''
                        }
                    }
                    if (!user.phone) {
                        user.phone = {
                            id: null,
                            country_code: '',
                            national_number: null,
                        }
                    }
                    if (!user.email) {
                        user.email = {
                            id: null,
                            address: '',
                            status: 'UNVERIFIED',
                        }
                    }
                    return user;
                });
                this.pagination.current_page = data.pageable.page_number + 1;
                this.pagination.per_page = data.pageable.page_size;
                this.pagination.total = data.total_elements;
                this.pagination.last_page = data.total_pages;
                this.pagination.from = data.pageable.offset + 1;
                this.pagination.to = this.pagination.from + data.number_of_elements - 1;
            } catch (e) {
                console.warn(`Users API failed.`);
                console.log(e);
            } finally {
                const initial = this.initialLoad;
                this.initialLoad = false;
                this.loading = false;
                if (initial) {
                    if (window.innerWidth > 800) {
                        this.$nextTick(() => {
                            this.$refs.userSearch.$el.getElementsByTagName('input')[0].focus();
                        })
                    }
                } else {
                    this.$nextTick(() => {
                        this.$refs.userSearch.$el.getElementsByTagName('input')[0].focus();
                    })
                }
            }
        },
        async fetchRoles() {
            try {
                const response = await Role.api.get('size=100');
                this.roles = response.data.content;
            } catch (e) {
                console.warn('Roles API failed.');
                console.log(e);
            }
        },
        async fetchSessions() {
            try {
                this.loadingSessions = true;
                const response = await Session.api.getByArena(this.$store.getters.arena.id, `active=true&size=100`);
                this.sessions = response.data.content;
            } catch (e) {
                console.warn(`Arena Sessions API failed.`);
                console.log(e);
            } finally {
                this.loadingSessions = false;
            }
        },
        async save() {
            try {
                this.saving = true;

                if (!this.$refs.form.validate()) {
                    return;
                }

                const data = {
                    roles: this.editedEntity.roles,
                    active: this.editedEntity.active,
                    banned: this.editedEntity.banned,
                };

                if (this.$store.getters.arena) {
                    data.registered_in_arena = this.$store.getters.arena.id;
                }

                if (this.editedEntity.card_id) {
                    data.card_id = this.editedEntity.card_id;
                }

                // Password not required on update
                if (this.editedEntity.password && this.editedEntity.password.length) {
                    data.password = this.editedEntity.password
                }

                // Only on create
                if (this.editedIndex === -1) {
                    data.username = this.editedEntity.username;
                }

                if (this.editedEntity.identity.name || this.editedEntity.identity.pidn || this.editedEntity.identity.address || this.editedEntity.identity.birthdate) {
                    data.identity = {};
                }

                if (this.editedEntity.identity.name) {
                    data.identity.name = this.editedEntity.identity.name;
                }

                if (this.editedEntity.identity.pidn) {
                    data.identity.pidn = this.editedEntity.identity.pidn;
                }

                if (this.editedEntity.identity.address) {
                    data.identity.address = this.editedEntity.identity.address;
                }

                if (this.editedEntity.identity.birthdate) {
                    data.identity.birthdate = this.editedEntity.identity.birthdate;
                }

                // TODO => Image
                // if (this.editedEntity.identity.image) {
                //     data.identity.image = this.editedEntity.identity.image;
                // }

                if (this.editedEntity.phone.country_code && this.editedEntity.phone.national_number) {
                    data.phone = {
                        country_code: this.editedEntity.phone.country_code,
                        national_number: this.editedEntity.phone.national_number
                    };
                }

                if (this.editedEntity.email.address) {
                    data.email = this.editedEntity.email.address;
                }

                if (this.editedIndex > -1) {
                    await User.api.update(this.editedEntity.id, data)
                        .then((response) => {
                            if (this.user.id === this.editedEntity.id) {
                                const user = {
                                    id: response.data.id,
                                    credential: response.data.username,
                                    initials: response.data.username.substring(0, 2),
                                    name: response.data.identity ? response.data.identity.name : null,
                                    email: response.data.email ? response.data.email.address : null,
                                    username: response.data.username,
                                    pidn: response.data.identity ? response.data.identity.pidn : null,
                                    roles: response.data.roles,
                                    avatar: response.data.avatar
                                }
                                localStorage.setItem('user', btoa(unescape(encodeURIComponent(JSON.stringify(user)))));
                            }
                            if (this.pagination.search.length) {
                                this.pagination.search = '';
                            } else {
                                this.fetchEntities();
                            }
                            this.showSnackbar('success', this.$t('entities.users.notifications.update'));
                            this.closeEditDialog();
                        }).catch(error => {
                            this.axiosCatch(error);
                        });
                } else {
                    await User.api.create(data)
                        .then(() => {
                            if (this.pagination.search.length) {
                                this.pagination.search = '';
                            } else {
                                this.fetchEntities();
                            }
                            this.showSnackbar('success', this.$t('entities.users.notifications.creation'));
                            this.closeEditDialog();
                        }).catch(error => {
                            this.axiosCatch(error);
                        });
                }
            } catch (error) {
                console.warn(`User Save API failed.`);
                console.log(error);
            } finally {
                this.saving = false;
            }
        },
        async deleteEntity() {
            try {
                this.deleting = true;
                await User.api.delete(this.toDeleteEntity.id)
                    .then(() => {
                        if (this.user.id === this.toDeleteEntity.id) {
                            logout.methods.logout();
                        }
                        this.fetchEntities();
                        this.closeDeleteDialog();
                        this.validationErrors = [];
                        this.showSnackbar('success', this.$t('entities.users.notifications.deletion'));
                    }).catch(error => {
                        this.axiosCatch(error);
                    });
            } catch (error) {
                console.warn(`Delete User API failed.`);
                console.log(error);
            } finally {
                this.deleting = false;
            }
        },
        async deleteArenaUserPass(entity) {
            try {
                await User.api.deletePass(entity.id)
                    .then(() => {
                        this.viewDialog = false;
                    }).catch(error => {
                        this.axiosCatch(error);
                    });
            } catch (error) {
                console.warn(`Delete User Arena Pass API failed.`);
                console.log(error);
            }
        },
        async openViewDialog(entity) {
            this.loadingView = true;
            this.editedIndex = this.entities.indexOf(entity);
            this.editedEntity = {...entity};
            await this.getUserInventory(entity.id);
            await this.getUserBonuses(entity.id);
            await this.getUserSubscriptions(entity.id);
            await this.getUserAchievements(entity.id, '&view=true');
            await this.getUserQuests(entity.id, '&view=true');
            await this.getUserSettings(entity.id);
            this.viewDialog = true;
            this.loadingView = false;
        },
        async openAwardDialog(entity) {
            this.loadingAward = true;
            this.editedIndex = this.entities.indexOf(entity);
            this.editedEntity = {...entity};
            this.awardDialog = true;
            this.loadingAward = false;
        },
        async openSimulateSessionDialog(entity) {
            this.loadingSimulation = true;
            this.editedIndex = this.entities.indexOf(entity);
            this.editedEntity = {...entity};
            this.simulateSessionDialog = true;
            this.simulationResult = null;
            this.loadingSimulation = false;
        },
        async getUserAchievements(id, append = '') {
            let data = [];
            try {
                const response = await User.api.getAchievements(id, append);
                data = response.data.content.map(AchievementDTO => new Achievement(AchievementDTO))
            } catch (e) {
                console.warn(`User Achievements API failed.`);
                console.log(e);
            }
            this.$set(this.editedEntity, 'achievements', data);
        },
        async getUserQuests(id, append = '') {
            let data = [];
            try {
                const response = await User.api.getQuests(id, append);
                data = response.data.content.map(QuestDTO => new Quest(QuestDTO))
            } catch (e) {
                console.warn(`User Quests API failed.`);
                console.log(e);
            }
            this.$set(this.editedEntity, 'quests', data);
        },
        async getUserInventory(id) {
            let data = [];
            try {
                const response = await User.api.getInventoryItems(id);
                data = response.data.map(InventoryItemDTO => new InventoryItem(InventoryItemDTO))
            } catch (e) {
                console.warn(`User Inventory API failed.`);
                console.log(e);
            }
            this.$set(this.editedEntity, 'inventory', data);
        },
        async getUserBonuses(id) {
            let data = [];
            try {
                const response = await User.api.getBonuses(id);
                data = response.data;
            } catch (e) {
                console.warn(`User Bonuses API failed.`);
                console.log(e);
            }
            this.$set(this.editedEntity, 'bonuses', data);
        },
        async getUserSubscriptions(id) {
            let data = [];
            try {
                const response = await User.api.getSubscriptions(id);
                data = response.data.map(SubscriptionDTO => new Subscription(SubscriptionDTO))
            } catch (e) {
                console.warn(`User Subscriptions API failed.`);
                console.log(e);
            }
            this.$set(this.editedEntity, 'subscriptions', data);
        },
        async getUserSettings(id) {
            let data = [];
            try {
                const response = await User.api.getSettings(id);
                data = response.data.map(UserSettingDTO => new UserSetting(UserSettingDTO))
            } catch (e) {
                console.warn(`User Settings API failed.`);
                console.log(e);
            }
            this.$set(this.editedEntity, 'settings', data);
        },
        async resetPassword(id) {
            try {
                this.resettingPassword = true;
                await User.api.resetPassword(id)
                    .then(response => {
                        this.editedEntity.reset_password = response.data.reset_password;
                        this.showSnackbar('success', this.$t(`entities.users.notifications.resetPassword`));
                    }).catch(error => {
                        this.axiosCatch(error);
                    });
            } catch (error) {
                console.warn(`User Reset Password API failed.`);
                console.log(error);
            } finally {
                this.resettingPassword = false;
            }
        },
        async approveIdentity(id) {
            try {
                this.approvingIdentity = true;
                await User.api.approveIdentity(id)
                    .then(response => {
                        this.editedEntity.identity.status = response.data.status;
                        this.showSnackbar('success', this.$t(`entities.users.notifications.approveIdentity`));
                    }).catch(error => {
                        if (error.response.status === 403) {
                            this.showSnackbar('error', error.response.data.message);
                        }
                    });
            } catch (error) {
                console.warn(`User Approve Identity API failed.`);
                console.log(error);
            } finally {
                this.approvingIdentity = false;
            }
        },
        async rejectIdentity(id) {
            try {
                this.rejectingIdentity = true;
                await User.api.rejectIdentity(id)
                    .then(response => {
                        this.editedEntity.identity.status = response.data.status;
                        this.showSnackbar('success', this.$t(`entities.users.notifications.rejectIdentity`));
                    }).catch(error => {
                        this.axiosCatch(error);
                    });
            } catch (error) {
                console.warn(`User Reject Identity API failed.`);
                console.log(error);
            } finally {
                this.rejectingIdentity = false;
            }
        },
        async startSession(entity) {
            try {
                this.loadingIndexes.push(entity.id);
                this.loadingSessions = true;
                const data = {
                    arena_id: this.$store.getters.arena.id,
                    device_id: 'ps',
                    username: entity.username,
                    user_id: entity.id,
                }
                await Session.api.create(this.$store.getters.arena.id, data)
                    .then(() => {
                        this.refresh();
                    }).catch(error => {
                        this.loadingSessions = false;
                        // Server error
                        if (error.response.status === 500) {
                            this.showSnackbar('error', error.response.data);
                        }
                    });
            } catch (error) {
                console.warn(`Start Arena Session API failed.`);
                console.log(error);
            } finally {
                this.loadingIndexes = this.loadingIndexes.filter(i => i !== entity.id)
            }
        },
        async stopSession(entity) {
            try {
                this.loadingIndexes.push(entity.id);
                const session = this.userHasActiveSession(entity.id);
                this.loadingSessions = true;
                const data = {
                    reason: 'closed_by_employee',
                };
                await Session.api.destroy(this.$store.getters.arena.id, session.id, data)
                    .then(() => {
                        this.refresh();
                    }).catch(error => {
                        this.loadingSessions = false;
                        // Server error
                        if (error.response.status === 500) {
                            this.showSnackbar('error', error.response.data);
                        }
                    });
            } catch (error) {
                console.warn(`Delete Arena Session API failed.`);
                console.log(error);
            } finally {
                this.loadingIndexes = this.loadingIndexes.filter(i => i !== entity.id)
            }
        },
        async estimateSessionDuration() {
            if (!this.$refs.estimateDurationForm.validate()) {
                return;
            }

            this.simulatingSession = true;
            const data = {
                user_id: this.editedEntity.id,
                coins: parseInt(this.editedEntity.availableCoins),
            };
            try {
                const simulateSessionResponse = await Session.api.simulateSession(this.$store.getters.arena.id, data)
                    .catch(error => {
                        this.simulatingSession = false;
                        this.simulationResult = null;
                        if (error.response.status >= 300) {
                            this.showSnackbar('error', error.response.data);
                        }
                    });
                if (simulateSessionResponse.status < 300) {
                    this.simulationResult = simulateSessionResponse.data
                }
            } catch (error) {
                console.warn(`Simulate Arena Session API Error`);
                console.log(error);
                this.simulationResult = null;
            }
            this.simulatingSession = false;
        },
        async estimateSessionCost() {
            if (!this.$refs.estimateCostForm.validate()) {
                return;
            }

            this.simulatingSession = true;
            const data = {
                user_id: this.editedEntity.id,
                duration_minutes: Number(this.editedEntity.wanted_playtime_hours) * 60 + Number(this.editedEntity.wanted_playtime_minutes),
            };
            console.log(data)
            try {
                const simulateSessionResponse = await Session.api.simulateSession(this.$store.getters.arena.id, data)
                    .catch(error => {
                        this.simulatingSession = false;
                        this.simulationResult = null;
                        if (error.response.status >= 300) {
                            this.showSnackbar('error', error.response.data);
                        }
                    });
                if (simulateSessionResponse.status < 300) {
                    this.simulationResult = simulateSessionResponse.data
                }
            } catch (error) {
                console.warn(`Simulate Arena Session API Error`);
                console.log(error);
                this.simulationResult = null;
            }
            this.simulatingSession = false;
        },
        getHoursFromMinutes(minutes) {
            return Math.floor(Number(minutes) / 60);
        },
        getRemainingMinutes(minutes) {
            return Math.round(Number(minutes) % 60);
        },
        getHoursFromSeconds(seconds) {
            return this.getHoursFromMinutes(Math.floor(Number(seconds) / 60));
        },
        getRemainingMinutesFromSeconds(seconds) {
            return this.getRemainingMinutes(Number(seconds) / 60);
        },
        awardEvent() {
            if (!this.$refs.awardForm.validate()) {
                return;
            }

            this.awardingEvent = true;

            const data = {
                user_id: this.editedEntity.id,
                username: this.editedEntity.username,
                events: this.editedEntity.events.map(event => ({
                    context: event.context,
                    data: { value: 1 }
                }))
            };

            User.api.awardEvent(data)
                .catch(error => {
                    this.showSnackbar('error', this.$t('error', { message: error.response.status }));
                });
            this.awardingEvent = false;
            this.awardDialog = false;
            this.showSnackbar('success', this.$t(`entities.users.notifications.progress`));
        },
        canManage(user) {
            if (this.isAdmin) {
                return true;
            }
            if (this.isManager) {
                return !user.roles.some(r => ['admin', 'manager'].indexOf(r) >= 0);
            }
            return !user.roles.some(r => ['admin', 'employee', 'manager'].indexOf(r) >= 0);
        },
        saveDate(date) {
            this.$refs.menu.save(date);
        },
        userHasActiveSession(userId) {
            const session = this.sessions.find(s => s.user_id === userId);
            return session || null;
        },
        pcSession(userId) {
            const session = this.userHasActiveSession(userId);
            if (session) {
                if (session.device_id === "ps") {
                    return false;
                } else {
                    return true;
                }
            } else {
                return false;
            }
        },
        toggle() {
            this.$nextTick(() => {
                 if (this.selectedAllAttributes) {
                    this.selectedSearchAttributes = [];
                } else {
                    this.selectedSearchAttributes = this.searchAttributes.map(({value}) => value);
                }
            })
        },
        openEditDialog(entity) {
            this.editedIndex = this.entities.indexOf(entity);
            this.editedEntity = JSON.parse(JSON.stringify(entity));
            if (this.$refs.form) {
                this.$refs.form.resetValidation();
            }
            this.editDialog = true;
        },
        closeEditDialog() {
          this.editedEntity = JSON.parse(JSON.stringify(this.defaultEntity));
          this.editedIndex = -1;
          this.editDialog = false;
        },
        openDeleteDialog(entity) {
            this.editedIndex = this.entities.indexOf(entity);
            this.toDeleteEntity = {...entity};
            this.deleteDialog = true;
        },
        closeDeleteDialog() {
            this.deleteDialog = false;
            this.toDeleteEntity = {...this.defaultEntity};
            this.editedIndex = -1;
        },
        refresh() {
            this.fetchEntities();
            if (this.$store.getters.arena) {
                this.fetchSessions();
            }
        }
    }
});

</script>


<style>

.position-relative {
    position: relative;
}

.required-fields {
    position: absolute;
    right: 13px;
    top: 15px;
    color: red;
}

</style>
