import AuthApi from '../services/auth/AuthApi';
import resetAppState from "@/mixins/reset-app-state";
import router from '@/router';

export const logout = {
    methods: {
        async logout() {
            const refreshToken = localStorage.getItem('refresh_token');
            try {
                if (refreshToken) {
                    await AuthApi.logout(refreshToken);
                }
            } catch (error) {
                console.warn('Logout API failed');
            } finally {
                resetAppState();
                await router.push('/');
            }
        },
    }
};