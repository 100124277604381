import CategoriesApi from '@/services/shop/CategoriesApi';

export interface CategoryInterface {
    id: bigint;
    name: string;
    attributes: object[];
}

export class CategoryDTO implements CategoryInterface {
    id = 0n;
    name = '';
    attributes = [];
}

export default class Category extends CategoryDTO {
    static api = CategoriesApi;

    constructor(DTO: CategoryDTO){
        super();
        Object.assign(this, DTO);
    }
}
