import { AxiosResponse } from 'axios';
import axios from '@/services/axios';

export default abstract class QuestsApi {

    public static route = process.env.VUE_APP_ACHIEVEMENTS_BASE_URI;
    public static entity = 'quests';

    public static async get(params: string): Promise<AxiosResponse>{
        return await axios.get(`${this.route}/${this.entity}?${params}`);
    }

    public static async updateActive(data: object): Promise<AxiosResponse>{
        return await axios.post(`${this.route}/${this.entity}`, data);
    }

    public static async getTypes(params: string): Promise<AxiosResponse>{
        return await axios.get(`${this.route}/quest-types?${params}`);
    }

    public static async create(data: object): Promise<AxiosResponse>{
        return await axios.post(`${this.route}/quest-types`, data);
    }

    public static async update(id: bigint, data: object): Promise<AxiosResponse>{
        return await axios.put(`${this.route}/quest-types/${id}`, data);
    }

    public static async delete(id: bigint): Promise<AxiosResponse> {
        return await axios.delete(`${this.route}/quest-types/${id}`);
    }

    public static async getUsers(id: bigint, params: string): Promise<AxiosResponse>{
        return await axios.get(`${this.route}/${this.entity}/${id}/users?${params}`);
    }
}