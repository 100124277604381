const convertCase = {
    methods: {
        snakeToTitleCase(value: string): string {
            if (!value) return '';
            return value.split('_').map(function(item) {
                return item.charAt(0).toUpperCase() + item.substring(1);
            }).join(' ');
        },
        snakeToCamelCase(value: string): string {
            return value.replace(/([-_][a-z])/ig, (item) => {
                return item.toUpperCase()
                    .replace('-', '')
                    .replace('_', '');
            });
        },
        titleToCamelCase(value: string): string {
            return value.toLowerCase().replace(/\s+(.)/g, function(_, group) {
                return group.toUpperCase();
            });
        },
        titleToKebabCase(value: string): string {
            return value.toLowerCase()
                .replace(/\d+/g, ' ')
                .split(/ |\B(?=[A-Z])/)
                .map((word) => word.toLowerCase())
                .join('-');
        },
        camelToSnakeCase(value: string): string {
            return value.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`)
        }
    }
};

export default convertCase;