<template>
    <div>
        <v-card class="elevation-2">
            <DataTableHeader
                :pagination="pagination"
                :searchAttributes="searchAttributes"
                :selectedSearchAttributes.sync="selectedSearchAttributes"
                :disabled="loading"
            />
            <DataTableSkeletonLoader :loading="initialLoad"/>
            <v-data-table
                v-show="!initialLoad"
                :headers="headers"
                :items="entities"
                :dense="$vuetify.breakpoint.xs && !loading"
                :loading="loading"
                :loading-text="$t('components.noData.fetchingEntries')"
                :options.sync="options"
                :server-items-length="pagination.total"
                :items-per-page="pagination.per_page"
                :header-props="{ sortIcon: 'arrow_circle_up', sortByText: $t('miscellaneous.sortBy') }"
                :class="{ 'mobile-empty-datatable' : $vuetify.breakpoint.xsOnly }"
                hide-default-footer
            >
                <template v-slot:top>
                    <v-toolbar flat>
                        <v-toolbar-title>{{ $t('entities.sessions.title') }}</v-toolbar-title>
                        <v-divider
                            class="mx-4"
                            inset
                            vertical
                        ></v-divider>
                        <small class="mr-2">{{ addThousandSeparator(pagination.total) }}</small>
                        <RefreshButton
                            :refresh="refresh"
                            :loading="loading"
                        />
                    </v-toolbar>
                </template>
                <template v-slot:item.arena_id="{ item }">
                    <template v-if="item.arena_id">
                        {{ item.arena_id }}
                    </template>
                </template>
                <template v-slot:item.active="{ item }">
                    <Boolean :boolean="item.active"/>
                </template>
                <template v-slot:item.base_rate="{ item }">
                    {{ addThousandSeparator(item.base_rate) }}
                    <CoinImage/>
                </template>
                <template v-slot:item.started_at="{ item }">
                    {{ fromUtcDateTimeToCurrent(item.started_at, true, true) }}
                </template>
                <template v-slot:item.stopped_at="{ item }">
                    <template v-if="item.stopped_at">
                        {{ fromUtcDateTimeToCurrent(item.stopped_at, true, true) }}
                    </template>
                    <template v-else>
                        <v-tooltip
                            color="transparent"
                            top
                        >
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    @click="stopSession(item)"
                                    v-on="on"
                                    :disabled="!$store.getters.arena || loading || loadingIndexes.includes(item.id)"
                                    color="error"
                                    class="glow ml-2"
                                    depressed
                                    x-small
                                    fab
                                >
                                    <template v-if="loadingIndexes.includes(item.id)">
                                        <ProgressCircular color="error"/>
                                    </template>
                                    <template v-else>
                                        <v-icon
                                            class="white--text"
                                            small
                                        >
                                            power_settings_new
                                        </v-icon>
                                    </template>
                                </v-btn>
                            </template>
                            <v-card class="elevation-2 px-4 py-2">
                                <span>{{ $t('entities.sessions.stop') }}</span>
                            </v-card>
                        </v-tooltip>
                    </template>
                </template>
                <template v-slot:no-data>
                    <NoData
                        :pagination="pagination"
                        :typing="typing"
                        :fetchEntities="fetchEntities"
                    />
                </template>
            </v-data-table>
            <DataTableFooter 
                :pagination="pagination"
                :loading="loading"
            />
        </v-card>
        <Snackbar
            :show.sync="snackbar.show"
            :color="snackbar.color"
            :message="snackbar.message"
        />
    </div>
</template>

<script>

import Vue from 'vue';
import Boolean from '@/components/Boolean.vue';
import CoinImage from '@/components/CoinImage.vue';
import NoData from '@/components/crud/NoData.vue';
import ProgressCircular from '@/components/ProgressCircular.vue';
import RefreshButton from '@/components/crud/RefreshButton.vue';
import DataTableHeader from '@/components/crud/DataTableHeader.vue';
import DataTableSkeletonLoader from '@/components/crud/DataTableSkeletonLoader.vue';
import DataTableFooter from '@/components/crud/DataTableFooter.vue';
import Session from "@/models/main/Session";
import Snackbar from '@/components/Snackbar.vue';

import { axiosCatch } from '@/mixins/crud/methods/axios-catch.js';
import { paginationWatch } from '@/mixins/crud/watch/pagination.js';
import thousandSeparator from '@/mixins/thousand-separator';
import convertDate from '@/mixins/convert-date';
import isRole from '@/mixins/crud/computed/isRole';
import { showSnackbar } from "@/mixins/crud/methods/show-snackbar";

export default Vue.extend({
    components: {
        RefreshButton,
        Boolean,
        NoData,
        Snackbar,
        ProgressCircular,
        DataTableHeader,
        DataTableSkeletonLoader,
        DataTableFooter,
        CoinImage,
    },

    mixins: [
        thousandSeparator,
        axiosCatch,
        paginationWatch,
        convertDate,
        isRole,
        showSnackbar,
    ],

    data: () => ({
        initialLoad: true,
        loading: false,
        typing: false,
        loadingIndexes: [],
        entities: [],
        pagination: {
            search: '',
            current_page: 1,
            from: 1,
            last_page: 0,
            path: '',
            per_page: 10,
            to: 0,
            total: 0
        },
        options: {
            page: 1,
            itemsPerPage: 10,
            sortBy: ['started_at'],
            sortDesc: ['DESC'],
            groupBy: [],
            groupDesc: [],
            mustSort: false,
            multiSort: false
        },
        selectedSearchAttributes: ['Username'],
    }),

    computed: {
        headers() {
            const headers = [
                { text: this.$t('entities.users.attributes.username'), align: 'left', value: 'username' },
                { text: this.$t('entities.devices.entity'), align: 'left', value: 'device_id' },
                { text: this.$t('entities.arenas.attributes.active'), align: 'left', value: 'active' },
                { text: this.$t('entities.arenas.attributes.baseRate'), align: 'left', value: 'base_rate', sortable: false },
                { text: this.$t('entities.sessions.attributes.startedAt'), align: 'left', value: 'started_at' },
                { text: this.$t('entities.sessions.attributes.stoppedAt'), align: 'left', value: 'stopped_at' },
            ];

            if (!this.$store.getters.arena) {
                headers.splice(0, 0, { text: this.$t('miscellaneous.arena'), align: 'left', value: 'arena.name', sortable: false })
            }

            return headers;
        },
        searchAttributes() {
            return [
                { label: this.$t('entities.users.attributes.username'), value: 'Username' },
                { label: this.$t('entities.devices.entity'), value: 'Device' },
            ]
        },
    },

    mounted() {
        this.refresh();
    },

    methods: {
        async fetchEntities() {
            try {
                let append = this.setParams();

                this.loading = true;
                let response;
                // Change param name
                if (append.includes('device=')) {
                    append = append.replace('device=', 'deviceId=');
                }
                if (this.isAdmin) {
                    if (this.$store.getters.arena) {
                        response = await Session.api.getByArena(this.$store.getters.arena.id, `${append}`);
                    } else {
                        response = await Session.api.get(`${append}`);
                    }
                } else {
                    response = await Session.api.getByArena(this.$store.getters.arena ? this.$store.getters.arena.id : -1, `${append}`);
                }
                const data = response.data;
                this.entities = data.content.map(SessionDTO => new Session(SessionDTO));
                this.pagination.current_page = data.pageable.page_number + 1;
                this.pagination.per_page = data.pageable.page_size;
                this.pagination.total = data.total_elements;
                this.pagination.last_page = data.total_pages;
                this.pagination.from = data.pageable.offset + 1;
                this.pagination.to = this.pagination.from + data.number_of_elements - 1;
            } catch (e) {
                console.warn(`Sessions API failed.`);
                console.log(e);
            } finally {
                this.initialLoad = false;
                this.loading = false;
            }
        },
        async stopSession(entity) {
            try {
                this.loadingIndexes.push(entity.id);
                this.loading = true;
                const data = {
                    reason: 'closed_by_employee',
                };
                await Session.api.destroy(this.$store.getters.arena.id, entity.id, data)
                    .then(() => {
                        this.refresh();
                    }).catch(error => {
                        this.loading = false;
                        // Server error
                        if (error.response.status === 500) {
                            this.showSnackbar('error', error.response.data);
                        }
                    });
            } catch (error) {
                console.warn(`Delete Arena Session API failed.`);
                console.log(error);
            } finally {
                this.loadingIndexes = this.loadingIndexes.filter(i => i !== entity.id)
            }
        },
        refresh() {
            this.fetchEntities();
        }
    }
});

</script>