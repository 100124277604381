import { AxiosResponse } from 'axios';
import axios from '@/services/axios';

export default abstract class MaxBonusesApi {

    public static route = process.env.VUE_APP_INVENTORY_BASE_URI;
    public static entity = 'max-bonuses';

    public static async get(): Promise<AxiosResponse> {
        return await axios.get(`${this.route}/${this.entity}`);
    }

    public static async update(data: object): Promise<AxiosResponse>{
        return await axios.post(`${this.route}/${this.entity}/update`, data);
    }
}
