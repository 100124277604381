<template>
    <div>
        <v-card class="elevation-2">
            <DataTableHeader
                :pagination="pagination"
                :searchAttributes="searchAttributes"
                :selectedSearchAttributes.sync="selectedSearchAttributes"
                :disabled="loading"
            />
            <DataTableSkeletonLoader :loading="initialLoad"/>
            <v-data-table
                v-show="!initialLoad"
                :headers="headers"
                :items="entities"
                :dense="$vuetify.breakpoint.xs && !loading"
                :loading="loading"
                :loading-text="$t('components.noData.fetchingEntries')"
                :options.sync="options"
                :server-items-length="pagination.total"
                :items-per-page="pagination.per_page"
                :header-props="{ sortIcon: 'arrow_circle_up', sortByText: $t('miscellaneous.sortBy') }"
                :class="{ 'mobile-empty-datatable' : $vuetify.breakpoint.xsOnly }"
                hide-default-footer
            >
                <template v-slot:top>
                    <v-toolbar flat>
                        <v-toolbar-title>{{ $t('entities.categories.title') }}</v-toolbar-title>
                        <v-divider
                            class="mx-4"
                            inset
                            vertical
                        ></v-divider>
                        <small class="mr-2">{{ pagination.total }}</small>
                        <RefreshButton
                            :refresh="refresh"
                            :loading="loading"
                        />
                        <v-spacer></v-spacer>
                        <v-dialog
                            v-model="editDialog"
                            max-width="1000px"
                        >
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    v-if="isAdmin"
                                    v-on="on"
                                    color="primary"
                                    dark
                                    class="mb-2"
                                >
                                    {{ $t('miscellaneous.add') }}
                                </v-btn>
                            </template>
                            <v-card>
                                <v-card-text class="px-0 pb-0">
                                    <EditToolbar
                                        :formTitle="formTitle"
                                        :closeEditDialog="closeEditDialog"
                                    />
                                    <v-container>
                                        <v-card class="px-4 pb-4">
                                            <v-form
                                                @submit.prevent="save"
                                                method="POST"
                                                ref="form"
                                            >
                                                <RequiredFields/>
                                                <DialogImage
                                                    :image="editedEntity.image"
                                                    :name="editedEntity.name"
                                                />
                                                <v-row
                                                    align="center"
                                                    class="mt-2"
                                                >
                                                    <v-col
                                                        cols="12"
                                                        class="position-relative py-0"
                                                        sm="8"
                                                    >
                                                        <v-text-field
                                                            :value="editedEntity.name"
                                                            @change="v => (editedEntity.name = v)"
                                                            :rules="[required]"
                                                            :label="$t('generalAttributes.name')"
                                                            prepend-icon="mdi-format-title"
                                                        ></v-text-field>
                                                        <span class="required-fields">*</span>
                                                    </v-col>
                                                    <v-col
                                                        cols="12"
                                                        sm="4"
                                                        class="position-relative py-0"
                                                    >
                                                        <v-flex>
                                                            <ImageInput
                                                                :file.sync="file"
                                                                :alreadyHasImage="editedEntity.image"
                                                            />
                                                        </v-flex>
                                                    </v-col>
                                                    <v-col
                                                        cols="12"
                                                        sm="6"
                                                        class="position-relative pt-4"
                                                    >
                                                        <span>{{ $t('entities.categories.attributes.attributes') }}</span>
                                                    </v-col>
                                                    <v-col
                                                        v-if="editedIndex !== -1"
                                                        cols="12"
                                                    >
                                                        <v-alert type="warning">
                                                            <span class="mb-0 text--white">{{ $t('entities.categories.updateAttributesMessage') }}</span>
                                                        </v-alert>
                                                    </v-col>
                                                    <template v-if="editedEntity.attributes && editedEntity.attributes.length">
                                                        <v-col
                                                            v-for="index in editedEntity.attributes.length"
                                                            :key="`name-${index}`"
                                                            cols="12"
                                                            class="py-0"
                                                        >
                                                            <v-row align="center">
                                                                <v-col
                                                                    cols="12"
                                                                    sm="4"
                                                                    class="position-relative py-0"
                                                                >
                                                                    <v-text-field
                                                                        :value="editedEntity.attributes[index - 1].name"
                                                                        @change="v => (editedEntity.attributes[index - 1].name = v)"
                                                                        :rules="[required]"
                                                                        :label="$t('generalAttributes.name')"
                                                                        prepend-icon="mdi-format-title"
                                                                    ></v-text-field>
                                                                    <span class="required-fields">*</span>
                                                                </v-col>
                                                                <v-col
                                                                    cols="12"
                                                                    sm="3"
                                                                    class="position-relative text-center pt-3 pb-0"
                                                                >
                                                                    <v-select
                                                                        :value="editedEntity.attributes[index - 1].type"
                                                                        @change="v => (editedEntity.attributes[index - 1].type = v)"
                                                                        :items="dataTypes"
                                                                        :rules="[v => v && !!v.length || $t('validation.dataTypeIsRequired')]"
                                                                        :label="$t('entities.categories.rules')"
                                                                        :menu-props="{ bottom: true, offsetY: true }"
                                                                        solo
                                                                    >
                                                                        <template v-slot:label>
                                                                            {{ $t('entities.categories.chooseDataType') }}
                                                                            <span :style="{ color: 'red' }">*</span>
                                                                        </template>
                                                                        <template v-slot:selection="data">
                                                                            <v-chip
                                                                                color="primary"
                                                                                small
                                                                            >
                                                                                {{ $t(`entities.categories.validationAttributes.${data.item}`) }}
                                                                            </v-chip>
                                                                        </template>
                                                                        <template v-slot:item="data">
                                                                            {{ $t(`entities.categories.validationAttributes.${data.item}`) }}
                                                                        </template>
                                                                    </v-select>
                                                                </v-col>
                                                                <v-col
                                                                    cols="12"
                                                                    sm="4"
                                                                    class="position-relative text-center pt-3 pb-0"
                                                                >
                                                                    <v-select
                                                                        :value="editedEntity.attributes[index - 1].rules"
                                                                        @change="v => (editedEntity.attributes[index - 1].rules = v)"
                                                                        :items="validationAttributes"
                                                                        :rules="[v => v && !!v.length || $t('validation.atLeastOneRule')]"
                                                                        :label="$t('entities.categories.rules')"
                                                                        :menu-props="{ bottom: true, offsetY: true }"
                                                                        multiple
                                                                        solo
                                                                    >
                                                                        <template v-slot:label>
                                                                            {{ $t('entities.categories.chooseRules') }}
                                                                            <span :style="{ color: 'red' }">*</span>
                                                                        </template>
                                                                        <template v-slot:selection="data">
                                                                            <v-chip
                                                                                color="primary"
                                                                                small
                                                                            >
                                                                                {{ $t(`entities.categories.validationAttributes.${data.item}`) }}
                                                                            </v-chip>
                                                                        </template>
                                                                        <template v-slot:item="data">
                                                                            {{ $t(`entities.categories.validationAttributes.${data.item}`) }}
                                                                        </template>
                                                                    </v-select>
                                                                </v-col>
                                                                <v-col
                                                                    cols="12"
                                                                    sm="1"
                                                                    class="position-relative text-center pb-5"
                                                                >
                                                                    <v-btn
                                                                        @click="remove(index)"
                                                                        color="error"
                                                                        rounded
                                                                    >
                                                                        X
                                                                    </v-btn>
                                                                </v-col>
                                                            </v-row>
                                                        </v-col>
                                                    </template>
                                                    <template v-else>
                                                        <v-col
                                                            cols="12"
                                                            class="position-relative pt-4"
                                                        >
                                                            <span>{{ $t('entities.categories.noAttributes') }}</span>
                                                        </v-col>
                                                    </template>
                                                    <v-col
                                                        cols="12"
                                                    >
                                                        <v-row
                                                            justify="center"
                                                            align="center"
                                                        >
                                                            <v-col
                                                                cols="auto"
                                                                sm="12"
                                                                class="text-center"
                                                            >
                                                                <v-btn
                                                                    @click="add()"
                                                                    color="primary"
                                                                >
                                                                    {{ $t('entities.categories.add') }}
                                                                </v-btn>
                                                            </v-col>
                                                        </v-row>
                                                    </v-col>
                                                </v-row>
                                                <ValidationErrors
                                                    v-if="validationErrors.length"
                                                    :errors="validationErrors"
                                                    class="mt-4"
                                                />
                                            </v-form>
                                        </v-card>
                                    </v-container>
                                </v-card-text>
                                <EditCardActions
                                    :save="save"
                                    :saving="saving"
                                    :closeEditDialog="closeEditDialog"
                                />
                            </v-card>
                        </v-dialog>
                        <v-dialog
                            v-model="deleteDialog"
                            max-width="1000px"
                        >
                            <v-card>
                                <v-card-text class="px-0 pb-0">
                                    <DeleteToolbar
                                        :text="$t('entities.categories.deleteEntity')"
                                        :closeDeleteDialog="closeDeleteDialog"
                                    />
                                    <v-container>
                                        <DialogImage
                                            :image="toDeleteEntity.image"
                                            :name="toDeleteEntity.name"
                                        />
                                        <v-card>
                                            <v-col
                                                cols="12"
                                                class="py-0"
                                            >
                                                <v-alert type="warning">
                                                    <span class="font-weight-bold mb-0 text--white">{{ $t('entities.categories.deleteEntityMessageEffect', { category: toDeleteEntity.name }) }}</span>
                                                </v-alert>
                                            </v-col>
                                            <v-col
                                                cols="12"
                                                class="py-0"
                                            >
                                                <v-alert type="warning">
                                                    <span class="mb-0 text--white">{{ $t('entities.categories.deleteEntityMessage', { category: toDeleteEntity.name }) }}</span>
                                                </v-alert>
                                            </v-col>
                                            <v-simple-table
                                                class="do-not-apply-hover"
                                                dense
                                            >
                                                <template v-slot:default>
                                                    <tbody>
                                                        <tr>
                                                            <td>{{ $t('generalAttributes.name') }}</td>
                                                            <td class="font-weight-bold">
                                                                {{ toDeleteEntity.name }}
                                                            </td>
                                                        </tr>
                                                        <template v-if="toDeleteEntity.attributes && toDeleteEntity.attributes.length">
                                                            <tr>
                                                                <td colspan="2">{{ $t('entities.categories.attributes.attributes') }}</td>
                                                            </tr>
                                                            <tr
                                                                v-for="(attribute, key) in toDeleteEntity.attributes"
                                                                :key="key"
                                                            >
                                                                <td class="font-weight-bold">
                                                                    {{ attribute.name }}
                                                                </td>
                                                                <td>
                                                                    <v-chip
                                                                        v-for="(attr, k) in attribute.values"
                                                                        :key="k"
                                                                        color="primary"
                                                                        class="ml-2"
                                                                        small
                                                                    >
                                                                        {{ $t(`entities.categories.validationAttributes.${attr}`) }}
                                                                    </v-chip>
                                                                </td>
                                                            </tr>
                                                        </template>
                                                        <template v-else>
                                                            <tr>
                                                                <td colspan="3">
                                                                    {{ $t('entities.categories.attributes.attributes') }}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td
                                                                    colspan="3"
                                                                    class="font-weight-bold"
                                                                >
                                                                    /
                                                                </td>
                                                            </tr>
                                                        </template>
                                                    </tbody>
                                                </template>
                                            </v-simple-table>
                                        </v-card>
                                    </v-container>
                                </v-card-text>
                                <DeleteCardActions
                                    :deleteEntity="deleteEntity"
                                    :deleting="deleting"
                                    :closeDeleteDialog="closeDeleteDialog"
                                />
                            </v-card>
                        </v-dialog>
                    </v-toolbar>
                </template>
                <template v-slot:item.attributes="{ item }">
                    <template v-if="item.attributes">
                        <span
                            v-for="(attribute, key) in item.attributes"
                            :key="key"
                            class="d-block my-1"
                        >
                            {{ attribute.name }} =>
                            <v-chip
                                v-for="(attr, k) in attribute.rules"
                                :key="k"
                                color="primary"
                                class="ml-2"
                                small
                            >
                                {{ $t(`entities.categories.validationAttributes.${attr}`) }}
                            </v-chip>
                            <br>
                        </span>
                    </template>
                    <template v-else>
                        <i>{{ $t('entities.categories.noRequiredAttributes') }}</i>
                    </template>
                </template>
                <template v-slot:item.action="{ item }">
                    <EditButton
                        v-if="isAdmin"
                        :text="$t('entities.categories.editEntity')"
                        :item="item"
                        :openEditDialog="openEditDialog"
                    />
                    <DeleteButton
                        v-if="isAdmin"
                        :text="$t('entities.categories.deleteEntity')"
                        :item="item"
                        :openDeleteDialog="openDeleteDialog"
                    />
                </template>
                <template v-slot:no-data>
                    <NoData
                        :pagination="pagination"
                        :typing="typing"
                        :fetchEntities="fetchEntities"
                    />
                </template>
            </v-data-table>
            <DataTableFooter 
                :pagination="pagination"
                :loading="loading"
            />
        </v-card>
        <Snackbar
            :show.sync="snackbar.show"
            :color="snackbar.color"
            :message="snackbar.message"
        />
    </div>
</template>

<script>

import Vue from 'vue';
import NoData from '@/components/crud/NoData.vue';
import ImageInput from '@/components/ImageInput.vue';
import RefreshButton from '@/components/crud/RefreshButton.vue';
import EditButton from '@/components/crud/edit/EditButton.vue';
import EditToolbar from '@/components/crud/edit/EditToolbar.vue';
import EditCardActions from '@/components/crud/edit/EditCardActions.vue';
import DeleteButton from '@/components/crud/delete/DeleteButton.vue';
import DeleteToolbar from '@/components/crud/delete/DeleteToolbar.vue';
import DeleteCardActions from '@/components/crud/delete/DeleteCardActions.vue';
import DialogImage from '@/components/DialogImage';
import RequiredFields from '@/components/crud/RequiredFields.vue';
import DataTableHeader from '@/components/crud/DataTableHeader.vue';
import DataTableSkeletonLoader from '@/components/crud/DataTableSkeletonLoader.vue';
import DataTableFooter from '@/components/crud/DataTableFooter.vue';
import ValidationErrors from '@/components/crud/ValidationErrors.vue';
import Snackbar from '@/components/Snackbar.vue';
import Category from '@/models/shop/Category';
import FilesApi from '@/services/misc/FilesApi';

import isRole from '@/mixins/crud/computed/isRole';
import required from '@/mixins/rules/required';
import { paginationWatch } from '@/mixins/crud/watch/pagination.js';
import { editDialog } from '@/mixins/crud/watch/edit-dialog.js';
import { deleteDialog } from '@/mixins/crud/watch/delete-dialog.js';
import { deleteEntity } from '@/mixins/crud/methods/delete-entity.js';
import { axiosCatch } from '@/mixins/crud/methods/axios-catch.js';
import { showSnackbar } from '@/mixins/crud/methods/show-snackbar';

export default Vue.extend({
    components: {
        NoData,
        DataTableHeader,
        DataTableSkeletonLoader,
        DataTableFooter,
        ValidationErrors,
        ImageInput,
        RefreshButton,
        EditToolbar,
        EditButton,
        EditCardActions,
        DeleteToolbar,
        DeleteButton,
        DeleteCardActions,
        DialogImage,
        RequiredFields,
        Snackbar,
    },

    mixins: [
        paginationWatch,
        editDialog,
        deleteDialog,
        deleteEntity,
        axiosCatch,
        isRole,
        showSnackbar,
        required,
    ],

    data: () => ({
        initialLoad: true,
        loading: false,
        saving: false,
        deleting: false,
        typing: false,
        apiURI: process.env.VUE_APP_SHOP_BASE_URI,
        entity: 'categories',
        route: 'categories',
        entities: [],
        validationErrors: [],
        pagination: {
            search: '',
            current_page: 1,
            from: 1,
            last_page: 0,
            path: '',
            per_page: 10,
            to: 0,
            total: 0
        },
        options: {
            page: 1,
            itemsPerPage: 10,
            sortBy: [],
            sortDesc: [],
            groupBy: [],
            groupDesc: [],
            mustSort: false,
            multiSort: false
        },
        selectedSearchAttributes: ['Name'],
        editDialog: false,
        deleteDialog: false,
        editedIndex: -1,
        dataTypes: [
            'string',
            'boolean',
            'integer',
            'numeric',
            'currency',
            'array',
            'date',
            'datetime',
            'time',
        ],
        validationAttributes: [
            'required',
            'nullable',
            'date_format:H:i',
            'between:1,100'
        ],
        file: null,
        image: null,
        editedEntity: {
            id: '',
            name: '',
            attributes: [],
            image: ''
        },
        defaultEntity: {
            id: '',
            name: '',
            attributes: [],
            image: ''
        },
        toDeleteEntity: {
            id: '',
            name: '',
            attributes: [],
            image: ''
        }
    }),

    computed: {
        headers() {
            const headers = [
                { text: this.$i18n.t('generalAttributes.name'), align: 'left', value: 'name' },
                { text: this.$i18n.t('entities.categories.attributes.attributes'), align: 'left', value: 'attributes', sortable: false },
            ];

            if (this.isAdmin) {
                headers.push({ text: this.$i18n.t('miscellaneous.actions'), value: 'action', sortable: false });
            }

            return headers;
        },
        searchAttributes() {
            return [
                { label: this.$t('generalAttributes.name'), value: 'Name' }
            ]
        },
        formTitle() {
            return this.editDialog ? (this.$t(`entities.${this.entity}.${this.editedIndex === -1 ? 'new' : 'edit'}Entity`)) : '';
        },
    },

    mounted() {
        this.refresh();
    },

    methods: {
        async fetchEntities(append = `&size=${this.pagination.per_page}&page=${this.pagination.current_page}`) {
            try {
                this.loading = true;
                const response = await Category.api.get(1, append);
                this.entities = response.data.data.map((CategoryDTO) => new Category(CategoryDTO));
                response.data.meta.per_page = parseInt(response.data.meta.per_page);
                Object.assign(this.pagination, response.data.meta);
            } catch (e) {
                console.warn('Categories API failed.');
                console.log(e);
            } finally {
                this.initialLoad = false;
                this.loading = false;
            }
        },
        async save() {
            try {
                this.saving = true;

                if (!this.$refs.form.validate()) {
                    return;
                }

                if (this.file) {
                    await FilesApi.upload('shop', this.file)
                        .then((response) => {
                            this.file = null;
                            if (!response || response.status !== 200) {
                                this.showSnackbar('error', this.$t('miscellaneous.fileUploadFailed'));
                                return;
                            }
                            this.image = response.data.link;
                        })
                        .catch(() => {
                            this.showSnackbar('error', this.$t('miscellaneous.fileUploadFailed'));
                        });
                }

                const data = {
                    name: this.editedEntity.name
                };

                if (this.image) {
                    data.image = this.image;
                }

                if (this.editedEntity.attributes && this.editedEntity.attributes.length) {
                    const attributes = [];
                    for (let i = 0; i < this.editedEntity.attributes.length; i++) {
                        attributes[i] = {};
                        attributes[i].name = this.editedEntity.attributes[i].name;
                        attributes[i].type = this.editedEntity.attributes[i].type;
                        attributes[i].rules = [];
                        for (let j = 0; j < this.editedEntity.attributes[i].rules.length; j++) {
                            attributes[i].rules[j] = this.editedEntity.attributes[i].rules[j];
                        }
                    }
                    data.attributes = attributes;
                }

                if (this.editedIndex > -1) {
                    await Category.api.update(this.editedEntity.id, data)
                        .then(() => {
                            if (this.pagination.search.length) {
                                this.pagination.search = '';
                            } else {
                                this.fetchEntities();
                            }
                            this.showSnackbar('success', this.$t('entities.categories.notifications.update'));
                            this.closeEditDialog();
                            this.image = null;
                        })
                        .catch(error => {
                            this.axiosCatch(error);
                        });
                } else {
                    await Category.api.create(data)
                        .then(() => {
                            if (this.pagination.search.length) {
                                this.pagination.search = '';
                            } else {
                                this.fetchEntities();
                            }
                            this.showSnackbar('success', this.$t('entities.categories.notifications.creation'));
                            this.closeEditDialog();
                            this.image = null;
                        })
                        .catch(error => {
                            this.axiosCatch(error);
                        });
                }
            } catch (error) {
                console.warn(`Category Save API failed.`);
                console.log(error);
            } finally {
                this.saving = false;
            }
        },
        add() {
            if (!this.editedEntity.attributes) {
                this.$set(this.editedEntity, 'attributes', []);
            }
            this.editedEntity.attributes.push({...this.defaultEntity.attributes});
        },
        remove(index) {
            this.editedEntity.attributes.splice(index - 1, 1);
        },
        openEditDialog(entity) {
            this.editedIndex = this.entities.indexOf(entity);
            this.editedEntity = JSON.parse(JSON.stringify(entity));
            if (this.$refs.form) {
                this.$refs.form.resetValidation();
            }
            this.editDialog = true;
        },
        closeEditDialog() {
            this.editedEntity = JSON.parse(JSON.stringify(this.defaultEntity));
            this.editedIndex = -1;
            this.file = null;
            this.editDialog = false;
        },
        openDeleteDialog(entity) {
            this.editedIndex = this.entities.indexOf(entity);
            this.toDeleteEntity = {...entity};
            this.deleteDialog = true;
        },
        closeDeleteDialog() {
            this.deleteDialog = false;
            this.toDeleteEntity = {...this.defaultEntity};
            this.editedIndex = -1;
        },
        refresh() {
            this.fetchEntities();
        },
    }
});

</script>


<style>

.position-relative {
    position: relative;
}

.required-fields {
    position: absolute;
    right: 13px;
    top: 15px;
    color: red;
}

</style>
