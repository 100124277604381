import OrdersApi from '@/services/shop/OrdersApi';

export interface OrderInterface {
    id: bigint;
    user: object[];
    payment_method: object[];
    arena: object[];
    gross: number;
    net: number;
    vat: number;
    delivery_amount: number;
    status: number;
    payment_status: number;
    delivery_status: number;
    description: string;
    note: string;
    billing_information: object[];
    shipping_information: object[];
    referred_from: string;
    serial_number: string;
}

export class OrderDTO implements OrderInterface {
    id = 0n;
    user = [];
    payment_method = [];
    arena = [];
    gross = 0;
    net = 0;
    vat = 0;
    delivery_amount = 0;
    status = 0;
    payment_status = 0;
    delivery_status = 0;
    description = '';
    note = '';
    billing_information = [];
    shipping_information = [];
    referred_from = '';
    serial_number = '';
}

export default class Order extends OrderDTO {
    static api = OrdersApi;

    constructor(DTO: OrderDTO){
        super();
        Object.assign(this, DTO);
    }
}