

import Vue from 'vue';

export default Vue.extend({
    props: ['text'],

    data: () => ({
        copied: false
    }),

    methods: {
        async copyText(text: string) {
            try {
                await navigator.clipboard.writeText(text);
                this.copied = true;
            } catch (err) {
                this.copied = false;
                console.error('Failed to copy: ', err);
            }
        },
        updateCopyTooltip() {
            this.copied = false;
        },
    }
})

