import PaymentMethodsApi from '@/services/misc/PaymentMethodsApi';

export interface PaymentMethodInterface {
    id: bigint;
    process_type: string;
    name: string;
    image: string;
}

export class PaymentMethodDTO implements PaymentMethodInterface {
    id = 0n;
    process_type = '';
    name = '';
    image = '';
}

export default class PaymentMethod extends PaymentMethodDTO {
    static api = PaymentMethodsApi;

    constructor(DTO: PaymentMethodDTO){
        super();
        Object.assign(this, DTO);
    }
}