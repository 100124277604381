

import Vue from 'vue';

export default Vue.extend({
    props: {
        text: {
            type: String
        },
        pagination: {
            type: Object
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },

    computed: {
        rowsPerPageItems(): Array<number> {
            return this.specialRowsPerPage() ? [12, 24, 48, 96] : [5, 10, 25];
        }
    },

    methods: {
        specialRowsPerPage(): boolean {
            return this.$router.currentRoute.path.includes('apps') || this.$router.currentRoute.path.includes('games')
                || this.$router.currentRoute.path.includes('events') || this.$router.currentRoute.path.includes('articles');
        }
    }

});

