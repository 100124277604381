import { AxiosResponse } from 'axios';
import axios from '@/services/axios';

export default abstract class ItemsApi {

    public static route = process.env.VUE_APP_SHOP_BASE_URI;
    public static entity = 'items';

    public static async get(route: string, params: string): Promise<AxiosResponse> {
        return await axios.get(`${this.route}/${this.entity}${route}${params}`);
    }

    public static async create(data: object): Promise<AxiosResponse> {
        return await axios.post(`${this.route}/${this.entity}`, data);
    }

    public static async update(id: bigint, data: object): Promise<AxiosResponse> {
        return await axios.post(`${this.route}/${this.entity}/${id}`, data);
    }

    public static async delete(id: bigint, data: object): Promise<AxiosResponse> {
        return await axios.delete(`${this.route}/${this.entity}/${id}`, { params: data } );
    }
}
