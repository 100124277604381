<template>
    <div>
        <v-card
            class="mx-auto"
            max-width="1000"
        >
            <v-row
                align="center"
                class="mb-4"
            >
                <v-col
                    cols="12"
                    class="text-center mt-4 py-0"
                >
                   <template v-if="user.avatar">
                        <img
                            :src="user.avatar"
                            :alt="user.username"
                            width="40px"
                        />
                    </template>
                    <template v-else>
                        <v-icon
                            color="primary"
                            x-large
                        >
                            person
                        </v-icon>
                    </template>
                </v-col>
                <v-col
                    col="12"
                    class="text-center py-0"
                >
                    <p class="mb-0">{{ user.username }}</p>
                    <p
                        v-if="user.name"
                        class="mb-0"
                    >
                        {{ user.name }}
                    </p>
                    <p
                        v-if="user.email"
                        class="mb-0"
                    >
                        {{ user.email }}
                    </p>
                </v-col>
            </v-row>
            <v-divider/>
            <v-container fluid>
                <v-row
                    align="center"
                    class="mt-4"
                >
                    <v-col
                        cols="12"
                        sm="2"
                        class="text-left text-sm-right py-0"
                    >
                        <p class="mb-0">
                            {{ $t('profile.language') }}
                        </p>
                    </v-col>
                    <v-col
                        cols="12"
                        sm="4"
                        class="py-0"
                    >
                        <LanguageSelection/>
                    </v-col>
                    <v-col
                        cols="12"
                        sm="2"
                        class="text-left text-sm-right py-0"
                    >
                        <p class="mb-0">
                            {{ $t('profile.theme') }}
                        </p>
                    </v-col>
                    <v-col
                        cols="12"
                        sm="4"
                        class="py-0"
                    >
                        <ThemeSelection/>
                    </v-col>
                </v-row>
                <v-row
                    align="center"
                    class="mt-4"
                >

                </v-row>
            </v-container>
        </v-card>
        <v-card
            class="mx-auto"
            max-width="1000"
        >
            <v-row
                align="center"
                class="mb-4"
            >
                <v-col cols="12">
                    <v-simple-table
                        class="do-not-apply-hover"
                        dense
                    >
                        <DataTableSkeletonLoader :loading="loading"/>
                        <template 
                            v-if="!loading && entity"
                            v-slot:default
                        >
                            <thead>
                                <tr>
                                    <th 
                                        colspan="3"
                                        class="text-left"
                                    >
                                        {{ $t('entities.orders.generalInformation') }}
                                    </th>
                                    <th class="text-right">
                                        #{{ entity.id }}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{{ $t('entities.users.attributes.username') }}</td>
                                    <td class="font-weight-bold">
                                        {{ entity.username }}
                                    </td>
                                    <td>{{ $t('entities.roles.title') }}</td>
                                    <td class="font-weight-bold">
                                        <v-chip
                                            v-for="(role, index) in entity.roles"
                                            color="primary"
                                            :key="`role-${index}`"
                                            class="font-weight-bold mr-2"
                                            text-color="white"
                                            small
                                        >
                                            {{ role }}
                                        </v-chip>
                                    </td>
                                </tr>
                                <tr>
                                    <td>{{ $t('entities.users.attributes.active') }}</td>
                                    <td class="font-weight-bold">
                                        <Boolean :boolean="entity.active"/>
                                    </td>
                                    <td>{{ $t('entities.users.attributes.banned') }}</td>
                                    <td class="font-weight-bold">
                                        <Boolean :boolean="entity.banned"/>
                                    </td>
                                </tr>
                                <tr>
                                    <td>{{ $t('generalAttributes.coins') }}</td>
                                    <td class="font-weight-bold">
                                        {{ addThousandSeparator(entity.wallet.balance) }}
                                        <CoinImage/>
                                    </td>
                                    <td>{{ $t('entities.ranks.attributes.experience') }}</td>
                                    <td class="font-weight-bold">
                                        {{ addThousandSeparator(entity.rank.experience) }}
                                        <ExperienceImage class="pt-1"/>
                                    </td>
                                </tr>
                                <tr>
                                    <td>{{ $t('entities.users.attributes.rank') }}</td>
                                    <td class="font-weight-bold">
                                        <v-chip
                                            color="primary"
                                            class="font-weight-bold mr-2"
                                            text-color="white"
                                            small
                                        >
                                            {{ entity.rank.info.name }}
                                        </v-chip>
                                    </td>
                                    <td>{{ $t('entities.users.attributes.registeredAt') }}</td>
                                    <td class="font-weight-bold">
                                        {{ fromUtcDateTimeToCurrent(entity.created_at, true, true) }}
                                    </td>
                                </tr>
                                <tr>
                                    <td>{{ $t('entities.users.attributes.cardId') }}</td>
                                    <td class="font-weight-bold">
                                        {{ entity.card_id || '/' }}
                                    </td>
                                    <td>{{ $t('entities.users.attributes.avatar') }}</td>
                                    <td class="font-weight-bold">
                                        <template v-if="entity.avatar">
                                            <img
                                                :src="entity.avatar"
                                                alt="Avatar"
                                                width="20px"
                                            />
                                        </template>
                                        <template v-else>
                                            /
                                        </template>
                                    </td>
                                </tr>
                                <tr v-if="entity.reset_password">
                                    <td colspan="2">{{ $t('entities.users.passwordResetExpiresAt') }}</td>
                                    <td 
                                        colspan="2"
                                        class="font-weight-bold"
                                    >
                                        {{ fromUtcDateTimeToCurrent(entity.reset_password.expires_at, true, true) }}
                                    </td>
                                </tr>
                            </tbody>
                            <thead>
                                <tr>
                                    <th 
                                        colspan="4"
                                        class="text-left"
                                    >
                                        {{ $t('entities.users.identity') }}
                                        <Status 
                                            v-if="entity.identity && entity.identity.id"
                                            :status="entity.identity.status"
                                        />
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <template v-if="entity.identity && entity.identity.id">
                                    <tr>
                                        <td>{{ $t('generalAttributes.fullName') }}</td>
                                        <td class="font-weight-bold">
                                            {{ entity.identity.name || '/' }}
                                        </td>
                                        <td>{{ $t('generalAttributes.address') }}</td>
                                        <td class="font-weight-bold">
                                            {{ entity.identity.address || '/' }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>{{ $t('entities.users.attributes.birthdate') }}</td>
                                        <td class="font-weight-bold">
                                            {{ entity.identity.birthdate || '/' }}
                                        </td>
                                        <td>{{ $t('miscellaneous.createdAt') }}</td>
                                        <td class="font-weight-bold">
                                            {{ fromUtcDateTimeToCurrent(entity.identity.created_at, true, true) }}
                                        </td>
                                    </tr>
                                </template>
                                <template v-else>
                                    <tr>
                                        <td colspan="4">
                                            <i>{{ $t('entities.users.noIdentitySet') }}</i>
                                        </td>
                                    </tr>
                                </template>
                            </tbody>
                            <thead>
                                <tr>
                                    <th 
                                        colspan="4"
                                        class="text-left"
                                    >
                                        {{ $t('entities.users.attributes.email') }}
                                        <Status 
                                            v-if="entity.email && entity.email.id"
                                            :status="entity.email.status"
                                        />
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <template v-if="entity.email && entity.email.id">
                                        <td>{{ $t('generalAttributes.address') }}</td>
                                        <td class="font-weight-bold">
                                            <CopyTooltip :text="entity.email.address"/>
                                        </td>
                                        <td>{{ $t('miscellaneous.createdAt') }}</td>
                                        <td class="font-weight-bold">
                                            {{ fromUtcDateTimeToCurrent(entity.email.created_at, true, true) }}
                                        </td>
                                    </template>
                                    <template v-else>
                                        <td colspan="4">
                                            <i>{{ $t('entities.users.noEmailSet') }}</i>
                                        </td>
                                    </template>
                                </tr>
                            </tbody>
                            <thead>
                                <tr>
                                    <th 
                                        colspan="4"
                                        class="text-left"
                                    >
                                        {{ $t('entities.users.attributes.phone') }}
                                        <Status 
                                            v-if="entity.phone && entity.phone.id"
                                            :status="entity.phone.status"
                                        />
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <template v-if="entity.phone && entity.phone.id">
                                        <td>{{ $t('entities.orders.attributes.number') }}</td>
                                        <td class="font-weight-bold">
                                            <CopyTooltip :text="`+${entity.phone.number}`"/>
                                        </td>
                                        <td>{{ $t('miscellaneous.createdAt') }}</td>
                                        <td class="font-weight-bold">
                                            {{ fromUtcDateTimeToCurrent(entity.phone.created_at, true, true) }}
                                        </td>
                                    </template>
                                    <template v-else>
                                        <td colspan="4">
                                            <i>{{ $t('entities.users.noPhoneSet') }}</i>
                                        </td>
                                    </template>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-col>
            </v-row>
        </v-card>
    </div>
</template>

<script>

import Vue from 'vue';
import Boolean from '@/components/Boolean.vue';
import Status from '@/components/Status.vue';
import CoinImage from '@/components/CoinImage.vue';
import ExperienceImage from '@/components/ExperienceImage.vue';
import CopyTooltip from '@/components/CopyTooltip.vue';
import ThemeSelection from '@/components/app-bar/components/ThemeSelection.vue';
import LanguageSelection from '@/components/app-bar/components/LanguageSelection.vue';
import DataTableSkeletonLoader from '@/components/crud/DataTableSkeletonLoader.vue';
import User from '@/models/users/User';

import user from '@/mixins/crud/computed/user';
import convertDate from '@/mixins/convert-date';
import thousandSeparator from '@/mixins/thousand-separator';

export default Vue.extend({
    components: {
        Boolean,
        Status,
        CoinImage,
        ExperienceImage,
        CopyTooltip,
        ThemeSelection,
        LanguageSelection,
        DataTableSkeletonLoader,
    },

    mixins: [
        user,
        convertDate,
        thousandSeparator,
    ],

    data: () => ({
        loading: false,
        entity: null,
    }),

    mounted() {
        this.fetchUser();
    },

    methods: {
        async fetchUser() {
            try {
                this.loading = true;
                const response = await User.api.find(this.user.id);
                const data = response.data;
                this.entity = data;
            } catch (e) {
                console.warn(`User API failed.`);
                console.log(e);
            } finally {
                this.loading = false;
            }
        }
    }
});

</script>
