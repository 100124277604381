import QuestsApi from '@/services/competitions/QuestsApi';

export interface QuestInterface {
    id: bigint;
    name: string;
    description: string;
}

export class QuestDTO implements QuestInterface {
    id = 0n;
    name = '';
    description = '';
}

export default class Quest extends QuestDTO {
    public static api = QuestsApi;

    constructor(DTO: QuestDTO){
        super();
        Object.assign(this, DTO);
    }
}