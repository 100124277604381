import { app } from '@/main';
import router from '@/router';

export default function (val: string): void {
    app.$i18n.locale = val;
    localStorage.setItem('locale', val);
    const routeName = router.currentRoute.name;
    if (routeName) {
        document.title = 'FF | ' + app.$i18n.t(routeName).toString();
    }
}