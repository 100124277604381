import AchievementsApi from '@/services/competitions/AchievementsApi';

export interface AchievementInterface {
    id: bigint;
    name: string;
    description: string;
}

export class AchievementDTO implements AchievementInterface {
    id = 0n;
    name = '';
    description = '';
}

export default class Achievement extends AchievementDTO {
    public static api = AchievementsApi;

    constructor(DTO: AchievementDTO){
        super();
        Object.assign(this, DTO);
    }
}