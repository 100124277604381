<template>
    <v-row
        :class="`px-${rowPadding}`"
        class="mb-2"
        align="center"
        justify="start"
    >
        <v-col
            cols="12"
            md="4"
            class="text-center"
        >
            <RowsPerPage
                :pagination="pagination"
                :text="text"
                :disabled="disabled"
            />
        </v-col>
        <v-col
            cols="12"
            md="4"
            class="text-center"
        >
            <v-text-field
                v-model="pagination.search"
                @click:append="() => (pagination.search = '')"
                @keydown.native.escape="pagination.search = ''"
                :label="selectedSearchAttributes.length ? $t('components.dataTableHeader.search') : $t('components.dataTableHeader.searchDisabled')"
                :disabled="!selectedSearchAttributes.length || disabled"
                :append-icon="pagination.search ? 'close': ''"
                prepend-inner-icon="search"
                class="search"
                ref="search"
                hide-details
                solo
            ></v-text-field>
        </v-col>
        <v-col
            cols="12"
            md="4"
            class="text-center"
        >
            <v-select
                @change="$emit('update:selectedSearchAttributes', $event)"
                :items="searchAttributes"
                :value="selectedSearchAttributes"
                :label="$t('components.dataTableHeader.searchBy')"
                :menu-props="{ bottom: true, offsetY: true }"
                :no-data-text="$t('components.dataTableHeader.noDataAvailable')"
                :disabled="disabled"
                item-text="label"
                item-value="value"
                hide-details="auto"
                multiple
                solo
            >
                <template v-slot:selection="data">
                    <v-chip
                        color="primary"
                        small
                    >
                        {{ data.item.label }}
                    </v-chip>
                </template>
                <template v-slot:prepend-item>
                    <v-list-item
                        @click="toggle"
                        ripple
                    >
                        <v-list-item-action>
                            <v-icon :color="selectedSearchAttributes.length > 0 ? 'primary darken-4' : ''">{{ icon }}</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title>{{ $t(`components.dataTableHeader.${selectedAllAttributes ? 'de' : ''}selectAll`) }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-divider class="mt-2"></v-divider>
                </template>
            </v-select>
        </v-col>
    </v-row>
</template>

<script>

import Vue from 'vue';
import RowsPerPage from '@/components/crud/RowsPerPage.vue';

export default Vue.extend({
    props: {
        text: {
            type: String
        },
        pagination: {
            type: Object
        },
        searchAttributes: {
            type: Array
        },
        selectedSearchAttributes: {
            type: Array
        },
        rowPadding: {
            type: String,
            default: '4'
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },

    components: {
        RowsPerPage,
    },

    watch: {
        disabled: {
            immediate: true,
            handler (val) {
                if (!val && this.pagination.search) {
                    this.$nextTick(() => {
                        this.$refs.search.$el.getElementsByTagName('input')[0].focus();
                    })
                }
            }
        }
    },

    computed: {
        selectedAllAttributes() {
            return this.selectedSearchAttributes.length === this.searchAttributes.length
        },
        selectedSomeAttributes() {
            return this.selectedSearchAttributes.length > 0 && !this.selectedAllAttributes
        },
        icon() {
            if (this.selectedAllAttributes) return 'mdi-close-box'
            if (this.selectedSomeAttributes) return 'mdi-minus-box'
            return 'mdi-checkbox-blank-outline'
        },
    },

    methods: {
        toggle() {
            this.$nextTick(() => {
                if (this.selectedAllAttributes) {
                    this.$emit('update:selectedSearchAttributes', []);
                } else {
                    this.$emit('update:selectedSearchAttributes', this.searchAttributes.map(({value}) => value));
                }
            })
        },
    }
});

</script>

<style lang="scss">

.v-data-table-header th.sortable.active .v-data-table-header__icon {
    color: var(--v-primary-base) !important;
}

</style>
