<template>
    <div
        id="print"
        class="container d-none"
    >
        <div
            v-if="invoiceData.arena.company.image"
            class="col-12 text-center"
        >
            <img
                :src="invoiceData.arena.company.image"
                width="100px"
            />
        </div>
        <br><br>
        <div
            v-if="invoiceData.arena.invoice_settings.invoice_header"
            v-html="invoiceData.arena.invoice_settings.invoice_header"
            class="col-12 text-center"
        />
        <div class="col-12 text-center">
            <p class="mb-0">{{ invoiceData.arena.company.name }}</p>
            <p class="mb-0">{{ invoiceData.arena.company.address }}</p>
            <p class="mb-0">{{ $t('entities.companies.attributes.pidn', invoiceData.arena.company.country.locale ) }}: {{ invoiceData.arena.company.pidn }}</p>
            <div v-if="invoiceData.invoice.user && invoiceData.invoice.user.company">
                <p class="mb-0">{{ $t('entities.invoices.printData.company.invoice', invoiceData.arena.company.country.locale ) }}: {{ invoiceData.invoice.label }}</p>
                <p class="mb-0">{{ $t('entities.invoices.printData.company.name', invoiceData.arena.company.country.locale ) }}: {{ invoiceData.invoice.user.company.name }}</p>
                <p class="mb-0">{{ $t('entities.invoices.printData.company.address', invoiceData.arena.company.country.locale ) }}: {{ invoiceData.invoice.user.company.address }}</p>
                <p>{{ $t('entities.invoices.printData.company.pidn', invoiceData.arena.company.country.locale ) }}: {{ invoiceData.invoice.user.company.pidn }}</p>
            </div>
            <div v-else>
                <p>{{ $t('entities.invoices.entity', invoiceData.arena.company.country.locale ) }}: {{ invoiceData.invoice.label }}</p>
            </div>
        </div>
        <div class="col-12">
            <table style="width: 100%; border-collapse: collapse;">
                <thead>
                    <tr style="border-top: 1px solid #000; border-bottom: 1px solid #000;">
                        <td style="padding: 5px 0;">{{ $t('entities.invoices.printData.product', invoiceData.arena.company.country.locale ) }}</td>
                        <td style="padding: 5px 0;">{{ $t('entities.invoices.printData.quantity', invoiceData.arena.company.country.locale ) }}</td>
                        <td style="padding: 5px 0;">{{ $t('entities.invoices.printData.price', invoiceData.arena.company.country.locale ) }}</td>
                        <td
                            style="padding: 5px 0;"
                            class="text-end"
                        >
                            {{ $t('entities.invoices.printData.amount', invoiceData.arena.company.country.locale ) }}
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        v-for="(item, index) in invoiceData.invoice.cart.items"
                        :key="'item-' + index"
                        :style="{ borderBottom : index === invoiceData.invoice.cart.items.length - 1 ? '1px solid #000' : 'none' }"
                    >
                        <td :style="{
                                paddingTop : `${index === 0 ? '10' : '2'}px`,
                                paddingBottom : `${index === invoiceData.invoice.cart.items.length - 1 ? '10' : '0'}px`
                            }"
                        >
                            {{ item.name }}
                        </td>
                        <td :style="{
                                paddingTop : `${index === 0 ? '10' : '2'}px`,
                                paddingBottom : `${index === invoiceData.invoice.cart.items.length - 1 ? '10' : '0'}px`
                            }"
                        >
                            {{ item.name === 'Coins' ? (item.quantity > 0 ? 1 : -1) : item.quantity }}
                        </td>
                        <td :style="{
                                paddingTop : `${index === 0 ? '10' : '2'}px`,
                                paddingBottom : `${index === invoiceData.invoice.cart.items.length - 1 ? '10' : '0'}px`
                            }"
                        >
                            <template v-if="item.name === 'Coins'">
                                {{ item.quantity > 0 ? '' : '-' }}{{ format(item.price * item.quantity, false, invoiceData.arena.company.country.currency) }}
                            </template>
                            <template v-else>
                                {{ format(item.price, false, invoiceData.arena.company.country.currency) }}
                            </template>
                        </td>
                        <td :style="{
                                paddingTop : `${index === 0 ? '10' : '2'}px`,
                                paddingBottom : `${index === invoiceData.invoice.cart.items.length - 1 ? '10' : '0'}px`
                            }"
                            class="text-end"
                        >
                            {{ format(item.total_price, false, invoiceData.arena.company.country.currency) }}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div
            class="row"
            style="border-bottom: 1px solid #000; padding: 5px 0;"
        >
            <div class="col-6">
                <p class="mb-0">{{ $t('entities.invoices.printData.base', invoiceData.arena.company.country.locale ) }}</p>
            </div>
            <div class="col-6 text-end">
                <p class="mb-0">{{ format(invoiceData.invoice.cart.net, true, invoiceData.arena.company.country.currency) }}</p>
            </div>
            <div
                v-if="invoiceData.arena.company.fiscalData.inTaxSystem && Object.keys(invoiceData.invoice.taxes).length"
                class="col-12"
            >
                <div
                    v-for="(item, index) in invoiceData.invoice.taxes"
                    :key="'tax-' + index"
                    class="row"
                >
                    <div class="col-6">
                        <p class="mb-0">{{ item.name }}</p>
                    </div>
                    <div class="col-6 text-end">
                        <p class="mb-0">{{ format(item.amount, true, invoiceData.arena.company.country.currency) }}</p>
                    </div>
                </div>
            </div>
        </div>
        <div
            v-if="!invoiceData.arena.company.fiscalData.inTaxSystem"
            class="row"
            style="border-bottom: 1px solid #000; padding: 5px 0;"
        >
            <div class="col-12">
                <p class="mb-0">{{ $t('entities.invoices.printData.notInTaxSystem', invoiceData.arena.company.country.locale ) }}</p>
            </div>
        </div>
        <div
            class="row"
            style="border-bottom: 1px solid #000; padding: 5px 0;"
        >
            <div class="col-6">
                <p class="mb-0">{{ $t('entities.invoices.printData.total', invoiceData.arena.company.country.locale ) }}:</p>
            </div>
            <div class="col-6 text-end">
                <p class="mb-0">
                    <template v-if="invoiceData.arena.company.country.locale === 'hr'">
                        {{ format(invoiceData.invoice.cart.gross, true, invoiceData.arena.company.country.currency, true) }}
                    </template>
                    <template v-else>
                        {{ format(invoiceData.invoice.cart.gross, true, invoiceData.arena.company.country.currency) }}
                    </template>
                </p>
            </div>
            <div
                v-if="invoiceData.arena.company.country.locale === 'hr'"
                class="col-12"
            >
                <p class="mb-0">Fiksni tečaj konverzije: 1 EUR = 7,53450 HRK</p>
            </div>
        </div>
        <div
            class="row"
            style="border-bottom: 1px solid #000; padding: 5px 0;"
        >
            <div class="col-12">
                <p class="mb-0">{{ $t('entities.invoices.printData.operator', invoiceData.arena.company.country.locale ) }}: {{ invoiceData.invoice.byUser.name || '/' }}</p>
                <p class="mb-0">
                    <template v-if="invoiceData.invoice.paymentMethod.name">
                        {{ $t('miscellaneous.paymentMethod', invoiceData.arena.company.country.locale ) }}: {{ $t(`miscellaneous.paymentMethods.${titleToCamelCase(invoiceData.invoice.paymentMethod.name)}`, invoiceData.arena.company.country.locale) }}
                    </template>
                </p>
                <p class="mb-0">{{ $t('entities.invoices.printData.dateAndTime', invoiceData.arena.company.country.locale ) }}: {{ fromUtcDateTimeToCurrent(invoiceData.invoice.created_at, true, true) }}</p>
            </div>
        </div>
        <div
            v-if="invoiceData.arena.company.country.locale === 'hr'"
            class="row"
            style="border-bottom: 1px solid #000; padding: 5px 0;"
        >
            <div class="col-12">
                <p class="mb-0">ZKI: {{ invoiceData.invoice.fiscalData && invoiceData.invoice.fiscalData.zki ? invoiceData.invoice.fiscalData.zki : '/'}}</p>
                <p class="mb-0">JIR: {{ invoiceData.invoice.fiscalData && invoiceData.invoice.fiscalData.jir ? invoiceData.invoice.fiscalData.jir : '/'}}</p>
            </div>
        </div>
        <div
            v-if="(invoiceData.arena.company.country.locale === 'hr' || invoiceData.arena.company.country.locale === 'at') && invoiceData.invoice.fiscalData && invoiceData.invoice.fiscalData.qr"
            class="row"
            style="border-bottom: 1px solid #000; padding: 5px 0;"
        >
            <div class="col-12 text-center">
                <img
                    :src="invoiceData.invoice.fiscalData.qr"
                    width="100px"
                    alt="QR"
                />
            </div>
        </div>
        <div
            v-if="invoiceData.invoice.restoredInvoiceLabel"
            class="row"
            style="border-bottom: 1px solid #000; padding: 5px 0;"
        >
            <div class="col-12">
                <p class="mb-0">{{ $t('entities.invoices.printData.restoredNote', invoiceData.arena.company.country.locale, { label: invoiceData.invoice.restoredInvoiceLabel } ) }}</p>
            </div>
        </div>
        <div
            v-if="invoiceData.arena.invoice_settings.invoice_footer"
            v-html="invoiceData.arena.invoice_settings.invoice_footer"
            class="col-12 text-center"
        />
    </div>
</template>

<script>

import Vue from 'vue';

import convertCase from '@/mixins/convert-case';
import convertDate from '@/mixins/convert-date';
import currency from '@/mixins/currency';

export default Vue.extend({
    props: ['invoiceData'],

    mixins: [
        convertCase,
        convertDate,
        currency
    ],
})

</script>
