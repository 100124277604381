<template>
    <div>
        <GlobalButton
            :route="'/achievements'"
            :text="$t('entities.achievements.title')"
            icon="mdi-trophy-award"
        />
        <v-card class="elevation-2">
            <DataTableHeader
                :pagination="pagination"
                :searchAttributes="searchAttributes"
                :selectedSearchAttributes.sync="selectedSearchAttributes"
                :disabled="loading"
            />
            <DataTableSkeletonLoader :loading="initialLoad"/>
            <v-data-table
                v-show="!initialLoad"
                :headers="headers"
                :items="entities"
                :dense="$vuetify.breakpoint.xs && !loading"
                :loading="loading"
                :loading-text="$t('components.noData.fetchingEntries')"
                :options.sync="options"
                :server-items-length="pagination.total"
                :items-per-page="pagination.per_page"
                :header-props="{ sortIcon: 'arrow_circle_up', sortByText: $t('miscellaneous.sortBy') }"
                :class="{ 'mobile-empty-datatable' : $vuetify.breakpoint.xsOnly }"
                hide-default-footer
            >
                <template v-slot:top>
                    <v-toolbar flat>
                        <v-toolbar-title>{{ $t('entities.achievementGroups.title') }}</v-toolbar-title>
                        <v-divider
                            class="mx-4"
                            inset
                            vertical
                        ></v-divider>
                        <small class="mr-2">{{ addThousandSeparator(pagination.total) }}</small>
                        <RefreshButton
                            :refresh="refresh"
                            :loading="loading"
                        />
                        <v-spacer/>
                        <v-dialog
                            v-model="viewDialog"
                            max-width="1000px"
                        >
                            <v-card>
                                <v-card-text class="px-0 pb-0">
                                    <v-toolbar
                                        class="elevation-2"
                                        color="primary"
                                    >
                                        <v-toolbar-title class="ml-0">
                                            <span class="font-weight-bold white--text">{{ $t('entities.achievementGroups.viewEntity') }}</span>
                                        </v-toolbar-title>
                                        <v-spacer/>
                                        <v-btn
                                            @click="viewDialog = false"
                                            icon
                                            dark
                                        >
                                            <v-icon>mdi-close</v-icon>
                                        </v-btn>
                                    </v-toolbar>
                                    <v-container>
                                        <v-card>
                                            <v-simple-table
                                                class="do-not-apply-hover"
                                                dense
                                            >
                                                <template v-slot:default>
                                                    <tbody>
                                                        <tr>
                                                            <td>{{ $t('generalAttributes.name') }}</td>
                                                            <td class="font-weight-bold">
                                                              {{ editedEntity.name }}
                                                            </td>
                                                            <td>{{ $t('entities.achievementGroups.attributes.active') }}</td>
                                                            <td class="font-weight-bold">
                                                                <Boolean :boolean="editedEntity.active"/>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colspan="4">
                                                                {{ $tc(`entities.achievements.context`, 2) }}
                                                            </td>
                                                        </tr>
                                                        <template v-for="(context, index) in editedEntity.contexts">
                                                            <tr :key="`context-${context.id}-0`">
                                                                <td colspan="4">
                                                                    {{ index + 1 }}.
                                                                </td>
                                                            </tr>
                                                            <tr :key="`context-${context.id}-1`">
                                                                <td
                                                                    colspan="1"
                                                                    class="pl-10"
                                                                >
                                                                    {{ $t(`entities.achievements.strategy`) }}
                                                                </td>
                                                                <td
                                                                    colspan="7"
                                                                    class="font-weight-bold text-capitalize"
                                                                >
                                                                    {{ $t(`entities.achievements.strategies.${snakeToCamelCase(context.strategy.toLowerCase())}`) }}
                                                                </td>
                                                            </tr>
                                                            <tr :key="`context-${context.id}-2`">
                                                                <td 
                                                                    colspan="8"
                                                                    class="pl-10"
                                                                >
                                                                    {{ $t(`entities.achievements.data`) }}
                                                                </td>
                                                            </tr>
                                                            <template v-for="(value, key) in context.data">
                                                                <tr :key="`context-${context.id}-${key}-3`">
                                                                    <td
                                                                        v-if="viewDialog"
                                                                        colspan="1"
                                                                        class="text-capitalize pl-14"
                                                                    >
                                                                        {{ snakeToTitleCase(key) }}
                                                                    </td>
                                                                    <td
                                                                        colspan="7"
                                                                        class="text-capitalize font-weight-bold"
                                                                    >
                                                                        {{ value }}
                                                                    </td>
                                                                </tr>
                                                            </template>
                                                        </template>
                                                    </tbody>
                                                </template>
                                            </v-simple-table>
                                            <template v-if="viewDialog">
                                                <v-data-table
                                                    :headers="achievementsHeaders"
                                                    :items="editedEntity.achievements"
                                                    :items-per-page="5"
                                                    :header-props="{ sortIcon: 'arrow_circle_up', sortByText: $t('miscellaneous.sortBy') }"
                                                    :no-data-text="$t('components.dataTableHeader.noDataAvailable')"
                                                    :footer-props="{ itemsPerPageText : $t('components.rowsPerPage.rows'), itemsPerPageAllText: $t('components.rowsPerPage.all') }"
                                                    class="do-not-apply-hover px-2"
                                                    dense
                                                >
                                                    <template v-slot:top>
                                                        <v-toolbar flat>
                                                            <v-toolbar-title>{{ $t('entities.achievements.title') }}</v-toolbar-title>
                                                            <v-divider
                                                                class="mx-4"
                                                                inset
                                                                vertical
                                                            ></v-divider>
                                                            <small>{{ editedEntity.achievements.length }}</small>
                                                            <v-spacer></v-spacer>
                                                        </v-toolbar>
                                                    </template>
                                                    <template v-slot:item.points="{ item }">
                                                        <PrimaryChip 
                                                            :text="item.points.toString()"
                                                            margin="ml-0"
                                                        />
                                                    </template>
                                                    <template v-slot:item.active="{ item }">
                                                        <Boolean :boolean="item.active"/>
                                                    </template>
                                                    <template v-slot:footer.page-text="items">
                                                        {{ items.pageStart }} - {{ items.pageStop }} {{ $t('components.pagination.of') }} {{ items.itemsLength }}
                                                    </template>
                                                </v-data-table>
                                            </template>
                                        </v-card>
                                    </v-container>
                                </v-card-text>
                                <v-divider/>
                                <v-card-actions>
                                    <v-spacer/>
                                    <v-btn
                                        @click="viewDialog = false"
                                        color="dark darken-1"
                                        text
                                    >
                                        {{ $t('miscellaneous.ok') }}
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                        <v-dialog
                            v-model="editDialog"
                            max-width="1000px"
                        >
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    v-on="on"
                                    color="primary"
                                    class="mb-2"
                                    dark
                                >
                                    {{ $t('miscellaneous.add') }}
                                </v-btn>
                            </template>
                            <v-card>
                                <v-card-text class="px-0 pb-0">
                                    <EditToolbar
                                        :formTitle="formTitle"
                                        :closeEditDialog="closeEditDialog"
                                    />
                                    <v-container>
                                        <v-card class="px-4 pb-4">
                                            <v-form
                                                @submit.prevent="save"
                                                method="POST"
                                                ref="form"
                                            >
                                                <RequiredFields/>
                                                <v-row
                                                    align="center"
                                                    class="mt-2"
                                                >
                                                    <v-col
                                                        cols="12"
                                                        sm="9"
                                                        class="position-relative"
                                                    >
                                                        <v-text-field
                                                            :value="editedEntity.name"
                                                            @change="v => (editedEntity.name = v)"
                                                            :rules="[required]"
                                                            :label="$t('generalAttributes.name')"
                                                            prepend-icon="mdi-format-title"
                                                        ></v-text-field>
                                                        <span class="required-fields">*</span>
                                                    </v-col>
                                                    <v-col
                                                        cols="12"
                                                        sm="3"
                                                        class="position-relative"
                                                    >
                                                        <v-switch
                                                            :input-value="editedEntity.active"
                                                            @change="v => (editedEntity.active = v)"
                                                            :label="$t('entities.achievementGroups.attributes.active')"
                                                            prepend-icon="done"
                                                            inset
                                                        ></v-switch>
                                                    </v-col>
                                                    <v-col
                                                        v-for="(value, index) in editedEntity.contexts"
                                                        :key="`context-${index}`"
                                                        cols="12"
                                                        class="py-0"
                                                    >
                                                        <v-row align="center">
                                                            <v-col
                                                                v-if="index === 0"
                                                                cols="12"
                                                                class="text-left"
                                                            >
                                                                <h3>
                                                                    {{ $tc('entities.achievements.context', 2) }}
                                                                </h3>
                                                            </v-col>
                                                        </v-row>
                                                        <v-card class="my-4 pa-4">
                                                            <v-row align="center">
                                                                <v-col
                                                                    cols="11"
                                                                    class="position-relative pb-1"
                                                                >
                                                                    <v-select
                                                                        :value="editedEntity.contexts[index].strategy"
                                                                        @change="editedEntity.contexts[index].strategy = $event"
                                                                        :items="strategies"
                                                                        :rules="[v => !!v || $t('validation.required')]"
                                                                        :menu-props="{ bottom: true, offsetY: true }"
                                                                        class="do-not-apply-transform"
                                                                        prepend-inner-icon="category"
                                                                        return-object
                                                                        solo
                                                                    >
                                                                        <template v-slot:label>
                                                                            {{ $t(`entities.achievements.strategy`) }}
                                                                            <span :style="{ color: 'red' }">*</span>
                                                                        </template>
                                                                        <template v-slot:selection="data">
                                                                            <span class="text-capitalize">{{ $t('entities.achievements.strategy') }}: {{ $t(`entities.achievements.strategies.${snakeToCamelCase(data.item.toLowerCase())}`) }}</span>
                                                                        </template>
                                                                        <template v-slot:item="data">
                                                                            <span class="text-capitalize">{{ $t(`entities.achievements.strategies.${snakeToCamelCase(data.item.toLowerCase())}`) }}</span>
                                                                        </template>
                                                                    </v-select>
                                                                </v-col>
                                                                <v-col
                                                                    cols="12"
                                                                    sm="1"
                                                                    class="mb-2 pl-0 pr-4"
                                                                >
                                                                    <v-btn
                                                                        @click="editedEntity.contexts.splice(index, 1)"
                                                                        color="error"
                                                                        rounded
                                                                    >
                                                                        X
                                                                    </v-btn>
                                                                </v-col>
                                                                <v-col
                                                                    v-if="editDialog"
                                                                    cols="12"
                                                                    class="position-relative"
                                                                >
                                                                    <v-textarea
                                                                        v-model="editedEntity.contexts[index].data"
                                                                        :label="$t('entities.achievements.data')"
                                                                        rows="1"
                                                                        clear-icon="mdi-close-circle"
                                                                        persistent-hint
                                                                        clearable
                                                                        auto-grow
                                                                        outlined
                                                                    ></v-textarea>
                                                                </v-col>
                                                            </v-row>
                                                        </v-card>
                                                    </v-col>
                                                    <v-col cols="12">
                                                        <v-row
                                                            justify="center"
                                                            align="center"
                                                        >
                                                            <v-col
                                                                cols="auto"
                                                                sm="12"
                                                                class="text-center"
                                                            >
                                                                <v-btn
                                                                    @click="addContext()"
                                                                    color="primary"
                                                                >
                                                                    {{ $t('entities.achievements.addContext') }}
                                                                </v-btn>
                                                            </v-col>
                                                        </v-row>
                                                    </v-col>
                                                </v-row>
                                                <ValidationErrors
                                                    v-if="validationErrors.length"
                                                    :errors="validationErrors"
                                                    class="mt-6"
                                                />
                                            </v-form>
                                        </v-card>
                                    </v-container>
                                </v-card-text>
                                <EditCardActions
                                    :save="save"
                                    :saving="saving"
                                    :closeEditDialog="closeEditDialog"
                                />
                            </v-card>
                        </v-dialog>
                        <v-dialog
                            v-model="deleteDialog"
                            max-width="1000px"
                        >
                            <v-card>
                                <v-card-text class="px-0 pb-0">
                                    <DeleteToolbar
                                        :text="$t('entities.achievementGroups.deleteEntity')"
                                        :closeDeleteDialog="closeDeleteDialog"
                                    />
                                    <v-container>
                                        <v-card>
                                            <v-simple-table
                                                class="do-not-apply-hover"
                                                dense
                                            >
                                                <template v-slot:default>
                                                    <tbody>
                                                        <tr>
                                                            <td>{{ $t('generalAttributes.name') }}</td>
                                                            <td class="font-weight-bold">
                                                                {{ toDeleteEntity.name }}
                                                            </td>
                                                            <td>{{ $t('entities.achievementGroups.attributes.active') }}</td>
                                                            <td class="font-weight-bold">
                                                                <Boolean :boolean="toDeleteEntity.active"/>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </template>
                                            </v-simple-table>
                                        </v-card>
                                        <ValidationErrors
                                            v-if="validationErrors.length"
                                            :errors="validationErrors"
                                            class="mt-6"
                                        />
                                    </v-container>
                                </v-card-text>
                                <DeleteCardActions
                                    :deleteEntity="deleteEntity"
                                    :deleting="deleting"
                                    :closeDeleteDialog="closeDeleteDialog"
                                />
                            </v-card>
                        </v-dialog>
                    </v-toolbar>
                </template>
                <template v-slot:item.active="{ item }">
                    <Boolean :boolean="item.active"/>
                </template>
                <template v-slot:item.action="{ item }">
                    <v-tooltip
                        color="transparent"
                        top
                    >
                        <template v-slot:activator="{ on }">
                            <v-btn
                                @click="openViewDialog(item)"
                                v-on="on"
                                :disabled="loadingView"
                                class="glow ml-2"
                                color="secondary"
                                depressed
                                x-small
                                fab
                            >
                                <template v-if="loadingView">
                                    <ProgressCircular color="secondary"/>
                                </template>
                                <template v-else>
                                    <v-icon
                                        class="white--text"
                                        small
                                    >
                                        pageview
                                    </v-icon>
                                </template>
                            </v-btn>
                        </template>
                        <v-card class="elevation-2 px-4 py-2">
                            <span>{{ $t('entities.achievementGroups.viewEntity') }}</span>
                        </v-card>
                    </v-tooltip>
                    <EditButton
                        :text="$t('entities.achievementGroups.editEntity')"
                        :item="item"
                        :openEditDialog="openEditDialog"
                    />
                    <DeleteButton
                        :text="$t('entities.achievementGroups.deleteEntity')"
                        :item="item"
                        :openDeleteDialog="openDeleteDialog"
                    />
                </template>
                <template v-slot:no-data>
                    <NoData
                        :pagination="pagination"
                        :typing="typing"
                        :fetchEntities="fetchEntities"
                    />
                </template>
            </v-data-table>
            <DataTableFooter
                :pagination="pagination"
                :loading="loading"
            />
        </v-card>
        <Snackbar
            :show.sync="snackbar.show"
            :color="snackbar.color"
            :message="snackbar.message"
        />
    </div>
</template>

<script>

import Vue from 'vue';
import Boolean from '@/components/Boolean.vue';
import PrimaryChip from '@/components/PrimaryChip.vue';
import NoData from '@/components/crud/NoData.vue';
import RequiredFields from '@/components/crud/RequiredFields.vue';
import DataTableHeader from '@/components/crud/DataTableHeader.vue';
import RefreshButton from '@/components/crud/RefreshButton.vue';
import GlobalButton from '@/components/GlobalButton.vue';
import ProgressCircular from '@/components/ProgressCircular.vue';
import EditButton from '@/components/crud/edit/EditButton.vue';
import EditToolbar from '@/components/crud/edit/EditToolbar.vue';
import EditCardActions from '@/components/crud/edit/EditCardActions.vue';
import DeleteButton from '@/components/crud/delete/DeleteButton.vue';
import DeleteToolbar from '@/components/crud/delete/DeleteToolbar.vue';
import DeleteCardActions from '@/components/crud/delete/DeleteCardActions.vue';
import DataTableSkeletonLoader from '@/components/crud/DataTableSkeletonLoader.vue';
import DataTableFooter from '@/components/crud/DataTableFooter.vue';
import AchievementGroup from '@/models/competitions/AchievementGroup';
import Achievement from '@/models/competitions/Achievement';
import ValidationErrors from '@/components/crud/ValidationErrors.vue';
import Snackbar from '@/components/Snackbar.vue';

import thousandSeparator from '@/mixins/thousand-separator';
import convertCase from '@/mixins/convert-case';
import required from '@/mixins/rules/required';
import { deleteEntity } from '@/mixins/crud/methods/delete-entity.js';
import { paginationWatch } from '@/mixins/crud/watch/pagination.js';
import { editDialog } from '@/mixins/crud/watch/edit-dialog.js';
import { axiosCatch } from '@/mixins/crud/methods/axios-catch.js';
import { showSnackbar } from "@/mixins/crud/methods/show-snackbar";

export default Vue.extend({
    components: {
        NoData,
        Boolean,
        ProgressCircular,
        PrimaryChip,
        DataTableHeader,
        ValidationErrors,
        RefreshButton,
        GlobalButton,
        RequiredFields,
        DataTableSkeletonLoader,
        DataTableFooter,
        EditToolbar,
        EditButton,
        EditCardActions,
        DeleteToolbar,
        DeleteButton,
        DeleteCardActions,
        Snackbar,
    },

    mixins: [
        deleteEntity,
        paginationWatch,
        editDialog,
        showSnackbar,
        axiosCatch,
        thousandSeparator,
        convertCase,
        required,
    ],

    data: () => ({
        initialLoad: true,
        loading: false,
        loadingView: false,
        saving: false,
        deleting: false,
        typing: false,
        apiURI: process.env.VUE_APP_ACHIEVEMENTS_BASE_URI,
        route: 'achievement-groups',
        entity: 'achievementGroups',
        entities: [],
        strategies: [
            'COLLECT',
            'COMPARE',
            'COLLECT_DISTINCT',
            'COMPARE_DAYS',
            'EQUAL',
        ],
        selectedSearchAttributes: ['Name'],
        validationErrors: [],
        pagination: {
            search: '',
            current_page: 1,
            from: 1,
            last_page: 0,
            path: '',
            per_page: 10,
            to: 0,
            total: 0
        },
        options: {
            page: 1,
            itemsPerPage: 10,
            sortBy: ['id'],
            sortDesc: ['DESC'],
            groupBy: [],
            groupDesc: [],
            mustSort: false,
            multiSort: false
        },
        viewDialog: false,
        editDialog: false,
        deleteDialog: false,
        editedIndex: -1,
        defaultEntity: {
            id: '',
            name: '',
            active: false,
            contexts: [],
            achievements: [],
        },
        editedEntity: {
            id: '',
            name: '',
            active: false,
            contexts: [],
            achievements: [],
        },
        toDeleteEntity: {
            id: '',
            name: '',
            active: false,
            contexts: [],
            achievements: [],
        },
        snackbar: {
            show: false,
            color: '',
            message: '',
        }
    }),

    computed: {
        headers() {
            return [
                { text: this.$t('generalAttributes.name'), align: 'left', value: 'name' },
                { text: this.$t('entities.achievementGroups.attributes.active'), align: 'left', value: 'active' },
                { text: this.$t('miscellaneous.actions'), value: 'action', sortable: false }
            ];
        },
        achievementsHeaders() {
            return [
                { text: this.$t('generalAttributes.name'), align: 'left', value: 'name' },
                { text: this.$t('entities.achievements.attributes.task'), align: 'left', value: 'task' },
                { text: this.$tc('entities.achievements.attributes.points', 2), align: 'left', value: 'points' },
                { text: this.$t('entities.achievements.attributes.active'), align: 'left', value: 'active' },
            ];
        },
        searchAttributes() {
            return [
                { label: this.$t('generalAttributes.name'), value: 'Name' },
            ]
        },
        formTitle() {
            return this.editDialog ? (this.$t(`entities.${this.entity}.${this.editedIndex === -1 ? 'new' : 'edit'}Entity`)) : '';
        },
    },

    watch: {
        viewDialog(val) {
            if (!val) {
                this.editedIndex = -1;
                this.editedEntity = {...this.defaultEntity};
                this.editedEntity.achievements = [];
            }
        },
        deleteDialog(val) {
            if (!val) {
                this.validationErrors = [];
            }
        }
    },

    mounted() {
        this.refresh();
    },

    methods: {
        async fetchEntities(append = `&size=${this.pagination.per_page}&page=${this.pagination.current_page}&sort=id,DESC`) {
            try {
                this.loading = true;
                const response = await AchievementGroup.api.get(append);
                const data = response.data;
                this.entities = data.content.map(AchievementGroupDTO => new AchievementGroup(AchievementGroupDTO));
                this.pagination.current_page = data.pageable.page_number + 1;
                this.pagination.per_page = data.pageable.page_size;
                this.pagination.total = data.total_elements;
                this.pagination.last_page = data.total_pages;
                this.pagination.from = data.pageable.offset + 1;
                this.pagination.to = this.pagination.from + data.number_of_elements - 1;
            } catch (e) {
                console.warn('Achievement Groups API failed.');
                console.log(e);
            } finally {
                this.initialLoad = false;
                this.loading = false;
            }
        },
        async save() {
            try {
                this.saving = true;

                if (!this.$refs.form.validate()) {
                    return;
                }
                
                const data = {
                    name: this.editedEntity.name,
                    active: this.editedEntity.active ? 1 : 0,
                };

                data.contexts = this.editedEntity.contexts.map(function(context) {
                    const contextData = {
                        data: JSON.parse(context.data),
                        strategy: context.strategy,
                    };
                    if (context.id) {
                        contextData.id = context.id;
                    }
                    return contextData;
                });

                if (this.editedIndex > -1) {
                    await AchievementGroup.api.update(this.editedEntity.id, data)
                        .then(() => {
                            if (this.pagination.search.length) {
                                this.pagination.search = '';
                            } else {
                                this.fetchEntities();
                            }
                            this.showSnackbar('success', this.$t('entities.achievementGroups.notifications.update'));
                            this.closeEditDialog();
                        })
                        .catch(error => {
                            this.axiosCatch(error);
                        });
                } else {
                    await AchievementGroup.api.create(data)
                        .then(() => {
                            if (this.pagination.search.length) {
                                this.pagination.search = '';
                            } else {
                                this.fetchEntities();
                            }
                            this.showSnackbar('success', this.$t('entities.achievementGroups.notifications.creation'));
                            this.closeEditDialog();
                        }).catch(error => {
                            this.axiosCatch(error);
                        });
                }
            } catch (error) {
                console.warn(`Achievement Group Save API failed.`);
                console.log(error);
            } finally {
                this.saving = false;
            }
        },
        async openViewDialog(entity) {
            this.loadingView = true;
            this.editedIndex = this.entities.indexOf(entity);
            this.editedEntity = {...entity};
            await this.fetchGroupAchievements(entity.id);
            this.viewDialog = true;
            this.loadingView = false;
        },
        async fetchGroupAchievements(groupId) {
            try {
                const response = await Achievement.api.get(`size=100&groupId=${groupId}`);
                this.$set(this.editedEntity, 'achievements', response.data.content.map(AchievementDTO => new Achievement(AchievementDTO)));
            } catch (e) {
                console.warn(`Group Achievements API failed.`);
                console.log(e);
            }
        },
        openEditDialog(entity) {
            this.editedIndex = this.entities.indexOf(entity);
            this.editedEntity = JSON.parse(JSON.stringify(entity));
            if (entity.contexts.length) {
                for (const i in entity.contexts) {
                    this.editedEntity.contexts[i].data = JSON.stringify(this.editedEntity.contexts[i].data, undefined, 4);
                }
            }
            if (this.$refs.form) {
                this.$refs.form.resetValidation();
            }
            this.editDialog = true;
        },
        closeEditDialog() {
            this.editedEntity = JSON.parse(JSON.stringify(this.defaultEntity));
            this.editedIndex = -1;
            this.editDialog = false;
        },
        openDeleteDialog(entity) {
            this.editedIndex = this.entities.indexOf(entity);
            this.toDeleteEntity = {...entity};
            this.deleteDialog = true;
        },
        closeDeleteDialog() {
            this.deleteDialog = false;
            this.toDeleteEntity = {...this.defaultEntity};
            this.editedIndex = -1;
        },
        addContext() {
            this.editedEntity.contexts.push({
                strategy: 'COLLECT',
                data: JSON.stringify({}, undefined, 4)
            });
        },
        refresh() {
            this.fetchEntities();
        }
    }
});

</script>