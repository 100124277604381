

import Vue from 'vue';
import Logo from './components/Logo.vue';
import ProfileSection from './components/ProfileSection.vue';
import ToolbarTitle from './components/ToolbarTitle.vue';

export default Vue.extend({
    components: {
        Logo,
        ToolbarTitle,
        ProfileSection,
    },

    props: ['drawer', 'currentRouteName']
})
