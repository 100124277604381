<template>
    <div>
        <GlobalButton
            :route="'/quest-types'"
            :text="$t('entities.questTypes.title')"
            icon="category"
        />
        <v-card class="elevation-2">
            <DataTableHeader
                :pagination="pagination"
                :searchAttributes="searchAttributes"
                :selectedSearchAttributes.sync="selectedSearchAttributes"
                :disabled="loading"
            />
            <DataTableSkeletonLoader :loading="initialLoad"/>
            <v-data-table
                v-show="!initialLoad"
                :headers="headers"
                :items="entities"
                :dense="$vuetify.breakpoint.xs && !loading"
                :loading="loading"
                :loading-text="$t('components.noData.fetchingEntries')"
                :options.sync="options"
                :server-items-length="pagination.total"
                :items-per-page="pagination.per_page"
                :header-props="{ sortIcon: 'arrow_circle_up', sortByText: $t('miscellaneous.sortBy') }"
                :class="{ 'mobile-empty-datatable' : $vuetify.breakpoint.xsOnly }"
                hide-default-footer
            >
                <template v-slot:top>
                    <v-toolbar flat>
                        <v-toolbar-title>{{ $t('entities.quests.title') }}</v-toolbar-title>
                        <v-divider
                            class="mx-4"
                            inset
                            vertical
                        ></v-divider>
                        <small class="mr-2">{{ pagination.total }}</small>
                        <RefreshButton
                            :refresh="refresh"
                            :loading="loading"
                        />
                        <v-spacer/>
                        <v-btn
                            @click="save"
                            :disabled="changed || saving"
                            :loading="saving"
                            color="primary"
                            class="mb-2"
                        >
                            {{ saving ? $t('miscellaneous.saving') : $t('miscellaneous.save') }}
                        </v-btn>
                    </v-toolbar>
                </template>
                <template v-slot:item.active="{ item }">
                    <v-switch
                        :input-value="item.active"
                        @change="v => (item.active = v)"
                        class="my-0"
                        style="position: relative; top: 10px;"
                    ></v-switch>
                </template>
                <template v-slot:item.pool_type="{ item }">
                    <v-chip
                        color="primary"
                        class="font-weight-bold mr-2"
                        text-color="white"
                        small
                    >
                        {{ $t(`entities.quests.${item.pool_type.toLowerCase()}`) }}
                    </v-chip>
                </template>
                <template v-slot:no-data>
                    <NoData
                        :pagination="pagination"
                        :typing="typing"
                        :fetchEntities="fetchEntities"
                    />
                </template>
            </v-data-table>
            <DataTableFooter
                :pagination="pagination"
                :loading="loading"
            />
        </v-card>
        <Snackbar
            :show.sync="snackbar.show"
            :color="snackbar.color"
            :message="snackbar.message"
        />
    </div>
</template>

<script>

import Vue from 'vue';
import NoData from '@/components/crud/NoData.vue';
import GlobalButton from '@/components/GlobalButton.vue';
import RefreshButton from '@/components/crud/RefreshButton.vue';
import DataTableHeader from '@/components/crud/DataTableHeader.vue';
import DataTableSkeletonLoader from '@/components/crud/DataTableSkeletonLoader.vue';
import DataTableFooter from '@/components/crud/DataTableFooter.vue';
import Snackbar from '@/components/Snackbar.vue';
import Quest from '@/models/competitions/Quest';

import { axiosCatch } from '@/mixins/crud/methods/axios-catch.js';
import { showSnackbar } from "@/mixins/crud/methods/show-snackbar";
import { paginationWatch } from '@/mixins/crud/watch/pagination.js';

export default Vue.extend({
    components: {
        NoData,
        RefreshButton,
        GlobalButton,
        DataTableHeader,
        DataTableSkeletonLoader,
        DataTableFooter,
        Snackbar,
    },

    mixins: [
        axiosCatch,
        showSnackbar,
        paginationWatch,
    ],

    data: () => ({
        initialLoad: true,
        loading: false,
        saving: false,
        typing: false,
        apiURI: process.env.VUE_APP_ACHIEVEMENTS_BASE_URI,
        entities: [],
        selectedSearchAttributes: ['Name'],
        pagination: {
            search: '',
            current_page: 1,
            from: 1,
            last_page: 0,
            path: '',
            per_page: 10,
            to: 0,
            total: 0
        },
        options: {
            page: 1,
            itemsPerPage: 10,
            sortBy: ['poolType'],
            sortDesc: [],
            groupBy: [],
            groupDesc: [],
            mustSort: false,
            multiSort: false
        },
    }),

    computed: {
        headers() {
            return [
                { text: this.$t('generalAttributes.name'), align: 'left', value: 'name' },
                { text: this.$t('entities.quests.attributes.active'), align: 'left', value: 'active' },
                { text: this.$t('entities.quests.attributes.pool'), align: 'left', value: 'pool_type' },
                { text: this.$t('entities.achievements.attributes.task'), align: 'left', value: 'task' },
            ];
        },
        searchAttributes() {
            return [
                { label: this.$t('generalAttributes.name'), value: 'Name' },
                { label: this.$t('entities.achievements.attributes.task'), value: 'Task' },
            ]
        },
        changed() {
            return !this.entities.some(qp => qp.active !== qp.original_active);
        }
    },

    mounted() {
        this.refresh();
    },

    methods: {
        async fetchEntities() {
            try {
                const append = this.setParams();
                this.loading = true;
                const response = await Quest.api.get(append);
                const data = response.data;
                this.entities = data.content.map(QuestDTO => {
                    const quest = new Quest(QuestDTO)
                    quest.original_active = quest.active;
                    return quest;
                });
                this.pagination.current_page = data.pageable.page_number + 1;
                this.pagination.per_page = data.pageable.page_size;
                this.pagination.total = data.total_elements;
                this.pagination.last_page = data.total_pages;
                this.pagination.from = data.pageable.offset + 1;
                this.pagination.to = this.pagination.from + data.number_of_elements - 1;
            } catch (e) {
                console.warn(`Quests API failed.`);
                console.log(e);
            } finally {
                this.initialLoad = false;
                this.loading = false;
            }
        },
        async save() {
            try {
                this.saving = true;

                const data = this.entities.filter(qp => qp.active !== qp.original_active)
                    .map(qp => ({
                        id: qp.id,
                        active: qp.active
                    }));

                await Quest.api.updateActive(data)
                    .then(() => {
                        if (this.pagination.search.length) {
                            this.pagination.search = '';
                        } else {
                            this.fetchEntities();
                        }
                        this.showSnackbar('success', this.$t('entities.quests.notifications.active'));
                    }).catch(error => {
                        this.showSnackbar('error', this.$t('error', { message: error.response.status }));
                    });
            } catch (error) {
                console.warn(`Quest Save API failed.`);
                console.log(error);
            } finally {
                this.saving = false;
            }
        },
        refresh() {
            this.fetchEntities();
        }
    }
});

</script>