import ArenaGamesApi from '@/services/configuration/ArenaGamesApi';

export interface ArenaGameInterface {
    id: string;
    name: string;
    active: boolean;
    args: string;
    background: string;
    description: string;
    display_order: bigint;
    genres: object[];
    icon: string;
    image: string;
    launchers: object[];
    path: string;
    procs: object[];
    tags: object[];
    video: string;
}

export class ArenaGameDTO implements ArenaGameInterface {
    id = '';
    name = '';
    active = false;
    args = '';
    background = '';
    description = '';
    display_order = 1000n;
    genres = [];
    icon = '';
    image = '';
    launchers = [];
    path = '';
    procs = [];
    tags = [];
    video = '';

}

export default class ArenaGame extends ArenaGameDTO {
    static api = ArenaGamesApi;

    constructor(DTO: ArenaGameDTO){
        super();
        Object.assign(this, DTO);
    }
}