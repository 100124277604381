<template>
    <div>
        <GlobalButton
            :route="'/transactions'"
            :text="$t('entities.invoices.transactions')"
            icon="receipt"
        />
        <v-card class="elevation-2">
            <InvoicesDataTable
                :initialLoad.sync="initialLoad"
                :loading.sync="loading"
                :title="$t('entities.invoices.title')"
            />
        </v-card>
    </div>
</template>

<script>


import Vue from 'vue';
import GlobalButton from '@/components/GlobalButton.vue';
import InvoicesDataTable from '@/components/invoices/InvoicesDataTable';

export default Vue.extend({
    components: {
        GlobalButton,
        InvoicesDataTable,
    },

    data: () => ({
        initialLoad: true,
        loading: false,
    }),
});

</script>


<style>

.position-relative {
    position: relative;
}

.required-fields {
    position: absolute;
    right: 13px;
    top: 15px;
    color: red;
}

</style>
