import InvoicesApi from '@/services/shop/InvoicesApi';

export interface InvoiceInterface {
    id: bigint;
    label: string;
    number: number;
    gross: number;
    net: number;
    arena: string;
    created_at: string;
    fiscalized: boolean;
    payment_method: string;
}

export class InvoiceDTO implements InvoiceInterface {
    id = 0n;
    label = '';
    number = 0;
    gross = 0;
    net = 0;
    arena = '';
    created_at = '';
    fiscalized = false;
    payment_method = '';
}

export default class Invoice extends InvoiceDTO {
    static api = InvoicesApi;

    constructor(DTO: InvoiceDTO){
        super();
        Object.assign(this, DTO);
    }
}