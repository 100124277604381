import AchievementGroupsApi from '@/services/competitions/AchievementGroupsApi';

export interface AchievementGroupInterface {
    id: bigint;
    name: string;
    active: boolean;
}

export class AchievementGroupDTO implements AchievementGroupInterface {
    id = 0n;
    name = '';
    active = false;
}

export default class AchievementGroup extends AchievementGroupDTO {
    static api = AchievementGroupsApi;

    constructor(DTO: AchievementGroupDTO){
        super();
        Object.assign(this, DTO);
    }
}