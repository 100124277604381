export default {
    'primary': '#795548',
    'secondary': '#826d6d',
    'accent': '#4caf50',
    'error': '#ff5722',
    'warning': '#ff9800',
    'info': '#745151',
    'success': '#8bc34a',
    'first-gradient-level': '#795548',
    'second-gradient-level': '#5e3f33',
    'third-gradient-level': '#44231a',
    'navigation-drawer-active-list-item': '#44231a',
    'navigation-drawer-active-group': '#8a4f3f',
    'main-background': '#f0f2f6',
    'primary-text': '#9e9e9e',
    'dark-text': '#000000',
};