export interface UserSettingInterface {
    namespace: string;
    key: string;
    value: string;
}

export class UserSettingDTO implements UserSettingInterface {
    namespace = '';
    key = '';
    value = '';
}

export default class UserSetting extends UserSettingDTO {
    constructor(DTO: UserSettingDTO){
        super();
        Object.assign(this, DTO);
    }
}
