import QuestGroupsApi from '@/services/competitions/QuestGroupsApi';

export interface QuestGroupInterface {
    id: bigint;
    name: string;
    active: boolean;
}

export class QuestGroupDTO implements QuestGroupInterface {
    id = 0n;
    name = '';
    active = false;
}

export default class QuestGroup extends QuestGroupDTO {
    static api = QuestGroupsApi;

    constructor(DTO: QuestGroupDTO){
        super();
        Object.assign(this, DTO);
    }
}