export interface InventoryItemInterface {
    id: bigint;
}

export class InventoryItemDTO implements InventoryItemInterface {
    id = 0n;
}

export default class InventoryItem extends InventoryItemDTO {
    constructor(DTO: InventoryItemDTO){
        super();
        Object.assign(this, DTO);
    }
}
