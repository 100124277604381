

import Vue from 'vue';

import convertCase from '@/mixins/convert-case';
import isRole from '@/mixins/crud/computed/isRole';

export default Vue.extend({
    props: ['drawer', 'loggedIn'],

    mixins: [
        convertCase,
        isRole,
    ],

    data: () => ({
        appVersion: process.env.VUE_APP_VERSION,
        localDrawer: null,
        activeGroup: '',
    }),

    computed: {
        groups() {
            // Initialize routes available for everyone
            const mainRoutes = [
                {
                    icon: 'mdi-account-details',
                    key: 'employeeDashboard',
                    route: '/employee-dashboard'
                },
                {
                    icon: 'games',
                    key: 'playingNow',
                    route: '/playing-now'
                },
                {
                    icon: 'mdi-gamepad-square',
                    key: 'sessions',
                    route: '/sessions'
                },
            ];

            const userRoutes = [
                {
                    icon: 'people',
                    key: 'users',
                    route: '/users'
                },
            ];

            const shopRoutes = [
                {
                    icon: 'mdi-cash',
                    key: 'purchase',
                    route: '/purchase'
                },
                {
                    icon: 'mdi-shape-outline',
                    key: 'items',
                    route: '/items'
                },
                {
                    icon: 'receipt',
                    key: 'invoices',
                    route: '/invoices'
                },
                {
                    icon: 'shopping_cart',
                    key: 'carts',
                    route: '/carts'
                },
                {
                    icon: 'book',
                    key: 'orders',
                    route: '/orders'
                }
            ];

            // Push ranks, roles, settings, subscriptions, avatars & shop categories for admins & managers
            if (this.isManager || this.isAdmin) {
                userRoutes.push(
                    {
                        icon: 'mdi-chevron-triple-up',
                        key: 'ranks',
                        route: '/ranks'
                    },
                    {
                        icon: 'mdi-account-circle',
                        key: 'roles',
                        route: '/roles'
                    },
                    {
                        icon: 'settings',
                        key: 'settings',
                        route: '/settings'
                    },
                    {
                        icon: 'face',
                        key: 'avatars',
                        route: '/avatars'
                    },
                    {
                        icon: 'mdi-store-check',
                        key: 'subscriptions',
                        route: '/subscriptions'
                    },
                );
                shopRoutes.splice(
                    1, 0, {
                        icon: 'category',
                        key: 'categories',
                        route: '/categories'
                    },
                )
            }

            const configurationRoutes = [
                {
                    icon: 'games',
                    key: 'games',
                    route: '/games'
                },
                {
                    icon: 'apps',
                    key: 'apps',
                    route: '/apps'
                },
               
            ];

            const socialRoutes = [
                {
                    icon: 'mdi-post',
                    key: 'articles',
                    route: '/articles'
                },
                {
                    icon: 'event',
                    key: 'events',
                    route: '/events'
                },
            ];

            const dataRoutes = [
                {
                    icon: 'mdi-finance',
                    key: 'finance',
                    route: '/finance'
                },
            ];

            // Add admin dashboard, inventory item types, mobile notifications & analytics only for admins & managers
            if (this.isAdmin || this.isManager) {
                mainRoutes.unshift({
                    icon: 'dashboard',
                    key: 'adminDashboard',
                    route: '/admin-dashboard'
                });
                configurationRoutes.push(
                    {
                        icon: 'inventory',
                        key: 'inventoryItemTypes',
                        route: '/inventory-item-types'
                    },
                );
                configurationRoutes.push(
                    {
                        icon: 'mdi-sale',
                        key: 'maxBonuses',
                        route: '/max-bonuses'
                    },
                );
                socialRoutes.push({
                    icon: 'mdi-bell',
                    key: 'mobileNotifications',
                    route: '/mobile-notifications'
                });
                dataRoutes.push({
                    icon: 'analytics',
                    key: 'analytics',
                    route: '/analytics'
                });
            }

            const grouped = [
                {
                    key: 'main',
                    icon: 'house',
                    items: mainRoutes
                },
                {
                    key: 'users',
                    icon: 'people',
                    items: userRoutes
                },
                {
                    key: 'shop',
                    icon: 'attach_money',
                    items: shopRoutes
                },
                {
                    key: 'newsManager',
                    icon: 'library_books',
                    items: socialRoutes
                },
                {
                    key: 'data',
                    icon: 'mdi-chart-donut',
                    items: dataRoutes,
                },
                {
                    key: 'configuration',
                    icon: 'settings',
                    items: configurationRoutes
                },
            ];

            // Add competitions & setup for admins
            if (this.isAdmin) {
                grouped.push(
                    {
                        key: 'competitions',
                        icon: 'mdi-trophy-variant-outline',
                        items: [
                            {
                                icon: 'mdi-trophy-award',
                                key: 'achievements',
                                route: '/achievements'
                            },
                            {
                                icon: 'mdi-trophy',
                                key: 'quests',
                                route: '/quests'
                            },
                            {
                                icon: 'mdi-family-tree',
                                key: 'skills',
                                route: '/skills'
                            },
                        ]
                    },
                    {
                        key: 'setup',
                        icon: 'mdi-database-settings',
                        items: [
                            {
                                icon: 'location_on',
                                key: 'arenas',
                                route: '/arenas'
                            },
                            {
                                icon: 'business',
                                key: 'companies',
                                route: '/companies'
                            },
                            {
                                icon: 'mdi-image-filter-hdr',
                                key: 'countries',
                                route: '/countries'
                            },
                        ]
                    },
                );
            }

            return grouped;
        },

        arenaName() {
            return this.$store.getters.arena ? `/${this.titleToKebabCase(this.$store.getters.arena.name)}` : '';
        }
    },

    watch: {
        $route: function (to) {
            this.setGroup(to.path);
        },
        drawer: function() {
            this.localDrawer = this.drawer;
        },
        localDrawer: function() {
            this.$emit('update:drawer', this.localDrawer);
        }
    },

    mounted() {
        this.setGroup(this.$route.path);
    },

    methods: {
        setGroup(path = '') {
            if (this.arenaName.length) {
                path = path.replace(this.arenaName, '');
            }
            for (const group in this.groups) {
                for (const item in this.groups[group].items) {
                    if (this.groups[group].items[item].route === path) {
                        this.activeGroup = this.groups[group].key;
                        return;
                    }
                }
            }
        }
    }
})
