<template>
    <div>
        <GlobalButton
            :route="'/achievement-groups'"
            :text="$t('entities.achievementGroups.title')"
            icon="category"
        />
        <v-card class="elevation-2">
            <DataTableHeader
                :pagination="pagination"
                :searchAttributes="searchAttributes"
                :selectedSearchAttributes.sync="selectedSearchAttributes"
                :disabled="loading"
            />
            <DataTableSkeletonLoader :loading="initialLoad"/>
            <v-data-table
                v-show="!initialLoad"
                :headers="headers"
                :items="entities"
                :dense="$vuetify.breakpoint.xs && !loading"
                :loading="loading"
                :loading-text="$t('components.noData.fetchingEntries')"
                :options.sync="options"
                :server-items-length="pagination.total"
                :items-per-page="pagination.per_page"
                :header-props="{ sortIcon: 'arrow_circle_up', sortByText: $t('miscellaneous.sortBy') }"
                :class="{ 'mobile-empty-datatable' : $vuetify.breakpoint.xsOnly }"
                hide-default-footer
            >
                <template v-slot:top>
                    <v-toolbar flat>
                        <v-toolbar-title>{{ $t('entities.achievements.title') }}</v-toolbar-title>
                        <v-divider
                            class="mx-4"
                            inset
                            vertical
                        ></v-divider>
                        <small class="mr-2">{{ pagination.total }}</small>
                        <RefreshButton
                            :refresh="refresh"
                            :loading="loading"
                        />
                        <v-spacer></v-spacer>
                        <v-dialog
                            v-model="viewDialog"
                            max-width="1000px"
                        >
                            <v-card>
                                <v-card-text class="px-0 pb-0">
                                    <v-toolbar
                                        class="elevation-2"
                                        color="primary"
                                    >
                                        <v-toolbar-title class="ml-0">
                                            <span class="font-weight-bold white--text">{{ $t('entities.achievements.viewEntity') }}</span>
                                        </v-toolbar-title>
                                        <v-spacer/>
                                        <v-btn
                                            @click="viewDialog = false"
                                            icon
                                            dark
                                        >
                                            <v-icon>mdi-close</v-icon>
                                        </v-btn>
                                    </v-toolbar>
                                    <v-container>
                                        <DialogImage
                                            :image="editedEntity.image"
                                            :name="editedEntity.name"
                                        />
                                        <v-card>
                                            <v-simple-table
                                                class="do-not-apply-hover"
                                                dense
                                            >
                                                <template v-slot:default>
                                                    <tbody>
                                                        <tr>
                                                            <td>{{ $t('generalAttributes.name') }}</td>
                                                            <td class="font-weight-bold">
                                                              {{ editedEntity.name }}
                                                            </td>
                                                            <td>{{ $t('entities.achievements.attributes.active') }}</td>
                                                            <td class="font-weight-bold">
                                                                <Boolean :boolean="editedEntity.active"/>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>{{ $t('entities.achievements.attributes.task') }}</td>
                                                            <td class="font-weight-bold">
                                                                {{ editedEntity.task }}
                                                            </td>
                                                            <td>{{ $tc('entities.achievements.attributes.points', editedEntity.points) }}</td>
                                                            <td class="font-weight-bold text-capitalize">
                                                                <PrimaryChip 
                                                                    v-if="viewDialog"
                                                                    :text="editedEntity.points.toString()"
                                                                    margin="ml-0"
                                                                />
                                                            </td>
                                                        </tr>
                                                        <template v-if="editedEntity.group">
                                                            <tr>
                                                                <td
                                                                    colspan="8"
                                                                    class="do-not-apply-hover mx-0 px-0"
                                                                >
                                                                    <v-toolbar
                                                                        class="elevation-2"
                                                                        color="secondary"
                                                                        dense
                                                                    >
                                                                        <v-toolbar-title class="ml-0">
                                                                            <small class="font-weight-bold white--text">
                                                                                {{ $t(`entities.achievements.group`) }}
                                                                            </small>
                                                                        </v-toolbar-title>
                                                                    </v-toolbar>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>{{ $t('generalAttributes.name') }}</td>
                                                                <td class="font-weight-bold">
                                                                {{ editedEntity.group.name }}
                                                                </td>
                                                                <td>{{ $t('entities.achievements.attributes.active') }}</td>
                                                                <td class="font-weight-bold">
                                                                    <Boolean :boolean="editedEntity.group.active"/>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td colspan="8">
                                                                    {{ $tc(`entities.achievements.context`, 2) }}
                                                                </td>
                                                            </tr>
                                                            <template v-for="(context, index) in editedEntity.group.contexts">
                                                                <tr :key="`context-${context.id}-0`">
                                                                    <td colspan="8">
                                                                        {{ index + 1 }}.
                                                                    </td>
                                                                </tr>
                                                                <tr :key="`context-${context.id}-1`">
                                                                    <td
                                                                        colspan="1"
                                                                        class="pl-10"
                                                                    >
                                                                        {{ $t(`entities.achievements.strategy`) }}
                                                                    </td>
                                                                    <td
                                                                        colspan="7"
                                                                        class="font-weight-bold text-capitalize"
                                                                    >
                                                                        {{ $t(`entities.achievements.strategies.${snakeToCamelCase(context.strategy.toLowerCase())}`) }}
                                                                    </td>
                                                                </tr>
                                                                <tr :key="`context-${context.id}-2`">
                                                                    <td 
                                                                        colspan="8"
                                                                        class="pl-10"
                                                                    >
                                                                        {{ $t(`entities.achievements.data`) }}
                                                                    </td>
                                                                </tr>
                                                                <template v-for="(value, key) in context.data">
                                                                    <tr :key="`context-${context.id}-${key}-3`">
                                                                        <td
                                                                            v-if="viewDialog"
                                                                            colspan="1"
                                                                            class="text-capitalize pl-14"
                                                                        >
                                                                            {{ snakeToTitleCase(key) }}
                                                                        </td>
                                                                        <td
                                                                            colspan="7"
                                                                            class="text-capitalize font-weight-bold"
                                                                        >
                                                                            {{ value }}
                                                                        </td>
                                                                    </tr>
                                                                </template>
                                                            </template>
                                                        </template>
                                                    </tbody>
                                                </template>
                                            </v-simple-table>
                                            <AchievableConfiguration
                                                :viewDialog="viewDialog"
                                                :settings="editedEntity.configuration.settings"
                                            />
                                            <v-row class="mt-6 px-4">
                                                <v-col
                                                    cols="12"
                                                    sm="4"
                                                    class="text-center py-0"
                                                >
                                                    <RowsPerPage :pagination="usersPagination"/>
                                                </v-col>
                                                <v-col
                                                    cols="12"
                                                    sm="4"
                                                    class="text-center py-0"
                                                >
                                                    <v-text-field
                                                        v-model="usersPagination.search"
                                                        @click:append="() => (usersPagination.search = '')"
                                                        @keydown.native.escape="usersPagination.search = ''"
                                                        :label="userSelectedSearchAttributes.length ? $t('components.dataTableHeader.search') : $t('components.dataTableHeader.searchDisabled')"
                                                        :disabled="!userSelectedSearchAttributes.length"
                                                        :append-icon="usersPagination.search ? 'close': ''"
                                                        prepend-inner-icon="search"
                                                        class="search mb-1"
                                                        hide-details
                                                        solo
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col
                                                    cols="12"
                                                    sm="4"
                                                    class="text-center py-0"
                                                >
                                                    <v-select
                                                        @change="userSelectedSearchAttributes = $event"
                                                        :items="userSearchAttributes"
                                                        :value="userSelectedSearchAttributes"
                                                        :label="$t('components.dataTableHeader.searchBy')"
                                                        :menu-props="{ bottom: true, offsetY: true }"
                                                        :no-data-text="$t('components.dataTableHeader.noDataAvailable')"
                                                        item-text="label"
                                                        item-value="value"
                                                        multiple
                                                        solo
                                                    >
                                                        <template v-slot:selection="data">
                                                            <v-chip
                                                                color="primary"
                                                                small
                                                            >
                                                                {{ data.item.label }}
                                                            </v-chip>
                                                        </template>
                                                    </v-select>
                                                </v-col>
                                            </v-row>
                                            <v-data-table
                                                :headers="achievedHeaders"
                                                :items="editedEntity.users"
                                                :options.sync="usersPagination"
                                                :server-items-length="usersPagination.total"
                                                :items-per-page="usersPagination.per_page"
                                                :header-props="{ sortIcon: 'arrow_circle_up', sortByText: $t('miscellaneous.sortBy') }"
                                                :no-data-text="$t('components.dataTableHeader.noDataAvailable')"
                                                :footer-props="{ itemsPerPageText : $t('components.rowsPerPage.rows'), itemsPerPageAllText: $t('components.rowsPerPage.all') }"
                                                class="achievements-users-table do-not-apply-hover px-2"
                                                hide-default-footer
                                                dense
                                            >
                                                <template v-slot:top>
                                                    <v-toolbar flat>
                                                        <v-toolbar-title>{{ $t('entities.users.title') }}</v-toolbar-title>
                                                        <v-divider
                                                            class="mx-4"
                                                            inset
                                                            vertical
                                                        ></v-divider>
                                                        <small>{{ usersPagination.total }}</small>
                                                        <v-spacer></v-spacer>
                                                    </v-toolbar>
                                                </template>
                                                <template v-slot:item.username="{ item }">
                                                    {{ item.username || '/' }}
                                                </template>
                                                <template v-slot:item.created_at="{ item }">
                                                    <span v-if="item.created_at">
                                                        {{ fromUtcDateTimeToCurrent(item.created_at, true, true) }}
                                                    </span>
                                                    <span v-else>
                                                        /
                                                    </span>
                                                </template>
                                                <template v-slot:item.progress.value="{ item }">
                                                    <span>{{ Math.round(item.progress.value * 100) / 100 }}%</span>
                                                </template>
                                                <template v-slot:item.progress.updated_at="{ item }">
                                                    <span v-if="item.progress.updated_at">
                                                        {{ fromUtcDateTimeToCurrent(item.progress.updated_at, true, true) }}
                                                    </span>
                                                    <span v-else>
                                                        /
                                                    </span>
                                                </template>
                                                <template v-slot:no-data>
                                                    <template v-if="usersPagination.search.length && searchingUser">
                                                        {{ $t('entities.users.fetching') }}
                                                    </template>
                                                    <template v-else-if="usersPagination.search.length && !editedEntity.users.length">
                                                        {{ $t('components.noData.noEntriesFound') }}
                                                    </template>
                                                    <template v-else>
                                                        {{ $t('purchase.searchUser') }}
                                                    </template>
                                                </template>
                                                <template v-slot:footer.page-text="items">
                                                    {{ items.pageStart }} - {{ items.pageStop }} {{ $t('components.pagination.of') }} {{ items.itemsLength }}
                                                </template>
                                            </v-data-table>
                                            <DataTableFooter :pagination="usersPagination"/>
                                        </v-card>
                                    </v-container>
                                </v-card-text>
                                <v-divider/>
                                <v-card-actions>
                                    <v-spacer/>
                                    <v-btn
                                        @click="viewDialog = false"
                                        color="dark darken-1"
                                        text
                                    >
                                        {{ $t('miscellaneous.ok') }}
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                        <v-dialog
                            v-model="editDialog"
                            max-width="1000px"
                        >
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    v-on="on"
                                    color="primary"
                                    class="mb-2"
                                    dark
                                >
                                    {{ $t('miscellaneous.add') }}
                                </v-btn>
                            </template>
                            <v-card>
                                <v-card-text class="px-0 pb-0">
                                    <EditToolbar
                                        :formTitle="formTitle"
                                        :closeEditDialog="closeEditDialog"
                                    />
                                    <v-container>
                                        <v-card class="px-4 pb-4">
                                            <v-form
                                                @submit.prevent="save"
                                                method="POST"
                                                ref="form"
                                            >
                                                <RequiredFields/>
                                                <DialogImage
                                                    :image="editedEntity.image"
                                                    :name="editedEntity.name"
                                                />
                                                <v-row
                                                    align="center"
                                                    class="mt-2"
                                                >
                                                    <v-col
                                                        cols="12"
                                                        sm="6"
                                                        class="position-relative"
                                                    >
                                                        <v-text-field
                                                            :value="editedEntity.name"
                                                            @change="v => (editedEntity.name = v)"
                                                            :rules="[required]"
                                                            :label="$t('generalAttributes.name')"
                                                            prepend-icon="mdi-format-title"
                                                        ></v-text-field>
                                                        <span class="required-fields">*</span>
                                                    </v-col>
                                                    <v-col
                                                        cols="12"
                                                        sm="6"
                                                        class="position-relative"
                                                    >
                                                      <v-flex>
                                                        <ImageInput
                                                            :file.sync="file"
                                                            :alreadyHasImage="editedEntity.image"
                                                        />
                                                      </v-flex>
                                                    </v-col>
                                                    <v-col
                                                        cols="12"
                                                        sm="6"
                                                        class="position-relative"
                                                    >
                                                        <v-text-field
                                                            :value="editedEntity.task"
                                                            @change="v => (editedEntity.task = v)"
                                                            :rules="[required]"
                                                            :label="$t('entities.achievements.attributes.task')"
                                                            prepend-icon="short_text"
                                                        ></v-text-field>
                                                        <span class="required-fields">*</span>
                                                    </v-col>
                                                    <v-col
                                                        cols="12"
                                                        sm="3"
                                                        class="position-relative"
                                                    >
                                                        <v-switch
                                                            :input-value="editedEntity.active"
                                                            @change="v => (editedEntity.active = v)"
                                                            :label="$t('entities.achievements.attributes.active')"
                                                            prepend-icon="done"
                                                            inset
                                                        ></v-switch>
                                                    </v-col>
                                                    <v-col
                                                        cols="12"
                                                        sm="6"
                                                        class="position-relative"
                                                    >
                                                        <v-text-field
                                                            :value="editedEntity.points"
                                                            @change="v => (editedEntity.points = v)"
                                                            :rules="[required, integer, min]"
                                                            :label="$tc('entities.achievements.attributes.points', 2)"
                                                            type="number"
                                                            prepend-icon="looks_one"
                                                        >
                                                        </v-text-field>
                                                        <span class="required-fields">*</span>
                                                    </v-col>
                                                    <v-col
                                                        cols="12"
                                                        sm="6"
                                                    >
                                                        <v-select
                                                            :value="editedEntity.group ? editedEntity.group.id : null"
                                                            @change="updateGroup($event)"
                                                            :items="groups"
                                                            :rules="[v => !!v || $t('validation.required')]"
                                                            :menu-props="{ bottom: true, offsetY: true }"
                                                            item-text="name"
                                                            item-value="id"
                                                            class="do-not-apply-transform"
                                                            prepend-inner-icon="category"
                                                            return-object
                                                            solo
                                                        >
                                                            <template v-slot:label>
                                                                {{ $t('entities.achievements.selectGroup') }}
                                                                <span :style="{ color: 'red' }">*</span>
                                                            </template>
                                                            <template v-slot:selection="data">
                                                                {{ $t('entities.achievementGroups.entity') }}: {{ data.item.name }}
                                                            </template>
                                                        </v-select>
                                                    </v-col>
                                                    <v-col
                                                        cols="12"
                                                        class="py-0"
                                                    >
                                                        <v-select
                                                            :value="editedEntity.configuration.settings.type"
                                                            @change="editedEntity.configuration.settings.type = $event"
                                                            :items="types"
                                                            :rules="[v => !!v || $t('validation.required')]"
                                                            :menu-props="{ bottom: true, offsetY: true }"
                                                            item-text="name"
                                                            item-value="id"
                                                            class="do-not-apply-transform"
                                                            prepend-inner-icon="category"
                                                            return-object
                                                            solo
                                                        >
                                                            <template v-slot:label>
                                                                {{ $t(`entities.achievements.type`) }}
                                                                <span :style="{ color: 'red' }">*</span>
                                                            </template>
                                                            <template v-slot:selection="data">
                                                                {{ $t(`entities.achievements.type`) }}:
                                                                <PrimaryChip :text="$t(`entities.achievements.${data.item.toLowerCase()}`)"/>
                                                            </template>
                                                            <template v-slot:item="data">
                                                                <PrimaryChip
                                                                    :text="$t(`entities.achievements.${data.item.toLowerCase()}`)"
                                                                    margin="ml-0"
                                                                />
                                                            </template>
                                                        </v-select>
                                                    </v-col>
                                                    <template v-if="editedEntity.configuration.settings.type === 'RECURRENT' && editedEntity.configuration.settings.period">
                                                        <v-col
                                                            cols="12"
                                                            sm="4"
                                                            class="pb-0"
                                                        >
                                                            <v-select
                                                                :value="editedEntity.configuration.settings.period.inner_type"
                                                                @change="editedEntity.configuration.settings.period.inner_type = $event"
                                                                :items="innerTypes"
                                                                :rules="[v => !!v || $t('validation.required')]"
                                                                :menu-props="{ bottom: true, offsetY: true }"
                                                                item-text="name"
                                                                item-value="id"
                                                                class="do-not-apply-transform"
                                                                prepend-inner-icon="category"
                                                                return-object
                                                                solo
                                                            >
                                                                <template v-slot:label>
                                                                    {{ $t(`entities.achievements.type`) }}
                                                                    <span :style="{ color: 'red' }">*</span>
                                                                </template>
                                                                <template v-slot:selection="data">
                                                                    {{ $t(`entities.achievements.innerType`) }}:
                                                                    <PrimaryChip :text="$t(`entities.achievements.${data.item.toLowerCase()}`)"/>
                                                                </template>
                                                                <template v-slot:item="data">
                                                                    <PrimaryChip :text="$t(`entities.achievements.${data.item.toLowerCase()}`)"/>
                                                                </template>
                                                            </v-select>
                                                        </v-col>
                                                        <v-col
                                                            cols="12"
                                                            sm="3"
                                                            class="pb-0"
                                                        >
                                                            <v-select
                                                                :value="editedEntity.configuration.settings.period.unit"
                                                                @change="editedEntity.configuration.settings.period.unit = $event"
                                                                :items="units"
                                                                :rules="[v => !!v || $t('validation.required')]"
                                                                :menu-props="{ bottom: true, offsetY: true }"
                                                                item-text="name"
                                                                item-value="id"
                                                                class="do-not-apply-transform"
                                                                prepend-inner-icon="category"
                                                                return-object
                                                                solo
                                                            >
                                                                <template v-slot:label>
                                                                    {{ $t(`entities.achievements.unit`) }}
                                                                    <span :style="{ color: 'red' }">*</span>
                                                                </template>
                                                                <template v-slot:selection="data">
                                                                    {{ $t(`entities.achievements.unit`) }}: {{ $t(`miscellaneous.units.${data.item.toLowerCase()}`) }}
                                                                </template>
                                                                <template v-slot:item="data">
                                                                    {{ $t(`miscellaneous.units.${data.item.toLowerCase()}`) }}
                                                                </template>
                                                            </v-select>
                                                        </v-col>
                                                        <v-col
                                                            cols="12"
                                                            sm="2"
                                                        >
                                                            <v-text-field
                                                                :value="editedEntity.configuration.settings.period.count"
                                                                @change="v => (editedEntity.configuration.settings.period.count = v)"
                                                                :rules="[integer, min]"
                                                                :label="$t('entities.achievements.count')"
                                                                type="number"
                                                                prepend-icon="looks_one"
                                                            >
                                                            </v-text-field>
                                                            <span class="required-fields">*</span>
                                                        </v-col>
                                                        <v-col
                                                            cols="12"
                                                            sm="3"
                                                        >
                                                            <v-switch
                                                                :input-value="editedEntity.configuration.settings.period.consecutive"
                                                                @change="v => (editedEntity.configuration.settings.period.consecutive = v)"
                                                                :label="$t('entities.achievements.consecutive')"
                                                                prepend-icon="done"
                                                                inset
                                                            ></v-switch>
                                                            <span class="required-fields">*</span>
                                                        </v-col>
                                                    </template>
                                                    <v-col
                                                        v-for="(value, index) in editedEntity.configuration.settings.conditions"
                                                        :key="`condition-${index}`"
                                                        cols="12"
                                                        class="py-0"
                                                    >
                                                        <v-row align="center">
                                                            <v-col
                                                                v-if="index === 0"
                                                                cols="12"
                                                                class="text-left"
                                                            >
                                                                <h3>
                                                                    {{ $tc('entities.quests.attributes.condition', multipleConditions() ? 2 : 1) }}
                                                                </h3>
                                                            </v-col>
                                                        </v-row>
                                                        <v-card
                                                            v-if="index < 1 ? true : multipleConditions()"
                                                            class="my-4 pa-4"
                                                        >
                                                            <v-row align="center">
                                                                <v-col
                                                                    cols="12"
                                                                    sm="6"
                                                                    class="position-relative"
                                                                >
                                                                    <v-select
                                                                        :value="editedEntity.configuration.settings.conditions[index].context ? editedEntity.configuration.settings.conditions[index].context.id : null"
                                                                        @change="editedEntity.configuration.settings.conditions[index].context = $event"
                                                                        :items="editedEntity.group && editedEntity.group.id ? editedEntity.group.contexts : []"
                                                                        :rules="[v => !!v || $t('validation.required')]"
                                                                        :menu-props="{ bottom: true, offsetY: true }"
                                                                        item-text="strategy"
                                                                        item-value="id"
                                                                        class="do-not-apply-transform"
                                                                        prepend-inner-icon="category"
                                                                        return-object
                                                                        solo
                                                                    >
                                                                        <template v-slot:label>
                                                                            {{ $t('entities.achievements.selectContext') }}
                                                                            <span :style="{ color: 'red' }">*</span>
                                                                        </template>
                                                                        <template v-slot:selection="data">
                                                                            {{ $tc('entities.achievements.context', 1) }}: {{ data.item.strategy }}
                                                                        </template>
                                                                    </v-select>
                                                                </v-col>
                                                                <v-col
                                                                    cols="12"
                                                                    :sm="index === 0 ? 6 : 5"
                                                                    class="position-relative"
                                                                >
                                                                    <v-text-field
                                                                        :value="editedEntity.configuration.settings.conditions[index].value"
                                                                        @change="v => (editedEntity.configuration.settings.conditions[index].value = v)"
                                                                        :rules="[required, integer, min]"
                                                                        :label="$t('entities.achievements.value')"
                                                                        type="number"
                                                                        prepend-icon="looks_one"
                                                                    ></v-text-field>
                                                                    <span class="required-fields">*</span>
                                                                </v-col>
                                                                <v-col
                                                                    v-if="index !== 0"
                                                                    cols="12"
                                                                    sm="1"
                                                                    class="mb-2 pl-0 pr-4"
                                                                >
                                                                    <v-btn
                                                                        @click="editedEntity.configuration.settings.conditions.splice(index, 1)"
                                                                        color="error"
                                                                        rounded
                                                                    >
                                                                        X
                                                                    </v-btn>
                                                                </v-col>
                                                            </v-row>
                                                        </v-card>
                                                    </v-col>
                                                    <v-col
                                                        v-if="multipleConditions()"
                                                        cols="12"
                                                    >
                                                        <v-row
                                                            justify="center"
                                                            align="center"
                                                        >
                                                            <v-col
                                                                cols="auto"
                                                                sm="12"
                                                                class="text-center"
                                                            >
                                                                <v-btn
                                                                    @click="addCondition()"
                                                                    color="primary"
                                                                >
                                                                    {{ $t('entities.achievements.addCondition') }}
                                                                </v-btn>
                                                            </v-col>
                                                        </v-row>
                                                    </v-col>
                                                </v-row>
                                                <ValidationErrors
                                                    v-if="validationErrors.length"
                                                    :errors="validationErrors"
                                                    class="mt-6"
                                                />
                                            </v-form>
                                        </v-card>
                                    </v-container>
                                </v-card-text>
                                <EditCardActions
                                    :save="save"
                                    :saving="saving"
                                    :closeEditDialog="closeEditDialog"
                                />
                            </v-card>
                        </v-dialog>
                        <v-dialog
                            v-model="deleteDialog"
                            max-width="1000px"
                        >
                            <v-card>
                                <v-card-text class="px-0 pb-0">
                                    <DeleteToolbar
                                        :text="$t('entities.achievements.deleteEntity')"
                                        :closeDeleteDialog="closeDeleteDialog"
                                    />
                                    <v-container>
                                        <DialogImage
                                            :image="toDeleteEntity.image"
                                            :name="toDeleteEntity.name"
                                        />
                                        <v-card>
                                            <v-simple-table
                                                class="do-not-apply-hover"
                                                dense
                                            >
                                                <template v-slot:default>
                                                    <tbody>
                                                        <tr>
                                                            <td>{{ $t('generalAttributes.name') }}</td>
                                                            <td class="font-weight-bold">
                                                                {{ toDeleteEntity.name }}
                                                            </td>
                                                            <td>{{ $t('entities.achievements.attributes.active') }}</td>
                                                            <td class="font-weight-bold">
                                                                <Boolean :boolean="toDeleteEntity.active"/>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>{{ $t('entities.achievements.attributes.task') }}</td>
                                                            <td
                                                                colspan="3"
                                                                class="font-weight-bold"
                                                            >
                                                                {{ toDeleteEntity.task }}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </template>
                                            </v-simple-table>
                                        </v-card>
                                    </v-container>
                                </v-card-text>
                                <DeleteCardActions
                                    :deleteEntity="deleteEntity"
                                    :deleting="deleting"
                                    :closeDeleteDialog="closeDeleteDialog"
                                />
                            </v-card>
                        </v-dialog>
                    </v-toolbar>
                </template>
                <template v-slot:item.name="{ item }">
                    {{ item.name }}
                </template>
                <template v-slot:item.points="{ item }">
                    <PrimaryChip 
                        :text="item.points.toString()"
                        margin="ml-0"
                    />
                </template>
                <template v-slot:item.active="{ item }">
                    <Boolean :boolean="item.active"/>
                </template>
                <template v-slot:item.action="{ item }">
                    <v-tooltip
                        color="transparent"
                        top
                    >
                        <template v-slot:activator="{ on }">
                            <v-btn
                                @click="openViewDialog(item)"
                                v-on="on"
                                :disabled="loadingView"
                                class="glow ml-2"
                                color="secondary"
                                depressed
                                x-small
                                fab
                            >
                                <template v-if="loadingView">
                                    <ProgressCircular color="secondary"/>
                                </template>
                                <template v-else>
                                    <v-icon
                                        class="white--text"
                                        small
                                    >
                                        pageview
                                    </v-icon>
                                </template>
                            </v-btn>
                        </template>
                        <v-card class="elevation-2 px-4 py-2">
                            <span>{{ $t('entities.achievements.viewEntity') }}</span>
                        </v-card>
                    </v-tooltip>
                    <EditButton
                        :text="$t('entities.achievements.editEntity')"
                        :item="item"
                        :openEditDialog="openEditDialog"
                    />
                    <DeleteButton
                        :text="$t('entities.achievements.deleteEntity')"
                        :item="item"
                        :openDeleteDialog="openDeleteDialog"
                    />
                </template>
                <template v-slot:no-data>
                    <NoData
                        :pagination="pagination"
                        :typing="typing"
                        :fetchEntities="fetchEntities"
                    />
                </template>
            </v-data-table>
            <DataTableFooter
                :pagination="pagination"
                :loading="loading"
            />
        </v-card>
        <Snackbar
            :show.sync="snackbar.show"
            :color="snackbar.color"
            :message="snackbar.message"
        />
    </div>
</template>

<script>

import Vue from 'vue';
import Boolean from '@/components/Boolean.vue';
import PrimaryChip from '@/components/PrimaryChip.vue';
import ProgressCircular from '@/components/ProgressCircular.vue';
import NoData from '@/components/crud/NoData.vue';
import ImageInput from '@/components/ImageInput.vue';
import GlobalButton from '@/components/GlobalButton.vue';
import RefreshButton from '@/components/crud/RefreshButton.vue';
import AchievableConfiguration from "@/components/AchievableConfiguration";
import EditButton from '@/components/crud/edit/EditButton.vue';
import EditToolbar from '@/components/crud/edit/EditToolbar.vue';
import EditCardActions from '@/components/crud/edit/EditCardActions.vue';
import DeleteButton from '@/components/crud/delete/DeleteButton.vue';
import DeleteToolbar from '@/components/crud/delete/DeleteToolbar.vue';
import DeleteCardActions from '@/components/crud/delete/DeleteCardActions.vue';
import DialogImage from '@/components/DialogImage';
import RequiredFields from '@/components/crud/RequiredFields.vue';
import DataTableHeader from '@/components/crud/DataTableHeader.vue';
import DataTableSkeletonLoader from '@/components/crud/DataTableSkeletonLoader.vue';
import DataTableFooter from '@/components/crud/DataTableFooter.vue';
import RowsPerPage from '@/components/crud/RowsPerPage.vue';
import ValidationErrors from '@/components/crud/ValidationErrors.vue';
import Snackbar from '@/components/Snackbar.vue';
import Achievement from '@/models/competitions/Achievement';
import AchievementGroup from '@/models/competitions/AchievementGroup';
import User from "@/models/users/User";
import FilesApi from '@/services/misc/FilesApi';

import required from '@/mixins/rules/required';
import convertDate from '@/mixins/convert-date';
import convertCase from '@/mixins/convert-case';
import thousandSeparator from '@/mixins/thousand-separator';
import { axiosCatch } from '@/mixins/crud/methods/axios-catch.js';
import { showSnackbar } from "@/mixins/crud/methods/show-snackbar";
import { paginationWatch } from '@/mixins/crud/watch/pagination.js';
import { editDialog } from '@/mixins/crud/watch/edit-dialog.js';
import { deleteDialog } from '@/mixins/crud/watch/delete-dialog.js';
import { deleteEntity } from '@/mixins/crud/methods/delete-entity.js';

export default Vue.extend({
    components: {
        AchievableConfiguration,
        PrimaryChip,
        ProgressCircular,
        Boolean,
        NoData,
        ImageInput,
        GlobalButton,
        RefreshButton,
        DataTableHeader,
        DataTableSkeletonLoader,
        DataTableFooter,
        RowsPerPage,
        ValidationErrors,
        EditToolbar,
        EditButton,
        EditCardActions,
        DeleteToolbar,
        DeleteButton,
        DeleteCardActions,
        DialogImage,
        RequiredFields,
        Snackbar,
    },

    mixins: [
        convertDate,
        axiosCatch,
        showSnackbar,
        paginationWatch,
        editDialog,
        deleteDialog,
        deleteEntity,
        convertCase,
        thousandSeparator,
        required,
    ],

    data: () => ({
        initialLoad: true,
        loading: false,
        loadingView: false,
        saving: false,
        deleting: false,
        searchingUser: false,
        typing: false,
        apiURI: process.env.VUE_APP_ACHIEVEMENTS_BASE_URI,
        route: 'achievements',
        entity: 'achievements',
        entities: [],
        groups: [],
        types: ['SIMPLE', 'AND', 'OR', 'RECURRENT'],
        innerTypes: ['SIMPLE', 'AND', 'OR'],
        units: ['DAY', 'WEEK', 'MONTH'],
        selectedSearchAttributes: ['Name'],
        validationErrors: [],
        pagination: {
            search: '',
            current_page: 1,
            from: 1,
            last_page: 0,
            path: '',
            per_page: 10,
            to: 0,
            total: 0
        },
        options: {
            page: 1,
            itemsPerPage: 10,
            sortBy: ['id'],
            sortDesc: ['DESC'],
            groupBy: [],
            groupDesc: [],
            mustSort: false,
            multiSort: false
        },
        usersPagination: {
            search: '',
            current_page: 1,
            from: 1,
            last_page: 0,
            path: '',
            per_page: 10,
            to: 0,
            total: 0
        },
        userOptions: {
            page: 1,
            itemsPerPage: 10,
            sortBy: ['id'],
            sortDesc: ['DESC'],
            groupBy: [],
            groupDesc: [],
            mustSort: false,
            multiSort: false
        },
        userSelectedSearchAttributes: ['Username'],
        viewDialog: false,
        editDialog: false,
        deleteDialog: false,
        editedIndex: -1,
        file: null,
        image: null,
        editedEntity: {
            id: null,
            name: '',
            task: '',
            image: '',
            points: 0,
            active: 1,
            configuration: {
                settings: {
                    conditions: [
                        {
                            value: 0,
                            context: {}
                        }
                    ],
                    period: {
                        inner_type: "SIMPLE",
                        unit: "DAY",
                        count: 0,
                        consecutive: 0
                    },
                    type: "SIMPLE",
                },
            },
            users: []
        },
        defaultEntity: {
            id: null,
            name: '',
            task: '',
            image: '',
            points: 0,
            active: 1,
            configuration: {
                settings: {
                    conditions: [
                        {
                            value: 0,
                            context: {}
                        }
                    ],
                    period: {
                        inner_type: "SIMPLE",
                        unit: "DAY",
                        count: 0,
                        consecutive: false
                    },
                    type: "SIMPLE",
                },
            },
            users: []
        },
        toDeleteEntity: {
            id: null,
            name: '',
            task: '',
            image: '',
            points: 0,
            active: 1,
            configuration: {
                settings: {
                    conditions: [
                        {
                            value: 0,
                            context: {}
                        }
                    ],
                    period: {
                        inner_type: "SIMPLE",
                        unit: "DAY",
                        count: 0,
                        consecutive: false
                    },
                    type: "SIMPLE",
                },
            },
            users: []
        }
    }),

    computed: {
        headers() {
            return [
                { text: this.$t('generalAttributes.name'), align: 'left', value: 'name' },
                { text: this.$t('entities.achievements.attributes.task'), align: 'left', value: 'task' },
                { text: this.$tc('entities.achievements.attributes.points', 2), align: 'left', value: 'points' },
                { text: this.$t('entities.achievements.attributes.active'), align: 'left', value: 'active' },
                { text: this.$t('miscellaneous.actions'), value: 'action', sortable: false }
            ];
        },
        achievedHeaders() {
            return [
                { text: this.$t('entities.achievements.userId'), align: 'left', value: 'user_id', sortable: false },
                { text: this.$t('entities.users.attributes.username'), align: 'left', value: 'username', sortable: false },
                { text: this.$t('entities.achievements.attributes.progress'), align: 'left', value: 'progress.value', sortable: false },
                { text: this.$t('miscellaneous.createdAt'), align: 'left', value: 'created_at', sortable: false },
                { text: this.$t('entities.achievements.attributes.progressUpdatedAt'), align: 'left', value: 'progress.updated_at', sortable: false },
            ];
        },
        searchAttributes() {
            return [
                { label: this.$t('generalAttributes.name'), value: 'Name' },
                { label: this.$t('entities.achievements.attributes.task'), value: 'Task' },
            ]
        },
        userSearchAttributes() {
            return [
                { label: this.$t('entities.users.attributes.username'), value: 'Username' },
            ]
        },
        formTitle() {
            return this.editDialog ? (this.$t(`entities.${this.entity}.${this.editedIndex === -1 ? 'new' : 'edit'}Entity`)) : '';
        },
        integer() {
            return v => !v || ((!isNaN(v) && parseInt(Number(v)) == v && !isNaN(parseInt(v, 10))) || this.$t('validation.integer'));
        },
        min() {
            return v => (v >= 0) || this.$t('validation.min', { amount: 0 });
        },
    },

    watch: {
        viewDialog(val) {
            if (!val) {
                this.editedIndex = -1;
                this.editedEntity = {...this.defaultEntity};
                this.editedEntity.users = [];
                this.usersPagination = {
                    search: '',
                    current_page: 1,
                    from: 1,
                    last_page: 0,
                    path: '',
                    per_page: 10,
                    to: 0,
                    total: 0
                };
            }
        },
        'usersPagination.per_page': function() {
            if (this.usersPagination.current_page !== 1) {
                this.usersPagination.current_page = 1;
            } else {
                this.getAchievementUsers(
                    this.editedEntity.id,
                    `&size=${this.usersPagination.per_page}&${this.formatUserSelectedSearchAttributes()}&page=${this.usersPagination.current_page}`
                );
            }
        },
        'usersPagination.search': function() {
            this.searchingUser = true;
            if (this.debounce) {
                clearTimeout(this.debounce)
            }
            this.debounce = setTimeout(() => {
                this.editedEntity.users = [];
                this.usersPagination.current_page = 1;
                this.usersPagination.from = 1;
                this.usersPagination.last_page = 0;
                this.usersPagination.path = '';
                this.usersPagination.per_page = 10;
                this.usersPagination.to = 0;
                this.usersPagination.total = 0;
                if (this.usersPagination.search) {
                    if (this.usersPagination.current_page !== 1) {
                        this.usersPagination.current_page = 1;
                    } else {
                        this.getAchievementUsers(
                            this.editedEntity.id,
                            `&size=${this.usersPagination.per_page}&${this.formatUserSelectedSearchAttributes()}&page=${this.usersPagination.current_page}`
                        );
                    }
                } else {
                    this.getAchievementUsers(this.editedEntity.id);
                }
                this.searchingUser = false;
            }, 250);
        },
        'usersPagination.current_page': function() {
            if (this.options.sortBy.length) {
                const sortBy = this.options.sortBy[0];
                const sortDirection = this.options.sortDesc[0] ? 'DESC' : 'ASC';
                this.getAchievementUsers(
                    this.editedEntity.id,
                    `&size=${this.usersPagination.per_page}&${this.formatUserSelectedSearchAttributes()}&page=${this.usersPagination.current_page}&sort=${sortBy},${sortDirection}`
                );
            } else {
                this.getAchievementUsers(
                    this.editedEntity.id,
                    `&size=${this.usersPagination.per_page}&${this.formatUserSelectedSearchAttributes()}&page=${this.usersPagination.current_page}`
                );
            }
        },
    },

    mounted() {
        this.refresh();
    },

    methods: {
        async fetchEntities() {
            try {
                const append = this.setParams();
                this.loading = true;
                const response = await Achievement.api.get(append);
                const data = response.data;
                this.entities = data.content.map(AchievementDTO => new Achievement(AchievementDTO));
                this.pagination.current_page = data.pageable.page_number + 1;
                this.pagination.per_page = data.pageable.page_size;
                this.pagination.total = data.total_elements;
                this.pagination.last_page = data.total_pages;
                this.pagination.from = data.pageable.offset + 1;
                this.pagination.to = this.pagination.from + data.number_of_elements - 1;
            } catch (e) {
                console.warn(`Achievements API failed.`);
                console.log(e);
            } finally {
                this.initialLoad = false;
                this.loading = false;
            }
        },
        async fetchGroups() {
            try {
                this.loading = true;
                const response = await AchievementGroup.api.get('size=100');
                this.groups = response.data.content.map(AchievementGroupDTO => new AchievementGroup(AchievementGroupDTO));
            } catch (e) {
                console.warn('Achievement Groups API failed.');
                console.log(e);
            } finally {
                this.loading = false;
            }
        },
        async save() {
            try {
                this.saving = true;

                if (!this.$refs.form.validate()) {
                    return;
                }

                if (this.file) {
                    await FilesApi.upload('achievements', this.file)
                        .then((response) => {
                            this.file = null;
                            if (!response || response.status !== 200) {
                                this.showSnackbar('error', this.$t('miscellaneous.fileUploadFailed'));
                                return;
                            }
                            this.image = response.data.link;
                        }).catch(() => {
                            this.showSnackbar('error', this.$t('miscellaneous.fileUploadFailed'));
                        });
                }

                const data = {
                    group_id: this.editedEntity.group.id,
                    name: this.editedEntity.name,
                    active: this.editedEntity.active ? 1 : 0,
                    task: this.editedEntity.task,
                    points: this.editedEntity.points,
                    configuration: {}
                };

                if (this.editedEntity.configuration.settings) {
                    data.configuration.settings = {
                        type: this.editedEntity.configuration.settings.type
                    };

                    if (this.editedEntity.configuration.settings.conditions) {
                        if (this.multipleConditions()) {
                            data.configuration.settings.conditions = this.editedEntity.configuration.settings.conditions.map(function(condition) {
                                const conditionData = {
                                    context: {
                                        id: condition.context.id,
                                        strategy: condition.context.strategy,
                                        data: condition.context.data,
                                    },
                                    value: condition.value,
                                };
                                if (condition.id) {
                                    conditionData.id = condition.id;
                                }
                                return conditionData;
                            });
                        } else {
                            const condition = this.editedEntity.configuration.settings.conditions[0];
                            const conditionData = {
                                context: {
                                    id: condition.context.id,
                                    strategy: condition.context.strategy,
                                    data: condition.context.data,
                                },
                                value: condition.value,
                            };
                            if (condition.id) {
                                conditionData.id = condition.id;
                            }
                            data.configuration.settings.conditions = [conditionData];
                        }
                    }

                    if (this.editedEntity.configuration.settings.type === 'RECURRENT') {
                        data.configuration.settings.period = {
                            unit: this.editedEntity.configuration.settings.period.unit,
                            innerType: this.editedEntity.configuration.settings.period.inner_type,
                            count: this.editedEntity.configuration.settings.period.count,
                            consecutive: this.editedEntity.configuration.settings.period.consecutive ? 1 : 0
                        };
                    }
                }

                if (this.image) {
                    data.image = this.image;
                }

                if (this.editedIndex > -1) {
                    await Achievement.api.update(this.editedEntity.id, data)
                        .then(() => {
                            if (this.pagination.search.length) {
                                this.pagination.search = '';
                            } else {
                                this.fetchEntities();
                            }
                            this.showSnackbar('success', this.$t('entities.achievements.notifications.update'));
                            this.closeEditDialog();
                            this.image = null;
                        })
                        .catch(error => {
                            this.axiosCatch(error);
                        });
                } else {
                    await Achievement.api.create(data)
                        .then(() => {
                            if (this.pagination.search.length) {
                                this.pagination.search = '';
                            } else {
                                this.fetchEntities();
                            }
                            this.showSnackbar('success', this.$t('entities.achievements.notifications.creation'));
                            this.closeEditDialog();
                            this.image = null;
                        }).catch(error => {
                            this.axiosCatch(error);
                        });
                }
            } catch (error) {
                console.warn(`Achievement Save API failed.`);
                console.log(error);
            } finally {
                this.saving = false;
            }
        },
        async openViewDialog(entity) {
            this.loadingView = true;
            this.editedIndex = this.entities.indexOf(entity);
            this.editedEntity = {...entity};
            await this.getAchievementUsers(entity.id);
            this.viewDialog = true;
            this.loadingView = false;
        },
        async getAchievementUsers(id, append = '') {
            if (id) {
                try {
                    const response = await Achievement.api.getUsers(id, append);
                    const data = response.data;
                    this.$set(this.editedEntity, 'users', data.content.map(UserDTO => new User(UserDTO)));
                    this.usersPagination.current_page = data.pageable.page_number + 1;
                    this.usersPagination.per_page = data.pageable.page_size;
                    this.usersPagination.total = data.total_elements;
                    this.usersPagination.last_page = data.total_pages;
                    this.usersPagination.from = data.pageable.offset + 1;
                    this.usersPagination.to = this.usersPagination.from + data.number_of_elements - 1;
                } catch (e) {
                    console.warn(`Achievement Users API failed.`);
                    console.log(e);
                }
            }
        },
        openEditDialog(entity) {
            this.editedIndex = this.entities.indexOf(entity);
            this.editedEntity = JSON.parse(JSON.stringify(entity));
            if (this.$refs.form) {
                this.$refs.form.resetValidation();
            }
            if (!entity.configuration.settings) {
                this.editedEntity.configuration.settings = JSON.parse(JSON.stringify(
                    {
                        conditions: [
                            {
                                value: 0,
                                context: {}
                            }
                        ],
                        period: {
                            inner_type: "SIMPLE",
                            unit: "DAY",
                            count: 0,
                            consecutive: 0
                        },
                        type: "SIMPLE",
                    },
                ));
            }
            if (!entity.configuration.settings || !entity.configuration.settings.period) {
                this.editedEntity.configuration.settings.period = JSON.parse(JSON.stringify(
                    {
                        inner_type: "SIMPLE",
                        unit: "DAY",
                        count: 0,
                        consecutive: 0
                    }
                ));
            }
            this.editDialog = true;
        },
        closeEditDialog() {
          this.editedEntity = JSON.parse(JSON.stringify(this.defaultEntity));
          this.editedIndex = -1;
          this.file = null;
          this.editDialog = false;
        },
        openDeleteDialog(entity) {
            this.editedIndex = this.entities.indexOf(entity);
            this.toDeleteEntity = {...entity};
            this.deleteDialog = true;
        },
        closeDeleteDialog() {
            this.deleteDialog = false;
            this.toDeleteEntity = {...this.defaultEntity};
            this.editedIndex = -1;
        },
        formatUserSelectedSearchAttributes() {
            if (!this.usersPagination.search.length) {
                return '';
            }

            const searchAttributes = this.userSelectedSearchAttributes.map(attribute => `${attribute.toLowerCase()}=${this.usersPagination.search}`);
            return '&' + searchAttributes.join('&');
        },
        addCondition() {
            this.editedEntity.configuration.settings.conditions.push({
                value: 0,
                context: {}
            });
        },
        multipleConditions() {
            return this.editedEntity.configuration.settings.type === 'AND'
                || this.editedEntity.configuration.settings.type === 'OR'
                || (this.editedEntity.configuration.settings.type === 'RECURRENT'
                    && this.editedEntity.configuration.settings.period
                    && this.editedEntity.configuration.settings.period.inner_type !== 'SIMPLE'
                );
        },
        updateGroup(group) {
            this.$set(this.editedEntity, 'group', group);
        },
        refresh() {
            this.fetchEntities();
            this.fetchGroups();
        }
    }
});

</script>


<style lang="scss">

.position-relative {
    position: relative;
}

.required-fields {
    position: absolute;
    right: 13px;
    top: 15px;
    color: red;
}

.achievements-users-table {
    .v-data-table__empty-wrapper td {
        padding-top: 20px !important;
        padding-bottom: 20px !important;
    }
}

.active-checkmark {
    position: absolute !important;
    bottom: 7px;
    left: 5px;
}

</style>
