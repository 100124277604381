const formatTime = {
    methods: {
        /**
         * Translates seconds into human readable format of seconds, minutes, hours, days, and years
         * 
         * @param  {number} seconds The number of seconds to be processed
         * @return {string}         The phrase describing the amount of time
         */
        fromSecondsToHumanReadableFormat(seconds: number): string {
            const levels = [
                [Math.floor(seconds / 31536000), 'years'],
                [Math.floor((seconds % 31536000) / 86400), 'days'],
                [Math.floor(((seconds % 31536000) % 86400) / 3600), 'hours'],
                [Math.floor((((seconds % 31536000) % 86400) % 3600) / 60), 'minutes'],
                [(((seconds % 31536000) % 86400) % 3600) % 60, 'seconds'],
            ];
            
            let formatted = '';
        
            for (let i = 0, max = levels.length; i < max; i++) {
                if (levels[i][0] === 0) {
                    continue;
                }
                const unit = levels[i][1].toString();
                formatted += ' ' + levels[i][0] + ' ' + (levels[i][0] === 1 ? unit.substring(0, unit.length - 1) : unit);
            }

            return formatted.trim();
        },
    }
};

export default formatTime;