export default {
    'primary': '#284b76',
    'secondary': '#607d8b',
    'accent': '#4caf50',
    'error': '#d32f2f',
    'warning': '#ff9800',
    'info': '#009688',
    'success': '#007E33',
    'first-gradient-level': '#284b76',
    'second-gradient-level': '#203b5c',
    'third-gradient-level': '#203b5c',
    'navigation-drawer-active-list-item': '#203b5c',
    'navigation-drawer-active-group': '#568fd2',
    'main-background': '#f0f2f6',
    'primary-text': '#9e9e9e',
    'dark-text': '#000000',
};