import MobileNotificationsApi from '@/services/social/MobileNotificationsApi';

export interface MobileNotificationInterface {
    id: bigint;
    title: string;
}

export class MobileNotificationDTO implements MobileNotificationInterface {
    id = 0n;
    title = '';
}

export default class MobileNotification extends MobileNotificationDTO {
    public static api = MobileNotificationsApi;

    constructor(DTO: MobileNotificationDTO){
        super();
        Object.assign(this, DTO);
    }
}