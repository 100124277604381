import { AxiosResponse } from 'axios';
import axios from '@/services/axios';

export default abstract class WarehousesApi {

    public static route = process.env.VUE_APP_SHOP_BASE_URI;
    public static entity = 'warehouses';

    public static async get(resources: number, params: string): Promise<AxiosResponse> {
        return await axios.get(`${this.route}/${this.entity}?resources=${resources}${params}`);
    }

    public static async update(data: object): Promise<AxiosResponse> {
        return await axios.post(`${this.route}/${this.entity}`, data);
    }

    public static async attachVariants(data: object): Promise<AxiosResponse> {
        return await axios.post(`${this.route}/${this.entity}/attach-variants`, data);
    }

    public static async detachVariants(data: object): Promise<AxiosResponse> {
        return await axios.post(`${this.route}/${this.entity}/detach-variants`, data);
    }
}
