import { app } from '@/main';
import store from "../store";
import updateTheme from '@/mixins/update-theme';

export default function (): void {
    if (typeof app !== 'undefined') {
        app.$emit('update:loggedIn', false);
        app.$emit('update:drawer', null);
    }
    store.commit('setAuthenticated', false);
    store.commit('setArena', null);
    store.commit('setSelectedCashRegisterLabel', null);
    const preferredArena = localStorage.getItem('preferred_arena');
    localStorage.clear();
    if (preferredArena) {
        localStorage.setItem('preferred_arena', preferredArena);
    }
    app.$i18n.locale = process.env.VUE_APP_I18N_LOCALE || 'en';
    updateTheme(process.env.VUE_APP_THEME || 'Classic', app);
}