import i18n from '../i18n';

const formatDay = {
    methods: {
        formatDay(date: string): string {
            const d = new Date(date);
            const days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
            const dayIndex = d.getDay();
            return i18n.t(`miscellaneous.days.${days[dayIndex]}`).toString();
        },
    }
};

export default formatDay;