import { AxiosResponse } from 'axios';
import axios from '@/services/axios';

export default abstract class SessionsApi {

    public static route = process.env.VUE_APP_ARENA_HUB_BASE_URI;
    public static entity = 'sessions';

    public static async get(params: string): Promise<AxiosResponse> {
        return await axios.get(`${this.route}/${this.entity}?${params}`);
    }

    public static async getByArena(arenaId: bigint, params: string): Promise<AxiosResponse> {
        return await axios.get(`${this.route}/arenas/${arenaId}/legacy/${this.entity}?${params}`);
    }

    public static async create(arenaId: bigint, data: object): Promise<AxiosResponse> {
        return await axios.post(`${this.route}/arenas/${arenaId}/${this.entity}`, data);
    }

    public static async destroy(arenaId: bigint, id: bigint, data: object): Promise<AxiosResponse> {
        return await axios.post(`${this.route}/arenas/${arenaId}/${this.entity}/${id}/stop`, data);
    }

    public static async simulateSession(arenaId: bigint, data: object): Promise<AxiosResponse> {
        return await axios.post(`${this.route}/arenas/${arenaId}/session-simulations/simulate`, data)
    }

    public static async getSessionsGames(arenaId: bigint, params: string): Promise<AxiosResponse> {
        return await axios.get(`${this.route}/arenas/${arenaId}/session-games?${params}`);
    }
}
