

import Vue from 'vue';

export default Vue.extend({
    props: {
        alreadyHasImage: {
            type: String
        },
        file: {
            type: File
        },
        disabled: {
            type: Boolean,
            default: false
        },
        label: {
            type: String,
            default: 'miscellaneous.image'
        },
        alreadyHasLabel: {
            type: String,
            default: 'miscellaneous.newImage'
        },
        rules: {
            type: Array,
            default: () => []
        }
    },
});

