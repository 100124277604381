

import Vue from 'vue';

import convertCase from '@/mixins/convert-case';

export default Vue.extend({
    props: ['route', 'text', 'icon'],

    mixins: [
        convertCase,
    ],

    computed: {
        arenaName() {
            return this.$store.getters.arena ? `/${this.titleToKebabCase(this.$store.getters.arena.name)}` : '';
        }
    },
})

