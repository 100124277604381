<template>
    <div>
        <Generated
            :date="dateString"
            :refresh="refresh"
            :loading="loading || loadingArena"
        />
        <v-row>
            <v-col
                cols="12"
                sm="3"
                class="px-3"
            >
                <EmployeeButton
                    :text="$t('employeeDashboard.makeAPurchase')"
                    :route="`${arenaName}/purchase`"
                    color="primary"
                    icon="mdi-cash"
                />
            </v-col>
            <v-col
                cols="12"
                sm="3"
                class="px-4"
            >
                <v-btn
                    @click="addNewUser"
                    color="error darken-2"
                    x-large
                    block
                    dark
                >
                    <v-icon left>person_add</v-icon>
                    {{ $t('employeeDashboard.addNewUser') }}
                </v-btn>
            </v-col>
            <v-col
                cols="12"
                sm="3"
                class="px-4"
            >
                <EmployeeButton
                    :text="$t('employeeDashboard.viewGames')"
                    :route="`${arenaName}/games`"
                    color="yellow darken-2"
                    icon="games"
                />
            </v-col>
            <v-col
                cols="12"
                sm="3"
                class="px-3"
            >
                <EmployeeButton
                    :text="$t('employeeDashboard.viewApps')"
                    :route="`${arenaName}/apps`"
                    color="green darken-2"
                    icon="apps"
                />
            </v-col>
        </v-row>
        <v-row 
            v-if="$store.getters.arena"
            class="mb-4"
        >
            <v-col
                cols="12"
                sm="6"
            >
                <CardStat
                    :loading="loadingArena"
                    :value="currentArenaBonus"
                    :text="$t('employeeDashboard.currentArenaBonus')"
                />
            </v-col>
            <v-col
                cols="12"
                sm="6"
            >
                <CardStat
                    :loading="loadingArena"
                    :value="`${nextArenaBonus} (${date.getHours() + 1}h)`"
                    :text="$t('employeeDashboard.nextArenaBonus')"
                    color="orange"
                    width="100px"
                />
            </v-col>
        </v-row>
        <div :key="updated">
            <v-row>
                <v-col
                    cols="12"
                    class="my-2 text-left"
                >
                    <v-card class="elevation-2">
                        <CurrentlyPlaying deviceType="playstations"/>
                    </v-card>
                    <v-card class="elevation-2 mt-8">
                        <CurrentlyPlaying deviceType="computers"/>
                    </v-card>
                </v-col>
            </v-row>
            <v-row>
                <v-col
                    cols="12"
                    class="my-2 text-left"
                >
                    <v-card>
                        <InvoicesDataTable
                            v-if="dateString"
                            :key="dateString"
                            :initialLoad.sync="initialLoad"
                            :loading.sync="loading"
                            :title="$t('employeeDashboard.todaysInvoices.title', { employee: user.username })"
                        />
                    </v-card>
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>

import Vue from 'vue';
import CardStat from '@/components/CardStat.vue';
import EmployeeButton from '@/components/EmployeeButton.vue';
import Generated from '@/components/Generated.vue';
import InvoicesDataTable from '@/components/invoices/InvoicesDataTable';
import CurrentlyPlaying from '@/components/CurrentlyPlaying.vue';
import Arena from '@/models/setup/Arena';

import convertCase from '@/mixins/convert-case';
import user from '@/mixins/crud/computed/user';
import isRole from '@/mixins/crud/computed/isRole';
import formatDate from '@/mixins/format-date';

export default Vue.extend({
    components: {
        InvoicesDataTable,
        CardStat,
        EmployeeButton,
        Generated,
        CurrentlyPlaying
    },

    mixins: [
        convertCase,
        user,
        isRole,
        formatDate,
    ],

    data: () => ({
        date: new Date(),
        dateString: null,
        initialLoad: true,
        loading: false,
        loadingArena: false,
        updated: 0,
        currentArenaBonus: '0%',
        nextArenaBonus: '0%',
    }),

    computed: {
        arenaName() {
            return this.$store.getters.arena ? `/${this.titleToKebabCase(this.$store.getters.arena.name)}` : '';
        }
    },

    mounted() {
        this.refresh(false);
    },

    methods: {
        async fetchArena() {
            try {
                this.loadingArena = true;
                const response = await Arena.api.read(this.$store.getters.arena.id);
                const arenaBonuses = response.data.bonuses;
                if (arenaBonuses.length) {
                    const currentHour = this.date.getHours();
                    let nextHour = this.date.getHours() + 1;
                    let day = (this.date.getDay() || 7) - 1;

                    this.currentArenaBonus = arenaBonuses[day][currentHour] + '%'
                    if (currentHour === 23) {
                        if (day == 6) {
                            day = 0;
                        } else {
                            day += 1;
                        }
                        nextHour = 0;
                    }
                    this.nextArenaBonus = arenaBonuses[day][nextHour] + '%'
                }
            } catch (e) {
                console.warn(`Arena API failed.`);
                console.log(e);
            } finally {
                this.loadingArena = false;
            }
        },
        addNewUser() {
            const params = {
                openDialog: true
            };

            if (this.$store.getters.arena) {
                params.arena = this.arenaName.substring(1)
            }

            this.$router.push({
                name: 'navigationDrawer.users.users',
                params: params
            });
        },
        refresh(manual = true) {
            if (this.$store.getters.arena) {
                this.fetchArena();
            }
            this.dateString = this.formatDate(new Date(), true, true);
            if (manual) {
                this.updated++; // Force component re-render on manual refresh
            }
        },
    }
})

</script>
