import WarehousesApi from '@/services/misc/WarehousesApi';

export interface WarehouseInterface {
    id: bigint;
    name: string;
}

export class WarehouseDTO implements WarehouseInterface {
    id = 0n;
    name = '';
}

export default class Warehouse extends WarehouseDTO {
    static api = WarehousesApi;

    constructor(DTO: WarehouseDTO){
        super();
        Object.assign(this, DTO);
    }
}