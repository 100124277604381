import SessionsApi from '@/services/main/SessionsApi';

export interface SessionInterface {
    id: bigint;
    active: boolean;
}

export class SessionDTO implements SessionInterface {
    id = 0n;
    active = false;
}

export default class Session extends SessionDTO {
    static api = SessionsApi;

    constructor(DTO: SessionDTO){
        super();
        Object.assign(this, DTO);
    }
}