

import Vue from 'vue';

export default Vue.extend({
    props: {
        pagination: {
            type: Object
        },
        typing: {
            type: Boolean,
            default: false
        },
        fetchEntities: {
            type: Function
        }
    },
});

