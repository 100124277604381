export interface TopicInterface {
    id: bigint;
    name: string;
}

export class TopicDTO implements TopicInterface {
    id = 0n;
    name = '';
}

export default class Topic extends TopicDTO {
    constructor(DTO: TopicDTO){
        super();
        Object.assign(this, DTO);
    }
}