import InventoryItemTypesApi from '@/services/configuration/InventoryItemTypesApi';

export interface InventoryItemInterface {
    id: bigint;
}

export class InventoryItemDTO implements InventoryItemInterface {
    id = 0n;
}

export default class InventoryItem extends InventoryItemDTO {
    static api = InventoryItemTypesApi;

    constructor(DTO: InventoryItemDTO){
        super();
        Object.assign(this, DTO);
    }
}
