

import Vue from 'vue';

export default Vue.extend({
    props: ['status'],

    methods: {
        getColorOrIcon(value = 'color') {
            if (this.status === 'VERIFIED' || this.status === 'APPROVED') {
                return value === 'color' ? 'success' : 'mdi-checkbox-marked-circle';
            } else if (this.status === 'UNVERIFIED' || this.status === 'REJECTED') {
                return value === 'color' ? 'error' : 'mdi-close-circle';
            } else if (this.status === 'PENDING') {
                return value === 'color' ? 'yellow darken-4' : 'mdi-timer-sand';
            } else {
                return value === 'color' ? 'primary' : '';
            }
        }
    }
})

