import CartsApi from '@/services/shop/CartsApi';

export interface CartInterface {
    id: bigint;
    arena_id: bigint;
    user_id: bigint;
    order_id: bigint;
    items: Array<object>;
    gross: number;
    checkout_step: number;
    expires_at: string;
}

export class CartDTO implements CartInterface {
    id = 0n;
    arena_id = 0n;
    user_id = 0n;
    order_id = 0n;
    items = [];
    gross = 0;
    checkout_step = 0;
    expires_at = '';
}

export default class Cart extends CartDTO {
    static api = CartsApi;

    constructor(DTO: CartDTO){
        super();
        Object.assign(this, DTO);
    }
}