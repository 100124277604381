import SubscriptionsApi from '@/services/users/SubscriptionsApi';

export interface SubscriptionInterface {
    id: bigint;
    arena_id: bigint;
    user_id: bigint;
    bonus: bigint;
    duration: bigint;
    daily_reward: string;
    image: string;
    name: string;
    created_at: string;
    expires_at: string;
}

export class SubscriptionDTO implements SubscriptionInterface {
    id = 0n;
    user_id = 0n;
    arena_id = 0n;
    bonus = 0n;
    duration = 0n;
    daily_reward = '';
    image = '';
    name = '';
    created_at = '';
    expires_at = '';
}

export default class Subscription extends SubscriptionDTO {
    static api = SubscriptionsApi;

    constructor(DTO: SubscriptionDTO){
        super();
        Object.assign(this, DTO);
    }
}
