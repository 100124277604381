const isRole = {

    computed: {
        isAdmin() {
            const user = localStorage.getItem('user');
            if (user) {
                return JSON.parse(decodeURIComponent(escape(window.atob(user)))).roles.some(function(role: string) {
                    return role === 'admin';
                });
            } else {
                return false;
            }
     
        },
        isManager() {
            const user = localStorage.getItem('user');
            if (user) {
                return JSON.parse(decodeURIComponent(escape(window.atob(user)))).roles.some(function(role: string) {
                    return role === 'manager';
                });
            } else {
                return false;
            }
        },
    },

};

export default isRole;