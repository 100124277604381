import SettingsApi from '@/services/users/SettingsApi';

export interface SettingInterface {
    id: bigint;
    namespace: string;
    key: string;
    default_value: string;
}

export class SettingDTO implements SettingInterface {
    id = 0n;
    namespace = '';
    key = '';
    default_value = '';
}

export default class Setting extends SettingDTO {
    static api = SettingsApi;

    constructor(DTO: SettingDTO){
        super();
        Object.assign(this, DTO);
    }
}