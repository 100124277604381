import ArenaAppsApi from '@/services/configuration/ArenaAppsApi';

export interface ArenaAppInterface {
    id: string;
    name: string;
    active: boolean;
    args: string;
    description: string;
    icon: string;
    image: string;
    path: string;
    procs: object;
    tags: object;
}

export class ArenaAppDTO implements ArenaAppInterface {
    id = '';
    name = '';
    active = false;
    args = '';
    description = '';
    icon = '';
    image = '';
    path = '';
    procs = {};
    tags = {};
}

export default class ArenaApp extends ArenaAppDTO {
    static api = ArenaAppsApi;

    constructor(DTO: ArenaAppDTO){
        super();
        Object.assign(this, DTO);
    }
}