<template>
    <div>
        <v-card class="elevation-2">
            <DataTableHeader
                :pagination="pagination"
                :searchAttributes="searchAttributes"
                :selectedSearchAttributes.sync="selectedSearchAttributes"
                :disabled="loading"
            />
            <DataTableSkeletonLoader :loading="initialLoad"/>
            <v-data-table
                v-show="!initialLoad"
                :headers="headers"
                :items="entities"
                :dense="$vuetify.breakpoint.xs && !loading"
                :loading="loading"
                :loading-text="$t('components.noData.fetchingEntries')"
                :options.sync="options"
                :server-items-length="pagination.total"
                :items-per-page="pagination.per_page"
                :header-props="{ sortIcon: 'arrow_circle_up', sortByText: $t('miscellaneous.sortBy') }"
                :class="{ 'mobile-empty-datatable' : $vuetify.breakpoint.xsOnly }"
                hide-default-footer
            >
                <template v-slot:top>
                    <v-toolbar flat>
                        <v-toolbar-title>{{ $t('entities.ranks.title') }}</v-toolbar-title>
                        <v-divider
                            class="mx-4"
                            inset
                            vertical
                        ></v-divider>
                        <small class="mr-2">{{ pagination.total }}</small>
                        <RefreshButton
                            :refresh="refresh"
                            :loading="loading"
                        />
                        <v-spacer></v-spacer>
                        <v-dialog
                            v-model="editDialog"
                            max-width="1000px"
                        >
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    v-if="isAdmin"
                                    v-on="on"
                                    color="primary"
                                    class="mb-2"
                                    dark
                                >
                                    {{ $t('miscellaneous.add') }}
                                </v-btn>
                            </template>
                            <v-card>
                                <v-card-text class="px-0 pb-0">
                                    <EditToolbar
                                        :formTitle="formTitle"
                                        :closeEditDialog="closeEditDialog"
                                    />
                                    <v-container>
                                        <v-card class="px-4 pb-4">
                                            <v-form
                                                @submit.prevent="save"
                                                method="POST"
                                                ref="form"
                                            >
                                                <RequiredFields/>
                                                <DialogImage
                                                    :image="editedEntity.image"
                                                    :name="editedEntity.name"
                                                />
                                                <v-row align="center">
                                                    <v-col
                                                        cols="12"
                                                        sm="6"
                                                        class="position-relative"
                                                    >
                                                        <v-flex>
                                                            <ImageInput
                                                                :file.sync="imageFile"
                                                                :alreadyHasImage="editedEntity.image"
                                                            />
                                                        </v-flex>
                                                    </v-col>
                                                    <v-col
                                                        cols="12"
                                                        sm="6"
                                                        class="position-relative"
                                                    >
                                                        <v-flex>
                                                            <ImageInput
                                                                :file.sync="iconFile"
                                                                :alreadyHasImage="editedEntity.icon"
                                                                :label="'miscellaneous.icon'"
                                                                :alreadyHasLabel="'miscellaneous.newIcon'"
                                                            />
                                                        </v-flex>
                                                    </v-col>
                                                    <v-col
                                                        cols="12"
                                                        sm="6"
                                                        class="position-relative"
                                                    >
                                                        <v-text-field
                                                            :value="editedEntity.name"
                                                            @change="v => (editedEntity.name = v)"
                                                            :rules="[required]"
                                                            :label="$t('generalAttributes.name')"
                                                            prepend-icon="mdi-format-title"
                                                        ></v-text-field>
                                                        <span class="required-fields">*</span>
                                                    </v-col>
                                                    <v-col
                                                        cols="12"
                                                        sm="6"
                                                        class="position-relative pl-5"
                                                    >
                                                        <v-text-field
                                                            :value="editedEntity.bonus"
                                                            @change="v => (editedEntity.bonus = v)"
                                                            :rules="[numeric, between]"
                                                            :label="$t('entities.ranks.attributes.bonus')"
                                                            type="number"
                                                            prepend-icon="mdi-sale"
                                                            append-icon="mdi-percent"
                                                        >
                                                            <template v-slot:append>
                                                                <v-icon
                                                                    small
                                                                    class="mt-1"
                                                                >
                                                                    mdi-percent
                                                                </v-icon>
                                                            </template>
                                                        </v-text-field>
                                                        <span class="required-fields">*</span>
                                                    </v-col>
                                                    <v-col
                                                        cols="12"
                                                        sm="6"
                                                        class="position-relative pl-5"
                                                    >
                                                        <v-text-field
                                                            :value="editedEntity.lower"
                                                            @change="v => (editedEntity.lower = v)"
                                                            :rules="[integer, min]"
                                                            :label="$t('entities.ranks.attributes.lower')"
                                                            type="number"
                                                        >
                                                            <template v-slot:prepend>
                                                                <ExperienceImage class="pt-1"/>
                                                            </template>
                                                        </v-text-field>
                                                        <span class="required-fields">*</span>
                                                    </v-col>
                                                    <v-col
                                                        cols="12"
                                                        sm="6"
                                                        class="position-relative pl-5"
                                                    >
                                                        <v-text-field
                                                            :value="editedEntity.upper"
                                                            @change="v => (editedEntity.upper = v)"
                                                            :rules="[integer, min]"
                                                            :label="$t('entities.ranks.attributes.upper')"
                                                            type="number"
                                                        >
                                                            <template v-slot:prepend>
                                                                <ExperienceImage class="pt-1"/>
                                                            </template>
                                                        </v-text-field>
                                                        <span class="required-fields">*</span>
                                                    </v-col>
                                                    <v-col
                                                        cols="12"
                                                        sm="6"
                                                        class="position-relative pl-5"
                                                    >
                                                        <v-text-field
                                                            :value="editedEntity.gain"
                                                            @change="v => (editedEntity.gain = v)"
                                                            :rules="[integer, min]"
                                                            :label="$t('entities.ranks.attributes.gain')"
                                                            type="number"
                                                        >
                                                            <template v-slot:prepend>
                                                                <ExperienceImage class="pt-1"/>
                                                            </template>
                                                            <TextFieldInfoTooltip
                                                                :text="$t('entities.ranks.gainInfo')"
                                                                slot="append"
                                                            />
                                                        </v-text-field>
                                                        <span class="required-fields">*</span>
                                                    </v-col>
                                                    <v-col
                                                        cols="12"
                                                        sm="6"
                                                        class="position-relative pl-5"
                                                    >
                                                        <v-text-field
                                                            :value="editedEntity.decay"
                                                            @change="v => (editedEntity.decay = v)"
                                                            :rules="[numeric, min]"
                                                            :label="$t('entities.ranks.attributes.decay')"
                                                            type="number"
                                                        >
                                                            <template v-slot:prepend>
                                                                <ExperienceImage class="pt-1"/>
                                                            </template>
                                                            <TextFieldInfoTooltip
                                                                :text="$t('entities.ranks.decayInfo')"
                                                                slot="append"
                                                            />
                                                        </v-text-field>
                                                        <span class="required-fields">*</span>
                                                    </v-col>
                                                    <v-col
                                                        cols="12"
                                                        class="position-relative"
                                                    >
                                                        <v-switch
                                                            :input-value="editedEntity.active"
                                                            @change="v => (editedEntity.active = v)"
                                                            :label="$t('entities.ranks.attributes.active')"
                                                            prepend-icon="done"
                                                            inset
                                                        ></v-switch>
                                                    </v-col>
                                                </v-row>
                                                <ValidationErrors
                                                    v-if="validationErrors.length"
                                                    :errors="validationErrors"
                                                    class="mt-6"
                                                />
                                            </v-form>
                                        </v-card>
                                    </v-container>
                                </v-card-text>
                                <EditCardActions
                                    :save="save"
                                    :saving="saving"
                                    :closeEditDialog="closeEditDialog"
                                />
                            </v-card>
                        </v-dialog>
                        <v-dialog
                            v-model="deleteDialog"
                            max-width="1000px"
                        >
                            <v-card>
                                <v-card-text class="px-0 pb-0">
                                    <DeleteToolbar
                                        :text="$t('entities.ranks.deleteEntity')"
                                        :closeDeleteDialog="closeDeleteDialog"
                                    />
                                    <v-container>
                                        <DialogImage
                                            :image="toDeleteEntity.image"
                                            :name="toDeleteEntity.name"
                                        />
                                        <v-card>
                                            <v-simple-table
                                                class="do-not-apply-hover"
                                                dense
                                            >
                                                <template v-slot:default>
                                                    <tbody>
                                                        <tr>
                                                            <td>{{ $t('generalAttributes.name') }}</td>
                                                            <td class="font-weight-bold">
                                                                {{ toDeleteEntity.name }}
                                                            </td>
                                                            <td>{{ $t('miscellaneous.icon') }}</td>
                                                            <td class="font-weight-bold">
                                                                <template v-if="toDeleteEntity.icon">
                                                                    <img
                                                                        :src="toDeleteEntity.icon"
                                                                        :alt="toDeleteEntity.name"
                                                                        width="20px"
                                                                    />
                                                                </template>
                                                                <template v-else>
                                                                    /
                                                                </template>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>{{ $t('entities.ranks.attributes.experience') }}</td>
                                                            <td
                                                                v-if="deleteDialog"
                                                                class="font-weight-bold"
                                                            >
                                                                {{ addThousandSeparator(toDeleteEntity.lower) }} - {{ addThousandSeparator(toDeleteEntity.upper) }}
                                                                <ExperienceImage/>
                                                            </td>
                                                            <td>{{ $t('entities.ranks.attributes.active') }}</td>
                                                            <td class="font-weight-bold">
                                                                <Boolean :boolean="toDeleteEntity.active"/>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>{{ $t('entities.ranks.attributes.gain') }}</td>
                                                            <td
                                                                colspan="3"
                                                                class="font-weight-bold"
                                                            >
                                                                {{ toDeleteEntity.gain }}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </template>
                                            </v-simple-table>
                                        </v-card>
                                    </v-container>
                                </v-card-text>
                                <DeleteCardActions
                                    :deleteEntity="deleteEntity"
                                    :deleting="deleting"
                                    :closeDeleteDialog="closeDeleteDialog"
                                />
                            </v-card>
                        </v-dialog>
                    </v-toolbar>
                </template>
                <template v-slot:item.lower="{ item }">
                    {{ addThousandSeparator(item.lower) }} - {{ addThousandSeparator(item.upper) }}
                    <ExperienceImage/>
                </template>
                <template v-slot:item.gain="{ item }">
                    {{ addThousandSeparator(item.gain) }}
                    <ExperienceImage/>
                </template>
                <template v-slot:item.bonus="{ item }">
                    {{ item.bonus }}%
                </template>
                <template v-slot:item.active="{ item }">
                    <Boolean :boolean="item.active"/>
                </template>
                <template v-slot:item.action="{ item }">
                    <EditButton
                        v-if="isAdmin"
                        :text="$t('entities.ranks.editEntity')"
                        :item="item"
                        :openEditDialog="openEditDialog"
                    />
                    <DeleteButton
                        v-if="isAdmin"
                        :text="$t('entities.ranks.deleteEntity')"
                        :item="item"
                        :openDeleteDialog="openDeleteDialog"
                    />
                </template>
                <template v-slot:no-data>
                    <NoData
                        :pagination="pagination"
                        :typing="typing"
                        :fetchEntities="fetchEntities"
                    />
                </template>
            </v-data-table>
            <DataTableFooter
                :pagination="pagination"
                :loading="loading"
            />
        </v-card>
        <Snackbar
            :show.sync="snackbar.show"
            :color="snackbar.color"
            :message="snackbar.message"
        />
    </div>
</template>

<script>

import Vue from 'vue';
import Boolean from '@/components/Boolean.vue';
import NoData from '@/components/crud/NoData.vue';
import ImageInput from '@/components/ImageInput.vue';
import RefreshButton from '@/components/crud/RefreshButton.vue';
import ExperienceImage from '@/components/ExperienceImage.vue';
import EditButton from '@/components/crud/edit/EditButton.vue';
import EditToolbar from '@/components/crud/edit/EditToolbar.vue';
import EditCardActions from '@/components/crud/edit/EditCardActions.vue';
import DeleteButton from '@/components/crud/delete/DeleteButton.vue';
import DeleteToolbar from '@/components/crud/delete/DeleteToolbar.vue';
import DeleteCardActions from '@/components/crud/delete/DeleteCardActions.vue';
import RequiredFields from '@/components/crud/RequiredFields.vue';
import TextFieldInfoTooltip from '@/components/TextFieldInfoTooltip';
import DialogImage from '@/components/DialogImage';
import DataTableHeader from '@/components/crud/DataTableHeader.vue';
import DataTableSkeletonLoader from '@/components/crud/DataTableSkeletonLoader.vue';
import DataTableFooter from '@/components/crud/DataTableFooter.vue';
import ValidationErrors from '@/components/crud/ValidationErrors.vue';
import Snackbar from '@/components/Snackbar.vue';
import Rank from '@/models/users/Rank';
import FilesApi from "@/services/misc/FilesApi";

import thousandSeparator from '@/mixins/thousand-separator';
import isRole from '@/mixins/crud/computed/isRole';
import required from '@/mixins/rules/required';
import { axiosCatch } from '@/mixins/crud/methods/axios-catch.js';
import { showSnackbar } from "@/mixins/crud/methods/show-snackbar";
import { paginationWatch } from '@/mixins/crud/watch/pagination.js';
import { editDialog } from '@/mixins/crud/watch/edit-dialog.js';
import { deleteDialog } from '@/mixins/crud/watch/delete-dialog.js';
import { deleteEntity } from '@/mixins/crud/methods/delete-entity.js';

export default Vue.extend({
    components: {
        Boolean,
        NoData,
        ImageInput,
        TextFieldInfoTooltip,
        RefreshButton,
        ExperienceImage,
        DataTableHeader,
        DataTableSkeletonLoader,
        DataTableFooter,
        ValidationErrors,
        EditToolbar,
        EditButton,
        EditCardActions,
        DialogImage,
        DeleteToolbar,
        DeleteButton,
        DeleteCardActions,
        RequiredFields,
        Snackbar,
    },

    mixins: [
        deleteEntity,
        axiosCatch,
        showSnackbar,
        paginationWatch,
        editDialog,
        deleteDialog,
        isRole,
        thousandSeparator,
        required,
    ],

    data: () => ({
        initialLoad: true,
        loading: false,
        saving: false,
        deleting: false,
        typing: false,
        apiURI: process.env.VUE_APP_CORE_BASE_URI,
        entity: 'ranks',
        route: 'ranks',
        entities: [],
        selectedSearchAttributes: ['Name'],
        validationErrors: [],
        pagination: {
            search: '',
            current_page: 1,
            from: 1,
            last_page: 0,
            path: '',
            per_page: 10,
            to: 0,
            total: 0
        },
        options: {
            sortBy: ['lower'],
            sortDesc: []
        },
        editDialog: false,
        deleteDialog: false,
        editedIndex: -1,
        imageFile: null,
        iconFile: null,
        image: null,
        icon: null,
        editedEntity: {
            id: '',
            name: '',
            image: '',
            icon: '',
            lower: null,
            upper: null,
            gain: null,
            decay: null,
            bonus: null,
            active: false,
        },
        defaultEntity: {
            id: '',
            name: '',
            image: '',
            icon: '',
            lower: null,
            upper: null,
            gain: null,
            decay: null,
            bonus: null,
            active: false,
        },
        toDeleteEntity: {
            id: '',
            name: '',
            image: '',
            icon: '',
            lower: null,
            upper: null,
            gain: null,
            decay: null,
            bonus: null,
            active: false,
        }
    }),

    computed: {
        headers() {
            const headers = [
                { text: this.$t('generalAttributes.name'), align: 'left', value: 'name' },
                { text: this.$t('entities.ranks.attributes.experience'), align: 'left', value: 'lower' },
                { text: this.$t('entities.ranks.attributes.gain'), align: 'left', value: 'gain' },
                { text: this.$t('entities.ranks.attributes.decay'), align: 'left', value: 'decay' },
                { text: this.$t('entities.ranks.attributes.bonus'), align: 'left', value: 'bonus' },
                { text: this.$t('entities.ranks.attributes.active'), align: 'left', value: 'active' },
            ];

            if (this.isAdmin) {
                headers.push({ text: this.$t('miscellaneous.actions'), value: 'action', sortable: false })
            }

            return headers;
        },
        searchAttributes() {
            return [
                { label: this.$t('generalAttributes.name'), value: 'Name' },
            ]
        },
        formTitle() {
            return this.editDialog ? (this.$t(`entities.${this.entity}.${this.editedIndex === -1 ? 'new' : 'edit'}Entity`)) : '';
        },
        between() {
            return v => (v >= 0 && v <= 100) || this.$t('validation.between', { from: 0, to: 100 });
        },
        integer() {
            return v => (!isNaN(v) && parseInt(Number(v)) == v && !isNaN(parseInt(v, 10))) || this.$t('validation.integer');
        },
        numeric() {
            return v  => (!isNaN(v)) || this.$t('validation.numeric');
        },
        min() {
            return v => (v >= 0) || this.$t('validation.min', { amount: 0 });
        },
    },

    mounted() {
        this.refresh();
    },

    methods: {
        async fetchEntities(append = `&size=${this.pagination.per_page}&page=${this.pagination.current_page}&sort=lower,ASC`) {
            try {
                this.loading = true;
                const response = await Rank.api.get(append);
                const data = response.data;
                this.entities = data.content.map(RankDTO => new Rank(RankDTO));
                this.pagination.current_page = data.pageable.page_number + 1;
                this.pagination.per_page = data.pageable.page_size;
                this.pagination.total = data.total_elements;
                this.pagination.last_page = data.total_pages;
                this.pagination.from = data.pageable.offset + 1;
                this.pagination.to = this.pagination.from + data.number_of_elements - 1;
            } catch (e) {
                console.warn(`Ranks API failed.`);
                console.log(e);
            } finally {
                this.initialLoad = false;
                this.loading = false;
            }
        },
        async save() {
            try {
                this.saving = true;

                if (!this.$refs.form.validate()) {
                    return;
                }

                if (this.imageFile) {
                    await FilesApi.upload('core', this.imageFile)
                        .then((response) => {
                            this.imageFile = null;
                            if (!response || response.status !== 200) {
                                this.showSnackbar('error', this.$t('miscellaneous.fileUploadFailed'));
                                return;
                            }
                            this.image = response.data.link;
                        })
                        .catch(() => {
                            this.showSnackbar('error', this.$t('miscellaneous.fileUploadFailed'));
                        });
                }

                if (this.iconFile) {
                    await FilesApi.upload('core', this.iconFile)
                        .then((response) => {
                            this.iconFile = null;
                            if (!response || response.status !== 200) {
                                this.showSnackbar('error', this.$t('miscellaneous.fileUploadFailed'));
                                return;
                            }
                            this.icon = response.data.link;
                        })
                        .catch(() => {
                            this.showSnackbar('error', this.$t('miscellaneous.fileUploadFailed'));
                        });
                }

                const data = {
                    name: this.editedEntity.name,
                    lower: parseInt(this.editedEntity.lower),
                    upper: parseInt(this.editedEntity.upper),
                    gain: parseInt(this.editedEntity.gain),
                    decay: this.editedEntity.decay,
                    bonus: this.editedEntity.bonus,
                    active: this.editedEntity.active ? 1 : 0,
                    image: this.image || this.editedEntity.image,
                    icon: this.icon || this.editedEntity.icon,
                };

                if (this.editedIndex > -1) {
                    await Rank.api.update(this.editedEntity.id, data)
                        .then(() => {
                            if (this.pagination.search.length) {
                                this.pagination.search = '';
                            } else {
                                this.fetchEntities();
                            }
                            this.closeEditDialog();
                            this.showSnackbar('success', this.$t('entities.ranks.notifications.update'));
                        })
                        .catch(error => {
                            this.axiosCatch(error);
                        });
                } else {
                    await Rank.api.create(data)
                        .then(() => {
                            if (this.pagination.search.length) {
                                this.pagination.search = '';
                            } else {
                                this.fetchEntities();
                            }
                            this.closeEditDialog();
                            this.showSnackbar('success', this.$t('entities.ranks.notifications.creation'));
                        })
                        .catch(error => {
                            this.axiosCatch(error);
                        });
                }
            } catch (error) {
                console.warn(`Rank Save API failed.`);
                console.log(error);
            } finally {
                this.saving = false;
            }
        },
        openEditDialog(entity) {
            this.editedIndex = this.entities.indexOf(entity);
            this.editedEntity = {...entity};
            if (this.$refs.form) {
                this.$refs.form.resetValidation();
            }
            this.editDialog = true;
        },
        closeEditDialog() {
            this.editedEntity = JSON.parse(JSON.stringify(this.defaultEntity));
            this.editedIndex = -1;
            this.image = null;
            this.icon = null;
            this.editDialog = false;
        },
        openDeleteDialog(entity) {
            this.editedIndex = this.entities.indexOf(entity);
            this.toDeleteEntity = {...entity};
            this.deleteDialog = true;
        },
        closeDeleteDialog() {
            this.deleteDialog = false;
            this.toDeleteEntity = {...this.defaultEntity};
            this.editedIndex = -1;
        },
        refresh() {
            this.fetchEntities();
        }
    }
});

</script>


<style>

.position-relative {
    position: relative;
}

.required-fields {
    position: absolute;
    right: 13px;
    top: 15px;
    color: red;
}

</style>
