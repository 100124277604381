import RolesApi from '@/services/users/RolesApi';

export interface RoleInterface {
    id: bigint;
    name: string;
    is_default: boolean;
    created_at: string;
    updated_at: string | null;
}

export class RoleDTO implements RoleInterface {
    id = 0n;
    name = '';
    is_default = false;
    created_at = '';
    updated_at = null;
}

export default class Role extends RoleDTO {
    public static api = RolesApi;

    constructor(DTO: RoleDTO){
        super();
        Object.assign(this, DTO);
    }
}
